import React from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';


const BreadcrumbsCustom = (props) => {
  return (
    <Breadcrumbs 
      maxItems={ props.maxItems ? props.maxItems : '' } 
      separator={<div style={{ color: props?.color ? props.color : ''}}>/</div>} 
      aria-label="breadcrumb"
    >
    {props?.listElements.map((element, index) => (
        <Link 
          key={index} 
          underline={ !element.active ? 'always' : 'none' } 
          color={ !element.active ? props?.color ? props.color : 'primary' : props.color ? props?.color :'inherit' } 
          href='#'
          onClick={() => { if (!element.active) { props.handleClick(element) } }}
          style={element.active ? {pointerEvents: 'none'} : {}} 
        >
          {element.label}
        </Link>
    ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsCustom;