import React, { useEffect, useState, forwardRef, useCallback, useContext } from 'react';
import { Stack} from "react-bootstrap";

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';

import momentDate from 'moment';

import { isCustomerRole } from 'components/config/Roles';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsBL } from "./ContextMissionDetailsBL";


const MissionDetailsBLView = forwardRef((props, ref) => {

  //init config
  const {update} = useContext(ContextBlock);
  const {blRef} = useContext(ContextMissionDetails);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //mission
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  //init receiver data
  const { rowsBLView, setRowsBLView } = useContext(ContextMissionDetailsBL);
  const { expandedRowsBLs, setExpandedRowsBLs } = useContext(ContextMissionDetailsBL);

  const handleExpandedRowsBLsChange = useCallback((rowsBLsIds) => {
    setExpandedRowsBLs(rowsBLsIds);
  }, []);

  //context
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  const {loadingTypesOfIntervention} = useContext(ContextMissionDetailsBL);
  const {typesOfIntervention, setTypesOfIntervention} = useContext(ContextMissionDetailsBL);
  
  const {loadBeansType} = useContext(ContextMissionDetailsBL);
  const {beansTypes, setBeansType} = useContext(ContextMissionDetailsBL);

  //handle
  const { handleClickBlockEdit } = useContext(ContextBlock);


  //useEffect
  useEffect(() => {
    if (beansTypes.length <= 0) {
      loadBeansType();
    }
    if (typesOfIntervention.length <= 0) {
      loadingTypesOfIntervention();
    }
  }, []);


  //xGrid
  const columnsBL : GridColDef[] = [
    { field: 'country', headerName: 'Place of signature', editable: false, sortable: true, flex:1 }
  ];
  const getBolDetailsHeight = useCallback(() => 250, []);
  const getBolsDetailPanelContent = (rowProp) => {
    var data = rowProp.row;
    var columns = [
      { field: 'bl_date', headerName: 'BL Date', editable: false, sortable: true, type:'date', minWidth:50, flex:1,
        valueFormatter: params => {if(params?.value != null) return momentDate(params?.value).format("DD MMM YY")}},
      { field: 'bol', headerName: 'Bill of lading number', editable: false, sortable: false, minWidth:120, flex:1},
      { field: 'type_of_intervention', headerName: 'Type of intervention', editable: false, sortable: false, minWidth:150, flex:1,
        valueFormatter: params => {
          return !!params?.value != false 
            ? typesOfIntervention[(typesOfIntervention.findIndex(obj => obj.value === (typeof params?.value == 'object' ? params?.value.id : Number(params?.value))))].label 
            : "-"
      }},
      { field: 'quantity', headerName: 'Cocoa - to be sold (kg)', editable: false, sortable: false, type: 'number', flex:1},
      { field: 'beans_type', headerName: 'Type of beans', editable: false, sortable: false, minWidth:150, flex:1,
        valueFormatter: params => {
          return !!params?.value != false 
            ? beansTypes[(beansTypes.findIndex(obj => obj.value === (typeof params?.value == 'object' ? params?.value.id : Number(params?.value))))].label 
            : "-"
      }},
      { field: 'deadline_date', headerName: 'Deadline', editable: false, sortable: false, type: 'date', minWidth:50, flex:1,
        valueFormatter: params => {if(params?.value != null) return momentDate(params?.value).format("DD MMM YY")}},
      { field: 'delivery_date', headerName: 'Delivery (cocoa)', editable: false, sortable: false, type: 'date', minWidth:50, flex:1,
        valueFormatter: params => {if(params?.value != null) return momentDate(params?.value).format("DD MMM YY")}},
      { field: 'smv', headerName: 'SMV (EUR)', editable: false, sortable: false, width:130, type: 'number'},
      { field: 'note', headerName: 'Comment', editable: false, sortable: false, flex:2}
    ];
    
    return (
      <Stack className="datagridBol">
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          density="compact"
          columns={columns}
          rows={data.bols}
          sx={{ flex: 1 }}
          hideFooter
        />
      </Stack>
    );
  }


  return (
    <div>
      <div className="lineContent">
        <FormControl sx={{ m: 1, width: '100%', height: 1 }} size="small" id="datagrid-bols">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            rows={rowsBLView}
            columns={columnsBL}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            getDetailPanelHeight={getBolDetailsHeight}
            getDetailPanelContent={getBolsDetailPanelContent}
            detailPanelExpandedRowIds={expandedRowsBLs}
            onDetailPanelExpandedRowIdsChange={handleExpandedRowsBLsChange}
            hideFooter
          />
        </FormControl>
      </div>

      {(update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode) && 
      <div className="block_button">
        <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(blRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
      </div>
      }
    </div>
  );
});

export default MissionDetailsBLView;
