import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {checkSecurity} from 'components/config/Security';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import environnement from 'components/config//Environnement';

import logo from 'imgs/logo.svg';
import 'styles/Login.css';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'api/axios';
const LOGIN_URL = 'user/login';

const Login = () => {

  // CHECK SECURITY
  const navigate = useNavigate();

  useEffect(() => {
    checkSecurity(navigate);
  }, []);
  // END SECURITY

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');

  /////// BOUCHON
  //ROLE 200
  //const [user, setUser] = useState('samuel@houseofcoding.fr');
  //const [pwd, setPwd] = useState('12345678');

  //ROLE 100
  //const [user, setUser] = useState('vincent@houseofcoding.fr');
  //const [pwd, setPwd] = useState('WindowsCestDeLaBombe');
  /////// END BOUCHON

  //const [user, setUser] = useState('');
  //const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          const response = await axios.post(LOGIN_URL,
              JSON.stringify({ email:user, password:pwd }),
              {
                  headers: { 'Content-Type': 'application/json' },

              }
          );
          const accessToken = response?.data?.access_token;
          const role = response?.data?.user.role;
          const userName = response?.data?.user.firstname;

          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("role", role);
          localStorage.setItem("user", userName);
          localStorage.setItem("userID", response?.data?.user.id);
          if (response?.data?.user.company) localStorage.setItem("id_company", response?.data?.user.company.id_company);
          if (response?.data?.user.company) localStorage.setItem("has_access_subcontractor", response?.data?.user.company.has_access_subcontractor);
          else localStorage.setItem("id_company", null);
          localStorage.setItem("firstname", response?.data?.user.firstname);
          localStorage.setItem("lastname", response?.data?.user.lastname);
          localStorage.setItem("email", user);
          localStorage.setItem("filter", null);

          setUser('');
          setPwd('');

          navigate(from, { replace: true });
      } catch (err) {
          console.log(err);
          if (!err?.response) {
              setErrMsg('No Server Response');
          } else if (err.response?.status === 400) {
              setErrMsg('Missing Username or Password');
          } else if (err.response?.status === 401) {
              setErrMsg('Invalid Username or Password');
          } else if (err.response?.status === 422) {
              setErrMsg('Invalid Username or Password');
          } else {
            setErrMsg('Login Failed');
          }
          errRef.current.focus();
      }
  }

    return (
      <div className="Login">
        <div className="welcome-to-gyptis">Welcome to Gyptis</div>
        <div className="Login-box">
          <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
          {(Number(environnement.id) > 100) &&
          <Stack spacing={1} alignItems="center" sx={{marginTop:'20px'}}>
            <Stack direction="row" spacing={1}>
              <Chip label={environnement.label} color={environnement.color}/>
            </Stack>
          </Stack>
          }
          <div className="Login-formulaire">
            <form onSubmit={handleSubmit}>
              <div ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</div>
              <div className="Login-identifier">Identifier</div>
              <input className="Login-identifier-input" type="text" id="user" ref={userRef} onChange={(e) => setUser(e.target.value)} value={user} required />
              <div className="Login-password">Password</div>
              <input className="Login-password-input" type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required/>
              <div className="Login-forgot-password"><a href="/renewPassword">Forgot Password?</a></div>
              <button className="Login-rectangle-button" onSubmit={handleSubmit}>LOGIN</button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default Login;
