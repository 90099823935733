import React, { useState, forwardRef, useContext, Children } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import ForwardIcon from '@mui/icons-material/Forward';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BlockFormSaveButton from '../../Block/BlockFormSaveButton';
import ShippingInstructionMissionLetter from './ShippingInstructionMissionLetter';
import ShippingInstructionMissionDressing from './ShippingInstructionMissionDressing';
import ShippingInstructionMissionInstruction from './ShippingInstructionMissionInstruction';
import ShippingInstructionCreateMissionPopin from '../Popin/ShippingInstructionCreateMissionPopin';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionMission } from "./ContextShippingInstructionMission";
import { ContextShippingInstructionGeneral } from "../General/ContextShippingInstructionGeneral";

import "styles/Receivers.css";


const ShippingInstructionMissionForm = forwardRef((props, ref) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { missionRef } = useContext(ContextShippingInstruction);
  const { contentRef } = useContext(ContextShippingInstruction);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //data SI
  const { portDepartureId, setPortDepartureId } = useContext(ContextShippingInstructionGeneral);
  
  //data missions
  const { rowsMissions, setRowsMissions } = useContext(ContextShippingInstructionMission);

  const [expandedMissions, setExpandedMissions] = useState([]);
  const [expandedMissionsLetter, setExpandedMissionsLetter] = useState([]);
  const [expandedMissionsDressing, setExpandedMissionsDressing] = useState([]);
  const [expandedMissionsInstruction, setExpandedMissionsInstruction] = useState([]);

  //func
  const { renderStatus } = useContext(ContextShippingInstructionMission);
  //handle
  const { handleExpand } = useContext(ContextShippingInstructionMission);
  const { saveShippingInstructionMission } = useContext(ContextShippingInstructionMission);
  const { handleClickCancel } = useContext(ContextShippingInstructionMission);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await saveShippingInstructionMission(true);
    await handleClickCancel(false);
  };

  //create mission popin
  const [openCreate, setOpenCreate] = useState(false);
  const handleClickCreateMission = async () => {
    if (portDepartureId == "0") {
      //open error poppin
      triggerInfosPopin("Fields mandatory", 'The departure port is mandatory to create a mission', "error");
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setOpenCreate(true);
    }
  }


  return (
    <div>

      {update == 1 && 
      <Box sx={{ '& > button': { m: 1 } }}>
        <LoadingButton
          onClick={handleClickCreateMission}
          loadingIndicator="Creating Mission"
          variant="outlined"
        >
          Create Mission
        </LoadingButton>
      </Box>
      }

      <div className="blocReceiverDiv" id="missionsForm">
        {rowsMissions.length >= 1 && Children.toArray(rowsMissions.map((mission, index) => {
          //generate title
          var missionTitle = mission?.jlb_ref;

          return (
            <div className="receiverDiv">
              <Accordion className="receiverAccordion" expanded={expandedMissions.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissions, setExpandedMissions)}>
                <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                  <div className="receiverIconSpacer"></div>
                  <div className="receiverNameDiv">
                    <div style={{ float: 'left', paddingTop: '3px', paddingRight: '20px' }}>
                      <a onClick={() => navigate('/missionDetails?id=' + mission.id, { replace: true })}>
                        {missionTitle}
                      </a>
                    </div>
                    <div style={{ float: 'left', paddingRight: '5px' }}>
                      {renderStatus(mission?.status?.status)}
                    </div>
                    <div style={{ float: 'left' }}>
                      <IconButton size="small" onClick={() => navigate('/missionDetails?id=' + mission.id, { replace: true })}>
                        <ForwardIcon fontSize="small" />
                      </IconButton>
                    </div>
                    <div className="spacer"></div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="blocReceiverLotDiv">

                    <div className="receiverLotDiv">
                      <Accordion className="receiverAccordion" expanded={expandedMissionsInstruction.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissionsInstruction, setExpandedMissionsInstruction)}>
                        <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                          <div className="receiverIconSpacer"></div>
                          <div className="receiverNameDiv">Instructions</div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <ShippingInstructionMissionInstruction editable={true} idMission={mission.id} />

                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="receiverLotDiv">
                      <Accordion className="receiverAccordion" expanded={expandedMissionsLetter.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissionsLetter, setExpandedMissionsLetter)}>
                        <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                          <div className="receiverIconSpacer"></div>
                          <div className="receiverNameDiv">Letters</div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <ShippingInstructionMissionLetter editable={true} idMission={mission.id} />
                            
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="receiverLotDiv">
                      <Accordion className="receiverAccordion" expanded={expandedMissionsDressing.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissionsDressing, setExpandedMissionsDressing)}>
                        <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                          <div className="receiverIconSpacer"></div>
                          <div className="receiverNameDiv">Dressings</div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <ShippingInstructionMissionDressing editable={true} idMission={mission.id} />
                            
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        }))}

      </div>

      {update == 1 && <ShippingInstructionCreateMissionPopin open={openCreate} setOpen={setOpenCreate} />}

      <BlockFormSaveButton blockRef={missionRef} blockCancel={async () => await handleClickCancel()} blockSave={handleClickSave} notWorkflowSaveButton={true} />

    </div>
  );
});

export default ShippingInstructionMissionForm;
