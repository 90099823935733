import React, { useState, useEffect, createContext, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { ContextBlock } from "context/ContextBlock";

import axios from 'api/axios';


const ContextShippingInstruction = createContext();

const ShippingInstructionProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { initBlock } = useContext(ContextBlock);
  const { actionSave } = useContext(ContextBlock);

  //API URL
  const GETSI = 'shipping_instruction';

  //composant refs
  const { blockListRefs, elmtListRefs } = useContext(ContextBlock);
  //elmt refs
  const contentRef = elmtListRefs.contentRef.ref;
  const fileRef = elmtListRefs.fileRef.ref;
  //block refs
  const generalRef = blockListRefs.generalRef.ref;
  const missionRef = blockListRefs.missionRef.ref;
  const reportRef = blockListRefs.reportRef.ref;
  const blRef = blockListRefs.blRef.ref;
  const noteRef = blockListRefs.noteRef.ref;

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //block config
  const { setStartSave } = useContext(ContextBlock);
  const { setActiveEdit } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //si data
  const { idElement } = useContext(ContextBlock);
  const [idShippingInstruction, setIdShippingInstruction] = useState(idElement);

  //si data
  const [principalsId, setPrincipalsId] = useState(0);
  const [surveyHandler, setSurveyHandler] = useState(0);
  const [contractNumber, setContractNumber] = useState('');
  const [principalsAlias, setPrincipalsAlias] = useState('');

  const [internalComment, setInternalComment] = useState('');

  //composant data
  const [bls, setBLs] = useState([]);
  const [general, setGeneral] = useState({});
  const [reports, setReports] = useState([]);
  const [missions, setMissions] = useState([]);


  //useEffect
  useEffect(() => {
    if (update == 1) {
      if (idShippingInstruction === 0) {
        navigate('/createShippingInstruction', { replace: true });
      } else if (!!actionSave != false) {
        if (actionSave == 1) {
          setSnackBarMessage("Shipping Instruction saved.");
          setSnackBarType("success");
        } else {
          setSnackBarMessage("Problem saving Shipping Instruction.");
          setSnackBarType("error");
        }
        setOpenSnackBar(true);
      }
    }

    // reload variables if id set
    if (idShippingInstruction > 0) {
      //Get details mission
      getShippingInstruction();
    }
  }, [idShippingInstruction]);

  useEffect(() => {
    //block is define
    if (initBlock && idShippingInstruction <= 0 && update !== 1) {
      //set all block on edit
      setActiveEdit();
    }
  }, [initBlock]);


  //function
  const getShippingInstruction = async () => {
    setLoadingUpdate(false);
    try {
      const config = {
        url: GETSI + "?id_shipping_instruction=" + idShippingInstruction,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      };

      const reponseGet = await axios(config);

      //INIT general data
      const gnrlData = structuredClone(reponseGet.data.data);
      delete gnrlData.si_bls;
      delete gnrlData.missions;
      delete gnrlData.si_reports;
      setGeneral(gnrlData);

      //INIT initValues
      //INIT mission data
      setMissions(reponseGet.data.data.missions);

      //INIT report data
      setReports(reponseGet.data.data.si_reports);

      //INIT bill of lading
      setBLs(reponseGet.data.data.si_bls);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingUpdate(true);
    }
  }

  //toogle display on edit by block
  function toogleBlockOnEdit(blockRef, currentEdit, setCurrentEdit) {
    //maj onEdit state
    var blockOnEdit = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === true));
    var newStateEdit = blockOnEdit >= 0 ? true : false;
    if (currentEdit != newStateEdit) {
      setCurrentEdit(newStateEdit);
    }
  }

  const blockRedirect = (actionSave = 0) => {
    navigate('/shippingInstructionDetails?id=' + idShippingInstruction + '&actionSave=' + actionSave, { replace: (update !== 1) });
  }
  const blockGetData = async () => {
    await getShippingInstruction();
  }

  const handleClickSave = (reload = false, button = null) => {
    setLoading(true);
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    if (contractNumber == "" || surveyHandler == "0" || principalsId == "0") {
      //open error poppin
      triggerInfosPopin("Fields required", 'Contract Number, Survey Handler and Principal are required', "error");
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
      setLoading(false);
    } else {
      setStartSave();
    }
  }


  return (
    <ContextShippingInstruction.Provider
      value=
      {{
        //config context
        getShippingInstruction,
        blockRedirect, blockGetData,
        handleClickSave,

        //composant data ref
        contentRef, generalRef, missionRef, reportRef, blRef, fileRef, noteRef,

        //si data
        idShippingInstruction, setIdShippingInstruction,

        principalsId, setPrincipalsId,
        principalsAlias, setPrincipalsAlias,
        surveyHandler, setSurveyHandler,
        contractNumber, setContractNumber,

        //comment
        internalComment, setInternalComment,

        //composant
        bls, setBLs,
        general, setGeneral,
        reports, setReports,
        missions, setMissions,

        //html
        toogleBlockOnEdit
      }}
    >
      {children}
    </ContextShippingInstruction.Provider>
  );
};

export { ShippingInstructionProvider, ContextShippingInstruction };
