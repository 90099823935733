import React, {useState, useContext, useEffect, useRef} from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Accordion from '@mui/material/Accordion';
import FormControl from '@mui/material/FormControl';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import InfosPopin from 'components/page/object/InfosPopin';
import TextFieldCustom from 'components/select/TextFieldCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import MailingListMembersForm from "components/page/mailing/MailingListMembersForm";

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";

import axios from 'api/axios';


export default function MissionDetailsWorkflowMailingCustomise(props) {

  //init config
  const navigate = useNavigate();
  const {update} = useContext(ContextBlock);
  //init composant ref
  const contentRef = useRef(null);

  //API URL
  const MAILINGLIST_URL = 'mailing/list';
  const TEMPLATEEMAIL_URL = 'mission/workflow/notification/email';
  
  //init param
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);

  //init mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  //workflow mailing state
  const [expanded, setExpanded] = useState(['to']);
  const [mailingListId, setMailingListId] = useState(0);
  const [mailingListSelectedId, setMailingListSelectedId] = useState(0);
  const [mailingListSelectedLabel, setMailingListSelectedLabel] = useState('');

  const [templateHTML, setTemplateHTML] = useState('');
  const [templateParams, setTemplateParams] = useState({});
  const [templateSubject, setTemplateSubject] = useState('');
  const [templateParamsHTML, setTemplateParamsHTML] = useState('');
  const [templateContentHTML, setTemplateContentHTML] = useState('');

  //treatment
  const {workflowMailingContent, setWorkflowMailingContent} = useContext(ContextMissionWorkflow);
  const {workflowMailingListContacts, setWorkflowMailingListContacts} = useContext(ContextMissionWorkflow);

  const { triggerInfosPopin } = useContext(ContextBlock);
  const {workflowStep} = useContext(ContextMissionWorkflow);
  const {controlButtons} = useContext(ContextMissionWorkflow);
  const {idButtonWorkflow, setIdButtonWorkflow} = useContext(ContextMissionWorkflow);
  const {workflowMailingCustomiseOpen, setWorkflowMailingCustomiseOpen} = useContext(ContextMissionWorkflow);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  };


  useEffect(() => {
    if (workflowMailingCustomiseOpen) {
      loadWorkflowTemplate();
    }
  }, [workflowMailingCustomiseOpen, idButtonWorkflow]);

  useEffect(() => {
    templateInitParams();
  }, [templateParams]);

  useEffect(() => {
    templateInitContent();
  }, [templateParamsHTML, workflowMailingContent]);

  
  const handleClose = () => {
    setWorkflowMailingCustomiseOpen(false);

    //reinitialize data
    setExpanded(['to']);
    setMailingListId(0);
    setMailingListSelectedId(0);
    setMailingListSelectedLabel('');
    setWorkflowMailingListContacts([]);
  
    setTemplateHTML('');
    setTemplateParams({});
    setTemplateSubject('');
    setTemplateContentHTML('');
    setWorkflowMailingContent('');
  }

  //function for change state of expand
  const handleExpand = (itemId) => () => {
    if (expanded.includes(itemId)) {
      setExpanded(prevExpanded => prevExpanded.filter(id => id !== itemId));
    } else {
      setExpanded(prevExpanded => [...prevExpanded, itemId]);
    }
  };

  function handleAddMailingList() {
    setMailingListId(mailingListSelectedId);
  }

  function handleChangeMailingList(mailingListSelectedId, mailingListSelectedLabel) {
    setMailingListSelectedId(mailingListSelectedId);
    setMailingListSelectedLabel(mailingListSelectedLabel);
  }

  function handleChangeTemplateContent(event) {
    setWorkflowMailingContent(event.target.value);
  }

  function createSelectItem(datas) {
    return { id: datas.id, label: datas.title.toUpperCase().trim(), labelValue: datas.title.toUpperCase().trim() };
  }

  function templateInitParams() {
    var templateReplace = templateHTML;
    //params.customer
    templateReplace = templateReplace.replaceAll('{{params.customer}}', localStorage.getItem('firstname') + ' ' + localStorage.getItem('lastname'));
    //check and delete all auto-espace for preview
    templateReplace = templateReplace.replaceAll('{% autoescape off %}', '');
    templateReplace = templateReplace.replaceAll('{% endautoescape %}', '');
    //dynamic params
    if (Object.keys(templateParams).length > 0) {
      if ('content' in templateParams) delete templateParams.content;
      for (const [key, value] of Object.entries(templateParams)) {
        templateReplace = templateReplace.replaceAll('{{params.'+key+'}}', value === null ? '' : value);
      };
    }
    setTemplateParamsHTML(templateReplace);
  }

  function templateInitContent() {
    var templateReplace = templateParamsHTML;
    templateReplace = templateReplace.replace('{{params.content}}', workflowMailingContent !== '' ? '<p>' + workflowMailingContent.replaceAll(/(?:\r\n|\r|\n)/g, "<br />") + '</p><br/>' : '');
    setTemplateContentHTML(templateReplace);
  }
  
  const loadWorkflowTemplate = async () => {
    try {
      var button = controlButtons.find(obj => obj.id === idButtonWorkflow);
      if (button) {
        var config = {
          url: TEMPLATEEMAIL_URL,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          params: {'id_mission': idMission, 'id_workflow_action': button.trigger_action?.id}
        };
        const response = await axios(config);
        const template = response.data.data;

        setTemplateHTML(template.template);
        setTemplateParams(template.params);
        setTemplateSubject(template.subject);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }
  
  const onConfirm = () => {
    if (workflowMailingListContacts?.length > 0) {
      if (!!props.notClose == false) {
        handleClose();
      }
      workflowStep(true);
    } else {
      //open error poppin
      triggerInfosPopin('Recipients required', 'Recipients list is empty, please add at least one recipient.', 'error');
      contentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }


  return (
    <Modal
      open={workflowMailingCustomiseOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      className="modalPopin info workflow">
      <Box sx={style}>
        <div className="headerPopin" style={{border:"none"}}>
          {templateSubject}
          <span className="closePopin">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        </div>
        <div className="contentPopin" ref={contentRef}>
          <Accordion className="mailingAccordion" expanded={expanded.includes('to')} onChange={handleExpand('to')}>
            <AccordionSummary className="mailingAccordionSummary fullNoMargeBottom" expandIcon={<ExpandMoreIcon/>}>
              <h4>Recipients</h4>
            </AccordionSummary>
            <AccordionDetails>
              <div className="lineContent noJustify noMargeTop fullNoMargeLeft">
                <SelectAutoCustom
                  title="Mailing List"
                  api={MAILINGLIST_URL}
                  apiParam=""
                  apiParamValue=""
                  dataValue={mailingListSelectedLabel}
                  setDataValue={handleChangeMailingList}
                  error={false}
                  setDataAlias=""
                  defaultAlias=""
                  creator={createSelectItem}
                  cached={false}
                  cacheTTL="600"
                  resetCache="1"
                  nosort
                />
                <LoadingButton
                  sx={{height: '40px', marginTop: '8px'}}
                  onClick={handleAddMailingList}
                  variant="outlined"
                  startIcon={!!mailingListSelectedLabel === false ? <ArrowLeftIcon/> : <AddIcon/>}
                  disabled={!!mailingListSelectedLabel === false ? true : false}
                >
                  {!!mailingListSelectedLabel === false ? 'Select a mailing list' : 'Add a mailing list'}
                </LoadingButton>
              </div>
              <MailingListMembersForm
                update={update}
                idMailingList={mailingListId}
                rowsMailingListMembers={workflowMailingListContacts}
                setRowsMailingListMembers={setWorkflowMailingListContacts}
                // Snackbar
                setSnackBarMessage={setSnackBarMessage}
                setSnackBarType={setSnackBarType}
                setOpenSnackBar={setOpenSnackBar}
              />
            </AccordionDetails>
          </Accordion>
          
          <Accordion className="mailingAccordion" expanded={expanded.includes('preview')} onChange={handleExpand('preview')}>
            <AccordionSummary className="mailingAccordionSummary" expandIcon={<ExpandMoreIcon/>}>
              <h4>Preview</h4>
            </AccordionSummary>
            <AccordionDetails dangerouslySetInnerHTML={{__html: templateContentHTML}}>
            </AccordionDetails>
          </Accordion>
        
          <FormControl className="textField-form-comment noMargeLeft" sx={{ marginBottom: '20px !important', width: '100%' }} size="small">
            <TextFieldCustom
              id="standard-multiline-internal-mailing-content"
              label="Add content"
              multiline
              value=""
              onChange={handleChangeTemplateContent}
              variant="standard"
              fullWidth
            />
          </FormControl>

        </div>
        <div className='footerPopin'>
          <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
            <LoadingButton
              onClick={handleClose}
              loadingIndicator="Cancel"
              variant="outlined"
              startIcon={<CloseIcon/>}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={(event) => {
                onConfirm();
              }}
              color="secondary"
              variant="contained"
              startIcon={<SendIcon/>}
            >
              Send
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};
