import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { randomId } from "@mui/x-data-grid-generator";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { DataGridPro, GridColDef, gridVisibleSortedRowIdsSelector, GridFooterContainer, GridFooter, } from "@mui/x-data-grid-pro";

import { checkSecurity } from "components/config/Security";
import ConfirmDialog from 'components/page/object/ConfirmDialog';

import axios from "api/axios";

const MailingListsList = (props) => {
    
    const navigate = useNavigate();
    const GETORDELETEMAILINGLIST_URL = "mailing/list";
    
    const [confirmMailingListDeleteOpen, setConfirmMailingListDeleteOpen] = useState(false);
    const [mailingListToDelete, setMailingListToDelete] = useState("");

    // CHECK SECURITY
    useEffect(() => {
        checkSecurity(navigate, "MailingLists");
        getMailingListsList();
    }, []);
    // END SECURITY

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            width: 30,
            headerAlign: "right",
            align: "right",
        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            minWidth: 500,
        },
        {
            field: "email_count",
            headerName: "Email Count",
            headerAlign: "center",
            align: "center",
            width: 150,
        },
        {
            field: "buttons",
            headerName: "",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit" placement="top">
                        <IconButton 
                            edge="end" 
                            aria-label="edit" 
                            style={{ marginRight: '3px' }} 
                            onClick={(e) => { e.stopPropagation(); handleEditMailingListClick(params) }} 
                            className="eventEnabled"
                        > 
                            <EditIcon/>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                        <IconButton 
                            edge="end" 
                            aria-label="delete" 
                            onClick={(e) => { e.stopPropagation(); handleDeleteMailingList(params) }}
                        > 
                            <DeleteIcon/> 
                        </IconButton>
                        </Tooltip>
                    </>
                )
            }
        }
    ];

    const getMailingListsList = async (e) => {
        try {
            props.data.setLoadingGrid(true);
            const config = {
                headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            };
            var url = GETORDELETEMAILINGLIST_URL;

            const response = await axios.get(url, config);

            var rowsFromApi = [];
            var idRow = 0;

            for (var i = 0; i < response.data.data.length; i++) {
                idRow = rowsFromApi.length;
                rowsFromApi[idRow] = {};

                if (response.data.data[i]?.id) {
                    rowsFromApi[idRow].id = response.data.data[i]?.id;
                }

                if (response.data.data[i]?.title) {
                    rowsFromApi[idRow].title = response.data.data[i]?.title;
                }

                if (response.data.data[i]?.members_count) {
                    rowsFromApi[idRow].email_count = response.data.data[i]?.members_count;
                }
            }
            props.data.setRows(rowsFromApi);
            props.data.setTotalRows(rowsFromApi);

            if (props.data.initFilterMailing) {
                props.data.initFilterMailing(rowsFromApi);
            }
        } catch (err) {
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            } else {
                console.log(err);
            }
        } finally {
            props.data.setLoadingGrid(false);
            props.data.setDataGridLoaded(true);
        }
    };
    
    const deleteMailingList = async () => {
        try {
            props.data.setLoadingGrid(true);
            const config = {
                headers: {
                Authorization: 
                    `Bearer ${localStorage.getItem("accessToken")}`,
                },
                method: "delete",
                data: {
                    id_mailing_list: mailingListToDelete
                }
            };
            var url = GETORDELETEMAILINGLIST_URL;

            const response = await axios(url, config);
            props.data.setRows(props.data.rows.filter((row) => row.id !== mailingListToDelete));
        } catch (err) {
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            } else {
                console.log(err);
            }
        } finally {
            props.data.setLoadingGrid(false);
            props.data.setDataGridLoaded(true);
        }
    };
    
    const handleEditMailingListClick = (params) => {
        navigate("/updateMailingList?id=" + params.row.id + "&key=" + randomId(), { replace: true, });
    };
    
    const handleDeleteMailingList = (params) => {
        setConfirmMailingListDeleteOpen(true);
        setMailingListToDelete(params.row.id);
    };

    function CustomNoRowsOverlay() {
        return <div></div>;
    }

    function CustomFooterMailingListsList() {
        
        const handleClick = () => {
            navigate("/createMailingList", { replace: true, });
        };

        return (
            <GridFooterContainer>
                <Button
                    className="iconAddButton"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleClick}
                >
                    Add Mailing List
                </Button>
                <GridFooter sx={{ border: "none", }}/>
            </GridFooterContainer>
        );
    }

    return (
        <>
            <DataGridPro
                sx={{ height: "100%", width: "100%" }}
                components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Footer: CustomFooterMailingListsList,
                }}
                initialState={{
                    pagination: {
                        pageSize: 15,
                    },
                    sorting: {
                        sortModel: [{ field: "title", sort: "asc" }],
                    }
                }}
                loading={props.data.loadingGrid}
                rows={props.data.rows}
                columns={columns}
                rowsPerPageOptions={[8, 15, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                onRowClick={handleEditMailingListClick}
                filterModel={props.data.filterModel}
                onFilterModelChange={props.data.rowsSelected}
                onStateChange={(state) => {
                    const newRows = gridVisibleSortedRowIdsSelector(state);
                    props.data.setVisibleRows(newRows);
                }}
            />
            <ConfirmDialog
                title={"Delete mailing list"}
                open={confirmMailingListDeleteOpen}
                setOpen={setConfirmMailingListDeleteOpen}
                onConfirm={deleteMailingList}
                params={mailingListToDelete}
            >
                {"Are you sure you want to delete this mailing list ?"}
            </ConfirmDialog>
        </>
    );
}

export default MailingListsList;
