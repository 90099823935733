import React, {useEffect, useRef} from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const FilterItemStatus = (props) => {

  const itemRef = useRef(props.itemRef);

  const handleClick = event => {
    if(!props.disable && !props.workflowLoaded && !!props.missionEdit == false) {
      props.changeStatusFilter(false);

      var statusFilterArg = !props.statusFilter;
      if (statusFilterArg)
      {
        props.filterStatus.push(props.value);
        props.setFilter(props.filterStatus, props.value, true);
      }
      else
      {
        var arr = props.filterStatus.filter(e => e !== props.value);
        props.setFilter(arr, props.value, false);
      }
    }
  };

  const handleMouseOver = event => {
    event.target.style.background = props.color;
    props.changeStatusFilter(false);
  };

  const handleMouseOut = event => {
    if (!props.statusFilter) event.target.style.background = 'none';
  };
  

  return (
    <BootstrapTooltip title={props.title} placement="top">
      <div className={props.statusFilter ? 'statusFilter filterActived' : 'statusFilter'} style={{opacity: props.opacity ? props.opacity : '100%'}} onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div ref={itemRef} className={props.statusFilterReset ? 'statusFilterbackground hide' : 'statusFilterbackground'} style={{backgroundColor: props.statusFilter ? props.color : 'none'}}>{props.value}</div>
      </div>
    </BootstrapTooltip>
  );
}
export default FilterItemStatus
