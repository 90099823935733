import React, { useState, forwardRef, useContext, Children } from 'react';
import { useNavigate } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import ForwardIcon from '@mui/icons-material/Forward';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isCustomerRole } from 'components/config/Roles';
import ShippingInstructionMissionLetter from './ShippingInstructionMissionLetter';
import ShippingInstructionMissionDressing from './ShippingInstructionMissionDressing';
import ShippingInstructionMissionInstruction from './ShippingInstructionMissionInstruction';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";
import { ContextShippingInstructionMission } from "./ContextShippingInstructionMission";

import "styles/Receivers.css";


const ShippingInstructionMissionView = forwardRef((props, ref) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { missionRef } = useContext(ContextShippingInstruction);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //data missions
  const { rowsMissions, setRowsMissions } = useContext(ContextShippingInstructionMission);

  const [expandedMissions, setExpandedMissions] = useState([]);
  const [expandedMissionsLetter, setExpandedMissionsLetter] = useState([]);
  const [expandedMissionsDressing, setExpandedMissionsDressing] = useState([]);
  const [expandedMissionsInstruction, setExpandedMissionsInstruction] = useState([]);


  //func
  const { renderStatus } = useContext(ContextShippingInstructionMission);
  //handle
  const { handleExpand } = useContext(ContextShippingInstructionMission);
  const { handleClickBlockEdit } = useContext(ContextBlock);


  return (
    <div>
      <div className="blocReceiverDiv">
        {rowsMissions.length >= 1 && Children.toArray(rowsMissions.map((mission, index) => {
          //generate title
          var missionTitle = mission?.jlb_ref;

          return (
            <div className="receiverDiv">
              <Accordion className="receiverAccordion" expanded={expandedMissions.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissions, setExpandedMissions)}>
                <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                  <div className="receiverIconSpacer"></div>
                  <div className="receiverNameDiv">
                    <div style={{ float: 'left', paddingTop: '3px', paddingRight: '20px' }}>
                      <a onClick={() => navigate('/missionDetails?id=' + mission.id, { replace: true })}>
                        {missionTitle}
                      </a>
                    </div>
                    <div style={{ float: 'left', paddingRight: '5px' }}>
                      {renderStatus(mission?.status?.status)}
                    </div>
                    <div style={{ float: 'left' }}>
                      <IconButton size="small" onClick={() => navigate('/missionDetails?id=' + mission.id, { replace: true })}>
                        <ForwardIcon fontSize="small" />
                      </IconButton>
                    </div>
                    <div className="spacer"></div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="blocReceiverLotDiv">

                    <div className="receiverLotDiv">
                      <Accordion className="receiverLotAccordion" expanded={expandedMissionsInstruction.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissionsInstruction, setExpandedMissionsInstruction)}>
                        <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                          <div className="receiverIconSpacer"></div>
                          <div className="receiverNameDiv">Instructions</div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <ShippingInstructionMissionInstruction editable={false} idMission={mission.id} />

                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="receiverLotDiv">
                      <Accordion className="receiverAccordion" expanded={expandedMissionsLetter.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissionsLetter, setExpandedMissionsLetter)}>
                        <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                          <div className="receiverIconSpacer"></div>
                          <div className="receiverNameDiv">Letters</div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <ShippingInstructionMissionLetter editable={false} idMission={mission.id} />
                          
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="receiverLotDiv">
                      <Accordion className="receiverAccordion" expanded={expandedMissionsDressing.includes(mission.id)} onChange={handleExpand(mission.id, expandedMissionsDressing, setExpandedMissionsDressing)}>
                        <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                          <div className="receiverIconSpacer"></div>
                          <div className="receiverNameDiv">Dressings</div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <ShippingInstructionMissionDressing editable={false} idMission={mission.id} />
                          
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        }))}
      </div>

      {(update == 1 && loadingUpdate && !JLBCustomerMode) &&
        <div className="block_button">
          <LoadingButton className="edit" onClick={() => handleClickBlockEdit(missionRef)} loadingPosition="start" startIcon={<EditIcon />}> Edit</LoadingButton>
        </div>
      }
    </div>
  );
});

export default ShippingInstructionMissionView;
