const roles = {
  'JLB_Marseille':100,
  'JLB_Customer':200,
  'Admin':300,
  'JLB_Port':400,
  'JLB_Invoice':500,
  'JLB_Direction':600
}

const isInternalRole = (role) => {
  return (role == roles.JLB_Marseille ||
          role == roles.JLB_Invoice ||
          role == roles.JLB_Direction ||
          role == roles.Admin
  );
}

const isInternalComptaRole = (role) => {
  return (role == roles.JLB_Invoice ||
          role == roles.JLB_Direction ||
          role == roles.Admin
  );
}

const isPortRole = (role) => {
  return (role == roles.JLB_Port);
}

const isCustomerRole = (role) => {
  return (role == roles.JLB_Customer);
}

const isAdminRole = (role) => {
  return (role == roles.Admin);
}

const isDirectionRole = (role) => {
  return (role == roles.JLB_Direction);
}

const getUserRole = (role) => {
  var labelRole;
  switch(Number(role)){
    case roles.JLB_Marseille:
      labelRole = 'JLB Marseille';
    break;
    case roles.JLB_Customer:
      labelRole = 'JLB Customer';
    break;
    case roles.JLB_Direction:
      labelRole = 'JLB Direction';
    break;
    case roles.JLB_Invoice:
      labelRole = 'JLB Invoice';
    break;
    case roles.JLB_Port:
      labelRole = 'JLB Port';
    break;
    case roles.Admin:
      labelRole = 'Admin';
    break;
  }
  return labelRole;
}

export {isInternalRole, isInternalComptaRole, isPortRole, isCustomerRole, isAdminRole, isDirectionRole, getUserRole};
