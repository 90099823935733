import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import axios from 'api/axios';
import { Skeleton } from '@mui/material';

const ProtisPhoto = (props) => {

  const navigate = useNavigate();
  const MISSIONPROTISPHOTOFILE_URL = "mission/photo/file";

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [elementDetails, setElementDetails] = useState(null);
  const [height, setHeight] = useState(64);
  const [width, setWidth] = useState(64);

  // Load all list elements
  useEffect(() => {
    setElementDetails(props.element)
    if (props.element?.isPhoto && !props.element.isLoaded && (props.loadPhoto === true || props.loadPhoto == undefined)) {
      loadPhotoFile(props.element);
    }
  }, [props?.element]);

  useEffect(() => {
    if (elementDetails && !elementDetails?.isLoaded && props.loadPhoto === true) {
      loadPhotoFile(elementDetails);
    }
  }, [props?.loadPhoto]);

  useEffect(() => {
    if (props.height) {
      setHeight(props.height);
    }
  }, [props?.height]);

  useEffect(() => {
    if (props.width) {
      setWidth(props.width);
    }
  }, [props?.width]);



  const loadPhotoFile = async (photo) => {
    try {
      setIsFileLoading(true);
      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          'id_mission_photo': photo.id,
          'id_mission': props.idMission
        }
      };

      var url = MISSIONPROTISPHOTOFILE_URL;
      const response = await axios.get(url, config);

      photo.file = response.data.data.file;
      photo.isLoaded = true;
      props.handleLoadedPhoto(photo);
      setIsFileLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  return (
    <div className="protisPhotoDiv" style={{ width: width + 'px', height: height + 'px' }} >
      {isFileLoading ?
        <Skeleton width={width} height={height} /> :
        <img src={`data:image/jpeg;base64,${elementDetails?.file?.file_path}`} style={{ maxWidth: width + 'px', maxHeight: height + 'px' }} />
      }
    </div>
  );

};

export default ProtisPhoto;
