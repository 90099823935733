import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from 'imgs/logo.svg';
import 'styles/Login.css';
import 'styles/RenewPassword.css';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'api/axios';
const LOGIN_URL = 'user/passwordlink';

const RenewPassword = () => {

  // CHECK SECURITY
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from?.pathname || "/renewPasswordDone";

  const userRef = useRef();
  const errRef = useRef();

  /////// BOUCHON
  //ROLE 200
  //const [user, setUser] = useState('samuel@houseofcoding.fr');

  //ROLE 100
  const [user, setUser] = useState('');
  /////// END BOUCHON

  //const [user, setUser] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [user])

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          await axios.post(LOGIN_URL,
              JSON.stringify({ email:user }),
              {
                  headers: { 'Content-Type': 'application/json' },
              }
          );

          setUser('');
          navigate(from, { replace: true });
      } catch (err) {
          if (!err?.response) {
              setErrMsg('No Server Response');
          } else if (err.response?.status === 400) {
              setErrMsg('Missing Username');
          } else if (err.response?.status === 401) {
              setErrMsg('Invalid Username');
          } else if (err.response?.status === 422) {
              setErrMsg('Invalid Username');
          } else {
            setErrMsg('Server Failed');
          }
          errRef.current.focus();
      }
  }

    return (
      <div className="Login">
        <div className="welcome-to-gyptis">Gyptis - Reset Your Password</div>
        <div className="Login-box">
          <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
          <div className="Login-formulaire">
            <form onSubmit={handleSubmit}>
              <div ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</div>
              <p className="info-reset-passwd">Please enter your email address below to receive an email with reset password instructions.</p>
              <div className="Login-identifier">Email</div>
              <input className="Login-identifier-input" type="text" id="user" ref={userRef} onChange={(e) => setUser(e.target.value)} value={user} required />
              <button className="Login-rectangle-button" onSubmit={handleSubmit}>RESET PASSWORD</button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default RenewPassword;
