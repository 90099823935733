import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";

import axios from 'api/axios';


const ContextShippingInstructionReport = createContext();

const ShippingInstructionReportProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { reportRef } = useContext(ContextShippingInstruction);
  const { getShippingInstruction } = useContext(ContextShippingInstruction);

  //API URL
  const SI_REPORT_URL = 'shipping_instruction/si_report';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //init id si
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //si bl state
  const { reports, setReports } = useContext(ContextShippingInstruction);

  const [rowsSiReports, setRowsSiReports] = useState([]);
  const [rowsSiReportsDeleted, setRowsSiReportsDeleted] = useState([]);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});

  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const { saveBlock } = useContext(ContextBlockTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async (reload = true) => {
    handleClickBlockCancel(reportRef, reload);
  }


  //INIT mission data
  useEffect(() => {
    //load data
    if (reports) {
      if (reports.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit = blockEdit.findIndex(obj => (obj.blockRef === reportRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadData > oldReloadData) {
            setOldReloadData(reloadData);
          }

          //INIT BLs List
          var rowsFromApi = [];
          if (reports.length > 0) {
            for (var i = 0; i < reports.length; i++) {
              var blAssoc = [];
              for (var bi = 0; bi < reports[i].si_bls?.length; bi++) {
                blAssoc[bi] = reports[i].si_bls[bi].id;
              }
              var missionAssoc = [];
              for (var mi = 0; mi < reports[i].missions?.length; mi++) {
                missionAssoc[mi] = reports[i].missions[mi].id;
              }
              var file = null;
              if (reports[i].si_file) {
                file = reports[i].si_file.file;
                //for treatment, id = id_si_file;
                file.id_file = file.id;
                file.id = reports[i].si_file.id;
              }

              rowsFromApi[i] = {
                id: reports[i].id,
                id_vessel: reports[i].vessel?.id,
                report_number: reports[i].report_number,
                invoice_month: reports[i].invoice_month,
                invoiced_without_si: reports[i].invoiced_without_si,

                file: file,
                bls: blAssoc,
                missions: missionAssoc
              };
            }
            rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
          }
          setRowsSiReports(rowsFromApi);
        }
      }
      else setRowsSiReports([]);
    }
    else setRowsSiReports([]);
  }, [reports, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(reportRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockEdit.findIndex(obj => obj.blockRef === reportRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlock(reportRef, saveShippingInstructionReport);
  }, [blockSave, idShippingInstruction]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockSave.findIndex(obj => obj.blockRef === reportRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function interactive
  const processRowUpdateSiReport = (newRow) => {
    setRowsSiReports(rowsSiReports.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  }

  const saveShippingInstructionReport = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var saveSiReportPromises = [];
      //Saving SI Report
      for (var i = 0; i < rowsSiReports.length; i++) {
        var data = {
          id_shipping_instruction: parseInt(idShippingInstruction),
          report_number: rowsSiReports[i].report_number,
          id_si_file: rowsSiReports[i].file ? rowsSiReports[i].file.id : null,
          id_vessel: rowsSiReports[i].id_vessel ? rowsSiReports[i].id_vessel : null,
          invoiced_without_si: rowsSiReports[i].invoiced_without_si ? rowsSiReports[i].invoiced_without_si : false,
          invoice_month: rowsSiReports[i].invoice_month ? momentDate(rowsSiReports[i].invoice_month).format("YYYY-MM-DD") : null,

          bls: rowsSiReports[i].bls ? rowsSiReports[i].bls : null,
          missions: rowsSiReports[i].missions ? rowsSiReports[i].missions : null
        };

        var methode = 'post';
        if (rowsSiReports[i].id.toString().length < 16) {
          methode = 'put';
          data.id_si_report = rowsSiReports[i].id;
        }

        var config = {
          url: SI_REPORT_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveSiReportPromises.push(response);

        if (rowsSiReports[i].id.toString().length > 16) {
          rowsSiReports[i].id = response.data.data.id;
          processRowUpdateSiReport(rowsSiReports[i]);
        }
      }

      //Deleting Mission Instruction
      for (var i = 0; i < rowsSiReportsDeleted.length; i++) {
        var config = {
          url: SI_REPORT_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_si_report: rowsSiReportsDeleted[i].id }
        };
        const response = await axios(config);
        saveSiReportPromises.push(response);
      }

      //end treatment
      setRowsSiReportsDeleted([]);
      await Promise.all(saveSiReportPromises);

      if (justBlock) {
        setSnackBarMessage("Reports saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getShippingInstruction();
      } else {
        setDoneBlockSave(reportRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(reportRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }


  return (
    <ContextShippingInstructionReport.Provider
      value=
      {{
        rowsSiReports, setRowsSiReports,
        rowsSiReportsDeleted, setRowsSiReportsDeleted,
        processRowUpdateSiReport,

        handleClickCancel,
        saveShippingInstructionReport
      }}
    >
      {children}
    </ContextShippingInstructionReport.Provider>
  );
};

export { ShippingInstructionReportProvider, ContextShippingInstructionReport };