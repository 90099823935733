import React, { useState, useEffect, createContext, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";
import { ContextShippingInstructionGeneral } from "../General/ContextShippingInstructionGeneral";

import axios from 'api/axios';


const ContextShippingInstructionMission = createContext();

const ShippingInstructionMissionProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { missionRef } = useContext(ContextShippingInstruction);
  const { getShippingInstruction } = useContext(ContextShippingInstruction);

  //API URL
  const MISSION_URL = 'mission';
  const MISSION_LETTER_URL = 'mission/letter';
  const MISSION_DRESSING_URL = 'mission/dressing';
  const MISSION_INSTRUCTION_URL = 'mission/instruction';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //init id si
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //si mission state
  const { missions, setMissions } = useContext(ContextShippingInstruction);

  const [rowsMissions, setRowsMissions] = useState([]);
  const [rowsMissionTypes, setRowsMissionTypes] = useState([]);

  const [rowsMissionLetter, setRowsMissionLetter] = useState([]);
  const [rowsMissionLetterDeleted, setRowsMissionLetterDeleted] = useState([]);

  const [rowsMissionDressing, setRowsMissionDressing] = useState([]);
  const [rowsMissionDressingDeleted, setRowsMissionDressingDeleted] = useState([]);

  const [rowsMissionInstruction, setRowsMissionInstruction] = useState([]);
  const [rowsMissionInstructionDeleted, setRowsMissionInstructionDeleted] = useState([]);

  //si general state
  const MISSION_TYPE_COCOA_DEPARTURE = 'COD';
  const { 
    tonnage, 
    traderId, 
    shipperId, 
    cargoTypeId,
    localOfficeId,
    principalsId, 
    surveyHandler,
    portDepartureId,
    rowsSiInstruction
  } = useContext(ContextShippingInstructionGeneral);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const { saveBlock } = useContext(ContextBlockTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async (reload = true) => {
    handleClickBlockCancel(missionRef, reload);
  }


  useEffect(() => {
    loadMissionTypes();
  }, []);

  //INIT mission data
  useEffect(() => {
    //load data
    if (missions) {
      if (missions.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit = blockEdit.findIndex(obj => (obj.blockRef === missionRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadData > oldReloadData) {
            setOldReloadData(reloadData);
          }
          
          //INIT Missions List
          var rowsFromMissionsApi = [];
          var rowsFromLettersApi = [];
          var rowsFromDressingsApi = [];
          var rowsFromInstructionsApi = [];
          for (var mi = 0; mi < missions.length; mi++) {
            const mission = missions[mi];
            rowsFromMissionsApi.push(mission);
            
            //INIT Mission Instruction
            if (mission.mission_instructions) {
              if (Object.keys(mission.mission_instructions).length > 0) {
                for (const [key, missionIntruction] of Object.entries(mission.mission_instructions)) {
                  rowsFromInstructionsApi.push({
                    id: missionIntruction.id,
                    id_mission: missionIntruction.id_mission,
                    id_si_instruction: missionIntruction.si_instruction?.id,
                    id_instruction_type: missionIntruction.instruction_type.id,
                    id_place_of_operations: missionIntruction.place_of_operations?.id,
                    begining_date: missionIntruction.begining_date,
                    completion_date: missionIntruction.completion_date,
                    dhl: missionIntruction.dhl,
                    remark: missionIntruction.remark
                  });
                }
                setRowsMissionInstruction(rowsFromInstructionsApi);
              }
              else setRowsMissionInstruction(rowsFromInstructionsApi);
            }
            else setRowsMissionInstruction(rowsFromInstructionsApi);

            //INIT Mission Letter
            if (mission.mission_letters) {
              if (Object.keys(mission.mission_letters).length > 0) {
                for (const [key, missionLetter] of Object.entries(mission.mission_letters)) {
                  rowsFromLettersApi.push({
                    id: missionLetter.id,
                    id_mission: missionLetter.id_mission,
                    date: missionLetter.date,
                    type: missionLetter.type,
                    reason: missionLetter.reason
                  });
                }
                setRowsMissionLetter(rowsFromLettersApi);
              }
              else setRowsMissionLetter(rowsFromLettersApi);
            }
            else setRowsMissionLetter(rowsFromLettersApi);

            //INIT Mission Dressing
            if (mission.mission_dressings) {
              if (Object.keys(mission.mission_dressings).length > 0) {
                for (const [key, missionDressing] of Object.entries(mission.mission_dressings)) {
                  rowsFromDressingsApi.push({
                    id: missionDressing.id,
                    id_mission: missionDressing.id_mission,
                    id_dry_bags_type: missionDressing.dry_bags_type?.id,
                    id_dry_bags_brand: missionDressing.dry_bags_brand?.id,
                    id_dressing_type: missionDressing.dressing_type?.id,
                    id_dressing_brand: missionDressing.dressing_brand?.id,
                    quantity: missionDressing.quantity
                  });
                }
                setRowsMissionDressing(rowsFromDressingsApi);
              }
              else setRowsMissionDressing(rowsFromDressingsApi);
            }
            else setRowsMissionDressing(rowsFromDressingsApi);
          }
          setRowsMissions(rowsFromMissionsApi);
        }
      }
      else setRowsMissions([]);
    }
    else setRowsMissions([]);
  }, [missions, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(missionRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockEdit.findIndex(obj => obj.blockRef === missionRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlock(missionRef, saveShippingInstructionMission);
  }, [blockSave, idShippingInstruction]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockSave.findIndex(obj => obj.blockRef === missionRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function interactive
  const processRowAddMissionInstruction = (newRow) => {
    setRowsMissionInstruction((row) => [...row, {
      id: newRow.id,
      id_mission: newRow.id_mission,
      id_si_instruction: newRow.si_instruction?.id,
      id_instruction_type: newRow.instruction_type.id,
      id_place_of_operations: newRow.place_of_operations?.id,
      begining_date: newRow.begining_date,
      completion_date: newRow.completion_date,
      dhl: newRow.dhl,
      remark: newRow.remark
    }]);
  }

  const processRowUpdateMissionInstruction = (newRow, strict = false) => {
    const updatedRow = !strict ? { ...newRow, id_si_instruction: null } : newRow;
    setRowsMissionInstruction(rowsMissionInstruction.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
    return updatedRow;
  }

  const processRowUpdateMissionLetter = (newRow) => {
    setRowsMissionLetter(rowsMissionLetter.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  }

  const processRowUpdateMissionDressing = (newRow) => {
    setRowsMissionDressing(rowsMissionDressing.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  }

  const loadMissionTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };
      const response = await axios.get('mission_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { id: response.data[i].id, label: response.data[i].label, code: response.data[i].nomenclature };
      }
      setRowsMissionTypes(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const saveShippingInstructionMission = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      //Mission Letter
      await saveMissionLetter();
      //Mission Dressing
      await saveMissionDressing();
      //Mission Instruction
      await saveMissionInstruction();

      if (justBlock) {
        setSnackBarMessage("Missions saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getShippingInstruction();
      } else {
        setDoneBlockSave(missionRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(missionRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }

  const saveMissionInstruction = async () => {
    try {
      const saveMissionInstructionPromises = [];
      //Saving Mission Instruction
      for (var i = 0; i < rowsMissionInstruction.length; i++) {
        const data = {
          dhl: rowsMissionInstruction[i].dhl,
          remark: rowsMissionInstruction[i].remark,
          id_mission: Number(rowsMissionInstruction[i].id_mission),
          id_instruction_type: Number(rowsMissionInstruction[i].id_instruction_type),
          id_si_instruction: rowsMissionInstruction[i].id_si_instruction ? Number(rowsMissionInstruction[i].id_si_instruction) : null,
          id_place_of_operations: rowsMissionInstruction[i].id_place_of_operations ? Number(rowsMissionInstruction[i].id_place_of_operations) : null,
          begining_date: rowsMissionInstruction[i].begining_date ? momentDate(rowsMissionInstruction[i].begining_date).format("YYYY-MM-DD") : null,
          completion_date: rowsMissionInstruction[i].completion_date ? momentDate(rowsMissionInstruction[i].completion_date).format("YYYY-MM-DD") : null
        };

        var methode = 'post';
        if (rowsMissionInstruction[i].id.toString().length < 16) {
          methode = 'put';
          data.id_mission_instruction = Number(rowsMissionInstruction[i].id);
        }

        const config = {
          url: MISSION_INSTRUCTION_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveMissionInstructionPromises.push(response);

        if (rowsMissionInstruction[i].id.toString().length > 16) {
          rowsMissionInstruction[i].id = Number(response.data.data.id);
          processRowUpdateMissionInstruction(rowsMissionInstruction[i], true);
        }
      }

      //Deleting Mission Instruction
      for (var i = 0; i < rowsMissionInstructionDeleted.length; i++) {
        const config = {
          url: MISSION_INSTRUCTION_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_mission_instruction: Number(rowsMissionInstructionDeleted[i].id) }
        };
        const response = await axios(config);
        saveMissionInstructionPromises.push(response);
      }

      //end treatment
      setRowsMissionInstructionDeleted([]);
      await Promise.all(saveMissionInstructionPromises);
    } catch (err) {
      console.log(err);
    }
  }

  const saveMissionLetter = async () => {
    try {
      const saveMissionLetterPromises = [];
      //Saving Mission Letter
      for (var i = 0; i < rowsMissionLetter.length; i++) {
        const data = {
          type: rowsMissionLetter[i].type,
          id_mission: Number(rowsMissionLetter[i].id_mission),
          reason: rowsMissionLetter[i].reason ? rowsMissionLetter[i].reason : null,
          date: rowsMissionLetter[i].date ? momentDate(rowsMissionLetter[i].date).format("YYYY-MM-DD") : null
        };

        var methode = 'post';
        if (rowsMissionLetter[i].id.toString().length < 16) {
          methode = 'put';
          data.id_mission_letter = Number(rowsMissionLetter[i].id);
        }

        const config = {
          url: MISSION_LETTER_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveMissionLetterPromises.push(response);

        if (rowsMissionLetter[i].id.toString().length > 16) {
          rowsMissionLetter[i].id = Number(response.data.data.id);
          processRowUpdateMissionLetter(rowsMissionLetter[i]);
        }
      }

      //Deleting Mission Letter
      for (var i = 0; i < rowsMissionLetterDeleted.length; i++) {
        const config = {
          url: MISSION_LETTER_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_mission_letter: Number(rowsMissionLetterDeleted[i].id) }
        };
        const response = await axios(config);
        saveMissionLetterPromises.push(response);
      }

      //end treatment
      setRowsMissionLetterDeleted([]);
      await Promise.all(saveMissionLetterPromises);
    } catch (err) {
      console.log(err);
    }
  }

  const saveMissionDressing = async () => {
    try {
      const saveMissionDressingPromises = [];
      //Saving Mission Dressing
      for (var i = 0; i < rowsMissionDressing.length; i++) {
        const data = {
          id_mission: Number(rowsMissionDressing[i].id_mission),
          quantity: rowsMissionDressing[i].quantity ? rowsMissionDressing[i].quantity : null,
          id_dry_bags_type: rowsMissionDressing[i].id_dry_bags_type ? Number(rowsMissionDressing[i].id_dry_bags_type) : null,
          id_dry_bags_brand: rowsMissionDressing[i].id_dry_bags_brand ? Number(rowsMissionDressing[i].id_dry_bags_brand) : null,
          id_dressing_type: rowsMissionDressing[i].id_dressing_type ? Number(rowsMissionDressing[i].id_dressing_type) : null,
          id_dressing_brand: rowsMissionDressing[i].id_dressing_brand ? Number(rowsMissionDressing[i].id_dressing_brand) : null
        };

        var methode = 'post';
        if (rowsMissionDressing[i].id.toString().length < 16) {
          methode = 'put';
          data.id_mission_dressing = Number(rowsMissionDressing[i].id);
        }

        const config = {
          url: MISSION_DRESSING_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveMissionDressingPromises.push(response);

        if (rowsMissionDressing[i].id.toString().length > 16) {
          rowsMissionDressing[i].id = Number(response.data.data.id);
          processRowUpdateMissionDressing(rowsMissionDressing[i]);
        }
      }

      //Deleting SI Instruction
      for (var i = 0; i < rowsMissionDressingDeleted.length; i++) {
        const config = {
          url: MISSION_DRESSING_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_mission_dressing: Number(rowsMissionDressingDeleted[i].id) }
        };
        const response = await axios(config);
        saveMissionDressingPromises.push(response);
      }

      //end treatment
      setRowsMissionDressingDeleted([]);
      await Promise.all(saveMissionDressingPromises);
    } catch (err) {
      console.log(err);
    }
  }

  const createMission = async () => {
    setLoading(true);
    setLoadingUpdate(false);

    try {
      const saveMissionPromises = [];
      //mission type id
      const missionType = rowsMissionTypes.find(
        (obj) => obj.code === MISSION_TYPE_COCOA_DEPARTURE
      );

      const data = {
        tonnage: Number(tonnage),
        id_port: Number(portDepartureId),
        id_principal: Number(principalsId),
        id_mission_type: Number(missionType.id),
        id_survey_handler: Number(surveyHandler),
        id_trader: traderId ? Number(traderId) : null,
        id_shipping_instruction: Number(idShippingInstruction),
        id_local_office: localOfficeId ? Number(localOfficeId) : null
      };

      const config = {
        url: MISSION_URL,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
      const responseMission = await axios(config);
      saveMissionPromises.push(responseMission);
      setRowsMissions((row) => [...row, responseMission.data.data]);

      //SI Instruction
      //Saving Mission Instruction
      for (var i = 0; i < rowsSiInstruction.length; i++) {
        if (!(rowsSiInstruction[i].id.toString().length > 16)) {
          const data = {
            dhl: rowsSiInstruction[i].dhl,
            remark: rowsSiInstruction[i].remark,
            id_mission: Number(responseMission.data.data.id),
            id_si_instruction: Number(rowsSiInstruction[i].id),
            id_instruction_type: Number(rowsSiInstruction[i].id_instruction_type),
            id_place_of_operations: rowsSiInstruction[i].id_place_of_operations ? Number(rowsSiInstruction[i].id_place_of_operations) : null,
            begining_date: rowsSiInstruction[i].begining_date ? momentDate(rowsSiInstruction[i].begining_date).format("YYYY-MM-DD") : null,
            completion_date: rowsSiInstruction[i].completion_date ? momentDate(rowsSiInstruction[i].completion_date).format("YYYY-MM-DD") : null
          };

          const config = {
            url: MISSION_INSTRUCTION_URL,
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data: data
          };
          const response = await axios(config);
          saveMissionPromises.push(response);
          processRowAddMissionInstruction(response.data.data);
        }
      }

      await Promise.all(saveMissionPromises);

      setSnackBarMessage("Mission created.");
      setSnackBarType("success");
      setOpenSnackBar(true);
      //reload data api
      await getShippingInstruction();
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
      setLoadingUpdate(true);
    }
  }

  //function for change state of expand
  const handleExpand = (itemId, items, callFunc) => () => {
    if (items.includes(itemId)) {
      callFunc(prevExpanded => prevExpanded.filter(id => id !== itemId));
    } else {
      callFunc(prevExpanded => [...prevExpanded, itemId]);
    }
  }

  const renderStatus = (missionStatus) => {
    if (missionStatus) {
      return (<div className="statusMissionGridColor" style={{ backgroundColor: missionStatus.color }}>
        {missionStatus.value}
      </div>);
    } 
    else return '';
  }


  return (
    <ContextShippingInstructionMission.Provider
      value=
      {{
        rowsMissions, setRowsMissions,

        rowsMissionInstruction, setRowsMissionInstruction,
        rowsMissionInstructionDeleted, setRowsMissionInstructionDeleted,
        processRowUpdateMissionInstruction,

        rowsMissionLetter, setRowsMissionLetter,
        rowsMissionLetterDeleted, setRowsMissionLetterDeleted,
        processRowUpdateMissionLetter,

        rowsMissionDressing, setRowsMissionDressing,
        rowsMissionDressingDeleted, setRowsMissionDressingDeleted,
        processRowUpdateMissionDressing,

        handleClickCancel,
        saveShippingInstructionMission,

        createMission,
        handleExpand, renderStatus
      }}
    >
      {children}
    </ContextShippingInstructionMission.Provider>
  );
};

export { ShippingInstructionMissionProvider, ContextShippingInstructionMission };