import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";

import ShippingInstructionGeneralForm from './ShippingInstructionGeneralForm';
import ShippingInstructionGeneralView from "./ShippingInstructionGeneralView";


const ShippingInstructionGeneral = forwardRef((props, ref) => {

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { toogleBlockOnEdit } = useContext(ContextShippingInstruction);


  //useEffect
  useEffect(() => {
    toogleBlockOnEdit(ref, onEdit, setOnEdit);
  }, [blockEdit]);


  return (
    <div id="block_general" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
      <h4>SI General Informations</h4>

      {(!onEdit) ? <ShippingInstructionGeneralView /> : <ShippingInstructionGeneralForm />}

    </div>
  );
});

export default ShippingInstructionGeneral;
