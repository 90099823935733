import React, { useState, forwardRef, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';

import { isCustomerRole } from 'components/config/Roles';
import ShippingInstructionBLList from './ShippingInstructionBLList';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";


const ShippingInstructionBLView = forwardRef((props, ref) => {

  //init config
  const { update } = useContext(ContextBlock);
  const { blRef } = useContext(ContextShippingInstruction);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //handle
  const { handleClickBlockEdit } = useContext(ContextBlock);


  return (
    <div>

      <ShippingInstructionBLList editable={false} />

      {(update == 1 && loadingUpdate && !JLBCustomerMode) &&
        <div className="block_button">
          <LoadingButton className="edit" onClick={() => handleClickBlockEdit(blRef)} loadingPosition="start" startIcon={<EditIcon />}> Edit</LoadingButton>
        </div>
      }
      
    </div>
  );
});

export default ShippingInstructionBLView;
