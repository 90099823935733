import React, { useState, useEffect, useContext } from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { useDeepCompareMemo } from 'use-deep-compare';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { ContextAnalytics } from "../../../../context/ContextAnalytics";
import '../../../../styles/Analytics.css';

const FieldsCompletionTableChart = (props) => {
    
    const { cubejsApi } = useContext(ContextAnalytics);
    const { openSnackBar, setOpenSnackBar } = useContext(ContextAnalytics);
    const { snackBarType, setSnackBarType } = useContext(ContextAnalytics);
    const { snackBarMessage, setSnackBarMessage } = useContext(ContextAnalytics);
    const { setSnackErrorMsg } = useContext(ContextAnalytics);
    
    const [portResultSet, setPortResultSet] = useState(null);
    const [customerResultSet, setCustomerResultSet] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [detailsSourceType, setDetailsSourceType] = useState(null);
    const [detailsSourceId, setDetailsSourceId] = useState(null);
    const [detailsFilters, setDetailsFilters] = useState([]);

    // Specific table types
    const tableTypeButtons = [
        { label: 'Group Type Details', value: 'groupTypeDetails' },
        { label: 'Mission Details (< 70.00 %)', value: 'missionDetails' },
    ];
    const [selectedTableType, setSelectedTableType] = useState(tableTypeButtons[0]?.value);

    // Fetchs port and customers data for the table chart
    useEffect(() => {
        const fetchData = async () => {
            if (props.formattedQueryStartDate && props.formattedQueryEndDate) {
                const portQuery = {
                    dimensions: [
                        "port.label",
                        "mission_fields_completion.id_port"
                    ],
                    timeDimensions: [
                        {
                            dimension: "mission.created_at",
                            dateRange: [props.formattedQueryStartDate, props.formattedQueryEndDate],
                        },
                    ],
                    measures: ["mission_fields_completion.total_completion_percentage_average"],
                    order: { "mission_fields_completion.total_completion_percentage_average": "desc" },
                    filters: detailsFilters
                };
                
                const customerQuery = {
                    dimensions: [
                        "company.name",
                        "mission_fields_completion.id_principal"
                    ],
                    timeDimensions: [
                        {
                            dimension: "mission.created_at",
                            dateRange: [props.formattedQueryStartDate, props.formattedQueryEndDate],
                        },
                    ],
                    measures: ["mission_fields_completion.total_completion_percentage_average"],
                    order: { "mission_fields_completion.total_completion_percentage_average": "desc" },
                    filters: detailsFilters
                };
    
                try {
                    const resultSetPort = await cubejsApi.load(portQuery);
                    const resultSetCustomer = await cubejsApi.load(customerQuery);
                    if (resultSetPort) {
                        setPortResultSet(resultSetPort);
                    }
                    if (resultSetCustomer) {
                        setCustomerResultSet(resultSetCustomer);
                    }
                } catch (error) {
                    var errorMsg = (!!error.response != false ? setSnackErrorMsg(error.response.data) : "");
                    setSnackBarMessage(errorMsg);
                    setSnackBarType("error");
                    setOpenSnackBar(true);
                    console.log(error);
                }
            }
        };
    
        fetchData();
    }, [props.formattedQueryStartDate, props.formattedQueryEndDate, detailsFilters]);
    
    // Fetchs data for more detailed view applying all filters
    useEffect(() => {
            let tempFilters = [];
            
            // Hide canceled missions
            tempFilters.push(
            {
                member: "mission.is_canceled",
                operator: "equals",
                values: ["false" ]
            })
            
            // Hide mission of certain type(s)
            if (props?.hiddenMissionTypes && props?.hiddenMissionTypes?.length > 0) {
                tempFilters.push(
                {
                    member: "mission_type.nomenclature",
                    operator: "notEquals",
                    values: props?.hiddenMissionTypes
                })
            } 
            
            if (props?.selectedMissionStatusList?.length > 0) {
                tempFilters.push(
                {
                    member: "status.status_type",
                    operator: "equals",
                    values: [ "mission-internal" ]
                },
                {
                    member: "status.value",
                    operator: "equals",
                    values: props?.selectedMissionStatusList
                });
            }
            
            if (props?.selectedMissionType) {
                tempFilters.push(
                {
                    member: "mission_type.nomenclature",
                    operator: "equals",
                    values: [props?.selectedMissionType]
                })
            }
            
            if (showDetails) {
                tempFilters.push({
                    member: "mission_fields_completion.total_completion_percentage",
                    operator: "lt",
                    values: ["70"]
                })
                
                if (detailsSourceType === 'port' && detailsSourceId) {
                    tempFilters.push({
                        member: "mission_fields_completion.id_port",
                        operator: "equals",
                        values: [detailsSourceId]
                    });
                } else if (detailsSourceType === 'customer' && detailsSourceId) {
                    tempFilters.push({
                        member: "mission_fields_completion.id_principal",
                        operator: "equals",
                        values: [detailsSourceId]
                    });
                }
            }
            setDetailsFilters(tempFilters)
    }, [showDetails, detailsSourceType, detailsSourceId, props?.selectedMissionStatusList, props?.selectedMissionType, props?.hiddenMissionTypes])

    // Formats data to be used in the table
    const formatTableData = (columns, data) => {
        function flatten(columns = []) {
            return columns.reduce((memo, column) => {
            if (column.children) {
                return [...memo, ...flatten(column.children)];
            }

            return [...memo, column];
            }, []);
        }

        const typeByIndex = flatten(columns).reduce((memo, column) => { 
            return { ...memo, [column.dataIndex]: column };
        }, {});

        function formatValue(value, { type, format } = {}) {
            if (value == undefined) {
                return value;
            }

            if (type === 'boolean') {
                if (typeof value === 'boolean') {
                    return value.toString();
                } else if (typeof value === 'number') {
                    return Boolean(value).toString();
                }

                return value;
            }

            if (type === 'number' && format === 'percent') {
                return [parseFloat(value).toFixed(2), ' %'].join('');
            }

            return value.toString();
        }

        function format(row) {
            return Object.fromEntries(
                Object.entries(row).map(([dataIndex, value]) => {
                    return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
                })
            );
        }
      
        return data.map(format);
    };
    
    // Handles a click on a row
    const handleRowClick = (row) => {
        if (!showDetails && !detailsSourceId && !detailsSourceType) {
            setShowDetails(true);
            setSelectedTableType(tableTypeButtons[1]?.value)
            if (row.type == 'Port') {
                setDetailsSourceType('port')
            } else if (row.type == 'Customer') {
                setDetailsSourceType('customer')
            }
            if (row.id_source) {
                setDetailsSourceId(row.id_source)
            }
        }
    }
    
    // Handles table type changes
    const handleTableTypeChange = (event, newValue) => {
        if (showDetails && newValue) {
            setSelectedTableType(newValue);
            setShowDetails(false);
            setDetailsSourceType(null);
            setDetailsSourceId(null);
        } 
    }

    // Renders a table based on data's columns and rows
    const TableRenderer = ({ resultSet, portResultSet, customerResultSet, pivotConfig }) => {
        const [tableColumns, dataSource] = useDeepCompareMemo(() => {
            const combinedData = [];
            
            // Transform resultSet data
            if (resultSet.tablePivot(pivotConfig).length > 0) {
                resultSet.tablePivot(pivotConfig)?.forEach(data => {
                    if (data['mission_fields_completion.total_completion_percentage_average'].toFixed(2) > 0) {
                        combinedData.push({
                            type: 'JLB',
                            name: 'Marseille',
                            average: data['mission_fields_completion.total_completion_percentage_average'].toFixed(2),
                            id_source: null
                        });
                    }
                });
            }
            
            if (portResultSet?.loadResponses[0]?.data?.length > 0) {
                // Transform portResultSet data
                Object.values(portResultSet?.loadResponses[0]?.data)?.forEach(portData => {
                    const { 'port.label': portLabel, 'mission_fields_completion.total_completion_percentage_average': portAverage, 'mission_fields_completion.id_port' : portId } = portData; // Exclude port.label
                    combinedData.push({
                        type: 'Port',
                        name: portLabel,
                        average: portAverage.toFixed(2),
                        id_source: portId
                    });
                });
            }
            
            if (customerResultSet?.loadResponses[0]?.data?.length > 0) {
                // Transform customerResultSet data
                Object.values(customerResultSet?.loadResponses[0]?.data)?.forEach(portData => {
                    const { 'company.name': companyName, 'mission_fields_completion.total_completion_percentage_average': customerAverage, 'mission_fields_completion.id_principal' : customerId} = portData; // Exclude company.name
                    combinedData.push({
                        type: 'Customer',
                        name: companyName,
                        average: customerAverage.toFixed(2),
                        id_source: customerId
                    });
                });
            }
    
            // Sort combinedData based on the descending percentage
            combinedData.sort((a, b) => { return a.average - b.average });
            
            const columns = [ {title: "Group Type"}, {title: "Name"}, {title: "Fields Completion Average"} ];
            
            return [
                columns,
                formatTableData(columns, combinedData),
            ];
        }, [resultSet, portResultSet, customerResultSet, pivotConfig]);
    
        return (
            <div className='chartTable'>
                <div className='chartTableHead'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column, index) => (
                                    <TableCell key={index} align={index === 0 ? "left" : "right"}>
                                        <Chip label={column?.title} variant="outlined" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className='chartTableBody'>
                    <Table className='chartTableComponent'>
                        <TableBody>
                            {dataSource.map((source, index) => (
                                <Tooltip 
                                    key={index}
                                    title="Show details" 
                                    placement='top'
                                    PopperProps={{
                                        sx: {
                                          '&[data-popper-reference-hidden]': {
                                            visibility: 'hidden',
                                            pointerEvents: 'none'
                                          }
                                        }
                                    }}
                                    arrow
                                >
                                    <TableRow
                                        key={index}
                                        hover
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                                        }}
                                        onClick={() => handleRowClick(source)}
                                    >
                                        <TableCell>
                                            {source['type']}
                                        </TableCell>
                                        <TableCell align="center">
                                            {source['name']}
                                        </TableCell>
                                        <TableCell align="right">
                                            {source['average'] + " %"} 
                                        </TableCell>
                                    </TableRow>
                                </Tooltip>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    };
    
    // Renders a detailed table based on data's columns and rows
    const TableDetailsRenderer = ({ resultSet, pivotConfig }) => {
        const [tableColumns, dataSource] = useDeepCompareMemo(() => {
            
            const columns = [ 
                {title: "Ref JLB"}, 
                {title: "Total Fields"}, 
                {title: "General Info Fields"},
                {title: "Receivers/Lots Fields"},  
                {title: "BL Fields"},
                {title: "Sub Invoices Fields"},
                {title: "Invoices Fields"}, 
            ];
            
            return [
                columns,
                formatTableData(columns, resultSet.tablePivot(pivotConfig)),
            ];
        }, [resultSet, pivotConfig]);
        
        // Function to format values with toFixed(2)
        const formatPercentage = (value) => {
            return parseFloat(value).toFixed(2);
        };
    
        return (
            <div className='chartTable'>
                <div className='chartTableHead'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column, index) => (
                                    <TableCell key={index} align={index === 0 ? "left" : "right"}>
                                        <Chip label={column?.title} variant="outlined" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className='chartTableBody'>
                    <Table className='chartTableComponent'>
                        <TableBody>
                            {dataSource.map((source, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    onClick={() => handleRowClick(source)}
                                >
                                    <TableCell style={{width: '9%'}}>
                                        {source['mission.jlb_ref']}
                                    </TableCell>
                                    <TableCell >
                                        {
                                            formatPercentage(source['mission_fields_completion.total_completion_percentage']) + " % (" + 
                                            source['mission_fields_completion.total_filled_fields'] + "/" +
                                            source['mission_fields_completion.total_mandatory_fields'] + ")"
                                        }
                                    </TableCell>
                                    <TableCell >
                                        {
                                            formatPercentage(source['mission_fields_completion.general_info_completion_percentage']) + " % (" + 
                                            source['mission_fields_completion.general_info_filled_fields'] + "/" +
                                            source['mission_fields_completion.general_info_mandatory_fields'] + ")"
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            formatPercentage(source['mission_fields_completion.receiver_and_lot_completion_percentage']) + " % (" + 
                                            source['mission_fields_completion.receiver_and_lot_filled_fields'] + "/" +
                                            source['mission_fields_completion.receiver_and_lot_mandatory_fields'] + ")"
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            formatPercentage(source['mission_fields_completion.bills_of_lading_completion_percentage']) + " % (" + 
                                            source['mission_fields_completion.bills_of_lading_filled_fields'] + "/" +
                                            source['mission_fields_completion.bills_of_lading_mandatory_fields'] + ")"
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            formatPercentage(source['mission_fields_completion.subcontractor_invoice_completion_percentage']) + " % (" + 
                                            source['mission_fields_completion.subcontractor_invoice_filled_fields'] + "/" +
                                            source['mission_fields_completion.subcontractor_invoice_mandatory_fields'] + ")"
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            formatPercentage(source['mission_fields_completion.invoice_completion_percentage']) + " % (" + 
                                            source['mission_fields_completion.invoice_filled_fields'] + "/" +
                                            source['mission_fields_completion.invoice_mandatory_fields'] + ")"
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    };

    // Renders a table or loading in progress
    const renderChart = ({ resultSet, error, pivotConfig, onDrilldownRequested }) => {

        return (
            (resultSet && !showDetails) ?
                (
                    <TableRenderer 
                        resultSet={resultSet} 
                        portResultSet={portResultSet}
                        customerResultSet={customerResultSet}
                        pivotConfig={pivotConfig} 
                    />
                )
            :
                (resultSet && showDetails)  ? 
                (
                    <TableDetailsRenderer 
                        resultSet={resultSet} 
                        pivotConfig={pivotConfig} 
                    />
                )  
            :   (
                    <div className="loadingChartContainer">
                        <CircularProgress/>
                    </div>
                )
        );
    };
    
    return (
        <div className='chartTableContainerWithToolbar'>
            <div className='chartTableToolbar'>
                    <ToggleButtonGroup
                        color='primary'
                        value={selectedTableType}
                        exclusive
                        onChange={handleTableTypeChange}
                        className='chartTableTypeToggleButtonGroup'
                    >
                        {tableTypeButtons.map((tableTypeButton, index) => (
                            <ToggleButton key={index} value={tableTypeButton.value} disabled={!showDetails ? true  : false} className='chartTableTypeToggleButton'>{tableTypeButton.label}</ToggleButton>
                        ))}
                    </ToggleButtonGroup>
            </div>
            {(props.formattedQueryStartDate && props.formattedQueryEndDate && !showDetails) &&
                // CubeJS query
                <QueryRenderer
                    query={{
                        measures: [ "mission_fields_completion.total_completion_percentage_average" ],
                        limit: 5000,
                        order: {},
                        timeDimensions: [{
                            dimension: "mission.created_at",
                            dateRange: [ props.formattedQueryStartDate, props.formattedQueryEndDate ]
                        }],
                        dimensions: [],
                        filters: detailsFilters
                    }}
                    cubejsApi={cubejsApi}
                    resetResultSetOnChange={false}
                    render={(props) => renderChart({
                        ...props,
                        chartType: 'number',
                        pivotConfig: {
                            x: [],
                            y: [ "measures" ],
                            fillMissingDates: true,
                            joinDateRange: false
                        }
                    })}
                />
            }
            
            {(props.formattedQueryStartDate && props.formattedQueryEndDate && showDetails) &&
                // CubeJS query
                <QueryRenderer
                    query={{
                        measures: [],
                        limit: 5000,
                        order: { "mission_fields_completion.total_completion_percentage" : "asc" },
                        timeDimensions: [{
                            dimension: "mission.created_at",
                            dateRange: [ props.formattedQueryStartDate, props.formattedQueryEndDate ]
                        }],
                        dimensions: [
                            "mission.jlb_ref",
                            "mission_fields_completion.total_mandatory_fields",
                            "mission_fields_completion.total_filled_fields",
                            "mission_fields_completion.total_completion_percentage",
                            "mission_fields_completion.general_info_mandatory_fields",
                            "mission_fields_completion.general_info_filled_fields",
                            "mission_fields_completion.general_info_completion_percentage",
                            "mission_fields_completion.receiver_and_lot_mandatory_fields",
                            "mission_fields_completion.receiver_and_lot_filled_fields",
                            "mission_fields_completion.receiver_and_lot_completion_percentage",
                            "mission_fields_completion.bills_of_lading_mandatory_fields",
                            "mission_fields_completion.bills_of_lading_filled_fields",
                            "mission_fields_completion.bills_of_lading_completion_percentage",
                            "mission_fields_completion.subcontractor_invoice_mandatory_fields",
                            "mission_fields_completion.subcontractor_invoice_filled_fields",
                            "mission_fields_completion.subcontractor_invoice_completion_percentage",
                            "mission_fields_completion.invoice_mandatory_fields",
                            "mission_fields_completion.invoice_filled_fields",
                            "mission_fields_completion.invoice_completion_percentage"
                        ],
                        
                        filters: detailsFilters
                    }}
                    cubejsApi={cubejsApi}
                    resetResultSetOnChange={false}
                    render={(props) => renderChart({
                        ...props,
                        chartType: 'table',
                        pivotConfig: {
                            x: [
                                "mission.jlb_ref",
                                "mission_fields_completion.total_mandatory_fields",
                                "mission_fields_completion.total_filled_fields",
                                "mission_fields_completion.total_completion_percentage",
                                "mission_fields_completion.general_info_mandatory_fields",
                                "mission_fields_completion.general_info_filled_fields",
                                "mission_fields_completion.general_info_completion_percentage",
                                "mission_fields_completion.receiver_and_lot_mandatory_fields",
                                "mission_fields_completion.receiver_and_lot_filled_fields",
                                "mission_fields_completion.receiver_and_lot_completion_percentage",
                                "mission_fields_completion.bills_of_lading_mandatory_fields",
                                "mission_fields_completion.bills_of_lading_filled_fields",
                                "mission_fields_completion.bills_of_lading_completion_percentage",
                                "mission_fields_completion.subcontractor_invoice_mandatory_fields",
                                "mission_fields_completion.subcontractor_invoice_filled_fields",
                                "mission_fields_completion.subcontractor_invoice_completion_percentage",
                                "mission_fields_completion.invoice_mandatory_fields",
                                "mission_fields_completion.invoice_filled_fields",
                                "mission_fields_completion.invoice_completion_percentage"
                            ],
                            y: [],
                            fillMissingDates: true,
                            joinDateRange: false
                        }
                    })}
                />
            }
        </div>
    );
}

export default FieldsCompletionTableChart;
      