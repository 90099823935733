import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { isCustomerRole } from 'components/config/Roles';
import TextFieldCustom from 'components/select/TextFieldCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";

import MissionDetailsInvoiceForm from './MissionDetailsInvoiceForm';
import MissionDetailsInvoiceView from "./MissionDetailsInvoiceView";


const MissionDetailsInvoice = forwardRef((props, ref) => {

  //config
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { isRequired } = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {controlFieldsInvoice, setControlFieldsInvoice} = useContext(ContextMissionWorkflow);
  const {toogleBlockOnEditAndCheckBlockOnWorkflowError} = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorMsg, setGetControlFieldsErrorMsg } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorState, setGetControlFieldsErrorState } = useContext(ContextMissionWorkflow);

  //invoice note
  const { invoicesNote, setInvoicesNote } = useContext(ContextMissionDetails);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit, controlFieldsInvoice);
  }, [blockEdit, controlFieldsError]);


  //function interactive
  const handleChangeInvoicesNote = (event) => {
    setInvoicesNote(event.target.value);
  };


  if (!JLBCustomerMode && (canWrite("invoice_block") || canRead("invoice_block"))) {
    return (
      <div id="block_invoice" className={(!onEdit) ? 'contentDetail block_details view' : 'contentDetail block_details view editing'}>
        <h4>Invoices</h4>

        {Boolean(canWrite("invoice_block") || canRead("invoice_block")) && 
          (!onEdit) ? <MissionDetailsInvoiceView/> : <MissionDetailsInvoiceForm/> 
        }

        <div className="lineContent">
          <TextFieldCustom
            id="standard-multiline-invoice"
            label="Invoices note"
            variant="standard"
            value={invoicesNote}
            fullWidth
            multiline
            onBlur={handleChangeInvoicesNote}
            read={canRead("invoices_note")}
            write={onEdit && canWrite("invoices_note")}
            required={isRequired("invoices_note")}
            controlFieldsStatus={onEdit ? fieldsSettingsLoaded : true}
            error={getControlFieldsErrorState("invoices_note")}
            helperText={getControlFieldsErrorMsg("invoices_note")}
          />
        </div>

      </div>
    );
  }
});

export default MissionDetailsInvoice;
