import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { MissionDetailsBLProvider } from "./ContextMissionDetailsBL";

import MissionDetailsBLForm from './MissionDetailsBLForm';
import MissionDetailsBLView from "./MissionDetailsBLView";


const MissionDetailsBL = forwardRef((props, ref) => {

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const {controlFieldsBL, setControlFieldsBL} = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {toogleBlockOnEditAndCheckBlockOnWorkflowError} = useContext(ContextMissionWorkflow);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit, controlFieldsBL);
  }, [blockEdit, controlFieldsError]);


  if (canWrite("bl_block") || canRead("bl_block")) {
    return (
      <MissionDetailsBLProvider>
        <div id="block_bl" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
          <h4>Bills of Lading</h4>

          {(!onEdit) ? <MissionDetailsBLView/> : <MissionDetailsBLForm/>}

        </div>
      </MissionDetailsBLProvider>
    );
  }
});

export default MissionDetailsBL;
