import React, { useState } from 'react';
import AnalyticsToolbar from './Toolbar/AnalyticsToolbar';

const AnalyticsDashboardInfo = (props) => {
    const [selectedChartType, setSelectedChartType] = useState(props?.defaultChartType);
    const [selectedMissionStatusList, setSelectedMissionStatusList] = useState([]);
    const [selectedMissionType, setSelectedMissionType] = useState(null);
    const [formattedQueryStartDate, setFormattedQueryStartDate] = useState(null);
    const [formattedQueryEndDate, setFormattedQueryEndDate] = useState(null);

    const ChartComponent = props?.chartComponentMap[selectedChartType];

    return (
        <div className="chartDashboardContainer">
            {/* Renders charts toolbars */}
            <AnalyticsToolbar
                selectedChartType={selectedChartType}
                setSelectedChartType={setSelectedChartType}
                selectedMissionStatusList={selectedMissionStatusList}
                setSelectedMissionStatusList={setSelectedMissionStatusList}
                selectedMissionType={selectedMissionType}
                setSelectedMissionType={setSelectedMissionType}
                setFormattedQueryStartDate={setFormattedQueryStartDate}
                setFormattedQueryEndDate={setFormattedQueryEndDate}
                chartTitle={props?.chartTitle}
                chartTypeButtons={props?.chartTypeButtons}
                missionStatusFilter={props?.missionStatusFilter}
                missionTypeFilter={props?.missionTypeFilter}
                hiddenMissionTypes={props?.hiddenMissionTypes}
            />
            {/* Renders charts */}
            <ChartComponent
                formattedQueryStartDate={formattedQueryStartDate}
                formattedQueryEndDate={formattedQueryEndDate}
                selectedMissionStatusList={props?.missionStatusFilter ? selectedMissionStatusList : null}
                selectedMissionType={props?.missionTypeFilter ? selectedMissionType : null}
                hiddenMissionTypes={props?.hiddenMissionTypes}
                hideCanceledMissions={props?.hideCanceledMissions}
            />
        </div>
    );
};

export default AnalyticsDashboardInfo;
