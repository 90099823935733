import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { randomId } from '@mui/x-data-grid-generator';

import SignaturePlaceRow from './MissionDetailsBLSignaturePlaceRow';
import UnaffectedRowsGrid from './MissionDetailsBLUnaffectedRowsGrid';
import BlockFormSaveButton from '../../../Block/BlockFormSaveButton';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";
import { ContextMissionDetailsBL } from "./ContextMissionDetailsBL";

import axios from 'api/axios';

import 'styles/BLForm.css';


const MissionDetailsBLForm = forwardRef((props, ref) => {

  //config
  const navigate = useNavigate();
  const {blRef} = useContext(ContextMissionDetails);

  //workflow state
  const {canWrite} = useContext(ContextMissionWorkflow);

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //init BL data
  const {rowsBL, setRowsBL} = useContext(ContextMissionDetailsReceiverBL);
  const {rowsSignaturePlace, setRowsSignaturePlace} = useContext(ContextMissionDetailsBL);

  const {countries, setCountries} = useContext(ContextMissionDetailsBL);

  const [rowsBLLoaded, setRowsBLLoaded] = useState(false);
  const [rowsUnaffectedUpdated, setRowsUnaffectedUpdated] = useState(false);

  //handle
  const {saveBLs} = useContext(ContextMissionDetailsBL);
  const {handleClickCancel} = useContext(ContextMissionDetailsBL);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await saveBLs(true);
    await handleClickCancel();
  };

  const handleClickSignaturePlace = () => {
    const id = randomId();
    setRowsSignaturePlace((oldRows) => [...oldRows, { id: id, label: '', bl_rows: [], isExpanded: true }]);
  };


  //useEffect
  useEffect(() => {
    loadingCountries();
    loadingTypesOfIntervention();
    loadBeansType();
  }, []);

  //Refresh Rows on props update
  useEffect(() => {
    if (!rowsBLLoaded || rowsUnaffectedUpdated) {
      refreshBLs(rowsBL);
      setRowsBLLoaded(true);
      setRowsUnaffectedUpdated(false);
    }
  }, [rowsBL, rowsUnaffectedUpdated]);


  //function interactive
  const { refreshBLs } = useContext(ContextMissionDetailsBL);
  const { loadBeansType } = useContext(ContextMissionDetailsBL);
  const { loadingTypesOfIntervention } = useContext(ContextMissionDetailsBL);

  const loadingCountries = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };
      const response = await axios.get("country/list", config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = {
          id: response.data[i].id,
          value: response.data[i].id,
          label: response.data[i].label
        };
      }
      rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      setCountries(rowsFromApi);

    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }


  return (
    <div>
      {Boolean(canWrite("bl_block")) &&
        <Box sx={{ '& > button': { m: 1 } }}>
          <LoadingButton
            onClick={handleClickSignaturePlace}
            loadingIndicator="Adding Signature Place"
            variant="outlined"
          >
            Add Signature Place
          </LoadingButton>
        </Box>
      }

      <div className="blocSignaturePlaceDiv">
        {rowsSignaturePlace.length >= 1 && rowsSignaturePlace.map((item, index) => {
          return (
            <SignaturePlaceRow
              key={item.id}
              {...props}
              signaturePlace={item}
              isExpanded={item.isExpanded !== undefined ? item.isExpanded : false}
            />
          );
        })}
      </div>

      <div className="blocUnaffectedRowsDiv">
        <UnaffectedRowsGrid {...props} />
      </div>

      <BlockFormSaveButton blockRef={blRef} blockSave={handleClickSave}/>

    </div>
  );
});

export default MissionDetailsBLForm;
