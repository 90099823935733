import React, { useEffect, useRef, forwardRef, useState } from 'react';

import { useNavigate } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';

import Menu from 'components/page/section/Menu';
import { FilterProvider } from "context/ContextFilter";
import { checkSecurity } from 'components/config/Security';
import { isInternalRole, isCustomerRole, isPortRole } from 'components/config/Roles';

import DashboardMainList from 'components/page/section/DashboardMain/DashboardMainList';
import DashboardMainFilter from "components/page/section/DashboardMain/DashboardMainFilter";

import 'styles/globalPortalDashboard.css';
import 'styles/DashboardList.css';
import 'bootstrap/dist/css/bootstrap.css';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DashboardMain = (props) => {
  //config
  const navigate = useNavigate();
  const filterRef = useRef('filterRef');
  const filterName = !props?.archivedList == 1 ? 'filter' : 'filterArchived';
  const JLBPortMode = isPortRole(localStorage.getItem("role"));
  const JLBCustomerMode = isCustomerRole(localStorage.getItem("role"));
  const JLBInternalMode = isInternalRole(localStorage.getItem("role"));


  //CHECK SECURITY
  useEffect(() => {
    checkSecurity(navigate, 'DashboardList');
  }, []);
  //END SECURITY


  //function
  function initFilterMissions(totalsRowParam) {
    filterRef.current.initFilter(totalsRowParam)
  }


  return (
    <div className='FULL-CONTENT DASHBOARD'>
      <FilterProvider>
        <div className="content">
          <DashboardMainFilter
            ref={filterRef}
            filterName={filterName}
            archivedList={props?.archivedList}
            // Set visible filters
            visibleFilters={{
              statusFilter: true,
              surveyHandlerFilter: !JLBPortMode,
              principalsFilter: !JLBCustomerMode,
              missionTypeFilter: true,
              portFilter: !JLBPortMode,
              insuredFilter: true,
              receiverFilter: true,
              vesselFilter: true,
              contractNumberFilter: !JLBCustomerMode,
              cancelFilter: JLBInternalMode
            }}
            // Set visible categories 
            visibleCategories={{
              refJLBCategory: true,
              portCategory: true,
              principalsCategory: !JLBCustomerMode,
              principalsRefCategory: JLBCustomerMode,
              vesselCategory: true,
              billOfLadingCategory: true,
              warehouseCategory: true,
              receiverCategory: true,
              cargoCategory: true,
              traderCategory: true,
              insuredCategory: !JLBCustomerMode,
              localOfficeCategory: JLBInternalMode,
              typeOfInterventionCategory: JLBInternalMode,
              contractNumberCategory: !JLBCustomerMode
            }}
          />
          <div className="contentList">
            <DashboardMainList
              archivedList={props?.archivedList}
              initFilterMissions={initFilterMissions}
              // Set visible columns
              visibleColumns={{
                missionColorColumn: true,
                refJLBColumn: true,
                internalNoteColumn: JLBCustomerMode || JLBPortMode,
                publicNoteColumn: JLBCustomerMode,
                missionTypeLabelColumn: JLBInternalMode,
                createdDateColumn: true,
                statusColumn: true,
                contractNumberColumn: !JLBCustomerMode,
                portColumn: true,
                principalsColumn: !JLBCustomerMode,
                principalsRefColumn: JLBCustomerMode,
                insuredColumn: true,
                vesselColumn: true,
                tonnageColumn: true,
                receiverColumn: true,
                billOfLadingColumn: true,
                billOfLadingSIColumn: JLBInternalMode,
                warehouseColumn: true,
                localOfficeColumn: JLBInternalMode,
                typeOfInterventionColumn: JLBInternalMode
              }}
            />
          </div>
        </div>
      </FilterProvider>
      <Menu />
    </div>
  )
}

export default DashboardMain;
