import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { forwardRef, useImperativeHandle } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { random } from "@mui/x-data-grid-generator";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";


import CountryForm from "../Company/Country/CountryForm";

import "bootstrap/dist/css/bootstrap.css";
import "styles/globalPortalDashboard.css";
import "styles/detailsCommon.css";
import "styles/Popin.css";

import axios from "api/axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CompanyForm = forwardRef((props, ref) => {
  // CHECK SECURITY
  const navigate = useNavigate();

  const GETCOMPANYBYID_URL = "company/" + props.idCompany;
  const GETCOMPANYTYPES_URL = "company/types";
  const GETCOMPANYNAMES_URL = "company/list";
  const POSTCOMPANY_URL = "/company";
  const UPDATECOMPANY_URL = "/company/update";

  const countryFormRef = useRef("countryForm");

  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [companyAddress, setCompanyAddress] = React.useState("");
  const [companyZipCode, setCompanyZipCode] = React.useState("");
  const [companyCity, setCompanyCity] = React.useState("");
  const [companyEmail, setCompanyEmail] = React.useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = React.useState("");

  const [rowsCompanyCountries, setRowsCompanyCountries] = React.useState([]);

  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [companyAccessRoles, setCompanyAccessRoles] = React.useState({
    is_jlb: false,
    access_sub: false,
  });
  const { is_jlb, access_sub } = companyAccessRoles;
  const [companyAllNames, setCompanyAllNames] = React.useState([]);

  const isAtLeastOneTypeChecked = companyTypes.some((type) => type.checked);
  const isCompanyNameEmpty = companyName.trim() === "" ? false : true; 
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  useEffect(() => {

    if (props.update == 1) {
        loadingCompanyDetails();
    } else {
        loadingAllCompanyTypes();
        loadingAllCompanyNames();
    }
  }, []);

  useImperativeHandle(ref, () => ({
     async saveComponent() {
        const {newElementId, newElementName} = await saveCompanyForm();
        return {newElementId, newElementName};
     },
  }));

  // BUTTON DISABLE CONTROL
  useEffect(() => {
    const validateFields = () => {
      return (
        !isAtLeastOneTypeChecked ||
        !isCompanyNameEmpty ||
        !!nameError ||
        !!emailError ||
        !!phoneError
      );
    };
    
    if (props.setComponentTreatmentNotValidated) {
      props.setComponentTreatmentNotValidated(validateFields());
    }
  }, [isAtLeastOneTypeChecked, isCompanyNameEmpty, nameError, emailError, phoneError]);
  


  function setSnackErrorMsg(data) {
    var errorMsg = "Technical error ! Please try again or contact our support.";
    return errorMsg;
  }

  const loadingAllCompanyTypes = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var url = GETCOMPANYTYPES_URL;

      const response = await axios.get(url, config);

      const formattedPreChecked = {};
      if (props.preChecked) {
        for (const preChecked in props.preChecked) {
          // Split camelCase or underscore_separated words
          const tempPreChecked = preChecked.split(/(?=[A-Z])|_/);
          // Convert words to lowercase and join with spaces
          const formattedKey = tempPreChecked.map(word => word.toLowerCase()).join(' ');
          formattedPreChecked[formattedKey] = props.preChecked[preChecked];
        }
      }  
      
      const transformedResponse = response.data.map((type) => ({
        id: type.id,
        label: type.label,
        roles: type.roles.map((role) => role.label),
        checked: formattedPreChecked ? type.roles.some((role) => formattedPreChecked[role.label.toLowerCase()]) : false,
        isPreChecked: formattedPreChecked ? type.roles.some((role) => formattedPreChecked[role.label.toLowerCase()]) : false,
        disabled: false,
      }));
      
      const countPreChecked = transformedResponse.filter(type => type.isPreChecked).length;

      if (countPreChecked === 1) {
        const preCheckedType = transformedResponse.find(type => type.isPreChecked);
        preCheckedType.disabled = true;
      }
      setCompanyTypes(transformedResponse);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg =
          !!err.response != false ? setSnackErrorMsg(err.response.data) : "";
        props.setSnackBarMessage(errorMsg);
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setLoadingUpdate(true);
    }
  };

  const loadingAllCompanyNames = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var url = GETCOMPANYNAMES_URL;

      const response = await axios.get(url, config);

      const transformedResponse = response.data.map((name) => ({
        id: name.id,
        name: name.name,
      }));
      setCompanyAllNames(transformedResponse);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg =
          !!err.response != false ? setSnackErrorMsg(err.response.data) : "";
        props.setSnackBarMessage(errorMsg);
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setLoadingUpdate(true);
    }
  };

  const loadingCompanyDetails = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var urlDetails = GETCOMPANYBYID_URL;
      var urlTypes = GETCOMPANYTYPES_URL;
      var urlNames = GETCOMPANYNAMES_URL;

      const responseDetails = await axios.get(urlDetails, config);
      const responseTypes = await axios.get(urlTypes, config);
      const responseNames = await axios.get(urlNames, config);   

      setCompanyName(responseDetails.data.data.name ? responseDetails.data.data.name : "");
      setCompanyAddress(responseDetails.data.data.adresse ? responseDetails.data.data.adresse : "");
      setCompanyZipCode(responseDetails.data.data.cp ? responseDetails.data.data.cp : "");
      setCompanyCity(responseDetails.data.data.ville ? responseDetails.data.data.ville : "");
      setCompanyEmail(responseDetails.data.data.email ? responseDetails.data.data.email : "");
      setCompanyPhoneNumber(responseDetails.data.data.tel ? responseDetails.data.data.tel : "");
      setCompanyAccessRoles({...companyAccessRoles,is_jlb: (responseDetails.data.data.jlb == "1"),access_sub: (responseDetails.data.data.access_subcontractor == "1")});
      
      var condition = responseDetails.data.data.types.length > 0 && responseTypes.data?.length > 0;
      const tempCompanyTypes = responseTypes.data.map((type) => {
        var checked = false;
        if (condition) {
          checked = responseDetails.data.data.types.some(
            (detailType) => detailType.label === type.label
          );
        }
        return {
          id: type.id,
          label: type.label,
          roles: type.roles.map((role) => role.label),
          checked: checked,
        };
      });
      setCompanyTypes(tempCompanyTypes);

      if (responseDetails.data.data.countries) {
        const tempRowsCompanyCountries = responseDetails.data.data.countries.map(
          (country) => ({
            id: country.country.id,
            country: country.country.label,
          })
        );
        setRowsCompanyCountries(tempRowsCompanyCountries);
      }

      if (responseNames.data.length > 0) {
        const tempCompanyAllNames = responseNames.data.map((name) => {
          return {
            id: name.id,
            name: name.name,
          };
        });
        setCompanyAllNames(tempCompanyAllNames);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg =
          !!err.response != false ? setSnackErrorMsg(err.response.data) : "";
        props.setSnackBarMessage(errorMsg);
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setLoadingUpdate(true);
    }
  };

  const handleCompanyNameChange = (event) => {
    const name = event.target.value;
    setCompanyName(event.target.value);

    if (name.trim() === "") {
      setNameError("");
      return;
    }

    const exists = companyAllNames.some(
      (company) => company.name.toLowerCase() === name.toLowerCase()
    );

    if (exists) {
      setNameError("Name already exists");
    } else {
      setNameError("");
    }
  };

  const handleCompanyAddressChange = (event) => {
    setCompanyAddress(event.target.value);
  };
  const handleCompanyZipCodeChange = (event) => {
    setCompanyZipCode(event.target.value);
  };
  const handleCompanyCityChange = (event) => {
    setCompanyCity(event.target.value);
  };
  const handleCompanyEmailChange = (event) => {
    const email = event.target.value;
    setCompanyEmail(email);

    if (email === "") {
      setEmailError("");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }
  };
  const handleCompanyPhoneNumberChange = (event) => {
    const phone = event.target.value;
    setCompanyPhoneNumber(phone);

    if (phone === "") {
      setPhoneError("");
    } else {
      const phoneRegex = /^[\d\s()+]+$/;;
      if (!phoneRegex.test(phone)) {
        setPhoneError("Invalid phone format");
      } else {
        setPhoneError("");
      }
    }
  };

  const handleCompanyTypesChange = (event) => {
    const { name, checked, disabled  } = event.target;

    const updatedCompanyTypes = companyTypes.map((companyType) => {
      if (companyType.label === name) {
        return { ...companyType, checked };
      }
      return companyType;
    });

    var filteredTypes = updatedCompanyTypes.filter((type) => type.checked && type.isPreChecked);
    var disabledTypes = updatedCompanyTypes.filter((type) => type.disabled);

    if(filteredTypes.length == 1 && disabledTypes.length !== 1 ){
       const finalCompanyTypes = updatedCompanyTypes.map((companyType) => {
        if (companyType.id === filteredTypes[0].id) {
          return { ...companyType, disabled: !disabled };
        }
        return companyType;
      });
      setCompanyTypes(finalCompanyTypes);
    } else if(filteredTypes.length > 1 && disabledTypes.length === 1 ){ 
      const finalCompanyTypes = updatedCompanyTypes.map((companyType) => {
      if (companyType.id === disabledTypes[0].id) {
        return { ...companyType, disabled: !!disabled };
      }
        return companyType;
      });
      setCompanyTypes(finalCompanyTypes);
    } else {
      setCompanyTypes(updatedCompanyTypes);
    }
  };

  const handleCompanyAccessRolesChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setCompanyAccessRoles({
        ...companyAccessRoles,
        is_jlb: name === "is_jlb" ? checked : false,
        access_sub: name === "access_sub" ? checked : false,
      });
    } else {
      setCompanyAccessRoles({
        ...companyAccessRoles,
        [name]: checked,
      });
    }
  };

  // CREATE/UPDATE COMPANY START
  const saveCompanyForm = async () => {
    setLoadingUpdate(true);
    try {
      var formData = new FormData();
      var saveCompanyDetailsPromises = [];

      if (props.update == 1) {
        formData.append("id_company", props.idCompany);
      }
      formData.append("name", companyName);
      formData.append("adresse", companyAddress);
      formData.append("cp", companyZipCode);
      formData.append("ville", companyCity);
      formData.append("email", companyEmail);
      formData.append("tel", companyPhoneNumber);

      if (
        companyTypes &&
        companyTypes.filter((type) => type.checked).length > 0
      ) {
        const tempCheckedCompanyTypes = [
          ...companyTypes
            .filter((row) => row.checked === true)
            .map((row) => row.id),
        ];
        for (var i = 0; i < tempCheckedCompanyTypes.length; i++) {
          formData.append("types[]", tempCheckedCompanyTypes[i]);
        }
      }

      if (companyAccessRoles) {
        formData.append("jlb", companyAccessRoles.is_jlb ? "1" : "0");
        formData.append(
          "access_subcontractor",
          companyAccessRoles.access_sub ? "1" : "0"
        );
      }

      // SAVE COUNTRIES
      countryFormRef.current.saveComponent(formData);

      var urlRequest = POSTCOMPANY_URL;
      if (props.update == 1) {
        urlRequest = UPDATECOMPANY_URL;
      }

      var config = {
        url: urlRequest,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      const response = await axios(config);
      saveCompanyDetailsPromises.push(response);

    // IDCOMPANY SETTER
      var idCompanyParam = props.idCompany;
      var nameCompany;
      if (props.update == 0) {
        var idCompanyParam = response.data.data.id;
        nameCompany = response.data.data.name;
      }

      // DELETE CACHE FOR REFRESH
      if (response && localStorage) {
        localStorage.removeItem("company/list?company_role=Principals");
        localStorage.removeItem("company/list?company_role=Trader");
        localStorage.removeItem("company/list?company_role=Insured");
        localStorage.removeItem("company/list?company_role=Local Office");
        localStorage.removeItem("company/list?company_role=Receiver")
        localStorage.removeItem("company/list?company_role=Warehouse Keeper")
        localStorage.removeItem("company/list?company_role=Shipper")
      }

      await Promise.all(saveCompanyDetailsPromises);
      return { newElementId: idCompanyParam, newElementName: nameCompany };

    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg =
          !!err.response != false ? setSnackErrorMsg(err.response.data) : "";
        props.setSnackBarMessage(errorMsg);
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  };

  return (
    <div>
      <div className="lineContent">
      {!props.popinStyle &&
      <span>
      {companyName ? (<h2> <strong>{companyName}</strong> </h2> ) : ( <h2> <strong>New company</strong> </h2> )}
      </span>
      }
      </div>
      <Stack direction="row" spacing={props.popinStyle ? 5 : 20} sx={{ pl: "1.2%" }}>
      <Box sx={{ width: props.popinStyle ? "35%" :"45%" }}>
          <div className="lineContent">
          <TextField
              required
              id="company_name"
              label="Name"
              type="text"
              value={companyName}
              variant="standard"
              onChange={handleCompanyNameChange}
              style={{ width: "100%" }}
              error={!isCompanyNameEmpty || !!nameError}
              helperText={nameError}
          />
          </div>
          <div className="lineContent">
          <TextField
              id="company_address"
              label="Address"
              type="text"
              value={companyAddress}
              variant="standard"
              onChange={handleCompanyAddressChange}
              style={{ width: "100%" }}
          />
          </div>
          <div className="lineContent">
          <Stack direction="row" spacing={4}>
              <TextField
              id="company_zip_code"
              label="Zip code"
              type="text"
              value={companyZipCode}
              variant="standard"
              onChange={handleCompanyZipCodeChange}
              style={{ width: "35%" }}
              />
              <TextField
              id="company_city"
              label="City"
              type="text"
              value={companyCity}
              variant="standard"
              onChange={handleCompanyCityChange}
              style={{ width: "calc(65% - 32px)" }}
              />
          </Stack>
          </div>
          <div className="lineContent">
          <TextField
              id="company_email"
              label="Email"
              type="email"
              value={companyEmail}
              variant="standard"
              onChange={handleCompanyEmailChange}
              style={{ width: "100%" }}
              error={!!emailError}
              helperText={emailError}
          />
          </div>
          <div className="lineContent">
          <TextField
              id="company_phone_number"
              label="Phone"
              type="text"
              value={companyPhoneNumber}
              variant="standard"
              onChange={handleCompanyPhoneNumberChange}
              style={{ width: "100%" }}
              error={!!phoneError}
              helperText={phoneError}
          />
          </div>
          <LinearProgress hidden={loadingUpdate} id="loadingGeneral" />
          <div className="lineContent">
          <CountryForm
              ref={countryFormRef}
              rows={rowsCompanyCountries}
              setSnackBarMessage={props.setSnackBarMessage}
              setSnackBarType={props.setSnackBarType}
              setOpenSnackBar={props.setOpenSnackBar}
          />
          </div>
      </Box>
      <Box>
          {/* TYPES START */}
          <h4
          style={{
              color: isAtLeastOneTypeChecked ? "black" : "rgb(198, 26, 36)",
          }}
          >
          Types *
          </h4>
          {companyTypes.map(
          (companyType, index) => {
            const itemsPerGroup = Math.ceil(companyTypes.length / 2);
              return index % itemsPerGroup === 0 && (
                <FormControl
                    key={index}
                    sx={{ m: 3, marginRight: 0 , marginLeft: index !== 0 ? 0 : '' }}
                    component="fieldset"
                    variant="standard"
                >
                    {companyTypes
                    .slice(index, index + itemsPerGroup)
                    .map((type, innerIndex) => (
                        <FormControlLabel
                        key={innerIndex}
                        control={
                            <Checkbox
                            checked={type.checked}
                            onChange={handleCompanyTypesChange}
                            name={type.label}
                            disabled={type.disabled}
                            />
                        }
                        label={
                            <div>
                            {type.label}
                            <Tooltip
                                title={type.roles.map((role, roleIndex) => (
                                <span
                                    key={roleIndex}
                                    style={{ whiteSpace: "pre-line" }}
                                >
                                    {role}
                                    <br />
                                </span>
                                ))}
                                placement="right"
                            >
                                <HelpOutlineIcon
                                fontSize="small"
                                sx={{ ml: "5px" }}
                                />
                            </Tooltip>
                            </div>
                        }
                        />
                    ))}
                </FormControl>
                )
              }
            )}
          {/* TYPES END */}

          {/* ACCESS ROLE START */}
          <Box sx={{ marginTop: "50px" }}>
          <h4>Access role</h4>
          <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              variant="standard"
          >
              <FormGroup>
              <FormControlLabel
                  control={
                  <Checkbox
                      checked={is_jlb}
                      onChange={handleCompanyAccessRolesChange}
                      name="is_jlb"
                  />
                  }
                  label="JLB company"
              />
              <FormControlLabel
                  control={
                  <Checkbox
                      checked={access_sub}
                      onChange={handleCompanyAccessRolesChange}
                      name="access_sub"
                  />
                  }
                  label={
                  <div>
                    Full access local offices
                    <Tooltip
                        title={
                        <span
                            key={random}
                            style={{ whiteSpace: "pre-line" }}
                        >
                          Can access all Local Office informations, including offices not considered as JLB company
                          <br />
                        </span>
                        }
                        placement="right"
                    >
                        <HelpOutlineIcon
                        fontSize="small"
                        sx={{ ml: "5px" }}
                        />
                    </Tooltip>
                  </div>
                  }
              />
              </FormGroup>
          </FormControl>
          {/* ACCESS ROLE END */}
          </Box>
      </Box>
      </Stack>
    </div>
  );
});

export default CompanyForm;