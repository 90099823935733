import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import axios from 'api/axios';
import 'styles/ProtisSection.css';
import ProtisListItem from './MissionDetailsProtisListItem';
import {formatDate} from 'components/page/object/Utils'

const MissionDetailsProtisDocumentsForm = forwardRef((props, ref) => {
  
    const navigate = useNavigate();
    const [missionCanceled, setMissionCanceled] = useState(!!props.missionCanceled !== false ? props.missionCanceled : false);
    
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [protisDocuments, setProtisDocuments] = useState([]);
    const [protisDocumentsLoaded, setProtisDocumentsLoaded] = useState(false);
    
    const MISSIONDOCUMENTS_URL = "mission/document/list";

    // Load all list elements
    useEffect(() => {
      if (props.idMission) {
        if (protisDocumentsLoaded === false) { 
          loadDocuments();
        } else {
          setProtisDocuments(props.documentsList);
        }
      }
    }, [props?.documentsList]);
    
    // Open a folder or view a file
    const handleViewElementClick = (element) => {
      props.setDetailElementInfo(element);
    };
     
    const handleDeleteElementClick = (element) => {
      props.setConfirmDeleteModalElementInfo(element);
    };

    const loadDocuments = async () => {
      try {
        var config = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          params: {
            id_mission: props.idMission
          }
        };

        const response = await axios.get(MISSIONDOCUMENTS_URL, config);

        var documents = response.data?.data.map((document, index) => {
          document.isDocument = true;
          document.listPosition = index + 1;
          return document;
        })
        
        props.setDocumentsList(documents);
        setProtisDocuments(documents);
        setProtisDocumentsLoaded(true);
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        } else {
          props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
          props.setSnackBarType("error");
          props.setOpenSnackBar(true);
        }
      }
    }
    
    return (
      <div style={{marginTop: "2%"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ margin: 0 }}>Documents </h4>
          </div>
        </div>
        <List sx={{ m:1, width: '100%', bgcolor: 'background.paper', padding:'0px', borderRadius: '5px', border: '1px solid rgba(224, 224, 224)', height: loadingFiles ? '200px' : '400px', overflow: 'auto'}}>
          {!loadingFiles ?
            <>
              {protisDocuments?.map((element, index) => (
                <div key={`${element.id} + '-' + ${index}`}>
                  <ProtisListItem
                    {...props}
                    element={element}
                    handleDeleteElementClick={handleDeleteElementClick}
                    handleViewElementClick={handleViewElementClick}
                  />
                  {index !== protisDocuments.length - 1 && <Divider style={{ backgroundColor: 'grey' }} />}
                </div>
              ))}
            </>
          :
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          }
        </List>
      </div>
    );
});

export default MissionDetailsProtisDocumentsForm;
