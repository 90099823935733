import React, { useState, forwardRef, useContext, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';

import momentDate from 'moment';

import SelectCustom from 'components/select/SelectCustom';
import DetailField from 'components/page/object/DetailField';
import SelectCustomRefresh from 'components/select/SelectCustomRefresh';
import { isInternalRole, isCustomerRole } from 'components/config/Roles';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsGeneral } from "./ContextMissionDetailsGeneral";


const MissionDetailsGeneralView = forwardRef((props, ref) => {

  //init config
  const {update} = useContext(ContextBlock);
  const {generalRef} = useContext(ContextMissionDetails);
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const {workflowLoaded, setWorkflowLoaded} = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);

  //mission general data
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);
  const { missionType, setMissionType } = useContext(ContextMissionDetailsGeneral);

  const { port, setPort } = useContext(ContextMissionDetailsGeneral);

  const { surveyHandler, setSurveyHandler } = useContext(ContextMissionDetailsGeneral);
  const { surveyHandlerDefault, setSurveyHandlerDefault } = useContext(ContextMissionDetailsGeneral);

  const { localOffice, setLocalOffice } = useContext(ContextMissionDetailsGeneral);

  const { localSurveyor, setLocalSurveyor } = useContext(ContextMissionDetailsGeneral);
  const { localSurveyorsList, setLocalSurveyorsList } = useContext(ContextMissionDetailsGeneral);
  const { loadingLocalSurveys, setLoadingLocalSurveys } = useContext(ContextMissionDetailsGeneral);

  const { principals, setPrincipals } = useContext(ContextMissionDetailsGeneral);
  const { principalsId, setPrincipalsId } = useContext(ContextMissionDetailsGeneral);
  const { principalsRef, setPrincipalsRef } = useContext(ContextMissionDetailsGeneral);

  const { trader, setTrader } = useContext(ContextMissionDetailsGeneral);

  const { insured, setInsured } = useContext(ContextMissionDetailsGeneral);
  const { insuredRef, setInsuredRef } = useContext(ContextMissionDetailsGeneral);

  const { vessel, setVessel } = useContext(ContextMissionDetailsGeneral);
  const { vesselArrival, setVesselArrival } = useContext(ContextMissionDetailsGeneral);

  const { tonnage, setTonnage } = useContext(ContextMissionDetailsGeneral);

  const { warehouse, setWarehouse } = useContext(ContextMissionDetailsGeneral);
  const { warehouseList, setWarehouseList } = useContext(ContextMissionDetailsGeneral);
  const { loadingWarehouse, setLoadingWarehouse } = useContext(ContextMissionDetailsGeneral);

  const { warehouseKeeper, setWarehouseKeeper } = useContext(ContextMissionDetailsGeneral);

  const { controlType, setControlType } = useContext(ContextMissionDetailsGeneral);

  const { dateETA, setDateETA } = useContext(ContextMissionDetailsGeneral);
  const { dateETB, setDateETB } = useContext(ContextMissionDetailsGeneral);
  const { dateCompletionOfDischarge, setDateCompletionOfDischarge } = useContext(ContextMissionDetailsGeneral);
  const { dateDOB, setDateDOB } = useContext(ContextMissionDetailsGeneral);
  const { dateDVD, setDateDVD } = useContext(ContextMissionDetailsGeneral);
  const { nextPortDischarge, setNextPortDischarge } = useContext(ContextMissionDetailsGeneral);

  const {dateBegining, setDateBegining} = useContext(ContextMissionDetailsGeneral);
  const {dateCompletion, setDateCompletion} = useContext(ContextMissionDetailsGeneral);
  const {dateInspection, setDateInspection} = useContext(ContextMissionDetailsGeneral);

  //context
  const { currentStage, setCurrentStage } = useContext(ContextMissionDetails);

  //handle
  const { handleClickBlockEdit } = useContext(ContextBlock);


  //function interactive
  const {createMissionTypeHandler} = useContext(ContextMissionDetailsGeneral);
  const {createSurveyHandlerHandler} = useContext(ContextMissionDetailsGeneral);
  const {createControlTypeHandler} = useContext(ContextMissionDetailsGeneral);
  const {createCurrentStageHandler} = useContext(ContextMissionDetailsGeneral);


  return (
    <div>
      <div className="lineContent noJustify">
        <SelectCustom
          title="Type"
          api="mission_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={missionType}
          setDataValue={setMissionType}
          defaultAlias="XXX"
          creator={createMissionTypeHandler}
          read={canRead("id_mission_type")}
          write={false}
          controlFieldsStatus={true}
        />

        <DetailField title="Port" content={port} size={!JLBCustomerMode ? '':'large'} canRead={canRead('id_port')} controlFieldsStatus={fieldsSettingsLoaded} />

        <SelectCustom
          title="Survey handler"
          api="survey_handler/list"
          apiParam=""
          apiParamValue=""
          dataValue={surveyHandler}
          setDataValue={setSurveyHandler}
          defaultAlias=""
          defaultValue={surveyHandlerDefault}
          creator={createSurveyHandlerHandler}
          read={canRead("id_survey_handler")}
          write={false}
          controlFieldsStatus={true}
        />

        {(!JLBCustomerMode) &&
          <DetailField title='Local office' content={localOffice} canRead={canRead('id_local_office')} controlFieldsStatus={fieldsSettingsLoaded} />
        }
        {(!JLBCustomerMode) &&
          <SelectCustomRefresh
            label="Local surveyor"
            value={localSurveyor}
            list={localSurveyorsList}
            hidden={loadingLocalSurveys}
            read={canRead("id_local_surveyor")}
            write={false}
            controlFieldsStatus={true}
          />
        }
      </div>
      <div className="lineContent noJustify">
        {(!JLBCustomerMode) &&
          <DetailField title='Principals' content={principals} canRead={canRead('id_principal')} controlFieldsStatus={fieldsSettingsLoaded} />
        }
        <DetailField title='Principal ref' content={principalsRef} size={!JLBCustomerMode ? '':'large'} canRead={canRead('principal_ref')} controlFieldsStatus={fieldsSettingsLoaded} />
        {(!JLBCustomerMode) &&
          <DetailField title='Trader' content={trader} canRead={canRead('id_trader')} controlFieldsStatus={fieldsSettingsLoaded} />
        }
        <DetailField title='Insured' content={insured} size={!JLBCustomerMode ? '':'large'} canRead={canRead('id_insured')} controlFieldsStatus={fieldsSettingsLoaded} />
        <DetailField title='Insured ref' content={insuredRef} size={!JLBCustomerMode ? '':'large'} canRead={canRead('insured_ref')} controlFieldsStatus={fieldsSettingsLoaded} />
      </div>
      <div className="lineContent separator noJustify">
        <DetailField title='Vessel (BL)' content={vessel} canRead={canRead('id_vessel')} controlFieldsStatus={fieldsSettingsLoaded} />
        <DetailField title='Vessel (Arrival)' content={vesselArrival} canRead={canRead('id_vessel_arrival')} controlFieldsStatus={fieldsSettingsLoaded} />
        <DetailField title='Tonnage' content={Number(tonnage).toLocaleString()} canRead={canRead('vessel_tonnage')} controlFieldsStatus={fieldsSettingsLoaded} />
        
        <SelectCustomRefresh
          label="Warehouse"
          value={warehouse}
          list={warehouseList}
          hidden={loadingWarehouse}
          read={canRead("id_warehouse")}
          write={false}
          controlFieldsStatus={true}
        />

        {(JLBInternalMode) &&
          <DetailField title='Warehouse Keeper' content={warehouseKeeper} canRead={canRead('id_warehouse_keeper')} controlFieldsStatus={fieldsSettingsLoaded} />
        }  
      </div>
      <div className="lineContent noJustify">
        <SelectCustom
          title="Control type"
          api="control_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={controlType}
          setDataValue={setControlType}
          defaultAlias=""
          creator={createControlTypeHandler}
          read={canRead("id_control_type")}
          write={false}
          controlFieldsStatus={true}
        />

        <DetailField title='ETA' content={!!dateETA != false ? momentDate(dateETA).format("DD MMM YY"):""} size="small" canRead={canRead('estimated_time_arrival')} controlFieldsStatus={fieldsSettingsLoaded} />
        <DetailField title='ETB' content={!!dateETB != false ? momentDate(dateETB).format("DD MMM YY"):""} size="small" canRead={canRead('estimated_time_berthing')} controlFieldsStatus={fieldsSettingsLoaded}  />
        <DetailField title='Date of berthing' content={!!dateDOB != false ? momentDate(dateDOB).format("DD MMM YY"):""} size="small" canRead={canRead('date_of_berthing')} controlFieldsStatus={fieldsSettingsLoaded} />
        <DetailField title='Completion of Discharge' content={!!dateCompletionOfDischarge != false ? momentDate(dateCompletionOfDischarge).format("DD MMM YY"):""} size="small" canRead={canRead('completion_of_discharge')} controlFieldsStatus={fieldsSettingsLoaded} />
      </div>
      <div className="lineContent noJustify box-1">
        <DetailField title='Date vessel departure' content={!!dateDVD != false ? momentDate(dateDVD).format("DD MMM YY"):""} size="small" canRead={canRead('date_vessel_departure')} controlFieldsStatus={fieldsSettingsLoaded} />
        <DetailField title='Next port of discharge' content={nextPortDischarge} size="small" canRead={canRead('next_port_of_discharge')} controlFieldsStatus={fieldsSettingsLoaded} />

        <SelectCustom
          title="Current Stage"
          api="mission_stage/list"
          apiParam=""
          apiParamValue=""
          dataValue={currentStage}
          setDataValue={setCurrentStage}
          setDataAlias=""
          defaultAlias=""
          creator={createCurrentStageHandler}
          read={canRead("id_current_stage")}
          write={false}
          controlFieldsStatus={true}
        />

        <DetailField title='Date inspection' content={!!dateInspection != false ? momentDate(dateInspection).format("DD MMM YY"):""} size="small" canRead={canRead('inspection_date')} controlFieldsStatus={fieldsSettingsLoaded} />
      </div>
      <div className="lineContent noJustify box-2">
        <DetailField title='Date begining operations' content={!!dateBegining != false ? momentDate(dateBegining).format("DD MMM YY"):""} size="small" canRead={canRead('operations_begining_date')} controlFieldsStatus={fieldsSettingsLoaded} />

        <DetailField title='Date completion operations' content={!!dateCompletion != false ? momentDate(dateCompletion).format("DD MMM YY"):""} size="small" canRead={canRead('operations_completion_date')} controlFieldsStatus={fieldsSettingsLoaded} />
      </div>

      {(update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode) && 
      <div className="block_button">
        <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(generalRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
      </div>
      }
    </div>
  );
});

export default MissionDetailsGeneralView;
