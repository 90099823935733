import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Moment from 'moment';
import { saveAs } from 'file-saver';

import Button from '@mui/material/Button';
import MuiAlert from "@mui/material/Alert";
import Snackbar from '@mui/material/Snackbar';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { checkSecurity } from "components/config/Security";
import SelectCustom from "components/select/SelectCustom";
import SelectMultiple from "components/select/SelectMultiCheckbox";
import DesktopDatePickerCustom from 'components/select/DesktopDatePickerCustom';

import axios from 'api/axios';
import Menu from "./section/Menu";
import "styles/globalPortalDashboard.css";
import 'styles/MissionDetails.css';
import 'bootstrap/dist/css/bootstrap.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExportBI = () => {
  // CHECK SECURITY
  const navigate = useNavigate();

  const EXPORT_BI_URL = 'mission/export/bi';

  const [isDownload, setIsDownload] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [statusListId, setStatusListId] = useState([]);
  const [missionTypeListId, setMissionTypeListId] = useState([]);

  const [cargoListId, setCargoListId] = useState([]);
  const [cargoLoadingTypeListId, setCargoLoadingTypeListId] = useState([]);

  const groupByDefault = 0; //lot
  const [groupBy, setGroupBy] = useState(0);

  const groupByList = [];
  groupByList.push({ id: 0, value: 0, label: 'LOT' });
  groupByList.push({ id: 1, value: 1, label: 'STEVEDORE' });


  useEffect(() => {
    checkSecurity(navigate, "ExportBI");
  }, []);


  const handleCloseDownload = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDownload(false);
  }
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  }

  function createLabelHandler(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), label: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }

  const handleClickExportBI = async (e) => {
    try {
      const config = {
        url: EXPORT_BI_URL,
        responseType: 'arraybuffer',
        method: "post",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: null,
        params: {}
      }
    
      // dates
      config.params.start_date = startDate;
      config.params.end_date = endDate;
      config.params.group_by = groupBy > 0 ? 'stevedore' : 'lot';
      // multi filter
      if (statusListId.length > 0) {
        config.params.status_list = statusListId;
      }
      if (missionTypeListId.length > 0) {
        config.params.mission_type_list = missionTypeListId;
      }
      if (cargoListId.length > 0) {
        config.params.cargo_list = cargoListId;
      }
      if (cargoLoadingTypeListId.length > 0) {
        config.params.cargo_loading_type_list = cargoLoadingTypeListId;
      }
        
      const response = await axios(config);

      // Let the user save the file.
      var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      saveAs(blob, 'Export-BI_' + Moment().format('YYYYMMDD-HHmmss') + '.xlsx');
      setIsDownload(true);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setOpenSnackBar(true);
      }
    }
  }

  return (
    <div className="FULL-CONTENT DASHBOARD">
      <div className="content">
        <div className="contentFilter">
          <div className="lineContent" style={{ paddingLeft: '8px' }}>
            <h4>Export BI</h4>
          </div>

          <div className="lineContent noJustify">
            <DesktopDatePickerCustom
              label="From"
              value={startDate}
              onChange={setStartDate}
            />

            <DesktopDatePickerCustom
              label="To"
              value={endDate}
              onChange={setEndDate}
            />
          </div>
          
          <div className="lineContent noJustify">
            <SelectMultiple
              title="Status"
              loadByList="false"
              api="status/list"
              apiParam="status_type"
              apiParamValue="mission-internal"
              dataValue={statusListId}
              setDataValue={setStatusListId}
              setDataAlias=""
              defaultAlias="XXX"
              disableNone="true"
              creator={createLabelHandler}
              cached="false"
            />

            <SelectMultiple
              title="Type"
              loadByList="false"
              api="mission_type/list"
              apiParam=""
              apiParamValue=""
              dataValue={missionTypeListId}
              setDataValue={setMissionTypeListId}
              setDataAlias=""
              defaultAlias="XXX"
              disableNone="true"
              creator={createLabelHandler}
              cached="false"
            />
          </div>

          <div className="lineContent noJustify">
            <SelectMultiple
              title="Cargo"
              loadByList="false"
              api="cargo/list"
              apiParam=""
              apiParamValue=""
              dataValue={cargoListId}
              setDataValue={setCargoListId}
              setDataAlias=""
              defaultAlias="XXX"
              disableNone="true"
              creator={createLabelHandler}
              cached="false"
            />

            <SelectMultiple
              title="Type of loading"
              loadByList="false"
              api="cargo/loading_type/list"
              apiParam=""
              apiParamValue=""
              dataValue={cargoLoadingTypeListId}
              setDataValue={setCargoLoadingTypeListId}
              setDataAlias=""
              defaultAlias="XXX"
              disableNone="true"
              creator={createLabelHandler}
              cached="false"
            />
          </div>

          <div className="lineContent noJustify">
            <SelectCustom
              title="Group by"
              api=""
              apiParam=""
              apiParamValue=""
              datas={groupByList}
              dataValue={groupBy}
              setDataValue={setGroupBy}
              setDataAlias=""
              defaultAlias=""
              disableNone={true}
              defaultValue={groupByDefault}
              creator={createLabelHandler}
            />
          </div>

          <div className="lineContent noJustify">
            <Button
              size="small"
              sx={{ height: '100%', paddingTop: '8.5px', paddingBottom: '8.5px', margin: 'auto' }}
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={handleClickExportBI}
            >
              Export discharge
            </Button>
          </div>

        </div>
      </div>

      <Menu />

      <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleCloseDownload} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseDownload} severity="success" sx={{ width: '100%' }}>
          File saved in your downloads folder.
        </Alert>
      </Snackbar>

      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
          Technical error ! Please try again or contact our support.
        </Alert>
      </Snackbar>

    </div>
  );
};

export default ExportBI;
