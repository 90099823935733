import { useNavigate } from "react-router-dom";
import React, {useEffect} from "react";

import axios from 'api/axios';
const LOGOUT_URL = 'user/logout';

const Logout = () => {

    const navigate = useNavigate();

    //reset session
    localStorage.clear();

    useEffect(() => {

    try {
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            }
        };
        //const response = axios.get(LOGOUT_URL, config);
        //console.log(response);
      }
      catch(err) {console.log(err);}



      navigate('/', { replace: true });
    }, []);

    return (
        <div>Logout</div>
    )
}

export default Logout
