import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import LinearProgress from '@mui/material/LinearProgress';

import { checkSecurity } from 'components/config/Security';
import Menu from './section/Menu';

import { BlockProvider, ContextBlock } from "context/ContextBlock";
import { BlockTreatmentProvider } from "context/ContextBlockTreatment";
import { ShippingInstructionProvider, ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ShippingInstructionGeneralProvider } from './section/ShippingInstruction/General/ContextShippingInstructionGeneral';
import { ShippingInstructionMissionProvider } from './section/ShippingInstruction/Mission/ContextShippingInstructionMission';
import { ShippingInstructionReportProvider } from './section/ShippingInstruction/Report/ContextShippingInstructionReport';
import { ShippingInstructionBLProvider } from "./section/ShippingInstruction/BL/ContextShippingInstructionBL";
import { ShippingInstructionNoteProvider } from './section/ShippingInstruction/Note/ContextShippingInstructionNote';

import BlockSnackBar from './section/Block/BlockSnackBar';
import BlockInfosPopin from './section/Block/BlockInfosPopin';
import ShippingInstructionFloatingSaveButton from './section/ShippingInstruction/ShippingInstructionFloatingSaveButton';
import ShippingInstructionGeneral from './section/ShippingInstruction/General/ShippingInstructionGeneral';
import ShippingInstructionMission from "./section/ShippingInstruction/Mission/ShippingInstructionMission";
import ShippingInstructionReport from "./section/ShippingInstruction/Report/ShippingInstructionReport";
import ShippingInstructionBL from "./section/ShippingInstruction/BL/ShippingInstructionBL";
import ShippingInstructionFiles from './section/ShippingInstruction/File/ShippingInstructionFiles';
import ShippingInstructionNote from "./section/ShippingInstruction/Note/ShippingInstructionNote";

import "bootstrap/dist/css/bootstrap.css";
import "styles/globalPortalDashboard.css";
import "styles/detailsCommon.css";
import "styles/Popin.css";


const ShippingInstruction = (props) => {

  //init config
  const navigate = useNavigate();

  //init composant ref
  const contentRef = useRef(null);
  const generalRef = useRef("generalComp");
  const missionRef = useRef("missionComp");
  const reportRef = useRef("reportComp");
  const blRef = useRef("blComp");
  const noteRef = useRef("noteComp");
  const fileRef = useRef("fileForm");

  //init context params
  const contextParams = {
    update: props.update,
    refs: {
      contentRef: { ref: contentRef },
      fileRef: { ref: fileRef }
    },
    blocks: {
      generalRef: { ref: generalRef, allowCreation: true, saveOrder: 0 },
      missionRef: { ref: missionRef, saveOrder: 1 },
      reportRef: { ref: reportRef, saveOrder: 2 },
      blRef: { ref: blRef, saveOrder: 3 },
      noteRef: { ref: noteRef, allowCreation: true, saveOrder: 4 }
    }
  };


  useEffect(() => {
    checkSecurity(navigate, 'ShippingInstruction');
  }, []);


  return (
    <div className='FULL-CONTENT DASHBOARD'>
      <BlockProvider value={contextParams}>
        <ContextBlock.Consumer>
          {({ update, loadingUpdate }) => (
            <ShippingInstructionProvider>
              <ContextShippingInstruction.Consumer>
                {({ idShippingInstruction, blockRedirect, blockGetData, missions }) => (
                  <BlockTreatmentProvider value={{ 
                    idElement: idShippingInstruction,
                    blockRedirect: blockRedirect, 
                    blockGetData: blockGetData,

                    canWrite: () => {},
                    workflowLoaded: false,
                    idButtonWorkflow: 0,
                    setCheckWorkflowData: () => {},
                    controlFieldsErrorTruncate: () => {}
                  }}>
                    <ShippingInstructionGeneralProvider>
                      <ShippingInstructionMissionProvider>
                        <ShippingInstructionReportProvider>
                          <ShippingInstructionBLProvider>
                            <ShippingInstructionNoteProvider>
                              <div className="content">
                                <div ref={contentRef}>
                                  <div>
                                    <ShippingInstructionGeneral ref={generalRef} />
                                    <ShippingInstructionMission ref={missionRef} />
                                    <ShippingInstructionBL ref={blRef} />
                                    <ShippingInstructionReport ref={reportRef} />
                                    
                                    {missions && missions.length > 0 &&
                                    <ShippingInstructionFiles ref={fileRef} />
                                    }

                                    <ShippingInstructionNote ref={noteRef} />
                                  </div>
                                  <ShippingInstructionFloatingSaveButton />
                                  <BlockInfosPopin />
                                  <BlockSnackBar />
                                </div>
                                <LinearProgress hidden={((loadingUpdate && update === 1) || (update !== 1))} id="loadingGeneral" className="top" />
                              </div>
                            </ShippingInstructionNoteProvider>
                          </ShippingInstructionBLProvider>
                        </ShippingInstructionReportProvider>
                      </ShippingInstructionMissionProvider>
                    </ShippingInstructionGeneralProvider>
                  </BlockTreatmentProvider>
                )}
              </ContextShippingInstruction.Consumer>
            </ShippingInstructionProvider>
          )}
        </ContextBlock.Consumer>
      </BlockProvider>
      <Menu />
    </div>
  );
};

export default ShippingInstruction;
