import React, { useContext } from 'react';

import ConfirmDialog from 'components/page/object/ConfirmDialog';

import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";


export default function MissionDetailsWorkflowConfirm(props) {

  const { workflowStep } = useContext(ContextMissionWorkflow);
  const { workflowConfirmOpen, setWorkflowConfirmOpen } = useContext(ContextMissionWorkflow);

  return (
    <ConfirmDialog
      title={''}
      open={workflowConfirmOpen}
      setOpen={setWorkflowConfirmOpen}
      onConfirm={workflowStep}
      onDisConfirm={()=> void(0)}
    >
      {"Do you really want to perform this action ?"}
    </ConfirmDialog>
  );
};
