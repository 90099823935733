import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { GridLinkOperator } from "@mui/x-data-grid-pro";

import { checkSecurity } from "components/config/Security";
import Menu from "components/page/section/Menu";
import MailingListsList from "components/page/mailing/MailingLists/MailingListsList";

import "styles/globalPortalDashboard.css";
import "styles/MailingLists.css";
import "bootstrap/dist/css/bootstrap.css";

const MailingLists = () => {
    const navigate = useNavigate();
    
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState([]);

    const [loadingGrid, setLoadingGrid] = useState(false);
    const [dataGridLoaded, setDataGridLoaded] = useState(false);

    const [searchTxt, setSearchTxt] = useState("");
    const [searchTxtState, setSearchTxtState] = useState("");

    const [filterModel, setFilterModel] = useState({ items: [] });
    const [filterOn, setFilterOn] = useState(false);
    const [visibleRows, setVisibleRows] = useState([]);
  
    // CHECK SECURITY
    useEffect(() => {
        checkSecurity(navigate, "MailingLists");
        var filter = JSON.parse(localStorage.getItem("filter_mailing_lists"));

        if (filter) {
            //INIT SEARCH TXT CATEGORY
            if (filter.search) {
                setSearchTxt(filter.search);
            }
            resetFilterResetButton(filter.search);
        }
    }, []);
    // END SECURITY

    function setFilterSelectedLocalStorage(searchTxt) {
        var filter = { search: searchTxt };
        localStorage.setItem("filter_mailing_lists", JSON.stringify(filter));
    }

    function initFilterMailing(totalRowsParam) {
      var filter = JSON.parse(localStorage.getItem("filter_mailing_lists"));

      if (filter) {
        selectDataContent(filter.search, totalRowsParam);
        setSearchTxtAction(filter.search);
      }
    }

    function selectDataContent(searchTxtParam, totalRowsParam) {
        var searchTxtVar = searchTxt;
        var totalRowsVar = totalRows;
        if (searchTxtParam || totalRowsParam) {
            searchTxtVar = searchTxtParam;
            totalRowsVar = totalRowsParam ? totalRowsParam : totalRows;
        }

        setLoadingGrid(true);
        var selectedRows = [];
        var addRow = true;

        for (var i = 0; i < totalRowsVar.length; i++) {
            addRow = true;

            if (addRow) selectedRows[selectedRows.length] = totalRowsVar[i];
        }
        setRows(selectedRows);
        setLoadingGrid(false);
        setFilterSelectedLocalStorage(searchTxtVar);
        resetFilterResetButton(searchTxtVar);
    }

    function setSearchTxtAction(searchTxtAct) {

        if (searchTxtAct.length > 0) {
            setFilterModel({
                items: [
                    {
                        id: 1,
                        columnField: "title",
                        operatorValue: "contains",
                        value: searchTxtAct,
                    }
                ],
                linkOperator: GridLinkOperator.Or,
            });
        } else {
            setFilterModel({ items: [] });
        }
        setFilterSelectedLocalStorage(searchTxtAct);
        resetFilterResetButton(searchTxtAct);
    }

    const handleChangeSearchText = (event) => {
        setSearchTxt(event.target.value);
        if (event.target.value.length > 0) {
            setSearchTxtState("");
            setSearchTxtAction(event.target.value);
        } else {
            if (event.target.value.length === 0) {
                setSearchTxtState("");
                setSearchTxtAction(event.target.value);
            } else {
                setSearchTxtState("warning");
            }
        }
    };

    function resetFilterResetButton(searchTxt) {
        var showFilter = false;
        if (searchTxt.length > 0) {
            showFilter = true;
        }
        setFilterOn(showFilter);
    }

    function handleResetAll() {
        setSearchTxt("");
        setSearchTxtState("");

        setSearchTxtAction("", "");
        setFilterOn(false);
        localStorage.setItem("filter_mailing_lists", null);
    }

    return (
        <div className="FULL-CONTENT DASHBOARD">
            <div className="content">
                <div className="contentFilter">
                    <Box
                        sx={{ display: "flex", alignItems: "flex-end", width: "99%" }}
                        className="FilterSearchBlockField"
                    >
                        <Box className="SearchBoxText" sx={{ width: "99%" }}>
                            <SearchRoundedIcon
                                sx={{ color: "action.active" }}
                                className="iconeLeft"
                            />
                            <FormControl
                                variant="standard"
                                sx={{
                                m: 1,
                                minWidth: 300,
                                width: "100%",
                                paddingLeft: 3,
                                paddingRight: 2,
                                }}
                            >
                                <TextField
                                    id="input-search-with-sx"
                                    label="Search"
                                    variant="standard"
                                    value={searchTxt}
                                    fullWidth
                                    onChange={handleChangeSearchText}
                                    color={searchTxtState}
                                />
                            </FormControl>
                        </Box>
                        <div
                            className={filterOn ? "resetButtonFilterMailing" : "resetButtonFilterMailing hide"}
                            onClick={handleResetAll}
                        >
                            <i className="bx bx-reset"></i>
                            <span>Reset all</span>
                        </div>
                    </Box>
                </div>
                <div className="contentListMailing">
                    <MailingListsList
                        data={{
                            filterModel,
                            setFilterModel,
                            rows,
                            setRows,
                            setTotalRows,
                            loadingGrid,
                            setLoadingGrid,
                            setDataGridLoaded,
                            setVisibleRows,
                            openSnackBar,
                            setOpenSnackBar,
                            initFilterMailing
                        }}
                    />
                </div>
            </div>
            <Menu />
        </div>
    );
};

export default MailingLists;
