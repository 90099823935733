import React, { useState, useContext, useEffect, forwardRef } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';

import TextFieldCustom from 'components/select/TextFieldCustom';
import {isPortRole, isCustomerRole} from 'components/config/Roles';
import BlockFormSaveButton from '../../Block/BlockFormSaveButton';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";
import { ContextShippingInstructionNote } from "./ContextShippingInstructionNote";


const ShippingInstructionNote = forwardRef((props, ref) => {

  //config
  const { update } = useContext(ContextBlock);
  const { noteRef } = useContext(ContextShippingInstruction);
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //context
  const { internalNote, setInternalNote } = useContext(ContextShippingInstructionNote);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { toogleBlockOnEdit } = useContext(ContextShippingInstruction);

  //handle
  const { handleClickBlockEdit } = useContext(ContextBlock); 


  //useEffect
  useEffect(() => {
    toogleBlockOnEdit(ref, onEdit, setOnEdit);
  }, [blockEdit]);

  
  //function interactive
  const handleChangeInternalNote = (event) => setInternalNote(event.target.value);


  return (
    <div id="block_note" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
      <h4>Notes</h4>
    
        <div className="lineContent">
          {(!JLBCustomerMode) &&
          <FormControl className="textField-form-comment" sx={{ m: 1, width: '100%' }} size="small">
            <TextFieldCustom
              id="standard-multiline-internal"
              label="Internal note"
              multiline
              value={internalNote}
              onBlur={handleChangeInternalNote}
              variant="standard"
              fullWidth
              read={true}
              write={onEdit}
              required={false}
              controlFieldsStatus={true}
            />
          </FormControl>
          }
        </div>

        {Boolean(!onEdit && update == 1 && loadingUpdate && !JLBCustomerMode && !JLBPortMode) &&  
        <div className="block_button">
          <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(noteRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
        </div>
        }

        <ContextShippingInstructionNote.Consumer>
        {({saveShippingInstructionNote, handleClickCancel}) => (
          <>
          {(onEdit && update == 1) && 
          <BlockFormSaveButton blockRef={noteRef} 
            blockCanel={async ()=> await handleClickCancel()} 
            blockSave={async () => {
              //Remove focus from any focused element
              if (document.activeElement) {
                document.activeElement.blur();
              }
              //saving
              await saveShippingInstructionNote(true);
              await handleClickCancel();
            }}
            notWorkflowSaveButton={true}
          />
          }
          </>
        )}
        </ContextShippingInstructionNote.Consumer>

    </div>
  );
});

export default ShippingInstructionNote;
