import React, { useState, useEffect, forwardRef, useContext } from 'react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import { randomId } from '@mui/x-data-grid-generator';

import momentDate from 'moment';

import SelectCustom from 'components/select/SelectCustom';
import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridDatePickerCustom, DataGridTextCustom, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';
import BlockFormSaveButton from '../../Block/BlockFormSaveButton';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsInvoice } from "./ContextMissionDetailsInvoice";


const MissionDetailsInvoiceForm = forwardRef((props, ref) => {

  //init config
  const {invoiceRef} = useContext(ContextMissionDetails);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { isRequired } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorMsg } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorState } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);

  //mission general data
  const { idMission, setIdMision } = useContext(ContextMissionDetails);

  // Invoices Grid
  const {rowsInvoice, setRowsInvoice} = useContext(ContextMissionDetailsInvoice);
  const {rowsInvoiceDeleted, setRowsInvoiceDeleted} = useContext(ContextMissionDetailsInvoice);
  const {rowsReceiversInvoice, setRowsReceiversInvoice} = useContext(ContextMissionDetails);

  const { invoicer, setInvoicer } = useContext(ContextMissionDetails);

  const {loadingInvoiceTypes} = useContext(ContextMissionDetailsInvoice);
  const {invoiceTypes, setInvoiceTypes} = useContext(ContextMissionDetailsInvoice);
  
  //function interactive
  const {createInvoicersHandler} = useContext(ContextMissionDetailsInvoice);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //handle
  const {saveInvoices} = useContext(ContextMissionDetailsInvoice);
  const {handleClickCancel} = useContext(ContextMissionDetailsInvoice);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await saveInvoices(true);
    await handleClickCancel();
  };


  //useEffect
  useEffect(() => {
    loadingInvoiceTypes();
  }, []);


  //function interactive
  const {processRowUpdateInvoice} = useContext(ContextMissionDetailsInvoice);

  const deleteRowInvoice = (event, id) => {
    setRowsInvoice(rowsInvoice.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsInvoiceDeleted((oldRows) => [...oldRows, { id: id }]);
  };

  //xGrid
  var listColumnInvoice = [], canWriteInvoice = canWrite("invoice_block");
  if (canWriteInvoice) {
    listColumnInvoice.push({
      field: 'id', headerName: '', editable: false, width: 60, sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => {
            deleteRowInvoice(event, cellValues.id);
          }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnInvoice.push({
    field: 'id_receiver', headerName: 'Receiver', sortable: true, width: 200, type: "singleSelect",
    editable: (canWriteInvoice ? true : false),
    valueOptions: rowsReceiversInvoice,
    valueFormatter: ({ id: rowId, value, field, api }) => {
      return DataGridListValueFormatterCustom(rowId, value, field, api);
    },
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridSelectCustom
          {...params}
          read={canRead('invoice_block')}
          write={canWrite('invoice_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateInvoice}
        />
      )
    }
  },
    {
      field: 'invoice_type', headerName: 'Invoice Type', sortable: true, flex: 1, type: "singleSelect",
      editable: (canWriteInvoice ? true : false),
      valueOptions: invoiceTypes,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    },
    {
      field: 'report_number', headerName: 'Report Number', sortable: true, flex: 1,
      editable: (canWriteInvoice ? true : false),
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    },
    {
      field: 'report_signature_date', headerName: 'Report Signature Date', sortable: true, width: 160, type: "date",
      editable: (canWriteInvoice ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    },
    {
      field: 'number', headerName: 'JLB Invoice Number', sortable: true, flex: 1,
      editable: (canWriteInvoice ? true : false),
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    },
    {
      field: 'date', headerName: 'JLB Invoice Date', sortable: true, width: 160, type: "date",
      editable: (canWriteInvoice ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    },
    {
      field: 'invoice_sent_date', headerName: 'Sent Date', sortable: true, width: 160, type: "date",
      editable: (canWriteInvoice ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    },
    {
      field: 'paid_on', headerName: 'Paid On', sortable: true, width: 160, type: "date",
      editable: (canWriteInvoice ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('invoice_block')}
            write={canWrite('invoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateInvoice}
          />
        )
      }
    });
  const columnsInvoice: GridColDef[] = listColumnInvoice;

  function EditToolbarInvoice(props) {
    const { setRowsInvoice } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsInvoice((oldRows) => [...oldRows, { id: id, receiver: '', number: '', report_number: '', date: '', report_signature_date: '', invoice_sent_date: '', invoice_type: '', paid_on: '' }]);
    };

    if (canWriteInvoice) {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add Invoice
            </Button>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <div className="iconAddButton">
              Invoice
            </div>
          </div>
        </GridToolbarContainer>
      );
    }
  }


  return (
    <div>

      <div className="lineContent noJustify">
        <SelectCustom
          title="Invoiced by"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Invoice"
          dataValue={invoicer}
          setDataValue={setInvoicer}
          setDataAlias=""
          defaultAlias=""
          creator={createInvoicersHandler}
          read={canRead("invoiced_by")}
          write={canWrite("invoiced_by")}
          required={isRequired("invoiced_by")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("invoiced_by")}
          helperText={getControlFieldsErrorMsg("invoiced_by")}
        />
      </div>
      
      {Boolean(canWrite("invoice_block") || canRead("invoice_block")) && 
        <div className="lineContent">
          <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-invoice">
            <DataGridPro
              disableSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              autoHeight
              hideFooter={true}
              rows={rowsInvoice}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdateInvoice}
              columns={columnsInvoice}
              experimentalFeatures={{ newEditingApi: true }}
              componentsProps={{
                toolbar: { setRowsInvoice },
              }}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                Toolbar: EditToolbarInvoice,
              }}
            />
          </FormControl>
        </div>
      }

      <BlockFormSaveButton blockRef={invoiceRef} blockSave={handleClickSave}/>

    </div>
  );
});

export default MissionDetailsInvoiceForm;
