import React, { useState, forwardRef, useContext, useEffect, GridRenderEditCellParams } from 'react';
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import { randomId } from '@mui/x-data-grid-generator';

import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridDatePickerCustom, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstructionMission } from "./ContextShippingInstructionMission";

import axios from 'api/axios';


const ShippingInstructionMissionLetter = forwardRef((props, ref) => {
  //init config
  const navigate = useNavigate();

  //generic
  const { setSnackErrorMsg } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //SI Instruction Grid
  const [rowsMissionLetterByIdMission, setRowsMissionLetterByIdMission] = useState([]);
  const { rowsMissionLetter, setRowsMissionLetter } = useContext(ContextShippingInstructionMission);
  const { rowsMissionLetterDeleted, setRowsMissionLetterDeleted } = useContext(ContextShippingInstructionMission);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //list dataGrid
  const [rowsMissionLetterTypes, setRowsMissionLetterTypes] = useState([]);
  const [rowsMissionLetterReasons, setRowsMissionLetterReasons] = useState([]);


  //useEffect
  useEffect(() => {
    loadMissionLetterTypes();
    loadMissionLetterReasons();

    //load mission letter by id mission
    if (rowsMissionLetter) {
      if (rowsMissionLetter.length > 0) {
        setRowsMissionLetterByIdMission(rowsMissionLetter.filter(obj => Number(obj.id_mission) === Number(props.idMission)));
      }
    }
  }, []);

  useEffect(() => {
    //load mission letter by id mission
    if (rowsMissionLetter) {
      if (rowsMissionLetter.length > 0) {
        setRowsMissionLetterByIdMission(rowsMissionLetter.filter(obj => Number(obj.id_mission) === Number(props.idMission)));
      }
    }
  }, [rowsMissionLetter]);


  //function interactive
  const { processRowUpdateMissionLetter } = useContext(ContextShippingInstructionMission);

  const loadMissionLetterTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('mission_letter_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i], label: response.data[i] };
      }
      setRowsMissionLetterTypes(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadMissionLetterReasons = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('mission_letter_reason/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i], label: response.data[i] };
      }
      setRowsMissionLetterReasons(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const deleteRowMissionLetter = (event, id) => {
    setRowsMissionLetter(rowsMissionLetter.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsMissionLetterDeleted((oldRows) => [...oldRows, { id: id }]);
  };

  function EditToolbarMissionLetter(props) {
    const { setRowsMissionLetter, idMission } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsMissionLetter((oldRows) => [...oldRows, { 
        id: id,
        id_mission: idMission,
        type: '',
        date: '', 
        reason: ''
      }]);
    };

    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Mission Letter
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }


  //xGrid
  var listColumnMissionLetter = [];
  //mode edit
  if (props.editable) {
    listColumnMissionLetter.push({
      field: 'id', headerName: '', editable: false, sortable: false, width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => { deleteRowMissionLetter(event, cellValues.id) }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnMissionLetter.push(
    { field: 'type', headerName: 'Type', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable, 
      valueOptions: rowsMissionLetterTypes,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateMissionLetter}
            error={params.value == 0 ? true : false}
            required={true}
          />
        )
      }
    },
    { field: 'reason', headerName: 'Reason', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable, 
      valueOptions: rowsMissionLetterReasons,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateMissionLetter}
          />
        )
      }
    },
    { field: 'date', headerName: 'Date *', width: 250, sortable: true, type: "date", editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            processRowUpdate={processRowUpdateMissionLetter}
            error={params.value == 0 ? true : false}
            required={true}
          />
        )
      }
    }
  );
  const columnsMissionLetter: GridColDef[] = listColumnMissionLetter;


  return (
    <div className="lineContent noJustify">
      <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-si-instruction">
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          hideFooter={true}
          rows={rowsMissionLetterByIdMission}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateMissionLetter}
          columns={columnsMissionLetter}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsMissionLetter, idMission: props.idMission },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: props.editable ? EditToolbarMissionLetter : null,
          }}
        />
      </FormControl>
    </div>
  );
});

export default ShippingInstructionMissionLetter;
