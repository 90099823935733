import React, { useState, useContext } from 'react';

import SelectCustom from 'components/select/SelectCustom';
import TextFieldCustom from 'components/select/TextFieldCustom';
import ConfirmDialog from 'components/page/object/ConfirmDialog';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";


const MissionDetailsCancelPopin = (props) => {

  //mission
  const { idMission, setIdMision } = useContext(ContextMissionDetails);
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  //cancelation data
  const {missionCancelComment, setMissionCancelComment} = useContext(ContextMissionDetailsTreatment);
  const {missionCancelReason, setMissionCancelReason} = useContext(ContextMissionDetailsTreatment);
  const [missionCancelReasonErrorState, setMissionCancelReasonErrorState] = useState(false);
  const [missionCancelReasonErrorMsg, setMissionCancelReasonErrorMsg] = useState('');
  const {saveMissionCancel} = useContext(ContextMissionDetailsTreatment);


  //function interactive
  function createCancelReasonHandler(data) {
    return {id:data.id, title:data.label.toUpperCase().trim(), alias:data.label, labelValue:data.label.toUpperCase().trim()};
  }

  const handleChangeCancelComment = (event) => {
    setMissionCancelComment(event.target.value);
  };

  const handleCancelMission = async (event, id) => {
    if ((!missionCanceled && !!missionCancelReason != false) || missionCanceled) {
      props.setOpen(false);
      setMissionCancelReasonErrorState(false);
      setMissionCancelReasonErrorMsg('');
      await saveMissionCancel(missionCanceled);
    } else {
      setMissionCancelReasonErrorState(true);
      setMissionCancelReasonErrorMsg('Required value');
    }
  };


  if (!missionCanceled) {
    return (
      <ConfirmDialog
        title="the Cancelation of the mission ?"
        notClose={true}
        open={props.open}
        setOpen={props.setOpen}
        onConfirm={async (event, id) => {await handleCancelMission(event, id)}}
        params={idMission}
      >
        <div className="lineContent">
          <SelectCustom
            cached={true}
            size="medium"
            title="Cancelation reason"
            api="mission_cancel_reason/list"
            apiParam=""
            apiParamValue=""
            dataValue={missionCancelReason}
            setDataValue={setMissionCancelReason}
            setDataAlias=""
            defaultAlias=""
            creator={createCancelReasonHandler}
            required={true}
            error={missionCancelReasonErrorState}
            helperText={missionCancelReasonErrorMsg}
          />
        </div>

        <div className="lineContent">
          <TextFieldCustom
            id="standard-multiline-invoice"
            label="Cancelation commentary"
            variant="standard"
            value={missionCancelComment}
            fullWidth
            multiline
            onBlur={handleChangeCancelComment}
          />
        </div>
      </ConfirmDialog>
    );
  } else {
    return (
      <ConfirmDialog
        title="the Uncancelation of the mission ?"
        notClose={true}
        open={props.open}
        setOpen={props.setOpen}
        onConfirm={async (event, id) => {await handleCancelMission(event, id)}}
        params={idMission}
      >
      </ConfirmDialog>
    );
  }
};

export default MissionDetailsCancelPopin;