import React, { useState, useContext, useEffect } from 'react';

import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import { isInternalRole } from 'components/config/Roles';
import MissionDetailsCancelPopin from './Popin/MissionDetailsCancelPopin';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";


const MissionDetailsFloatingSaveButton = () => {

  //config
  const {update} = useContext(ContextBlock);
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockGrid, getErrorGrid } = useContext(ContextBlock);

  //init control for save
  const [saveDisabled, setSaveDisable] = useState(false);

  //mission
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  //handle button
  const [onEdit, setOnEdit] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const handleOpenSave = () => setOpenSave(true);
  const handleCloseSaveDial = () => setOpenSave(false);
  const handleCloseSave = () => { setOpenSave(false); handleClickSave(false); }
  const handleCloseSaveCancel = async () => { setOpenSave(false); await handleClickCancel(); }
  const {handleClickSave} = useContext(ContextMissionDetailsTreatment);
  const {handleClickCancel} = useContext(ContextMissionDetailsTreatment);

  //cancel popin
  const [openCancel, setOpenCancel] = useState(false);
  const handleCloseMissionCancel = async () => { 
    setOpenSave(false);
    setOpenCancel(true);
  }


  //useEffect
  useEffect(() => {
    //maj onEdit state
    var blockOnEdit  = blockEdit.findIndex(obj => obj.onEdit === true);
    var newStateEdit = blockOnEdit >= 0 ? true : false;
    if (onEdit != newStateEdit) {
      setOnEdit(newStateEdit);
    }
  }, [blockEdit]);

  useEffect(() => {
    setSaveDisable(getErrorGrid());
  }, [blockGrid]);

  
  return(
    <div className={/*!onEdit*/ missionCanceled ? 'floatingSaveButton opacity' : 'floatingSaveButton opacity show'}>
      {(onEdit || JLBInternalMode) && 
      <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1 }}>
        <SpeedDial
          ariaLabel="Save menu"
          key="Save mission"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={loading ? <CircularProgress /> : <SaveRoundedIcon />}
          open={!loading ? openSave : false}
          onClose={handleCloseSaveDial}
          onOpen={handleOpenSave}
          onClick={handleCloseSave}
          FabProps={{disabled:(loading || !onEdit || saveDisabled)}}
        >
          {(update == 1 && JLBInternalMode) && 
          <SpeedDialAction
            className="cancel"
            key="Cancel mission"
            icon={<CancelIcon />}
            tooltipTitle="Cancel mission"
            onClick={async (event) => {
              event.stopPropagation();
              await handleCloseMissionCancel();
            }}
          />
          }

          {(update == 1 && onEdit) && 
          <SpeedDialAction
            key="Stop editing"
            icon={<EditOffIcon />}
            tooltipTitle="Stop editing"
            onClick={async (event) => {
              event.stopPropagation();
              await handleCloseSaveCancel();
            }}
          />
          }
        </SpeedDial>
      </Box>
      }

      {update == 1 && JLBInternalMode && <MissionDetailsCancelPopin open={openCancel} setOpen={setOpenCancel}/>}

    </div>
  );
};

export default MissionDetailsFloatingSaveButton;
