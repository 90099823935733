import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const MissionDetailsProtisDetailTree = (props) => {
  
  const [treeData, setTreeData] = useState([]);
  const [expandedTreeNodes, setExpandedTreeNodes] = useState([]);
  const [currentTreeNodeId, setCurrentTreeNodeId] = useState(null);

  // Load tree elements
  useEffect(() => {
    if (props?.protisData?.containers?.length > 0) {
      const root = {
        id: 'rootFolder',
        label: 'Root folder',
        isFolder: true,
        children: buildTree(props.protisData.containers)
      };
      setTreeData([root]);
    }
  }, [props?.protisData.containers]);
  
  // Sets all tree nodes as expanded
  useEffect(() => {
    if (treeData?.length > 0) {
      setExpandedTreeNodes(getExpandedTreeNodesIds(treeData));
    }
  }, [treeData]);
  
  // Sets a current tree node
  useEffect(() => {
    if (treeData?.length > 0 && props?.elementInfo) {
      if (props?.elementInfo?.isFolder) {
        setCurrentTreeNodeId(props?.elementInfo.id)
      } else if ((props?.elementInfo?.isPhoto || props?.elementInfo?.isVideo) && props?.elementInfo?.id_mission_container) {
        setCurrentTreeNodeId(props?.elementInfo?.id_mission_container);
      }
    }
  }, [treeData, props?.elementInfo]);
  
  // Build tree structure
  const buildTree = (data, parentId = null) => {
    const tree = [];
    data.forEach(item => {
      if (item.id_mission_container_master === parentId) {
        const children = buildTree(data, item.id);
        const node = { ...item, children };
        // Sort children if there are any
        if (children.length > 0) {
          node.children.sort(customSort);
        }
        tree.push(node);
      }
    });
    // Sort current level nodes
    tree.sort(customSort);
    return tree;
  };
  
  // Function to recursively collect all node IDs
  const getExpandedTreeNodesIds = (nodes) => {
    let ids = [];
    nodes.forEach(node => {
      ids.push(node.id.toString());
      if (node.children) {
        const childIds = getExpandedTreeNodesIds(node.children);
        ids = ids.concat(childIds);
      }
    });
    return ids;
  };
  
  // Sort by updated_at or by created_at
  const customSort = (a, b) => {
    const aUpdatedAt = a.updated_at || a.created_at;
    const bUpdatedAt = b.updated_at || b.created_at;
  
    const dateA = new Date(aUpdatedAt).getTime();
    const dateB = new Date(bUpdatedAt).getTime(); 
  
    return dateB - dateA;
  };   
  
  const handleNodeSelect = (node) => {
    props?.setMoveDestinationId(node?.id)
  }
  
  // Function to recursively render tree items
  const renderTree = (nodes, isParent = false) => (
    nodes.map((node) => (
      <TreeItem 
        key={node.id}
        className='customTreeItem'
        nodeId={node.id.toString()} 
        label={node.label}
        disabled={(props?.elementInfo?.isFolder && currentTreeNodeId) == node.id}
        style={{
          color: 'black', 
          borderLeft: isParent ? '2px dashed black' : 'none', 
          marginLeft: '-2px', 
          pointerEvents: (props?.elementInfo?.isFolder &&currentTreeNodeId == node.id) ? "none" : ""
        }}
        onClick={() => handleNodeSelect(node)}
      >
        {node?.children?.length > 0 && 
          renderTree(node?.children, true)
        }
      </TreeItem>
    ))
  );

  return (
    <Box sx={{ overflow: 'auto', borderRadius: 1, bgcolor: '#f0f0f0', height: '100%', width: '40%', marginRight: '20px', padding: '8px' }}>
      {(treeData.length > 0 && expandedTreeNodes?.length > 0) &&
        <TreeView
          aria-label="protis folder navigator"
          defaultCollapseIcon={<ExpandMoreIcon/>}
          defaultExpandIcon={<ChevronRightIcon/>}
          expanded={expandedTreeNodes}
          onNodeToggle={() => {}}
        >
          {renderTree(treeData)}
        </TreeView>
      }
    </Box>
  );
};

export default MissionDetailsProtisDetailTree;
