import React, { useState, createContext, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { ContextBlock } from "./ContextBlock";
import { ContextBlockTreatment } from "./ContextBlockTreatment";
import { ContextMissionDetails } from "./ContextMissionDetails";
import { ContextMissionWorkflow } from "./ContextMissionWorkflow";

import axios from 'api/axios';


const ContextMissionDetailsTreatment = createContext();

const MissionDetailsTreatmentProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();

  //composant form ref
  const contentRef = useContext(ContextMissionDetails);
  
  //init param
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);

  const { setStartSave } = useContext(ContextBlock);

  //workflow state
  const {controlBasicFieldsData} = useContext(ContextMissionWorkflow);

  //init mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  const {missionType, setMissionType} = useContext(ContextMissionDetails);
  const {portId, setPortId} = useContext(ContextMissionDetails);
  const {surveyHandler, setSurveyHandler} = useContext(ContextMissionDetails);
  const {principalsId, setPrincipalsId} = useContext(ContextMissionDetails);

  //init data save
  const [reloadMission, setReloadMission] = useState(false);
  const {idButtonWorkflow, setIdButtonWorkflow} = useContext(ContextMissionWorkflow);

  //init data cancelation
  const [missionCancelReason, setMissionCancelReason] = useState(0);
  const [missionCancelComment, setMissionCancelComment] = useState('');

  //handle button
  const { handleClickCancel } = useContext(ContextBlockTreatment);
  const handleClickSave = (reload = false, button = null) => {
    setLoading(true);
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    if ((missionType == "0") || (portId == "0") || (surveyHandler == "0") || (principalsId == "0")) {
      var basicData = new FormData();
      //REQUIRED
      basicData.append('id_mission_type', parseInt(missionType));
      basicData.append('id_port', parseInt(portId));
      basicData.append('id_principal', parseInt(principalsId));
      basicData.append('id_survey_handler', parseInt(surveyHandler));
      //CHECK BASIC FIELD
      controlBasicFieldsData(basicData);
      contentRef.contentRef.current.scrollIntoView({behavior: 'smooth'});
      setLoading(false);
    } else {
      setStartSave();
      setReloadMission(reload);
      setIdButtonWorkflow(button);
    }
  }


  //function treatment
  // block save
  const { saveBlock: saveBlockAndCheckWorkflow } = useContext(ContextBlockTreatment);
  // Cancel mission
  const saveMissionCancel = async (uncancel = false) => {
    setLoading(true);
    setLoadingUpdate(false);
    try {
      var data = new FormData();
      data.append('id_mission', idMission);
      
      if (!uncancel) {
        data.append('id_mission_cancel_reason', missionCancelReason);
        data.append('comment', missionCancelComment);
      } else {
        data.append('uncancel', uncancel);
      }

      var jsonRequestData = JSON.stringify(Object.fromEntries(data));
      var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL
      var jsonRequestDataFinal = jsonRequestDataFinal.replace(/"true"/g, true);    //PATCH ERREUR NULL
      var jsonRequestDataFinal = jsonRequestDataFinal.replace(/"false"/g, false);    //PATCH ERREUR NULL

      var config = {
        url: 'mission/cancel',
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataFinal
      };
      const response = await axios(config);

      setSnackBarMessage("Mission successfully "+ (uncancel ? 'un' : '') +"canceled.");
      setSnackBarType("success");
      setOpenSnackBar(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
      setLoadingUpdate(true);
    }
  }


  return (
    <ContextMissionDetailsTreatment.Provider
      value=
      {{
        //handle
        handleClickCancel, handleClickSave,

        //treament
        saveBlockAndCheckWorkflow,

        missionCancelReason, setMissionCancelReason,
        missionCancelComment, setMissionCancelComment,
        saveMissionCancel
      }}
    >
      {children}
    </ContextMissionDetailsTreatment.Provider>
  );
};

export { MissionDetailsTreatmentProvider, ContextMissionDetailsTreatment };
