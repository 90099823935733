const domainName =  window.location.hostname;
var environnement = {id:300, label:'- Serveur de dev -', color:'info'};

if (domainName === 'gyptis.jlbexpertises.com')
{
  environnement = {id:100, label:'- Serveur de prod -', color:'success'};
}
else if (domainName === 'gyptis-test.jlbexpertises.com')
{
  environnement = {id:200, label:'- Serveur de test -', color:'warning'};
}

console.log(environnement.label);

export default environnement
