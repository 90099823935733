import React, { useContext } from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import CircularProgress from '@mui/material/CircularProgress';
import { Line } from 'react-chartjs-2';
import { useDeepCompareMemo } from 'use-deep-compare';
import 'chart.js/auto';

import { ContextAnalytics } from "../../../../context/ContextAnalytics";
import '../../../../styles/Analytics.css';

const ConnectionsLineChart = (props) => {
    
    const {cubejsApi} = useContext(ContextAnalytics);
    
    const COLORS_SERIES = [
        '#0288d1'
    ];
    
    // Custom tooltips plugin (renders the biggest value)
    const customTooltips = (tooltipModel) => {
        const tooltipInfo = tooltipModel.dataset.tooltipInfo[tooltipModel.dataIndex];
        const nonZeroTooltipInfo = tooltipInfo.filter((info) => info?.value !== 0 && info?.value !== null && info?.value !== undefined);
    
        const total = nonZeroTooltipInfo.reduce((sum, info) => sum + info?.value, 0);
        const biggestValue = nonZeroTooltipInfo.sort((a, b) => b?.value - a?.value)[0];
    
        const outputArray = [`Total Count : ${total}`];
    
        if (total !== 0 && biggestValue) {
            const percentage = (biggestValue?.value / total) * 100;
            outputArray.push(`${biggestValue?.group} | ${biggestValue?.name} | ${biggestValue?.value} (${percentage?.toFixed(2)} %)`);
        }
    
        return outputArray.flat();
    };
     
    // Chart options
    const commonOptions = {
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
        },
        plugins: {
            legend: {
                display: false
                
            },
            tooltip: {
                callbacks: {
                    label: () => '',
                    afterLabel: customTooltips,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    padding: 12,
                    minRotation: 0,
                    // Format labels for x axis
                    callback: function(value, index, ticks) {
                        const date = new Date(this.getLabelForValue(value));
                        const day = date.getDate();
                        const month = date.toLocaleString('en-GB', { month: 'short' });
                        return `${day} ${month}`;
                    }
                    
                },
            },
            y: {
                ticks: {
                    autoSkip: true,
                }
            }
        },
    };
    
    // Called on tooltip rendering
    const useDrilldownCallback = ({ datasets, labels, onDrilldownRequested, pivotConfig, }) => {
        return React.useCallback((elements) => {
                if (elements.length <= 0) return;
                const { datasetIndex, index } = elements[0];
                const { yValues } = datasets[datasetIndex];
                const xValues = [labels[index]];
            
                if (typeof onDrilldownRequested === 'function') {
                    onDrilldownRequested(
                    {
                        xValues,
                        yValues,
                    },
                    pivotConfig
                    );
                }
            }, [datasets, labels, onDrilldownRequested]
        );
    };
    
    // Renders a line based on cumulated values of all other lines
    const LineChartRenderer = ({ resultSet, pivotConfig, onDrilldownRequested, }) => {
    const series = resultSet.series(pivotConfig);
    const datasets = useDeepCompareMemo(() => {
        // Combining values for cumulative line
        const cumulativeValues = Array.from({ length: series[0]?.series.length }, (_, index) => {
            const cumulativeValue = series.reduce((sum, s) => sum + s.series[index].value, 0);

            // Preparing tooltip information for each point
            const tooltipInfo = series.map((s) => {
                const match = s.title.match(/([^,]+),\s*([^,]+)/);
                const group = match ? match[1] : '';
                const name = match ? match[2] : '';
            
                return { group, name, value: s.series[index].value };
            });

            return { cumulativeValue, tooltipInfo };
        });

        const cumulativeLine = {
            data: cumulativeValues.map((point) => point.cumulativeValue),
            yValues: series[0]?.yValues,
            borderColor: COLORS_SERIES[0],
            pointRadius: 2,
            tension: 0.4,
            pointHoverRadius: 1,
            borderWidth: 2,
            tickWidth: 1,
            fill: true,
            backgroundColor: 'rgba(230, 246, 255, 0.5)',
            tooltipInfo: cumulativeValues.map((point) => point.tooltipInfo),
        };

        return [cumulativeLine];
    }, [resultSet, pivotConfig]);
        
        const formatDates = () =>{
            return resultSet.categories(pivotConfig).map((c) =>
                new Date(c.x).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                })
            );
        }
        
        const data = {
            labels: formatDates(),
            datasets,
        };
        
        const getElementAtEvent = useDrilldownCallback({
            datasets: data.datasets,
            labels: data.labels,
            pivotConfig,
            onDrilldownRequested,
        });
        
        return (
            <Line
                type="line"
                data={data}
                options={commonOptions}
                getElementAtEvent={getElementAtEvent}
            />
        );
    };
    
    // Renders a line or loading in progress
    const renderChart = ({ resultSet, error, pivotConfig, onDrilldownRequested }) => {
        
        return (
            (resultSet) ?
                (
                    <LineChartRenderer
                        resultSet={resultSet}
                        pivotConfig={pivotConfig}
                        onDrilldownRequested={onDrilldownRequested}
                    />
                )
            : 
                (
                    <div className="loadingChartContainer">
                        <CircularProgress/>
                    </div>
                )
        );
      
    };
      
    return (
        <div className='chartLineContainer'>
            {(props.formattedQueryStartDate && props.formattedQueryEndDate) &&
                // CubeJS query
                <QueryRenderer
                    query={{
                        measures: [ "log_api_connection.total_count" ],
                        limit: 5000,
                        order: { "log_api_connection.total_count": "desc" },
                        timeDimensions: [{
                            dimension: "log_api_connection.created_at",
                            dateRange: [ props.formattedQueryStartDate, props.formattedQueryEndDate ],
                            granularity: "day"
                        }],
                        dimensions: [
                            "log_api_connection.type",
                            "company.name"
                        ]
                    }}
                    cubejsApi={cubejsApi}
                    resetResultSetOnChange={false}
                    render={(props) => renderChart({
                        ...props,
                        chartType: 'line',
                        pivotConfig: {
                            x: [ "log_api_connection.created_at.day" ],
                            y: [
                                "log_api_connection.type",
                                "company.name",
                                "measures"
                            ],
                            fillMissingDates: true,
                            joinDateRange: false
                        }
                    })}
                />
            }
        </div>
    );
};

export default ConnectionsLineChart;