import React, { useState, createContext } from "react";
import cubejs from '@cubejs-client/core';

const ContextAnalytics = createContext();

const AnalyticsProvider = ({ children }) => {
  
  const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, { apiUrl: process.env.REACT_APP_CUBEJS_API_URL });
  
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("");
  
  const setSnackErrorMsg = (data) => {
    var errorMsg = "Technical error ! Please try again or contact our support.";
    /*if(typeof data != "undefined"){
      var responseMsg = "";
      var errorData = Array.from(new Map(Object.entries(data)));
      if(Array.isArray(errorData) && errorData.length >= 1){
        errorData.map((err, i) => {
          if(typeof err != String){
            err.map((msg, k) => {
              if(Array.isArray(msg)){
                msg.map((txt, idx) => {
                  responseMsg += "\n" + txt;
                });
              }
            });
          }else{
            responseMsg += "\n" + err;
          }
        });
      }else{
        responseMsg += "\n" + errorData;
      }
      errorMsg = errorMsg + responseMsg;
    }*/

    return errorMsg;
  }
  
  return (
    <ContextAnalytics.Provider
      value=
      {{
        cubejsApi,
        
        // SnackBar
        openSnackBar, setOpenSnackBar,
        snackBarType, setSnackBarType,
        snackBarMessage, setSnackBarMessage,
        setSnackErrorMsg,
      }}
    >
      {children}
    </ContextAnalytics.Provider>
  );
};

export { AnalyticsProvider, ContextAnalytics };
