import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import DesktopDatePickerCustom from 'components/select/DesktopDatePickerCustom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridDatePickerCustom, DataGridSelectCustom, DataGridTextCustom } from 'components/page/object/DataGridProCustom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";
import { ContextMissionDetailsBL } from "./ContextMissionDetailsBL";


export default function BLDateRow(props) {

  //workflow state
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { isRequired } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);

  //init BL data
  const { rowsBL, setRowsBL } = useContext(ContextMissionDetailsReceiverBL);
  const { rowsCheckBL, setRowsCheckBL } = useContext(ContextMissionDetailsBL);
  const { deletedRowsBL, setDeletedRowsBL } = useContext(ContextMissionDetailsReceiverBL);

  const { beansTypes, setBeansType } = useContext(ContextMissionDetailsBL);
  const { typesOfIntervention, setTypesOfIntervention } = useContext(ContextMissionDetailsBL);

  //component state
  const [blDate, setBlDate] = useState("");
  const signaturePlace = props.signaturePlace;
  const [rowsBLDate, setRowsBLDate] = useState([]);
  //new param for default extand
  const [expanded, setExpanded] = useState((!!props.blDateRow.isExpanded != false ? props.blDateRow.isExpanded : props.isExpanded));


  useEffect(() => {
    setBlDate(props.blDateRow.value);
    setRowsBLDate(props.blDateRow.bl_rows);
  }, [props.blDateRow]);


  //function
  const { handleRowEditStart } = useContext(ContextBlock);
  const { handleRowEditStop } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  const processRowUpdateBL = (newRow, checkData = false) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsBL(rowsBL.map((row) => (row.id === newRow.id ? updatedRow : row)));
    setRowsBLDate(rowsBLDate.map((row) => (row.id === newRow.id ? updatedRow : row)));
    if (checkData) {
      setRowsCheckBL([newRow.id]);
    }
    return updatedRow;
  };

  const deleteRowBL = (event, id) => {
    setRowsBL(rowsBL.filter((row) => row.id !== id));
    setRowsBLDate(rowsBLDate.filter((row) => row.id !== id));
    if (id.toString().length < 16) setDeletedRowsBL((oldRowsBl) => [...oldRowsBl, { id: id }]);
  };

  const duplicateRowBL = (event, values) => {
    const id = randomId();
    const bol = values.row.bol.toUpperCase().trim();
    const obj = {
      id: id,
      signature_place: signaturePlace,
      signaturePlaceRowId: signaturePlace.id,
      dateRowId: props.blDateRow.id,
      bl_date: blDate,
      bol: '',
      type_of_intervention: values.row.type_of_intervention,
      beans_type: values.row.beans_type,
      quantity: values.row.quantity,
      deadline_date: values.row.deadline_date,
      delivery_date: values.row.delivery_date,
      smv: values.row.smv,
      note: values.row.note,
      isExpanded: true
    };
    setRowsBL((oldRows) => [...oldRows, obj]);
    setRowsBLDate((oldRows) => [...oldRows, obj]);
  };

  const changeBlDate = (value) => {
    setBlDate(value);
    const rowsChanged = [];
    for (var i = 0; i < rowsBLDate.length; i++) {
      setRowsBL(rowsBL.map((row) => {
        if (row.id == rowsBLDate[i].id) {
          row.bl_date = value;
          rowsChanged.push(row.id);
        }
        return row;
      }));
    }
    setRowsCheckBL(rowsChanged);
  }

  //function for change state of extand of row
  const rowExpandUpdate = (isExpanded = false) => {
    props.setRowsBLDate((prevState) => {
      // Find the index of the object you want to update
      const index = prevState.findIndex(item => item.id === props.blDateRow.id);
      if (index !== -1) {
        // Create a new array with the updated object
        const updatedArray = [...prevState];
        updatedArray[index] = {
          ...updatedArray[index],
          // Update the property you want to change
          isExpanded: isExpanded
        };
        return updatedArray; // Return the updated array
      }
      // If the object with the given ID is not found, return the previous state
      return prevState;
    });

    for (var i = 0; i < rowsBLDate.length; i++) {
      setRowsBL(rowsBL.map((row) => {
        if (row.id == rowsBLDate[i].id) {
          row.isExpanded = isExpanded;
        }
        return row;
      }));
    }
  }

  //function for change state of extand of accordion
  const handleChange = (toggle) => (event: SyntheticEvent) => {
    setExpanded(!toggle);
  }

  const EditToolbarBL = () => {
    const handleClick = () => {
      const id = randomId();
      var data = {
        id: id,
        signature_place: signaturePlace,
        signaturePlaceRowId: signaturePlace.id,
        dateRowId: props.blDateRow.id,
        bl_date: blDate,
        bol: '',
        type_of_intervention: '',
        beans_type: '',
        quantity: '',
        deadline_date: '',
        delivery_date: '',
        smv: '',
        note: '',
        isExpanded: true
      };
      if (rowsBL) {
        setRowsBL((oldRows) => [...oldRows, data]);
      } else {
        setRowsBL([data]);
      }
      setRowsBLDate((oldRows) => [...oldRows, data]);
      if (props.blDateRow.length == 0) {
        rowExpandUpdate(true);
      }
    };

    if (canWrite("bl_block")) {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add Bill of lading
            </Button>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <div className="iconAddButton">
              Bill of lading
            </div>
          </div>
        </GridToolbarContainer>
      );
    }
  }

  var listColumn = [];
  if (canWrite("bl_block")) {
    listColumn.push({
      field: 'id', headerName: '', editable: false, sortable: false, height: 35,
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton aria-label="delete" onClick={(event) => {
              deleteRowBL(event, cellValues.id);
            }}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="duplicate" onClick={(event) => {
              duplicateRowBL(event, cellValues);
            }}>
              <ContentCopyIcon />
            </IconButton>
          </>
        );
      }
    });
  }
  listColumn.push({
    field: 'bol', headerName: 'BL number', sortable: false, minWidth: 120,
    editable: (canWrite("bl_block") ? true : false),
    valueParser: (value: GridCellValue, params: GridCellParams) => {
      return value.toString().toUpperCase();
    },
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      const specificProcessRowUpdate = (newRow) => {
        processRowUpdateBL(newRow, true);
      }
      return (
        <DataGridTextCustom
          {...params}
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={specificProcessRowUpdate}
        />
      )
    }
  }, {
    field: 'type_of_intervention', headerName: 'Type of intervention', sortable: false, width: 150, type: "singleSelect",
    valueOptions: typesOfIntervention,
    editable: (canWrite("bl_block") ? true : false),
    valueFormatter: ({ id: rowId, value, field, api }) => {
      return DataGridListValueFormatterCustom(rowId, value, field, api);
    },
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridSelectCustom
          {...params}
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'quantity', headerName: 'Cocoa - to be sold (kg)', sortable: false, width: 180, type: 'number',
    editable: (canWrite("bl_block") ? true : false),
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          type="decimal"
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'beans_type', headerName: 'Type of beans', sortable: false, width: 150, type: "singleSelect",
    valueOptions: beansTypes,
    editable: (canWrite("bl_block") ? true : false),
    valueFormatter: ({ id: rowId, value, field, api }) => {
      return DataGridListValueFormatterCustom(rowId, value, field, api);
    },
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridSelectCustom
          {...params}
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'deadline_date', headerName: 'Deadline', sortable: false, width: 160, type: 'date',
    editable: (canWrite("bl_block") ? true : false),
    valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridDatePickerCustom
          {...params}
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'delivery_date', headerName: 'Delivery (cocoa)', sortable: false, width: 160, type: 'date',
    editable: (canWrite("bl_block") ? true : false),
    valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridDatePickerCustom
          {...params}
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'smv', headerName: 'SMV (EUR)', sortable: false, width: 160, type: 'number',
    editable: (canWrite("bl_block") ? true : false),
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          type="decimal"
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'note', headerName: 'Comment', sortable: false, flex: 1,
    editable: (canWrite("bl_block") ? true : false),
    renderCell: (cellValues) => {
      return DataGridRenderCellEditableCustom(cellValues);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          fullWidth
          multiline
          read={canRead('bl_block')}
          write={canWrite('bl_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  });
  const columnsBL: GridColDef[] = listColumn;

  return (
    <Accordion className="signaturePlaceRowAccordion" expanded={expanded} onChange={handleChange(expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {blDate ? momentDate(blDate).format("DD MMM YY") : "New Date"}
      </AccordionSummary>
      <AccordionDetails>
        <DesktopDatePickerCustom
          label="BL Date"
          value={blDate}
          onChange={changeBlDate}

          read={canRead("bl_block")}
          write={canWrite("bl_block")}
          required={isRequired("bl_block")}
          controlFieldsStatus={workflowLoaded}
        />
        <span className={!canWrite("bl_block") ? "spacerBlDate justRead" : "spacerBlDate"}></span>

        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          density='compact'
          hideFooter={true}
          rows={rowsBLDate}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateBL}
          columns={columnsBL}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsBLDate },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: EditToolbarBL,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}