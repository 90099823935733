import React, { useEffect, useState, SyntheticEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'api/axios';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FilterCheckBox = (props) => {
  const [dataFilter, setDataFilter] = useState([]);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleChangeFilterHandler = (event) => {
      props.setDataValueHandler(event.target.value);
      props.setValueOfFilterAndSearch(event.target.value);
  };

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const getList = async (e) => {
    try {
      setLoading(false);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            }
        };
        var getValue = "";
        if (props.apiParam.length > 0)
        {
          getValue = "?"+props.apiParam+"="+props.apiParamValue;
        }

        const response = await axios.get(props.api+getValue, config);

        var rowsFromApi = [];
        for(var i=0; i < response.data.length; i++) {
          rowsFromApi[i] = {};
          //console.log(response);
          rowsFromApi[i] = props.creator(response.data[i]);
        }
        setDataFilter(rowsFromApi);

    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        setOpenSnackBar(true);
      }
    } finally
    {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (props.loadByList === 'false') {
        getList();
    }
    else {
      var rowsFromApi = [];
      for(var i=0; i < props.datas.length; i++) {
        rowsFromApi[i] = {};
        //console.log(response);
        rowsFromApi[i] = props.creator(props.datas[i]);
      }
      setDataFilter(rowsFromApi);
    }
  }, []);

  return (
    <FormControl sx={{ width: 200 }}  className='filterCheckBox'>
      <InputLabel id="filterCheckBox-multiple-checkbox-label">{props.title}</InputLabel>
      <Select
        labelId="filterCheckBox-multiple-checkbox-label"
        id="filterCheckBox-multiple-checkbox"
        value={props.dataValueHandler}
        onChange={handleChangeFilterHandler}
        input={<OutlinedInput label={props.title} />}
        MenuProps={MenuProps}
      >
        <MenuItem value=""><em>All</em></MenuItem>
        {dataFilter.map((dataItem) => (
          <MenuItem key={dataItem.id} value={dataItem.alias}>{dataItem.alias}</MenuItem>
        ))}
      </Select>
      <LinearProgress hidden={loading}  />
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Technical error ! Please try again or contact our support.
        </Alert>
      </Snackbar>
    </FormControl>
  );
}
export default FilterCheckBox
