import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsSI } from "../SI/ContextMissionDetailsSI";
import { ContextMissionDetailsInstruction } from "./ContextMissionDetailsInstruction";

import MissionDetailsInstructionForm from './MissionDetailsInstructionForm';
import MissionDetailsInstructionView from "./MissionDetailsInstructionView";


const MissionDetailsInstruction = forwardRef((props, ref) => {

  //config
  const { update } = useContext(ContextBlock);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { toogleBlockOnEditAndCheckBlockOnWorkflowError } = useContext(ContextMissionWorkflow);

  //mission general data
  const { missionCanceled, setMissionCanceled } = useContext(ContextMissionDetails);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);

  //SI data
  const { rowsInstruction, setRowsInstruction } = useContext(ContextMissionDetailsInstruction);
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextMissionDetailsSI);


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit);
  }, [blockEdit]);


  if (update == 1 && (idShippingInstruction || rowsInstruction.length > 0) && (canWrite('instruction_block') || canRead('instruction_block'))) {
    return (
      <div id="block_instruction" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
        <h4>Instructions</h4>

        {(!onEdit) ? <MissionDetailsInstructionView /> : <MissionDetailsInstructionForm />}

      </div>
    );
  }
});

export default MissionDetailsInstruction;
