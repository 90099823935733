import React from 'react';
import FormControl from '@mui/material/FormControl';

import "bootstrap/dist/css/bootstrap.css";
import "styles/globalPortalDashboard.css";
import "styles/detailsCommon.css";

export default function DetailField(props) {

  var size = "18%";
  if (props.size)
  {
    if (props.size === 'medium')
    {
      size = "23%";
    }
    else if (props.size === 'large')
    {
      size = "31%";
    }
    else if (props.size === 'full')
    {
      size = "100%";
    }
  }

  var contentTXT = "-";
  if (props.content)
  {
    contentTXT = (" "+props.content).trim();
  }

  function msgInitBr(txt) {
      return (!!props.multiline != false ? txt.replace(/(?:\r\n|\r|\n)/g, "<br />") : txt);
  }

  var canRead = typeof props?.canRead != 'undefined' ? props.canRead : 1;
  
  if (canRead)
    return (
      <>
        <FormControl className="textField-form-detail" sx={{ m: 1, width: size, marginLeft: 1, marginRight: 1, marginTop: 0, marginBottom: 0 }} size="small" >
            <div className="detailFieldTitle">{props.title ? props.title : '-'}</div>
            <div className="detailFieldContent" dangerouslySetInnerHTML={{__html: msgInitBr(contentTXT)}}></div>
        </FormControl>
      </>
    )
}
