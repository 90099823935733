import React from 'react';

import MissionDetailsHeaderContent from './MissionDetailsHeaderContent';
import MissionDetailsHeaderCanceled from './MissionDetailsHeaderCanceled';


const MissionDetailsHeader = (props) => {
  return (
    <div>
      <MissionDetailsHeaderCanceled/>
      <MissionDetailsHeaderContent floating={false}/>
      <div className={(!!props.floating != false && props.floating) ? 'floatingHeaderMission visible' : 'floatingHeaderMission'}>
        <MissionDetailsHeaderContent floating={true} visible={props.floating}/>
      </div>
    </div>
  );
}

export default MissionDetailsHeader
