import React, { useRef, useEffect, useState, useContext, SyntheticEvent, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { randomId } from '@mui/x-data-grid-generator';
import CommentIcon from '@mui/icons-material/Comment';
import ForwardIcon from '@mui/icons-material/Forward';
import LinearProgress from '@mui/material/LinearProgress';
import DownloadingIcon from '@mui/icons-material/Downloading';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { DataGridPro, GridColDef, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid-pro';

import ExportPopin from './ExportPopin'
import { ContextFilter } from "context/ContextFilter";
import { checkSecurity } from 'components/config/Security';
import { isInternalRole, isPortRole, isCustomerRole } from 'components/config/Roles';

import axios from 'api/axios';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DashboardMainList(props) {
  //config
  const navigate = useNavigate();
  const JLBPortRole = isPortRole(localStorage.getItem("role"));
  const JLBInternalRole = isInternalRole(localStorage.getItem("role"));
  const JLBCustomerRole = isCustomerRole(localStorage.getItem("role"));

  //URL API
  const GETMISSIONLIST_URL = 'mission/list';
  const EXPORTMISSIONLIST_URL = 'mission/export';
  const EXPORTMISSIONLIST_PORT_URL = 'mission/export/port';
  const EXPORTMISSIONLIST_CUSTOMER_URL = 'mission/export/customer';
  const EXPORTMISSIONLIST_COD_URL = 'mission/export/cocoa_departure';

  const MISSION_TYPE_COD_NOMENCLATURE = 'COD';

  //params
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //context
  const { visibleCODRows, setVisibleCODRows } = useContext(ContextFilter);
  const { visibleRows, setVisibleRows } = useContext(ContextFilter);
  const { isDownload, setIsDownload } = useContext(ContextFilter);

  const { loadingGrid, setLoadingGrid } = useContext(ContextFilter);
  const { setDataGridLoaded } = useContext(ContextFilter);
  const { filterModel } = useContext(ContextFilter);
  const { filterOn } = useContext(ContextFilter);

  const { category } = useContext(ContextFilter);
  const { searchTxt } = useContext(ContextFilter);

  const { filterDatas } = useContext(ContextFilter);
  const { filterStatusArray } = useContext(ContextFilter);

  const { rows, setRows } = useContext(ContextFilter);
  const { portRows, setPortRows } = useContext(ContextFilter);
  const { totalRows, setTotalRows } = useContext(ContextFilter);
  const { vesselRows, setVesselRows } = useContext(ContextFilter);
  const { surveyRows, setSurveyRows } = useContext(ContextFilter);
  const { missionRows, setMissionRows } = useContext(ContextFilter);
  const { insuredRows, setInsuredRows } = useContext(ContextFilter);
  const { shipperRows, setShipperRows } = useContext(ContextFilter);
  const { receiverRows, setReceiverRows } = useContext(ContextFilter);
  const { principalRows, setPrincipalRows } = useContext(ContextFilter);
  const { contractNumberRows, setContractNumberRows } = useContext(ContextFilter);

  const [missionTypeCOD, setMissionTypeCOD] = useState({});
  const [loadAdditionalResult, setLoadAdditionalResult] = useState(false);
  
  const [isExportPopinOpen, setIsExportPopinOpen] = useState(false);
  const exportPopinRef = useRef("exportPopin");


  // CHECK SECURITY
  useEffect(() => {
    checkSecurity(navigate, 'DashboardList');
    loadMissionTypes();
    getMissionList();
  }, []);
  // END SECURITY


  //function
  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDownload(false);
    setOpenSnackBar(false);
  };

  const RenderType = (props) => {
    const { value } = props;
    return (<div className="typeMissionGridColor" style={{ backgroundColor: value }}></div>
    );
  };

  RenderType.propTypes = {
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.instanceOf(Number),
  };

  const RenderNotif = (props) => {
    var notif = false;
    if (typeof props != "undefined") notif = props;
    if (notif) {
      return (
        <span className="puceNotifBorder">
          <Badge className="puceNotif" badgeContent="" variant="dot" overlap="circular"></Badge>
        </span>
      );
    }
  };

  const RenderStatus = (props) => {
    if (!!props.row.missionCancelReason == false || !JLBInternalRole) {
      return (<div className="statusMissionGridColor" style={{ backgroundColor: props.row.status.statut_color }}>
        {props.row.status.statut_type}
        {(JLBInternalRole) && (RenderNotif(props.row.has_unread_internal_notification))}
      </div>);
    } else {
      var txt = "";
      if (props.row.missionCancelReason) txt = props.row.missionCancelReason;

      if (txt.length > 0) {
        return (<div className="statusMissionGridColor" style={{ width: 'auto' }}>
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
            placement="right">
            <div><CancelIcon fontSize="large" sx={{ color: 'red', opacity: '0.6' }} /></div>
          </Tooltip>
          {(JLBInternalRole) && (RenderNotif(props.row.has_unread_internal_notification))}
        </div>);
      }
    }
  };

  const RenderDate = (props) => {
    const { created } = props.row;
    return (moment(created).format("DD MMM YY"));
  };

  const RenderReceiver = (props) => {
    var txt = "";
    var prefix = "";

    if (props.row.receiverSearch.length > 0) {
      for (var i = 0; i < props.row.receiverSearch.length; i++) {
        txt = txt + prefix + props.row.receiverSearch[i];
        prefix = "\n\r";
      }

      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <IconButton>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>);
    }
    else return (<></>);
  };

  const RenderBL = (props) => {
    var txt = "";
    var prefix = "";

    if (props.row.b_o_l.length > 0) {
      for (var i = 0; i < props.row.b_o_l.length; i++) {
        txt = txt + prefix + props.row.b_o_l[i];
        prefix = "\n\r";
      }

      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <IconButton>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>);
    }
    else return (<></>);
  };

  const RenderSiBL = (props) => {
    var txt = "";
    var prefix = "";

    if (props.row.b_o_l_si.length > 0) {
      for (var i = 0; i < props.row.b_o_l_si.length; i++) {
        txt = txt + prefix + props.row.b_o_l_si[i];
        prefix = "\n\r";
      }

      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <IconButton>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>);
    }
    else return (<></>);
  };

  const RenderCommentInternal = (props) => {
    var txt = "";
    if (props.row.internal_note) txt = props.row.internal_note;

    if (txt.length > 0) {
      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <CommentIcon fontSize="small" />
      </Tooltip>
      );
    }
    else return (<CommentIcon fontSize="small" style={{ opacity: '0.1' }} />);
  }

  const RenderCommentPublic = (props) => {
    var txt = "";
    if (props.row.public_note) txt = props.row.public_note;

    if (txt.length > 0) {
      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <CommentIcon fontSize="small" />
      </Tooltip>
      );
    }
    else return (<CommentIcon fontSize="small" style={{opacity: '0.1'}} />);
  }

  const RenderContractNumber = (props) => {
    if (props.row.id_shipping_instruction) {
      return (<Button size="small" className="small link" variant="outlined" endIcon={<ForwardIcon fontSize="small" />} onClick={(event) => {
        navigate('/shippingInstructionDetails?id=' + props.row.id_shipping_instruction, { replace: true });
        event.stopPropagation();
        event.preventDefault();
      }}>{props.row.contractNumber}</Button>);
    }
    else return ('');
  }

  function getStatusId(params) {
    if (!!params.row.missionCancelReason == false) {
      return `${params.row.status.statut_type}`;
    } else {
      return 'canceled';
    }
  }

  const handleRowClick = (params) => {
    navigate(`/missionDetails?id=${params.row.id}&key=${randomId()}`, { replace: true });
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const handleExport = async (event) => {
    if (props.archivedList != '1' && !JLBCustomerRole) {
      setIsExportPopinOpen(!isExportPopinOpen);
    } else {
      await exportPopinRef.current.exportFiles();
    }
  }

  const handleExportCocoaDeparture = async (e) => {
    if (visibleCODRows.length > 0) {
      try {
        var config = {
          url: EXPORTMISSIONLIST_COD_URL,
          responseType: 'arraybuffer',
          method: "post",
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id: visibleCODRows },
        };
        const response = await axios(config);

        // Let the user save the file.
        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'Export_Cocoa_Departure_files_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx');
        setIsDownload(true);
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
        }
        else {
          setOpenSnackBar(true);
        }
      }
    }
  }

  const handleLoadAdditionalResult = async (e) => {
    await getMissionList(true);
  }

  const loadMissionTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('mission_type/list', config);
      const typeCOD = response.data.find(obj => obj.nomenclature === MISSION_TYPE_COD_NOMENCLATURE);
      setMissionTypeCOD(typeCOD);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setOpenSnackBar(true);
      }
    }
  }

  const setFilterApiParams = () => {
    const apiParams = {};
    // Hide Cocoa Departure missions
    apiParams.hide_cocoa_departure = 1;

    // Filter by local office (Port)
    if (isPortRole(localStorage.getItem("role"))) {
      apiParams.id_local_office = parseInt(localStorage.getItem("id_company"));
    }

    // Insured filter
    var idInsuredFilter = insuredRows?.find(insured => String(insured?.name) === String(filterDatas?.insured))?.id;
    if (idInsuredFilter) {
      apiParams.id_insured = idInsuredFilter;
    }
    // Mission type filter
    var idMissionTypeFilter = missionRows?.find(missionType => String(missionType?.label) === String(filterDatas?.missionType))?.id;
    if (idMissionTypeFilter) {
      apiParams.id_mission_type = idMissionTypeFilter;
    }
    // Port filter
    var idPortFilter = portRows?.find(port => String(port?.label) === String(filterDatas?.port))?.id;
    if (idPortFilter) {
      apiParams.id_port = idPortFilter;
    }
    // Principal filter
    var idPrincipalFilter = principalRows?.find(principal => String(principal?.name) === String(filterDatas?.principals))?.id;
    if (idPrincipalFilter) {
      apiParams.id_principal = idPrincipalFilter;
    }
    // Receiver filter
    var idReceiverFilter = receiverRows?.find(receiver => String(receiver?.name) === String(filterDatas?.receiver))?.id;
    if (idReceiverFilter) {
      apiParams.id_receiver = idReceiverFilter;
    }
    // Survey filter
    var idSurveyFilter = surveyRows.find(survey => { const fullName = `${survey?.firstname} ${survey?.lastname}`; return String(fullName) === String(filterDatas?.survey); })?.id;
    if (idSurveyFilter) {
      apiParams.id_survey_handler = idSurveyFilter;
    }
    // Vessel filter
    var idVesselFilter = vesselRows?.find(vessel => String(vessel?.name) === String(filterDatas?.vessel))?.id;
    if (idVesselFilter) {
      apiParams.id_vessel = idVesselFilter;
    }
    // Shipper filter
    var idShipperFilter = shipperRows?.find(shipper => String(shipper?.name) === String(filterDatas?.shipper))?.id;
    if (idShipperFilter) {
      apiParams.id_shipper = idShipperFilter;
    }
    // Contract number filter
    var contractNumberFilter = contractNumberRows?.find(contractNumber => String(contractNumber.contract_number) === String(filterDatas?.contractNumber))?.contract_number;
    if (contractNumberFilter) {
      apiParams.contract_number = contractNumberFilter;
    }
    // Status filter
    if (filterStatusArray.length > 0) {
      apiParams.status_values = filterStatusArray;
    }
    // Category filter
    if (category) {
      apiParams.category = category;
    }
    // Text search 
    if (searchTxt.length > 0) {
      apiParams.search_txt = searchTxt;
    }

    return apiParams;
  }

  const rowsFromApiFormat = (data) => {
    const dataFormat = {};
    if (!!data !== false) {
      dataFormat.id = data.id;
      dataFormat.ref = data.jlb_ref;
      dataFormat.port = data.port_label;
      dataFormat.created = data.created_at;
      dataFormat.vessel = data.vessel_name;
      dataFormat.trader = data.trader_name;
      dataFormat.insured = data.insured_name;
      dataFormat.shipper = data.shipper_name;
      dataFormat.cargo = data.cargo_type_label;
      dataFormat.public_note = data.public_note;
      dataFormat.principals = data.principal_name;
      dataFormat.warehouse = data.warehouse_label;
      dataFormat.principalsRef = data.principal_ref;
      dataFormat.missionType = data.id_mission_type;
      dataFormat.internal_note = data.internal_note;
      dataFormat.localOffice = data.local_office_name;
      dataFormat.contractNumber = data.contract_number;
      dataFormat.missionColor = data.mission_type_color;
      dataFormat.missionTypeLabel = data.mission_type_label;
      dataFormat.id_shipping_instruction = data.id_shipping_instruction;
      if (data.vessel_tonnage) dataFormat.tonnage = Number(data.vessel_tonnage);
      dataFormat.has_unread_internal_notification = data.has_unread_internal_notification;
      dataFormat.survey_handler = data.survey_handler_firstname + ' ' + data.survey_handler_lastname;

      if (data.cancel_reason_label != null) {
        dataFormat.missionCancelReason = data.cancel_reason_label;
      }

      if (JLBCustomerRole) {
        if (data.public_status_value) {
          dataFormat.status = { statut_type: data.public_status_value, statut_color: data.public_status_color };
        } else {
          dataFormat.status = { statut_type: '0', statut_color: 'red' };
        }
      } else {
        if (data.id_status) {
          dataFormat.status = { statut_type: data.status_value, statut_color: data.status_color };
        } else {
          dataFormat.status = { statut_type: '0', statut_color: 'red' };
        }
      }

      var contentRowLoop = [];
      if (data.receivers) {
        for (var j = 0; j < data.receivers.length; j++) {
          if (data.receivers[j].name) {
            contentRowLoop[j] = data.receivers[j].name;
          }
        }
      }
      dataFormat.receiver = contentRowLoop;
      dataFormat.receiverSearch = contentRowLoop;

      var contentRowLoopBL = [];
      var contentRowLoopBLTypeOfIntervention = [];
      if (data.bills_of_lading) {
        for (var j = 0; j < data.bills_of_lading.length; j++) {
          contentRowLoopBL[j] = data.bills_of_lading[j].number;
          contentRowLoopBLTypeOfIntervention[j] = data.bills_of_lading[j].label;
        }
      }
      dataFormat.b_o_l = contentRowLoopBL;
      dataFormat.typeOfIntervention = contentRowLoopBLTypeOfIntervention;

      var contentRowLoopSiBL = [];
      if (data.si_bls) {
        for (var j = 0; j < data.si_bls.length; j++) {
          contentRowLoopSiBL[j] = data.si_bls[j].number;
        }
      }
      dataFormat.b_o_l_si = contentRowLoopSiBL;
    }

    return dataFormat;
  }

  const getMissionList = async (additionalRows = false) => {
    try {
      setLoadingGrid(true);

      //config call API
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        },
        params: {
          all:1
	      }
      }

      if (!JLBCustomerRole) {
        if (props.archivedList == 1) {
          config.params.archived_missions = !additionalRows ? 1 : 0;
        } else {
          config.params.archived_missions = !additionalRows ? 0 : 1;
        }

        if (JLBPortRole) {
          config.params.id_local_office = parseInt(localStorage.getItem("id_company"));
        }
      } else {
        config.params.id_principal = parseInt(localStorage.getItem("id_company"));
      }

      if (additionalRows) {
        config.params.additionnal_rows = 1;
        const filters = setFilterApiParams();
        config.params = { ...config.params, ...filters };
      }

      const response = await axios.get(GETMISSIONLIST_URL, config);

      //set data
      var idRow = 0;
      var ports = [];
      var insured = [];
      var vessels = [];
      var surveys = [];
      var missions = [];
      var shippers = [];
      var receivers = [];
      var principals = [];
      var rowsFromApi = [];
      var contractNumbers = [];

      for (var i = 0; i < response.data.length; i++) {

        if (JLBCustomerRole) {
          if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].id_principal)) {
            continue;
          }
        }
        if (JLBPortRole) {
          if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].id_local_office)) {
            continue;
          }
        }

        idRow = rowsFromApi.length;
        rowsFromApi[idRow] = rowsFromApiFormat(response.data[i]);
        
        //
        if (!additionalRows) {
          ports[ports.length] = {
            id: response.data[i].id_port,
            label: response.data[i].port_label
          };
          principals[principals.length] = {
            id: response.data[i].id_principal,
            name: response.data[i].principal_name
          };
          surveys[surveys.length] = {
            id: response.data[i].id_survey_handler,
            firstname: response.data[i].survey_handler_firstname,
            lastname: response.data[i].survey_handler_lastname
          };
          missions[missions.length] = {
            id: response.data[i].id_mission_type,
            label: response.data[i].mission_type_label,
            color: response.data[i].mission_type_color,
            nomenclature: response.data[i].mission_type_nomenclature
          };

          if (response.data[i].id_insured) {
            insured[insured.length] = {
              id: response.data[i].id_insured,
              name: response.data[i].insured_name
            };
          }
          if (response.data[i].id_shipping_instruction) {
            contractNumbers[contractNumbers.length] = {
              contract_number: response.data[i].contract_number, 
              contractNumber: rowsFromApi[idRow].contractNumber
            };
            if (response.data[i].id_shipper) {
              shippers[shippers.length] = {
                id: response.data[i].id_shipper,
                name: response.data[i].shipper_name
              };
            }
          }
          if (response.data[i].id_vessel) {
            vessels[vessels.length] = {
              id: response.data[i].id_vessel,
              name: response.data[i].vessel_name
            };
          }
          if (response.data[i].receivers) {
            for (var j = 0; j < response.data[i].receivers.length; j++) {
              if (response.data[i].receivers[j].name) {
                receivers[receivers.length] = {
                  id: response.data[i].receivers[j].id_company,
                  name: response.data[i].receivers[j].name
                };
              }
            }
          }
        }
      }

      //init rows
      setRows(rows.concat(rowsFromApi));

      if (!additionalRows) {
        setTotalRows(rowsFromApi);
        if (props.initFilterMissions) props.initFilterMissions(rowsFromApi);

        vessels.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setVesselRows(getUnique(vessels, 'id'));
        surveys.sort((a, b) => ((a.firstname + " " + a.lastname) > (b.firstname + " " + b.lastname)) ? 1 : -1);
        setSurveyRows(getUnique(surveys, 'id'));
        principals.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setPrincipalRows(getUnique(principals, 'id'));
        missions.sort((a, b) => (a.label > b.label) ? 1 : -1);
        setMissionRows(getUnique(missions, 'id'));
        ports.sort((a, b) => (a.label > b.label) ? 1 : -1);
        setPortRows(getUnique(ports, 'id'));
        insured.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setInsuredRows(getUnique(insured, 'id'));
        shippers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setShipperRows(getUnique(shippers, 'id'));
        receivers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setReceiverRows(getUnique(receivers, 'id'));
        contractNumbers.sort((a, b) => (a.contractNumber > b.contractNumber) ? 1 : -1);
        setContractNumberRows(getUnique(contractNumbers, 'contractNumber'));
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate('/logout', { replace: true });
      }
      else {
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingGrid(false);
      setDataGridLoaded(true);
    }
  }

  function CustomNoRowsOverlay() {
    return (<div></div>);
  }

  //xGrid
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: !props?.visibleColumns?.idColumn },
    { field: 'survey_handler', headerName: 'Survey handler', hide: !props?.visibleColumns?.surveyHandlerColumn },
    { field: 'missionColor', headerName: '', sortable: false, flex: 1, width: 5, minWidth: 5, maxWidth: 5, padding: 0, type: 'number', renderCell: RenderType, hide: !props?.visibleColumns?.missionColorColumn },
    { field: 'ref', headerName: 'Ref JLB', width: 145, hide: !props?.visibleColumns?.refJLBColumn },
    { field: 'internal_note', headerName: '', sortable: false, flex: 1, width: 40, padding: 0, align: 'center', renderCell: RenderCommentInternal, hide: !props?.visibleColumns?.internalNoteColumn },
    { field: 'public_note', headerName: '', sortable: false, flex: 1, width: 40, padding: 0, align: 'center', renderCell: RenderCommentPublic, hide: !props?.visibleColumns?.publicNoteColumn },
    { field: 'missionType', headerName: 'Mission Type', type: 'number', hide: !props?.visibleColumns?.missionTypeColumn },
    { field: 'missionTypeLabel', headerName: 'Type of mission', width: 170, hide: !props?.visibleColumns?.missionTypeLabelColumn },
    { field: 'created', headerName: 'Creation date', width: 110, type: 'date', align: 'center', renderCell: RenderDate, hide: !props?.visibleColumns?.createdDateColumn },
    { field: 'status', headerName: 'Status', width: 64, type: 'number', align: 'center', renderCell: RenderStatus, valueGetter: getStatusId, hide: !props?.visibleColumns?.statusColumn },
    { field: 'id_shipping_instruction', headerName: 'ID SI', hide: !props?.visibleColumns?.idSIColumn },
    { field: 'contractNumber', headerName: 'Contract N°', flex: 1, minWidth: 130, maxWidth: 150, renderCell: RenderContractNumber, hide: !props?.visibleColumns?.contractNumberColumn },
    { field: 'port', headerName: 'Port', flex: 1, minWidth: 110, maxWidth: 150, hide: !props?.visibleColumns?.portColumn },
    { field: 'principals', headerName: 'Principals', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.principalsColumn },
    { field: 'principalsRef', headerName: 'Your réf.', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.principalsRefColumn },
    { field: 'insured', headerName: 'Insured', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.insuredColumn },
    { field: 'vessel', headerName: 'Vessel', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.vesselColumn },
    { field: 'tonnage', headerName: 'Tonnage', flex: 0.7, minWidth: 100, type: 'number', hide: !props?.visibleColumns?.tonnageColumn },
    { field: 'receiver', headerName: 'Receiver', flex: 1, minWidth: 80, sortable: false, renderCell: RenderReceiver, hide: !props?.visibleColumns?.receiverColumn },
    { field: 'receiverSearch', headerName: 'Receiver search', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.receiverSearchColumn },
    { field: 'cargo', headerName: 'Cargo', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.cargoColumn },
    { field: 'trader', headerName: 'Trader', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.traderColumn },
    { field: 'shipper', headerName: 'Shipper', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.shipperColumn },
    { field: 'b_o_l', headerName: 'Bill of lading', flex: 0.7, minWidth: 140, sortable: false, renderCell: RenderBL, hide: !props?.visibleColumns?.billOfLadingColumn },
    { field: 'b_o_l_si', headerName: 'Bill of lading - SI', flex: 0.7, minWidth: 140, sortable: false, renderCell: RenderSiBL, hide: !props?.visibleColumns?.billOfLadingSIColumn },
    { field: 'warehouse', headerName: 'Warehouse', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.warehouseColumn },
    { field: 'localOffice', headerName: 'Local office', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.localOfficeColumn },
    { field: 'typeOfIntervention', headerName: 'Type of intervention', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.typeOfInterventionColumn },
  ];


  return (
    <>
      <DataGridPro sx={{ height: '100%', width: '100%' }}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        initialState={{
          pagination: {
            pageSize: 15,
          },
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
        }}
        loading={loadingGrid}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[8, 15, 25, 50, 100]}
        pagination
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        onRowClick={handleRowClick}
        filterModel={filterModel}
        onFilterModelChange={props.rowsSelected}
        onStateChange={(state) => {
          //all line
          const newRows = gridVisibleSortedRowIdsSelector(state);
          setVisibleRows(newRows);
          //COD line
          const codRows = [];
          rows.map(row => {
            if (row?.missionType == missionTypeCOD?.id) {
              codRows.push(row.id);
            }
          });
          setVisibleCODRows(codRows);
        }}
        getRowClassName={(params) => {
          var className = '';
          if (!!params.row.missionCancelReason != false) {
            className = 'dataGridRowMissionCanceled';
          }
          return className;
        }}
      />
      <div id="listExport">
        <Button size="small" variant="outlined" startIcon={<FileDownloadIcon />} onClick={handleExport}>Export</Button>
        &nbsp;

        {(JLBInternalRole) && 
          <Button size="small" variant="outlined" startIcon={<FileDownloadIcon />} onClick={handleExportCocoaDeparture}>Export Cocoa Departure</Button>
        }
        &nbsp;
        {(JLBInternalRole) && 
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            loading={loadAdditionalResult} loadingPosition="start"
            startIcon={<DownloadingIcon />}
            onClick={handleLoadAdditionalResult}
            className={!filterOn ? 'loadingButtonCustom disabled' : 'loadingButtonCustom'}
          >
            {props.archivedList ? 'load unarchived results' : 'load archived results'}
          </LoadingButton>
        }
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Technical error ! Please try again or contact our support.
        </Alert>
      </Snackbar>
      <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          File saved in your downloads folder.
        </Alert>
      </Snackbar>

      <ExportPopin
        ref={exportPopinRef}
        open={isExportPopinOpen}
        setOpen={setIsExportPopinOpen}
        archivedList={props.archivedList}
        exportUrl={!JLBCustomerRole ? (!JLBPortRole ? EXPORTMISSIONLIST_URL : EXPORTMISSIONLIST_PORT_URL) : EXPORTMISSIONLIST_CUSTOMER_URL}

        setFilterApiParams={setFilterApiParams}

        visibleRows={visibleRows}
        setIsDownload={setIsDownload}
        setOpenSnackBar={setOpenSnackBar}
      />
    </>
  );
}

DashboardMainList.propTypes = {
  visibleColumns: PropTypes.shape({
    idColumn: PropTypes.bool,
    surveyHandlerColumn: PropTypes.bool,
    missionColorColumn: PropTypes.bool,
    refJLBColumn: PropTypes.bool,
    internalNoteColumn: PropTypes.bool,
    publicNoteColumn: PropTypes.bool,
    missionTypeColumn: PropTypes.bool,
    missionTypeLabelColumn: PropTypes.bool,
    createdDateColumn: PropTypes.bool,
    statusColumn: PropTypes.bool,
    idSIColumn: PropTypes.bool,
    contractNumberColumn: PropTypes.bool,
    portColumn: PropTypes.bool,
    principalsColumn: PropTypes.bool,
    principalsRefColumn: PropTypes.bool,
    insuredColumn: PropTypes.bool,
    vesselColumn: PropTypes.bool,
    tonnageColumn: PropTypes.bool,
    receiverColumn: PropTypes.bool,
    receiverSearchColumn: PropTypes.bool,
    cargoColumn: PropTypes.bool,
    traderColumn: PropTypes.bool,
    shipperColumn: PropTypes.bool,
    billOfLadingColumn: PropTypes.bool,
    billOfLadingSIColumn: PropTypes.bool,
    warehouseColumn: PropTypes.bool,
    localOfficeColumn: PropTypes.bool,
    typeOfInterventionColumn: PropTypes.bool,
  })
};
