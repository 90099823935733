import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsGeneral } from "../General/ContextMissionDetailsGeneral";
import { ContextMissionDetailsInstruction } from "../Instruction/ContextMissionDetailsInstruction";

import axios from 'api/axios';


const ContextMissionDetailsSI = createContext();

const MissionDetailsSIProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';
  const SI_URL = 'shipping_instruction';
  const MISSION_INSTRUCTION_URL = 'mission/instruction';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //init id si
  const { idMission, setIdMission } = useContext(ContextMissionDetails);

  //mission si state
  const { si, setSi } = useContext(ContextMissionDetails);

  const [idShippingInstruction, setIdShippingInstruction] = useState(null);

  const [newSI, setNewSI] = useState('');
  const [newSIId, setNewSIId] = useState(0);

  const [portDeparture, setPortDeparture] = useState('');
  const [portDepartureId, setPortDepartureId] = useState(0);
  const [portDepartureAlias, setPortDepartureAlias] = useState("YYY");
  const [portDepartureCountryId, setPortDepartureCountryId] = useState(0);

  const [portDestination, setPortDestination] = useState('');
  const [portDestinationId, setPortDestinationId] = useState(0);
  const [portDestinationAlias, setPortDestinationAlias] = useState("YYY");
  const [portDestinationCountryId, setPortDestinationCountryId] = useState(0);

  const { surveyHandler, setSurveyHandler } = useContext(ContextMissionDetails);
  const [surveyHandlerDefault, setSurveyHandlerDefault] = useState(0);

  const [localOffice, setLocalOffice] = useState('');
  const [localOfficeId, setLocalOfficeId] = useState(0);

  const [principals, setPrincipals] = useState(null);
  const { principalsId, setPrincipalsId } = useContext(ContextMissionDetails);
  const { principalsAlias, setPrincipalsAlias } = useContext(ContextMissionDetails);

  const [trader, setTrader] = useState('');
  const [traderId, setTraderId] = useState(0);

  const [shipper, setShipper] = useState('');
  const [shipperId, setShipperId] = useState(0);

  const [cargoType, setCargoType] = useState('');
  const [cargoTypeId, setCargoTypeId] = useState(0);

  const [cargoLoadingTypeId, setCargoLoadingTypeId] = useState(0);
  const [cargoLoadingTypeDefault, setCargoLoadingTypeDefault] = useState(0);

  const [tonnage, setTonnage] = useState(0);
  const { remainingTonnage, setRemainingTonnage } = useContext(ContextMissionDetails);
  const { missionsVesselTonnage, setMissionsVesselTonnage } = useContext(ContextMissionDetails);
  const [contractNumber, setContractNumber] = useState('');

  //instruction data
  const { 
    rowsInstruction, setRowsInstruction,
    rowsInstructionDeleted, setRowsInstructionDeleted,
    processRowAddInstruction
  } = useContext(ContextMissionDetailsInstruction);

  //general mission data
  const { 
    portId: missionPortId,
    traderId: missionTraderId,
    principalsId: missionPrincipalsId, 
    surveyHandler: missionSurveyHandler,
    localOfficeId: missionLocalOfficeId
  } = useContext(ContextMissionDetailsGeneral);


  //INIT si data
  useEffect(() => {
    //load data
    if (si) {
      if (Object.keys(si).length > 0) {
        //INIT SI
        setIdShippingInstruction(si.id);

        //INIT Contract Number
        setContractNumber(si.contract_number);

        //INIT SURVEY HANDLER
        setSurveyHandler(si.survey_handler.id);

        //INIT PRINCIPALS
        setDatasPrincipals(si.principal.id, si.principal.name, si.principal.name);

        //INIT PORT
        if (si.departure_port) setDatasPortDeparture(si.departure_port.id, si.departure_port.label, si.departure_port.nomenclature, si.departure_port);
        else setDatasPortDeparture(0, '', '');
        if (si.destination_port) setDatasPortDestination(si.destination_port.id, si.destination_port.label, si.destination_port.nomenclature, si.destination_port);
        else setDatasPortDestination(0, '', '');

        //INIT Local Office
        //TODO label
        if (si.local_office) setDatasOffice(si.local_office.id, si.local_office.name, '');
        else setDatasOffice(0, '', '');

        //INIT Trader
        if (si.trader) setDatasTrader(si.trader.id, si.trader.name, '');
        else setDatasTrader(0, '', '');

        //INIT Shipper
        if (si.shipper) setDatasShipper(si.shipper.id, si.shipper.name, '');
        else setDatasShipper(0, '', '');

        //INIT Cargo Type
        if (si.cargo_type) setDatasCargoType(si.cargo_type.id, si.cargo_type.label, '');
        else setDatasCargoType(0, '', '');

        //INIT Cargo Loading Type
        if (si.cargo_loading_type) setCargoLoadingTypeId(si.cargo_loading_type.id);
        else setCargoLoadingTypeId(0);

        //INIT Tonnage
        if (si.tonnage) setTonnage(si.tonnage);
        else setTonnage(0);
        
        //INIT Missions Vessel Tonnage
        if (si.missions_vessel_tonnage) setMissionsVesselTonnage(si.missions_vessel_tonnage);
        else setMissionsVesselTonnage([]);
        
        //INIT Remaining Tonnage
        if (si.tonnage) {
          var totalMissionsTonnage = 0;
          si?.missions_vessel_tonnage?.forEach(mission => {
            if (mission?.vessel_tonnage) {
              totalMissionsTonnage += mission.vessel_tonnage;
            }
          });
          setRemainingTonnage(si.tonnage - totalMissionsTonnage);
        } else { 
          setRemainingTonnage(0) 
        }
      }
    } else setIdShippingInstruction(0);
  }, [si]);
  
  useEffect(() => {
    if (missionsVesselTonnage) {
      var totalMissionsTonnage = 0;
      missionsVesselTonnage?.forEach(mission => {
        if (mission?.vessel_tonnage) {
          totalMissionsTonnage += mission?.vessel_tonnage;
        }
      });
      setRemainingTonnage(tonnage - totalMissionsTonnage);
    }
  }, [missionsVesselTonnage])

  //function interactive
  function createSurveyHandlerHandler(data) {
    return { id: data.id, title: data.firstname + " " + data.lastname, alias: "", labelValue: data.firstname + " " + data.lastname };
  }

  function createCargoLoadingTypeHandler(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }

  function setDatasPortDeparture(id, label, alias, data) {
    setPortDepartureId(id);
    setPortDeparture(label);
    setPortDepartureAlias(alias);
    if (!!data !== false) setPortDepartureCountryId(data.id_country);
  }

  function setDatasPortDestination(id, label, alias, data) {
    setPortDestinationId(id);
    setPortDestination(label);
    setPortDestinationAlias(alias);
    if (!!data !== false) setPortDestinationCountryId(data.id_country);
  }

  function setDatasOffice(id, label, alias) {
    setLocalOfficeId(id)
    setLocalOffice(label);
  }

  function setDatasPrincipals(id, label, alias) {
    setPrincipalsId(id)
    setPrincipals(label?.toUpperCase().trim());
    setPrincipalsAlias(alias);
  }

  function setDatasTrader(id, label, alias) {
    setTraderId(id)
    setTrader(label?.toUpperCase().trim());
  }

  function setDatasShipper(id, label, alias) {
    setShipperId(id)
    setShipper(label?.toUpperCase().trim());
  }

  function setDatasCargoType(id, label, alias) {
    setCargoTypeId(id)
    setCargoType(label?.toUpperCase().trim());
  }

  //function treatment
  const saveMissionDetailsSI = async (justBlock = false, remove = false) => {
    setLoading(true);
    setLoadingUpdate(false);

    try {
      if ((idShippingInstruction === 0 && !remove) || remove) {
        //check if SI exist
        var siId = newSIId;
        if (newSIId === 0 && newSI !== '' && !remove) {
          const response = await createSI();
          siId = response.id;
        }
        setIdShippingInstruction(siId);

        //upd mission
        const config = {
          method: 'put',
          url: MISSION_URL,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_mission: idMission, id_shipping_instruction: siId > 0 ? siId : null }
        };
        await axios(config);

        //add mission instruction when SI exist
        if (newSIId > 0) {
          await createMissionInstructions(newSIId);
          setNewSI('');
          setNewSIId(0);
        } else {
          await deleteMissionInstructions();
        }

        setSnackBarMessage("SI saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
      setLoadingUpdate(true);
    }
  }

  const createSI = async () => {
    try {
      const data = {
        contract_number: newSI,
        id_principal: Number(missionPrincipalsId),
        id_survey_handler: Number(missionSurveyHandler),
        id_trader: missionTraderId ? Number(missionTraderId) : null,
        id_departure_port: missionPortId ? Number(missionPortId) : null,
        id_local_office: missionLocalOfficeId ? Number(missionLocalOfficeId) : null
      };

      const config = {
        url: SI_URL,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
      const responseSI = await axios(config);
      return responseSI.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  const createMissionInstructions = async (siId) => {
    try {
      const saveMissionPromises = [];

      //retrieve SI
      const si = await loadSI(siId);

      //SI Instruction
      //Saving Mission Instruction
      if (si.si_instructions) {
        if (Object.keys(si.si_instructions).length > 0) {
          for (const [key, siIntruction] of Object.entries(si.si_instructions)) {
            const data = {
              dhl: siIntruction.dhl,
              remark: siIntruction.remark,
              id_mission: Number(idMission),
              id_si_instruction: Number(siIntruction.id),
              id_instruction_type: Number(siIntruction.instruction_type.id),
              id_place_of_operations: siIntruction.place_of_operations ? Number(siIntruction.place_of_operations.id) : null,
              begining_date: siIntruction.begining_date ? momentDate(siIntruction.begining_date).format("YYYY-MM-DD") : null,
              completion_date: siIntruction.completion_date ? momentDate(siIntruction.completion_date).format("YYYY-MM-DD") : null
            };

            const config = {
              url: MISSION_INSTRUCTION_URL,
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
              },
              data: data
            };
            const response = await axios(config);
            saveMissionPromises.push(response);
            processRowAddInstruction(response.data.data);
          }
        }
      }

      await Promise.all(saveMissionPromises);

    } catch (err) {
      console.log(err);
    }
  }

  const deleteMissionInstructions = async () => {
    try {
      const saveInstructionPromises = [], rowsDelete = [];
      //Stack Instructions
      for (var i = 0; i < rowsInstruction.length; i++) {
        if (!!rowsInstruction[i].id_si_instruction !== false) rowsDelete.push(rowsInstruction[i].id);
      }
      for (var i = 0; i < rowsInstructionDeleted.length; i++) {
        rowsDelete.push(rowsInstructionDeleted[i].id);
      }

      //Deleting Mission Instruction
      for (var i = 0; i < rowsDelete.length; i++) {
        const id = rowsDelete[i];
        const config = {
          url: MISSION_INSTRUCTION_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_mission_instruction: Number(id) }
        };
        const response = await axios(config);
        saveInstructionPromises.push(response);
        setRowsInstruction(rowsInstruction.filter((row) => row.id !== id));
      }

      //end treatment
      setRowsInstructionDeleted([]);
      await Promise.all(saveInstructionPromises);
    } catch (err) {
      console.log(err);
    }
  }

  const loadSI = async (siId) => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: { id_shipping_instruction: siId }
      };
      const response = await axios.get('shipping_instruction', config);
      return response.data.data;
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }


  return (
    <ContextMissionDetailsSI.Provider
      value=
      {{
        idShippingInstruction, setIdShippingInstruction,

        newSI, setNewSI,
        newSIId, setNewSIId,

        portDeparture, setPortDeparture,
        portDepartureId, setPortDepartureId,
        portDepartureAlias, setPortDepartureAlias,
        portDepartureCountryId, setPortDepartureCountryId,

        portDestination, setPortDestination,
        portDestinationId, setPortDestinationId,
        portDestinationAlias, setPortDestinationAlias,
        portDestinationCountryId, setPortDestinationCountryId,

        surveyHandler, setSurveyHandler,
        surveyHandlerDefault, setSurveyHandlerDefault,
        createSurveyHandlerHandler,

        localOffice, setLocalOffice,
        localOfficeId, setLocalOfficeId,

        principals, setPrincipals,
        principalsId, setPrincipalsId,
        principalsAlias, setPrincipalsAlias,

        trader, setTrader,
        traderId, setTraderId,

        shipper, setShipper,
        shipperId, setShipperId,

        cargoType, setCargoType,
        cargoTypeId, setCargoTypeId,

        cargoLoadingTypeId, setCargoLoadingTypeId,
        cargoLoadingTypeDefault, setCargoLoadingTypeDefault,
        createCargoLoadingTypeHandler,

        tonnage, setTonnage,
        missionsVesselTonnage, setMissionsVesselTonnage,
        remainingTonnage, setRemainingTonnage,
        contractNumber, setContractNumber,

        saveMissionDetailsSI
      }}
    >
      {children}
    </ContextMissionDetailsSI.Provider>
  );
};

export { MissionDetailsSIProvider, ContextMissionDetailsSI };
