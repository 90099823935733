import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { checkSecurity } from "components/config/Security";
import { randomId } from "@mui/x-data-grid-generator";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from '@mui/material/Tooltip';


import LoadingButton from "@mui/lab/LoadingButton";

import Menu from "./section/Menu";
import ContactForm from "components/page/section/Company/Contact/ContactForm";
import CompanyForm from "components/page/section/Company/CompanyForm";

import "bootstrap/dist/css/bootstrap.css";
import "styles/globalPortalDashboard.css";
import "styles/detailsCommon.css";
import "styles/Popin.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditCompany = (props) => {
  // CHECK SECURITY
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  var companyIdParam = parseInt(queryParams.get("id"));
  if (isNaN(companyIdParam)) companyIdParam = 0;

  const companyFormRef = useRef("companyForm");
  const contactFormRef = useRef("contactForm");

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState("");

  const [idCompany, setIdCompany] = React.useState(companyIdParam);

  const [companyFormTreatmentNotValidated, setCompanyFormTreatmentNotValidated ] = React.useState(false);
  const [contactFormTreatmentNotValidated, setContactFormTreatmentNotValidated ] = React.useState(false);
  
  useEffect(() => {
    checkSecurity(navigate, "EditCompany");
    const message = sessionStorage.getItem("companySavedMessage");
    if (message) {
      setSnackBarMessage(message);
      setSnackBarType("success");
      setOpenSnackBar(true);
      sessionStorage.removeItem("companySavedMessage");
    }
    if (props.update == 0) {
      setIdCompany(companyIdParam);
    }
  }, []);

  function setSnackErrorMsg(data) {
    var errorMsg = "Technical error ! Please try again or contact our support.";
    return errorMsg;
  }

  // CREATE/UPDATE COMPANY START
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    try {
    companyFormRef.current.saveComponent().then(({newElementId}) => {
      setIdCompany(newElementId);
      contactFormRef.current.saveComponent(newElementId)
        .then(() => {
          // Redirection
          if (props.update == 0) {
            navigate("/updateCompany?id=" +newElementId+ "&key=" + randomId(), { replace: true });
            sessionStorage.setItem("companySavedMessage", "Company saved.");
          } else {
            setSnackBarMessage("Company saved.");
            setSnackBarType("success");
            setOpenSnackBar(true);
          }
        });
    });
      
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg =
          !!err.response != false ? setSnackErrorMsg(err.response.data) : "";
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  };

  const handleClickCancel = () => {
    navigate("/companies-list", { replace: true, });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <div className="FULL-CONTENT DASHBOARD">
      <div className="content">
        <div className="contentDetail">
          <CompanyForm 
          ref={companyFormRef}
          update={props.update}
          idCompany={idCompany}
          //SAVE BUTTON DISABLE CONTROL
          setComponentTreatmentNotValidated={setCompanyFormTreatmentNotValidated}
          //Snackbar
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          setOpenSnackBar={setOpenSnackBar}/>
          <Stack direction="row">
            <Box sx={{ width: "100%" }}>
              <div className="lineContent" id="contactFormId">
                <ContactForm
                  ref={contactFormRef}
                  update={props.update}
                  idCompany={idCompany}
                  // SAVE BUTTON DISABLE CONTROL
                  setComponentTreatmentNotValidated={setContactFormTreatmentNotValidated}
                  // Snackbar
                  setSnackBarMessage={setSnackBarMessage}
                  setSnackBarType={setSnackBarType}
                  setOpenSnackBar={setOpenSnackBar}
                />
              </div>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <LoadingButton
              onClick={handleClickCancel}
              loadingIndicator="Cancelling operation"
              variant="outlined"
            >
              Cancel
            </LoadingButton>
              <Tooltip title={companyFormTreatmentNotValidated || contactFormTreatmentNotValidated ? "Some fields are empty or contain errors, please check all red fields before saving" : ""} placement="top">
                <span>
                  <LoadingButton
                    onClick={handleClickSave}
                    loadingIndicator="Saving company"
                    variant="contained"
                    disabled={
                      companyFormTreatmentNotValidated ||
                      contactFormTreatmentNotValidated
                    }
                  >
                    Save
                  </LoadingButton>
                </span>
              </Tooltip>
          </Stack>
        </div>
      </div>
      <Menu />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBarType}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditCompany;
