import React, { useState, forwardRef, useContext, useEffect } from 'react';

import { isInternalRole } from 'components/config/Roles';

import SelectCustom from 'components/select/SelectCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import SelectCustomRefresh from 'components/select/SelectCustomRefresh';
import TextFieldCustom from 'components/select/TextFieldCustom';
import DesktopDatePickerCustom from 'components/select/DesktopDatePickerCustom';
import BlockFormSaveButton from '../../Block/BlockFormSaveButton';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsGeneral } from "./ContextMissionDetailsGeneral";


const MissionDetailsGeneralForm = forwardRef((props, ref) => {

  //init config
  const { generalRef } = useContext(ContextMissionDetails);
  const { contentRef } = useContext(ContextMissionDetails);
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //composant company ref
  const { companyPrincipalsSelectRef } = useContext(ContextBlock);
  const { companyTraderSelectRef } = useContext(ContextBlock);
  const { companyInsuredSelectRef } = useContext(ContextBlock);
  const { companyLocalOfficeSelectRef } = useContext(ContextBlock);
  const { companyWarehouseKeeperSelectRef } = useContext(ContextBlock);

  //generic
  const { loading, setLoading } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { isRequired } = useContext(ContextMissionWorkflow);
  const { getWorkflow } = useContext(ContextMissionWorkflow);
  const { controlBasicFieldsData } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorMsg } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorState } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);

  //mission general data
  const { idMission, setIdMission } = useContext(ContextMissionDetailsGeneral);
  const { missionType, setMissionType } = useContext(ContextMissionDetailsGeneral);
  const { missionAlias, setMissionAlias } = useContext(ContextMissionDetailsGeneral);

  const { port, setPort } = useContext(ContextMissionDetailsGeneral);
  const { portId, setPortId } = useContext(ContextMissionDetailsGeneral);
  const { setDatasPort } = useContext(ContextMissionDetailsGeneral);

  const { surveyHandler, setSurveyHandler } = useContext(ContextMissionDetailsGeneral);
  const { surveyHandlerDefault, setSurveyHandlerDefault } = useContext(ContextMissionDetailsGeneral);

  const { localOffice, setLocalOffice } = useContext(ContextMissionDetailsGeneral);

  const { localSurveyor, setLocalSurveyor } = useContext(ContextMissionDetailsGeneral);
  const { localSurveyorsList, setLocalSurveyorsList } = useContext(ContextMissionDetailsGeneral);
  const { localSurveyorEnable, setLocalSurveyorEnable } = useContext(ContextMissionDetailsGeneral);
  const { loadingLocalSurveys, setLoadingLocalSurveys } = useContext(ContextMissionDetailsGeneral);
  const { setDatasOffice } = useContext(ContextMissionDetailsGeneral);
  const handleChangeLocalSurveyor = (event) => setLocalSurveyor(event.target.dataset.value);

  const { principals, setPrincipals } = useContext(ContextMissionDetailsGeneral);
  const { principalsId, setPrincipalsId } = useContext(ContextMissionDetailsGeneral);
  const { principalsRef, setPrincipalsRef } = useContext(ContextMissionDetailsGeneral);
  const { setDatasPrincipals } = useContext(ContextMissionDetailsGeneral);
  const changePrincipalsRefHandler = (event) => setPrincipalsRef(event.target.value.toUpperCase().trim());

  const { trader, setTrader } = useContext(ContextMissionDetailsGeneral);
  const { setDatasTrader } = useContext(ContextMissionDetailsGeneral);

  const { insured, setInsured } = useContext(ContextMissionDetailsGeneral);
  const { insuredRef, setInsuredRef } = useContext(ContextMissionDetailsGeneral);
  const { setDatasInsured } = useContext(ContextMissionDetailsGeneral);
  const changeInsuredRefHandler = (event) => setInsuredRef(event.target.value.toUpperCase().trim());

  const { vessel, setVessel } = useContext(ContextMissionDetailsGeneral);
  const { setDatasVessel } = useContext(ContextMissionDetailsGeneral);
  const { vesselArrival, setVesselArrival } = useContext(ContextMissionDetailsGeneral);
  const { setDatasVesselArrival } = useContext(ContextMissionDetailsGeneral);

  const { tonnage, setTonnage } = useContext(ContextMissionDetailsGeneral);
  const changeTonnageHandler = (event) => setTonnage(event.target.value);
  const { missionsVesselTonnage, setMissionsVesselTonnage } = useContext(ContextMissionDetails);

  const { warehouse, setWarehouse } = useContext(ContextMissionDetailsGeneral);
  const { warehouseList, setWarehouseList } = useContext(ContextMissionDetailsGeneral);
  const { warehouseEnable, setWarehouseEnable } = useContext(ContextMissionDetailsGeneral);
  const { warehouseAddButtonEnable, setWarehouseAddButtonEnable } = useContext(ContextMissionDetailsGeneral);
  const { loadingWarehouse, setLoadingWarehouse } = useContext(ContextMissionDetailsGeneral);
  const handleChangeWarehouse = (event) => setWarehouse(event.target.dataset.value?.toUpperCase().trim());

  const { warehouseKeeper, setWarehouseKeeper } = useContext(ContextMissionDetailsGeneral);
  const { setDatasWarehouseKeeper } = useContext(ContextMissionDetailsGeneral);

  const { controlType, setControlType } = useContext(ContextMissionDetailsGeneral);

  const { dateETA, setDateETA } = useContext(ContextMissionDetailsGeneral);
  const { dateETB, setDateETB } = useContext(ContextMissionDetailsGeneral);
  const { dateCompletionOfDischarge, setDateCompletionOfDischarge } = useContext(ContextMissionDetailsGeneral);
  const { dateDOB, setDateDOB } = useContext(ContextMissionDetailsGeneral);
  const { dateDVD, setDateDVD } = useContext(ContextMissionDetailsGeneral);
  const { nextPortDischarge, setNextPortDischarge } = useContext(ContextMissionDetailsGeneral);
  const changeNextPortDischargeHandler = (event) => setNextPortDischarge(event.target.value.toUpperCase().trim());

  const {dateBegining, setDateBegining} = useContext(ContextMissionDetailsGeneral);
  const {dateCompletion, setDateCompletion} = useContext(ContextMissionDetailsGeneral);
  const {dateInspection, setDateInspection} = useContext(ContextMissionDetailsGeneral);

  //context
  const { currentStage, setCurrentStage } = useContext(ContextMissionDetails);

  //handle
  const {saveMissionDetailsGeneral} = useContext(ContextMissionDetailsGeneral);
  const {handleClickCancel} = useContext(ContextMissionDetailsGeneral);
  
  // Update si missions vessel tonnage
  useEffect(() => {
    // If tonnage changed
    if (missionsVesselTonnage?.some(mission => mission?.mission_id === idMission && mission?.vessel_tonnage !== Number(tonnage))) {
      const updatedMissionsVesselTonnage = missionsVesselTonnage.map(mission => {
        if (mission?.mission_id === idMission) {
          return { ...mission, vessel_tonnage: Number(tonnage) };
        }
        return mission;
      });
      setMissionsVesselTonnage(updatedMissionsVesselTonnage);
    }
  }, [tonnage, idMission, missionsVesselTonnage]);
  
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    if ((missionType == "0") || (portId == "0") || (surveyHandler == "0") || (principalsId == "0")) {
      setLoading(true);
      var basicData = new FormData();
      //REQUIRED
      basicData.append('id_mission_type', parseInt(missionType));
      basicData.append('id_port', parseInt(portId));
      basicData.append('id_principal', parseInt(principalsId));
      basicData.append('id_survey_handler', parseInt(surveyHandler));
      //CHECK BASIC FIELD
      controlBasicFieldsData(basicData);
      contentRef.current.scrollIntoView({behavior: 'smooth'});
      setLoading(false);
    } else {
      await saveMissionDetailsGeneral(true);
      await handleClickCancel(false);
    }
  };


  //function interactive
  const { companySelectRefresh } = useContext(ContextBlock);
  const {createMissionTypeHandler} = useContext(ContextMissionDetailsGeneral);
  const {createSurveyHandlerHandler} = useContext(ContextMissionDetailsGeneral);
  const {createControlTypeHandler} = useContext(ContextMissionDetailsGeneral);
  const {createCurrentStageHandler} = useContext(ContextMissionDetailsGeneral);

  function handleChangeMissionType(MissionTypeId) {
    setMissionType(MissionTypeId);
    if (MissionTypeId > 0) getWorkflow(MissionTypeId);
  }

  function createPortHandler(data) {
    return { label: data.label.toUpperCase().trim(), id: data.id, alias: data.nomenclature, labelValue: data.label.toUpperCase().trim() };
  }

  function createLocalOfficeHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createPrincipalsHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createTraderHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createInsuredHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createVesselHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createVesselArrivalHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function setNewWarehouse(data, label, alias) {
    setWarehouseEnable(false);
    setWarehouseList((oldRows) => [...oldRows, { id: data.id, label: label.toUpperCase().trim(), labelValue: label.toUpperCase().trim() }]);
    setWarehouse(data.id);
  }

  function createWarehouseKeeperHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }


  return (
    <div id="generalForm">
      <div className="lineContent noJustify">
        <SelectCustom
          title="Type"
          api="mission_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={missionType}
          setDataValue={handleChangeMissionType}
          setDataAlias={setMissionAlias}
          defaultAlias="XXX"
          disableNone="true"
          creator={createMissionTypeHandler}
          read={canRead("id_mission_type")}
          write={canWrite("id_mission_type")}
          required={isRequired("id_mission_type")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached="false"
          error={getControlFieldsErrorState("id_mission_type")}
          helperText={getControlFieldsErrorMsg("id_mission_type")}
        />

        <SelectAutoCustom
          title="Port"
          api="port/list"
          apiParam=""
          apiParamValue=""
          dataValue={port}
          setDataValue={setDatasPort}
          defaultAlias="YYY"
          creator={createPortHandler}
          enableAddButton
          classType="addButtonSelect2"
          apiAddButton="port"
          field1={{ field: "label", label: "Label", required: true }}
          field2={{ field: "nomenclature", label: "Nomenclature", required: true, alreadyExist: true }}
          field3={{ field: "id_country", label: "Country", api: "country/list", required: true, autocomplete: true }}
          read={canRead("id_port")}
          write={canWrite("id_port")}
          required={isRequired("id_port")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_port")}
          helperText={getControlFieldsErrorMsg("id_port")}
        />

        <SelectCustom
          title="Survey handler"
          api="survey_handler/list"
          apiParam=""
          apiParamValue=""
          dataValue={surveyHandler}
          setDataValue={setSurveyHandler}
          setDataAlias=""
          defaultAlias=""
          defaultValue={surveyHandlerDefault}
          creator={createSurveyHandlerHandler}
          read={canRead("id_survey_handler")}
          write={canWrite("id_survey_handler")}
          required={isRequired("id_survey_handler")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          error={getControlFieldsErrorState("id_survey_handler")}
          helperText={getControlFieldsErrorMsg("id_survey_handler")}
        />

        <SelectAutoCustom
          title="Local office"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Local Office"
          dataValue={localOffice}
          setDataValue={setDatasOffice}
          defaultAlias=""
          creator={createLocalOfficeHandler}
          ref={companyLocalOfficeSelectRef}
          companySelectRefresh={companySelectRefresh}
          read={canRead("id_local_office")}
          write={canWrite("id_local_office")}
          required={isRequired("id_local_office")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          error={getControlFieldsErrorState("id_local_office")}
          helperText={getControlFieldsErrorMsg("id_local_office")}
        />

        <SelectCustomRefresh
          disabled={localSurveyorEnable}
          label="Local surveyor"
          value={localSurveyor}
          list={localSurveyorsList}
          onClickSelect={handleChangeLocalSurveyor}
          hidden={loadingLocalSurveys}
          read={canRead("id_local_surveyor")}
          write={canWrite("id_local_surveyor")}
          required={isRequired("id_local_surveyor")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("id_local_surveyor")}
          helperText={getControlFieldsErrorMsg("id_local_surveyor")}
        />

      </div>
      <div className="lineContent noJustify">
        <SelectAutoCustom
          title="Principals"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Principals"
          dataValue={principals}
          setDataValue={setDatasPrincipals}
          defaultAlias=""
          creator={createPrincipalsHandler}
          enableAddButton={JLBInternalMode}
          ref={companyPrincipalsSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: true, trader: false, insured: false, receiver: false, warehouseKeeper: false, stevedore: false },
            }}
          read={canRead("id_principal")}
          write={canWrite("id_principal")}
          required={isRequired("id_principal")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_principal")}
          helperText={getControlFieldsErrorMsg("id_principal")}
        />
        <TextFieldCustom
          id="princilals-ref"
          label="Principals Réf."
          variant="standard"
          value={principalsRef}
          onBlur={changePrincipalsRefHandler}
          read={canRead("principal_ref")}
          write={canWrite("principal_ref")}
          required={isRequired("principal_ref")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("principal_ref")}
          helperText={getControlFieldsErrorMsg("principal_ref")}
        />
        <SelectAutoCustom
          title="Trader"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Trader"
          dataValue={trader}
          setDataValue={setDatasTrader}
          defaultAlias=""
          creator={createTraderHandler}
          enableAddButton={JLBInternalMode}
          ref={companyTraderSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: false, trader: true, insured: false, receiver: false, warehouseKeeper: false, stevedore: false },
            }}
          read={canRead("id_trader")}
          write={canWrite("id_trader")}
          required={isRequired("id_trader")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_trader")}
          helperText={getControlFieldsErrorMsg("id_trader")}
        />

        <SelectAutoCustom
          title="Insured"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Insured"
          dataValue={insured}
          setDataValue={setDatasInsured}
          defaultAlias=""
          creator={createInsuredHandler}
          enableAddButton={JLBInternalMode}
          ref={companyInsuredSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: false, trader: false, insured: true, receiver: false, warehouseKeeper: false, stevedore: false }
            }}
          read={canRead("id_insured")}
          write={canWrite("id_insured")}
          required={isRequired("id_insured")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_insured")}
          helperText={getControlFieldsErrorMsg("id_insured")}
        />

        <TextFieldCustom
          id="insured-ref"
          label="Insured Réf."
          variant="standard"
          value={insuredRef}
          onBlur={changeInsuredRefHandler}
          read={canRead("insured_ref")}
          write={canWrite("insured_ref")}
          required={isRequired("insured_ref")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("insured_ref")}
          helperText={getControlFieldsErrorMsg("insured_ref")}
        />
      </div>

      <div className={
        ((canWrite("id_vessel") || canRead("id_vessel"))
          || (canWrite("id_vessel_arrival") || canRead("id_vessel_arrival"))
          || (canWrite("vessel_tonnage") || canRead("vessel_tonnage"))
          || (canWrite("id_warehouse") || canRead("id_warehouse"))
          || (canWrite("id_warehouse_keeper") || canRead("id_warehouse_keeper")))
          ? "lineContent separator noJustify"
          : "lineContent noJustify"}
      >
        <SelectAutoCustom
          title="Vessel (BL)"
          api="vessel/list"
          apiParam=""
          apiParamValue=""
          dataValue={vessel}
          setDataValue={setDatasVessel}
          defaultAlias=""
          creator={createVesselHandler}
          enableAddButton
          classType="addButtonSelect2"
          apiAddButton="vessel"
          field1={{ field: "name", label: "Label", required: true }}
          read={canRead("id_vessel")}
          write={canWrite("id_vessel")}
          required={isRequired("id_vessel")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_vessel")}
          helperText={getControlFieldsErrorMsg("id_vessel")}
        />

        <SelectAutoCustom
          title="Vessel (Arrival)"
          api="vessel/list"
          apiParam=""
          apiParamValue=""
          dataValue={vesselArrival}
          setDataValue={setDatasVesselArrival}
          defaultAlias=""
          creator={createVesselArrivalHandler}
          enableAddButton
          classType="addButtonSelect2"
          apiAddButton="vessel"
          field1={{ field: "name", label: "Label", required: true }}
          read={canRead("id_vessel_arrival")}
          write={canWrite("id_vessel_arrival")}
          required={isRequired("id_vessel_arrival")}
          controlFieldsStatus={workflowLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_vessel_arrival")}
          helperText={getControlFieldsErrorMsg("id_vessel_arrival")}
        />

        <TextFieldCustom
          id="tonnage"
          label="Tonnage"
          variant="standard"
          value={tonnage}
          type="decimal"
          onChange={changeTonnageHandler}
          read={canRead("vessel_tonnage")}
          write={canWrite("vessel_tonnage")}
          required={isRequired("vessel_tonnage")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("vessel_tonnage")}
          helperText={getControlFieldsErrorMsg("vessel_tonnage")}
        />

        <SelectCustomRefresh
          disabled={warehouseEnable}
          label="Warehouse"
          value={warehouse}
          list={warehouseList}
          onClickSelect={handleChangeWarehouse}
          hidden={loadingWarehouse}
          disabledButton={warehouseAddButtonEnable}
          setNewElement={setNewWarehouse}
          api="warehouse"
          field1={{ field: "label", label: "Label", required: true }}
          field2={{ field: "city", label: "City", required: true }}
          field5={{ field: "id_port", id: portId, label: port }}
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          duplicate={false}
          read={canRead("id_warehouse")}
          write={canWrite("id_warehouse")}
          required={isRequired("id_warehouse")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("id_warehouse")}
          helperText={getControlFieldsErrorMsg("id_warehouse")}
        />

        <SelectAutoCustom
          title="Warehouse Keeper"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Warehouse Keeper"
          dataValue={warehouseKeeper}
          setDataValue={setDatasWarehouseKeeper}
          defaultAlias=""
          creator={createWarehouseKeeperHandler}
          enableAddButton={JLBInternalMode}
          ref={companyWarehouseKeeperSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: false, trader: false, insured: false, receiver: false, warehouseKeeper: true, stevedore: false }
            }}
          read={canRead("id_warehouse_keeper")}
          write={canWrite("id_warehouse_keeper")}
          required={isRequired("id_warehouse_keeper")}
          controlFieldsStatus={workflowLoaded}
          cached={true}
          cacheTTL="600"
          resetCache="1"
          error={getControlFieldsErrorState("id_warehouse_keeper")}
          helperText={getControlFieldsErrorMsg("id_warehouse_keeper")}
        />
      </div>
      <div className="lineContent noJustify">
        <SelectCustom
          title="Control type"
          api="control_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={controlType}
          setDataValue={setControlType}
          setDataAlias=""
          defaultAlias=""
          creator={createControlTypeHandler}
          read={canRead("id_control_type")}
          write={canWrite("id_control_type")}
          required={isRequired("id_control_type")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          error={getControlFieldsErrorState("id_control_type")}
          helperText={getControlFieldsErrorMsg("id_control_type")}
        />

        <DesktopDatePickerCustom
          label="ETA"
          value={dateETA}
          onChange={setDateETA}
          read={canRead("estimated_time_arrival")}
          write={canWrite("estimated_time_arrival")}
          required={isRequired("estimated_time_arrival")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("estimated_time_arrival")}
          helperText={getControlFieldsErrorMsg("estimated_time_arrival")}
        />

        <DesktopDatePickerCustom
          label="ETB"
          value={dateETB}
          onChange={setDateETB}
          read={canRead("estimated_time_berthing")}
          write={canWrite("estimated_time_berthing")}
          required={isRequired("estimated_time_berthing")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("estimated_time_berthing")}
          helperText={getControlFieldsErrorMsg("estimated_time_berthing")}
        />

        <DesktopDatePickerCustom
          label="Date of berthing"
          value={dateDOB}
          onChange={setDateDOB}
          read={canRead("date_of_berthing")}
          write={canWrite("date_of_berthing")}
          required={isRequired("date_of_berthing")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("date_of_berthing")}
          helperText={getControlFieldsErrorMsg("date_of_berthing")}
        />

        <DesktopDatePickerCustom
          label="Completion of Discharge"
          value={dateCompletionOfDischarge}
          onChange={setDateCompletionOfDischarge}
          read={canRead("completion_of_discharge")}
          write={canWrite("completion_of_discharge")}
          required={isRequired("completion_of_discharge")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("completion_of_discharge")}
          helperText={getControlFieldsErrorMsg("completion_of_discharge")}
        />
      </div>
      <div className="lineContent noJustify box-1">
        <DesktopDatePickerCustom
          label="Date vessel departure"
          value={dateDVD}
          onChange={setDateDVD}
          read={canRead("date_vessel_departure")}
          write={canWrite("date_vessel_departure")}
          required={isRequired("date_vessel_departure")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("date_vessel_departure")}
          helperText={getControlFieldsErrorMsg("date_vessel_departure")}
        />

        <TextFieldCustom
          label="Next port of discharge"
          variant="standard"
          value={nextPortDischarge}
          onBlur={changeNextPortDischargeHandler}
          read={canRead("next_port_of_discharge")}
          write={canWrite("next_port_of_discharge")}
          required={isRequired("next_port_of_discharge")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("next_port_of_discharge")}
          helperText={getControlFieldsErrorMsg("next_port_of_discharge")}
        />

        <SelectCustom
          title="Current Stage"
          api="mission_stage/list"
          apiParam=""
          apiParamValue=""
          dataValue={currentStage}
          setDataValue={setCurrentStage}
          setDataAlias=""
          defaultAlias=""
          creator={createCurrentStageHandler}
          read={canRead("id_current_stage")}
          write={canWrite("id_current_stage")}
          required={isRequired("id_current_stage")}
          controlFieldsStatus={fieldsSettingsLoaded}
          cached={true}
          error={getControlFieldsErrorState("id_current_stage")}
          helperText={getControlFieldsErrorMsg("id_current_stage")}
        />

        <DesktopDatePickerCustom
          label="Date inspection"
          value={dateInspection}
          onChange={setDateInspection}
          read={canRead("inspection_date")}
          write={canWrite("inspection_date")}
          required={isRequired("inspection_date")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("inspection_date")}
          helperText={getControlFieldsErrorMsg("inspection_date")}
        />
      </div>
      <div className="lineContent noJustify box-2">
        <DesktopDatePickerCustom
          label="Date begining operations"
          value={dateBegining}
          onChange={setDateBegining}
          read={canRead("operations_begining_date")}
          write={canWrite("operations_begining_date")}
          required={isRequired("operations_begining_date")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("operations_begining_date")}
          helperText={getControlFieldsErrorMsg("operations_begining_date")}
        />

        <DesktopDatePickerCustom
          label="Date completion operations"
          value={dateCompletion}
          onChange={setDateCompletion}
          read={canRead("operations_completion_date")}
          write={canWrite("operations_completion_date")}
          required={isRequired("operations_completion_date")}
          controlFieldsStatus={fieldsSettingsLoaded}
          error={getControlFieldsErrorState("operations_completion_date")}
          helperText={getControlFieldsErrorMsg("operations_completion_date")}
        />
      </div>

      <BlockFormSaveButton blockRef={generalRef} blockCancel={async ()=> await handleClickCancel()} blockSave={handleClickSave}/>

    </div>
  );
});

export default MissionDetailsGeneralForm;
