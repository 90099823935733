import React, { useContext } from 'react';

import InfosPopin from 'components/page/object/InfosPopin';

import { ContextBlock } from "context/ContextBlock";


const BlockInfosPopin = () => {

  //generic
  const { openInfosPopin, setOpenInfosPopin } = useContext(ContextBlock);
  const { infosPopinType, setInfosPopinType } = useContext(ContextBlock);
  const { infosPopinMsg, setInfosPopinMsg } = useContext(ContextBlock);
  const { infosPopinTitle, setInfosPopinTitle } = useContext(ContextBlock);
  const { infosPopinMsgIsHTML, setInfosPopinMsgIsHTML } = useContext(ContextBlock);


  return (
    <InfosPopin
      setOpen={setOpenInfosPopin}
      open={openInfosPopin}
      type={infosPopinType}
      title={infosPopinTitle}
      msg={infosPopinMsg}
      isHTML={infosPopinMsgIsHTML}
      setIsHTML={setInfosPopinMsgIsHTML}
    />
  );
};

export default BlockInfosPopin;
