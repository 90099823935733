import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, GridColDef, DataGridSelectAutoCustom } from 'components/page/object/DataGridProCustom';

import Tooltip from '@mui/material/Tooltip';
import FormControl from "@mui/material/FormControl";
import { randomId } from "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import "bootstrap/dist/css/bootstrap.css";

import axios from "api/axios";

const MailingListMembersForm = forwardRef((props, ref) => {
    const navigate = useNavigate();

    const MAILINGLISTDETAILS_URL = "/mailing" ;
    const POSTORPUTMAILINGLIST_URL = "/mailing/list" ;

    const [mailingListMembersFormFirstNameError, setMailingListMembersFormFirstNameError] = useState({});
    const [mailingListMembersFormLastNameError, setMailingListMembersFormLastNameError] = useState({});
    const [mailingListMembersFormEmailError, setMailingListMembersFormEmailError] = useState({});

    const [rowsMailingListMember, setRowsMailingListMember] = useState([]);
    
    useImperativeHandle(ref, () => ({
        async saveComponent() {
            const {newElementId} = await saveMailingListMembersForm();
            return {newElementId};
        }
    }));

    useEffect(() => {
        loadMailinigListMember();
    }, []);

    useEffect(() => {
        if (props.update == 1) {
            loadMailingListDetails();
        }
    }, [props?.idMailingList]);

    // BUTTON DISABLE CONTROL
    useEffect(() => {
        const validateFields = () => {
            return (
                props?.rowsMailingListMembers.length <= 0 ||
                Object.values(mailingListMembersFormFirstNameError).some((value) => value === true) ||
                Object.values(mailingListMembersFormLastNameError).some((value) => value === true) ||
                Object.values(mailingListMembersFormEmailError).some((value) => value === true)
            );
        };

        if (!!props.setMailingListMembersFormTreatmentNotValidated !== false) {
            props.setMailingListMembersFormTreatmentNotValidated(validateFields());
        }
    }, [props?.rowsMailingListMembers, mailingListMembersFormFirstNameError, mailingListMembersFormLastNameError, mailingListMembersFormEmailError]);


    const loadMailinigListMember = async () => {
        try {
            const config = {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                }
            };
            const response = await axios.get("mailing/member/list", config);
            var rowsFromApi = [];
            for (var i=0; i < response.data.length; i++) {
                rowsFromApi[i] = createMailingListMemberHandler(response.data[i]);
            }
            setRowsMailingListMember(rowsFromApi);
        } catch (err) {
            console.log(err);
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            } else {
                props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
                props.setSnackBarType("error");
                props.setOpenSnackBar(true);
            }
        }
    }

    function createMailingListMemberHandler(data) {
        var labelFormat = data.firstname + ' ' + data.lastname + ' - ' + data.email;
        return {id:data.id, label: labelFormat, alias: labelFormat, labelValue: labelFormat, firstname: data.firstname, lastname: data.lastname, email: data.email };
    }
  
    const loadMailingListDetails = async () => {
        if (props?.idMailingList != 0) {
            try {
                const config = {
                    headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`},
                    params:  {id_mailing_list: props?.idMailingList}
                };
                var url = MAILINGLISTDETAILS_URL;
                const response = await axios.get(url, config);

                if (response.data.data) {
                    if (response?.data?.data?.title && !!props?.setMailingListTitle !== false) {
                        props?.setMailingListTitle(response?.data?.data?.title)
                    }
                    const tempMailingListMembers = response.data.data.members.map( (member) => {
                        if (member?.firstname?.length == 0) {
                            setMailingListMembersFormFirstNameError((prevState) => ({
                                ...prevState,
                                [member.id]: true,
                            }));
                        }
                        if (member?.lastname?.length == 0) {
                            setMailingListMembersFormLastNameError((prevState) => ({
                                ...prevState,
                                [member.id]: true,
                            }));
                        }
                        if (member?.email?.length == 0) {
                            setMailingListMembersFormEmailError((prevState) => ({
                                ...prevState,
                                [member.id]: true,
                            }));
                        }

                        return {
                            id: member.id,
                            id_mailing_list_member: member.id,
                            firstname: member.firstname,
                            lastname: member.lastname,
                            email: member.email
                        };
                    });

                    tempMailingListMembers.map((obj) => {
                        if (!props?.rowsMailingListMembers.some(mb => mb.email === obj.email)) {
                            props?.setRowsMailingListMembers((oldRows) => [...oldRows, obj]);
                        }
                    });
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    navigate("/logout", { replace: true });
                } else {
                    props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
                    props.setSnackBarType("error");
                    props.setOpenSnackBar(true);
                }
            }
        }
    };

    const saveMailingListMembersForm = async () => {
        try {
            if (props?.rowsMailingListMembers && props?.rowsMailingListMembers?.length > 0) {
                var axiosMethod;
                
                // Remove ids for new members
                const filteredMembers = props?.rowsMailingListMembers.map(member => {
                    return member.id_mailing_list_member;
                });
                
                var data = {
                    title: props?.mailingListTitle,
                    members: filteredMembers
                }
                
                if (props?.update == 0) {
                    axiosMethod = "post"
                } else if (props?.update == 1) {
                    axiosMethod = "put"
                    data.id_mailing_list = props?.idMailingList
                }

                var config = {
                    url: POSTORPUTMAILINGLIST_URL,
                    method: axiosMethod,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                const response = await axios(config);
                return { newElementId: response.data.data.id };
            }

        } catch (err) {
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            } else {
                props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
                props.setSnackBarType("error");
                props.setOpenSnackBar(true);
            }
        }
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };
    
    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    function CustomNoRowsOverlay() {
        return <div className="customNoRowsOverlay">{props?.idMailingList != 0 ? 'Add Contact' : 'Select and add a Mailing List'}</div>;
    }

    var listColumnsMailingListMembers = [];

    listColumnsMailingListMembers.push(
        {
            field: "id_mailing_list_member", type: "singleSelect", headerName: !!props?.mailingListTitle !== false ? "Contact" : 'To', flex: 1, minWidth: 100, editable: true,
            valueOptions: rowsMailingListMember,
            valueFormatter: ({ id: rowId, value, field, api }) => {
                return DataGridListValueFormatterCustom(rowId, value, field, api);
            },
            renderCell: (cellValues) => {
                if (cellValues.value == "" || cellValues.value == null) return (
                    <Tooltip title="Double click to edit" placement="top">
                    <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
                    </Tooltip>
                )
            },
            renderEditCell: (params: GridRenderEditCellParams) => {

                const { value, colDef, row } = params;
                var valueField1 = '', valueField2 = '', valueFieldText = '';
                const valueOptions = typeof colDef.valueOptions !== 'function' ? colDef.valueOptions : colDef.valueOptions({ row });
                if (value !== 0) {
                  const option = valueOptions.find((obj) => value === obj.id);
                  //if value not exist in list, re-init value at 0
                  if (option) {
                    valueField1 = option.firstname;
                    valueField2 = option.lastname;
                    valueFieldText = option.email;
                  }
                }
                return (
                    <DataGridSelectAutoCustom
                        {...params}  
                        title="Mailing List Contact"
                        apiUrl="mailing/member/list" 
                        resultFormat={{'label': ['firstname', 'lastname', '-', 'email']}}
                        //renderOption={'alias'}

                        sizeComponents="100%"

                        enableAddButton={true}
                        enableUpdButton={true}
                        classType="addButtonSelect1"
                        apiAddButton="mailing/member"
                        apiUpdButton="mailing/member"
                        field1={{ field: "firstname", label: "First Name", initValues: valueField1, required: true, notUpper: true }}
                        field2={{ field: "lastname", label: "Last Name", initValues: valueField2, required: true, notUpper: true }}
                        fieldText={{ field: "email", label: "Email", initValues: valueFieldText, required: true, alreadyExist: true, notUpper: true }}

                        setValueOptions={setRowsMailingListMember}
                        setCreatorHandle={createMailingListMemberHandler}
                        processRowUpdate={processRowUpdateMailingListMember}
                    />
                )
            }
        },
        {
            field: "delete_column",headerName: "", editable: false, sortable: false, width: 10,
            renderCell: (cellValues) => {
                return (
                    <IconButton
                        aria-label="delete"
                        onClick={(event) => {
                            deleteRowMailingListMembers(event, cellValues.id);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                );
            },
        }
    );

    const columnsMailingListMembers: GridColDef[] = listColumnsMailingListMembers;

    const processRowUpdateMailingListMember = (tempRow) => {
        const option = rowsMailingListMember.find((obj) => tempRow.id_mailing_list_member === obj.id);
        if (option) {
            tempRow = { ...tempRow, 'firstname': tempRow?.firstname && !!option.firstname === false ? tempRow.firstname : option.firstname};
            tempRow = { ...tempRow, 'lastname': tempRow?.lastname && !!option.lastname === false ? tempRow.lastname : option.lastname};
            tempRow = { ...tempRow, 'email': tempRow?.email && !!option.email === false ? tempRow.email : option.email};
        }
        
        props?.setRowsMailingListMembers(
            props?.rowsMailingListMembers.map((row) =>
                row.id === tempRow.id ? tempRow : row
            )
        );
        return tempRow;
    };

    const deleteRowMailingListMembers = (event, id) => {
        props?.setRowsMailingListMembers(props?.rowsMailingListMembers.filter((row) => row.id !== id));
        
        // SAVE BUTTON DISABLE CONTROL
        setMailingListMembersFormFirstNameError((prevState) => ({
            ...prevState,
            [id]: false,
        }));
        setMailingListMembersFormLastNameError((prevState) => ({
            ...prevState,
            [id]: false,
        }));
        setMailingListMembersFormEmailError((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    function EditToolbarContacts(props) {
        const {
            mailingListTitle,
            setRowsMailingListMembers,
            setMailingListMembersFormFirstNameError,
            setMailingListMembersFormLastNameError,
            setMailingListMembersFormEmailError
        } = props;

        const handleAddMailingListMemberClick = () => {
            const id = randomId();
            setRowsMailingListMembers((oldRows) => [
                ...oldRows,
                {
                    id: id,
                    firstname: "",
                    lastname: "",
                    email: ""
                },
            ]);
            setMailingListMembersFormFirstNameError((prevState) => ({
                ...prevState,
                [id]: true,
            }));
            setMailingListMembersFormLastNameError((prevState) => ({
                ...prevState,
                [id]: true,
            }));
            setMailingListMembersFormEmailError((prevState) => ({
                ...prevState,
                [id]: true,
            }));
        };
        
        return (
            <GridToolbarContainer className="DataGridToolbar">
                <div className="toolbarButton left">
                <Button
                    className="iconAddButton"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddMailingListMemberClick}
                >
                    Add { !!mailingListTitle !== false ? 'Contact' : 'To' }
                </Button>
                </div>
            </GridToolbarContainer>
        );
    }

    return (
        <FormControl id="datagrid-mailingListMembers" sx={{ width: "100%", height: "10%" }} size="small">
            <DataGridPro
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdateMailingListMember}
                experimentalFeatures={{ newEditingApi: true }}
                columns={columnsMailingListMembers}
                rows={props?.rowsMailingListMembers}
                autoHeight
                hideFooter={true}
                componentsProps={{
                    toolbar: {
                        mailingListTitle: props?.mailingListTitle,
                        setRowsMailingListMembers: props?.setRowsMailingListMembers,
                        setMailingListMembersFormFirstNameError: setMailingListMembersFormFirstNameError, 
                        setMailingListMembersFormLastNameError: setMailingListMembersFormLastNameError, 
                        setMailingListMembersFormEmailError: setMailingListMembersFormEmailError 
                    }
                }}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Toolbar: EditToolbarContacts
                }}
            />
        </FormControl>
    );
});

export default MailingListMembersForm;
