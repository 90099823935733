import React, { useState, useEffect, useContext } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';

import momentDate from 'moment';

import { DataGridPro, DataGridListValueFormatterCustom, DataGridDatePickerCustom, DataGridSelectCustom, DataGridTextCustom } from 'components/page/object/DataGridProCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";
import { ContextMissionDetailsBL } from "./ContextMissionDetailsBL";


export default function UnaffectedRowsGrid(props) {

  //workflow state
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);

  //init BL data
  const { rowsBL, setRowsBL } = useContext(ContextMissionDetailsReceiverBL);
  const { rowsCheckBL, setRowsCheckBL } = useContext(ContextMissionDetailsBL);
  const { rowsUnaffectedBls, setRowsUnaffectedBls } = useContext(ContextMissionDetailsBL);

  const { countries, setCountries } = useContext(ContextMissionDetailsBL);
  const { typesOfIntervention, setTypesOfIntervention } = useContext(ContextMissionDetailsBL);

  //context
  const { handleRowEditStart } = useContext(ContextBlock);
  const { handleRowEditStop } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //function interactive
  const processRowUpdate = (newRow, checkData = false) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsBL(rowsBL.map((row) => (row.id === newRow.id ? updatedRow : row)));
    if (checkData) {
      setRowsCheckBL([newRow.id]);
    }
    return updatedRow;
  };

  const columns: GridColDef[] = [
    {
      field: 'signature_place', headerName: 'Signature Place', sortable: false, width: 150, type: "singleSelect",
      editable: (canWrite("bl_block") ? true : false),
      valueOptions: countries,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const specificProcessRowUpdate = (newRow) => {
          processRowUpdate(newRow, true);
        }
        return (
          <DataGridSelectCustom
            {...params}
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={specificProcessRowUpdate}
          />
        )
      }
    },
    {
      field: 'bl_date', headerName: 'BL Date', sortable: false, width: 160, type: "date",
      editable: (canWrite("bl_block") ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const specificProcessRowUpdate = (newRow) => {
          processRowUpdate(newRow, true);
        }
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={specificProcessRowUpdate}
          />
        )
      }
    },
    {
      field: 'bol', headerName: 'BL number', sortable: false, width: 150,
      editable: (canWrite("bl_block") ? true : false),
      valueParser: (value: GridCellValue, params: GridCellParams) => {
        return value.toUpperCase();
      },
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const specificProcessRowUpdate = (newRow) => {
          processRowUpdate(newRow, true);
        }
        return (
          <DataGridTextCustom
            {...params}
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={specificProcessRowUpdate}
          />
        )
      }
    },
    {
      field: 'type_of_intervention', headerName: 'Type of intervention', sortable: false, width: 250, type: "singleSelect",
      editable: (canWrite("bl_block") ? true : false),
      valueOptions: typesOfIntervention,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'quantity', headerName: 'Cocoa - to be sold (kg)', sortable: false, width: 180, type: 'number',
      editable: (canWrite("bl_block") ? true : false),
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'deadline_date', headerName: 'Deadline', sortable: false, width: 160, type: 'date',
      editable: (canWrite("bl_block") ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'delivery_date', headerName: 'Delivery (cocoa)', sortable: false, width: 160, type: 'date',
      editable: (canWrite("bl_block") ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'smv', headerName: 'SMV (EUR)', sortable: false, width: 180, type: 'number',
      editable: (canWrite("bl_block") ? true : false),
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'note', headerName: 'Comment', sortable: false, flex: 1,
      editable: (canWrite("bl_block") ? true : false),
      renderCell: (cellValues) => {
        if (canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            fullWidth
            multiline
            read={canRead('bl_block')}
            write={canWrite('bl_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
  ];


  if (rowsUnaffectedBls.length > 0) {
    return (
      <div className="unaffectedRowsDiv">
        <label>Unaffected Bills of Lading</label>
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          density='compact'
          hideFooter={true}
          rows={rowsUnaffectedBls}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsUnaffectedBls },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </div>
    );
  }
}
