import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ProtisPhoto from './MissionDetailsProtisPhoto';
import PdfRecursiveListItem from './PdfRecursiveListItem';

import useMissionDetailsProtisModalLogic from "./useMissionDetailsProtisModalLogic";

import axios from 'api/axios';


const LinkModal = (props) => {
  const navigate = useNavigate();
  const withoutVideo = false;

  const MISSIONDOCUMENTLINK_URL = 'mission/documents/link';

  const [link, setLink] = useState('');
  const [linkMessage, setLinkMessage] = useState('');

  //context
  const {
    elmtLoading, setElmtLoading,
    modalLoading, setModalLoading,
    selectedMedias, setSelectedMedias,
    protisFullTree, setProtisFullTree,
    selectedFolders, setSelectedFolders,
    currentFolderId, setCurrentFolderId,

    handleResetClick,
    handleSelectAllClick,
    resetForm,
    addMediaToSelected,
    handleRemoveMediaClick,
    handleLoadedPhoto
  } = useMissionDetailsProtisModalLogic({ protisData: props.protisData, withoutVideo: withoutVideo });


  useEffect(() => {
    if (props.open) {
      setCurrentFolderId(null);
    } else {
      resetForm();
    }
  }, [props.open]);


  const handleSaveClick = async () => {
    try {
      setElmtLoading(true);

      const filesIds = [];
      selectedFolders.forEach((row) => {
        row.photos.map((photo) => {
          if (photo.isSelected) {
            filesIds.push(photo.id_file);
          }
        });
        row.videos.map((video) => {
          if (video.isSelected) {
            filesIds.push(video.id_file);
          }
        });
      });
      selectedMedias.forEach((row) => {
        if (row.isSelected) {
          filesIds.push(row.id_file);
        }
      });

      var config = {
        method: 'post',
        url: MISSIONDOCUMENTLINK_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: {
          id_mission: props.idMission,
          files: filesIds,
          type: 'protis'
        }
      };

      const response = await axios(config);

      setLink(response.data.link.link);
      setLinkMessage('Public link of photo(s)/video(s) stored in sharepoint successfully');
      props?.fileForm?.current.reloadLinks();

      props.setSnackBarMessage('Public link of photo(s)/video(s) stored in sharepoint successfully');
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setElmtLoading(false);
    }
  }


  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      className={"modalPopin info"}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '99%',
          width: '60%',
          bgcolor: 'rgb(52,52,52)',
          //border: '2px solid #000',
          boxShadow: 24,
          borderRadius: 2,
          p: 2,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ margin: 0, color: 'white' }}>Generate Link</h4>
          </div>
          <IconButton aria-label="close" onClick={() => props.setOpen(false)}>
            <CloseIcon style={{ marginBottom: '3px', fontSize: '30px', color: 'white' }} />
          </IconButton>
        </div>
        <div style={{ display: 'flex', height: '75%', marginTop: '10px' }}>
          <div style={{ width: '49%', margin: '1%', height: '90%', border: '1px solid lightgray', overflow: 'auto' }}>
            <PdfRecursiveListItem
              {...props}
              withoutVideo={withoutVideo}
              currentFolderId={currentFolderId}
              setCurrentFolderId={setCurrentFolderId}
              currentFolderData={protisFullTree}
              addMediaToSelected={addMediaToSelected} />
          </div>
          <div style={{ width: '49%', margin: '1%', height: '90%', border: '1px solid lightgray', overflow: 'auto' }}>
            {selectedFolders.map((element, index) => (
              <div key={index} className='containerPdfModal'>
                <div className='containerPdfModalHeader'>
                  <div className='photoPdfModalColumnDiv' style={{ width: '93%' }}>
                    <div><b>{element.label}</b></div>
                  </div>
                </div>
                {element.photos.concat(element.videos).map((media, i) => (
                  media.isSelected && <div key={i} className='photoPdfModal'>
                    <div className={`photoPdfModalColumnDiv`} style={{ maxWidth: '30%' }}>
                      <IconButton onClick={() => handleRemoveMediaClick(media)}>
                        {media.isPhoto ? (
                          <ProtisPhoto
                            {...props}
                            element={media}
                            width={60}
                            height={60}
                            loadPhoto={media.isLoaded ? false : true}
                            handleLoadedPhoto={handleLoadedPhoto}
                          />
                        ) : (
                          <div className="protisPhotoDiv" style={{ width: '60px', height: '60px' }} >
                            <PlayCircleOutlineIcon sx={{ width: '60px', height: '60px' }} fontSize={'large'} />
                          </div>
                        )}
                        <Delete sx={{ color: 'red' }} className='photoPdfModalIcon' style={{ width: '50px', height: '50px' }} />
                      </IconButton>

                    </div>
                  </div>
                ))}
                <div className='containerSeparator'></div>
              </div>
            ))}
            {selectedMedias.map((element, index) => (
              <div key={index} className='photoPdfModal'>
                <div className={`photoPdfModalColumnDiv`} style={{ maxWidth: '30%' }}>
                  <IconButton onClick={() => handleRemoveMediaClick(element)}>
                    {element.isPhoto ? (
                      <ProtisPhoto
                        {...props}
                        element={element}
                        width={60}
                        height={60}
                        loadPhoto={!element.isLoaded}
                        handleLoadedPhoto={handleLoadedPhoto}
                      />
                    ) : (
                      <PlayCircleOutlineIcon sx={{ width: '60px', height: '60px', color: 'lightgray' }} fontSize={'large'} />
                    )}
                    <Delete sx={{ color: 'red' }} className='photoPdfModalIcon' style={{ width: '50px', height: '50px' }} />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
        {
          link != "" &&
          <div>
            <MuiAlert severity="success">{linkMessage}</MuiAlert>
              <div style={{ width: '100%', padding: '10px', color: 'white', overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap' }}>
              <IconButton
                aria-label="Copy to clipboard"
                title="Copy to clipboard"
                onClick={(event) => {
                  navigator.clipboard.writeText(link)
                  setLinkMessage("Link copied!")
                }}>
                <CopyAllIcon style={{ color: 'white' }} />
              </IconButton>
              {link}
            </div>
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            onClick={(e) => { handleResetClick(); }}
            variant="contained"
            disabled={
              ((selectedMedias.length == 0 && selectedFolders.length == 0) || elmtLoading)}
            style={{ marginRight: '8px' }}
            sx={{
              backgroundColor: 'red',
              "&.Mui-disabled": {
                background: "#727272"
              }
            }}
            loading={modalLoading}
          >
            Reset
          </LoadingButton>
          <LoadingButton
            onClick={(e) => { handleSelectAllClick(); }}
            variant="contained"
            disabled={elmtLoading}
            style={{ marginRight: '8px' }}
            sx={{
              backgroundColor: '#1976d2',
              "&.Mui-disabled": {
                background: "#727272"
              }
            }}
            loading={modalLoading}
          >
            Select all
          </LoadingButton>
          <LoadingButton
            onClick={(e) => { handleSaveClick(); }}
            variant="contained"
            disabled={(selectedMedias.length == 0 && selectedFolders.length == 0) || elmtLoading || link != ''}
            sx={{
              backgroundColor: '#1976d2',
              "&.Mui-disabled": {
                background: "#727272"
              }
            }}
            loading={modalLoading}
          >
            Generate
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default LinkModal;
