import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import ReactPlayer from 'react-player'
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Tooltip from '@mui/material/Tooltip';

import axios from 'api/axios';
import BreadcrumbsCustom from "./BreadcrumbsCustom";
import SelectCustom from 'components/select/SelectCustom';
import TextFieldCustom from 'components/select/TextFieldCustom';
import ConfirmDialog from 'components/page/object/ConfirmDialog'
import MissionDetailsProtisDetailTree from 'components/page/section/MissionDetails/Protis/MissionDetailsProtisDetailTree'
import DownloadIcon from 'imgs/download-2.svg';
import MoveIcon from 'imgs/move.svg';
import RotateCounterClockwiseIcon from 'imgs/rotate_counterclockwise.svg';
import RotateClockwiseIcon from 'imgs/rotate_clockwise.svg';
import CropIcon from 'imgs/crop.svg';
import FolderIcon from 'imgs/icon-folder.svg';
import HalfFolderIcon from 'imgs/icon-half-folder.svg';
import CancelIcon from 'imgs/cancel.svg';
import ReactCrop from 'react-image-crop';
import MagicWandIcon from 'imgs/magic-wand-icon.svg';

import 'react-image-crop/dist/ReactCrop.css'
import { formatDate } from 'components/page/object/Utils'

const ProtisDetail = (props) => {

  const navigate = useNavigate();
  const imgRef = useRef(null);
  const fileInput = useRef(null);
  const [missionCanceled] = useState(!!props.missionCanceled !== false ? props.missionCanceled : false);

  const [protisElementType, setProtisElementType] = useState("");
  const [protisElementTypeList, setProtisElementTypeList] = useState([]);
  const [protisFolderTitle, setProtisFolderTitle] = useState("");
  const [protisElementComment, setProtisElementComment] = useState("");
  const [elementsList, setElementsList] = useState([]);

  const [protisImageSrc, setProtisImageSrc] = useState("");
  const [protisFilename, setProtisFilename] = useState("");
  const [protisImgChanged, setProtisImgChanged] = useState(false);
  const [protisVideoSrc, setProtisVideoSrc] = useState("");
  const [protisVideoPlaying, setProtisVideoPlaying] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);

  const [protisDetailLoading, setProtisDetailLoading] = useState(false);
  const [protisSaveLoading, setProtisSaveLoading] = useState(false);
  const [imgCrop, setImgCrop] = useState(false);
  const [imgCropActive, setImgCropActive] = useState(false);
  const [imgHeight, setImgHeight] = useState(0);
  const [creationForm, setCreationForm] = useState(false);
  
  const [movingForm, setMovingForm] = useState(false);
  const [moveDestinationId, setMoveDestinationId] = useState(null);
  const [openMoveConfirmationDialog, setOpenMoveConfirmationDialog] = useState(false);
  
  const [folderTitleError, setFolderTitleError] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const MISSIONPROTISFOLDER_URL = "/mission/container"
  const MISSIONPROTISFOLDERMOVE_URL = "/mission/container/move"
  const MISSIONPROTISPHOTO_URL = "/mission/photo"
  const MISSIONPROTISPHOTOMOVE_URL = "/mission/photo/move"
  const MISSIONPROTISVIDEO_URL = "/mission/video"
  const MISSIONPROTISVIDEOMOVE_URL = "/mission/video/move"
  const MISSIONPROTISVIDEOFILE_URL = "/mission/video/file"
  const MISSIONPROTISDOCUMENT_URL = "/mission/document"
  const MISSIONPROTISDOCUMENTPAGE_URL = "/mission/document/file"
  const MISSIONPROTISDOCUMENTPREVIEW_URL = "/mission/document/preview"
  const MISSIONPROTISDOCUMENTSTORE_URL = "/mission/document/store"
  const MISSIONPROTISDOCUMENTSTYPES_URL = "file_type/list"

  // Load current element
  useEffect(() => {
    //is element photo or video ? (for display)
    setIsMedia(props.elementInfo?.isPhoto || props.elementInfo?.isVideo || props.elementInfo?.isDocumentPage);
    //If no id => set all fileds empty (creation)
    if (!props.elementInfo.id) {
      setCreationForm(true);
      setProtisImageSrc('');
      setProtisVideoSrc('');
      setProtisFilename('');
      setProtisElementType('');
      setProtisElementTypeList([]);
      setProtisFolderTitle('');
      setFolderTitleError('');
      setProtisElementComment('');
      setCurrentDocument(null);
    } else {//if id => initialize all data (update)
      setCreationForm(false);
      if (props.elementInfo?.type) {
        setProtisElementType(props.elementInfo.type.id);
      } else {
        setProtisFolderTitle(props.elementInfo.label);
      }
      if (props.elementInfo.isPhoto && props.elementInfo.file) {
        setProtisImageSrc(`data:image/jpeg;base64,${props.elementInfo.file.file_path}`)
        setProtisFilename(props.elementInfo.file.file_name);
      }
      if (props.elementInfo.isVideo && props.elementInfo.id) {
        loadProtisVideo();
        setProtisVideoPlaying(false);
      }
      if (props.elementInfo.isDocumentPage) {
        setProtisImageSrc(props.elementInfo.file_path);
        setProtisFilename(props.elementInfo.file_name);
      }
      setProtisElementComment(props.elementInfo.comment);
    }
  }, [props.elementInfo])
  
  // Load documents types separate variable
  useEffect(() => {
    if (props?.elementInfo?.isDocument && protisElementTypeList?.length == 0) {
      loadDocumentsTypes();
    }
  }, [props?.elementInfo, protisElementTypeList])

  // General loading check
  useEffect(() => {
    if (props.loadingFiles || protisSaveLoading) {
      setProtisDetailLoading(true);
    } else {
      setProtisDetailLoading(false);
    }
  }, [props.loadingFiles, protisSaveLoading])
  
  useEffect(() => {
    function handleWindowResize() {
      setImgHeight(imgRef.current?.offsetHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    setImgHeight(imgRef.current?.offsetHeight);
  }, []);

  useEffect(() => {
    setImgHeight(imgRef.current?.offsetHeight);
    setImgCrop(false);
  }, [imgCropActive]);

  useEffect(() => {
    setImgCropActive(false);
    setImgCrop(false); 
    if(props.open){
      if (props.elementInfo?.isDocument) {
        setElementsList(props.documentsList);
        if(props.elementInfo.files == undefined && !protisDetailLoading){
          props.loadDocumentDetails(props.elementInfo.id, setProtisDetailLoading);
        }
      }else{
        setElementsList(props.protisListElements);
      }
    }
  }, [props.open]);
  
  // Allows updating of data
  useEffect(() => {
    if (!props?.elementInfo.isDocument && !props?.elementInfo.isDocumentPage && props?.protisListElements.length > 0) {
      setElementsList(props?.protisListElements);
      updateElementInfoListPosition();
    }
  }, [props?.protisListElements, props.elementInfo])
  
  // Allows updating of documents data
  useEffect(() => {
    if (props?.documentsList?.length > 0) {
      if (props?.elementInfo.isDocument) {
        setElementsList(props?.documentsList)
        if (props?.elementInfo.files == undefined) {
          props.loadDocumentDetails(props?.elementInfo.id, setProtisDetailLoading);
        }
      }
      if (props?.elementInfo.isDocumentPage) {
        const currentDocument = props?.documentsList.find((document) => document.id === props?.elementInfo?.document?.id);
        setElementsList(currentDocument?.files);
      }
    }
  }, [props?.documentsList, props.elementInfo])
  
  // Open detail in moving mode
  useEffect(() => {
    setMovingForm(false);
    setMoveDestinationId(null); 
    if (props?.openMovingForm) {
      setMovingForm(true);
    }
  }, [props?.openMovingForm])

  const createProtisElementTypeHandler = (data) => {
    return { id: data.id, title: data.label, alias: data.code, labelValue: data.label };
  }

  const handlePreviousElementClick = () => {
    const previousListPosition = props.elementInfo.listPosition == 1 ? elementsList.length : props.elementInfo.listPosition - 1;
    const previousElement = elementsList.find((element) => element.listPosition == previousListPosition) || null;
    if (previousElement) {
      props.setElementInfo(previousElement);
      if (previousElement.isDocument && previousElement.files == undefined) {
        props.loadDocumentDetails(previousElement.id, setProtisDetailLoading);
      }
    }
    // Cancel crop mode
    setImgCropActive(false);
    setImgCrop(false);
    
    if (props?.allowMoving) {
      // Cancel move mode
      setMovingForm(false);
      props?.setOpenMovingForm(false);
    }
  }
  
  const handleNextElementClick = () => {
    const nextListPosition = props.elementInfo.listPosition >= elementsList.length ? 1 : props.elementInfo.listPosition + 1;
    const nextElement = elementsList.find((element) => element.listPosition == nextListPosition) || null;
    if (nextElement) {
      props.setElementInfo(nextElement);
      if (nextElement.isDocument && nextElement.files == undefined) {
        props.loadDocumentDetails(nextElement.id, setProtisDetailLoading);
      }
    }
    // Cancel crop mode on 'Next element' arrow click
    setImgCropActive(false);
    setImgCrop(false);
    
    // Cancel move mode on  'Next element' arrow click
    setMovingForm(false);
    props?.setOpenMovingForm(false);
  }

  const handleChangeProtisElementType = (protisElementTypeId) => {
    setProtisElementType(protisElementTypeId);
  }

  const handleChangeProtisFolderTitle = (event) => {
    const title = event.target.value;
    setProtisFolderTitle(event.target.value);
    
    if (title.trim() === "") {
      setFolderTitleError("");
      return;
    }

    const exists = props?.protisData?.containers.some(
      (container) => container.label.toLowerCase() === title.toLowerCase()
    );

    if (exists) {
      setFolderTitleError("Title already exists");
    } else {
      setFolderTitleError("");
    }
  }

  const handleElementCommentChange = (event) => {
    setProtisElementComment(event.target.value);
  }

  const handleMoveFileClick = () => {
    if (!movingForm) {
      setMovingForm(true); 
    }
    if(movingForm && moveDestinationId && !openMoveConfirmationDialog) {
        setOpenMoveConfirmationDialog(true);
    }
  };
  
  const handleMoveCancelClick = () => {
    setMovingForm(false);
    setMoveDestinationId(null);
  }
  
  const handleConfirmMoveDialog = () => {
    moveProtisElement();
  }
  
  const openDestinationWithNewBreadcrumbPath = () => {
    const destinationFolder = props?.protisData?.containers.find(container => container.id == moveDestinationId);
    const result = [{id: null, label: 'Root folder',  active: false}];

    if (destinationFolder) {
      const parentFolderId = destinationFolder?.id_mission_container_master;
        
      if (parentFolderId) {
          const folderHierarchy = []; // Store folder hierarchy in reverse order
          var currentFolderId = parentFolderId;
          
          // Traverse up the hierarchy and collect folder IDs
          while (currentFolderId !== null) {
              folderHierarchy.push(currentFolderId);
              const parentFolder = props?.protisData?.containers.find(container => container.id === currentFolderId);
              currentFolderId = parentFolder ? parentFolder.id_mission_container_master : null;
          }
          
          folderHierarchy.reverse().forEach(folderId => {
              const container = props?.protisData?.containers.find(container => container.id == folderId);
              const folderObject = {
                  id: container.id,
                  label: container.label,
                  active: false
              };
              result.push(folderObject);
          });
      }
      
      // Add destination folder
      const destinationFolderObject = {
          id: destinationFolder.id,
          label: destinationFolder.label,
          active: true
      };
      result.push(destinationFolderObject);
    }
    
    if (result.length == 1) {
      result[0].active = true
    }
    
    props?.setProtisBreadcrumbsList(result);
    props?.setProtisCurrentContainerId(moveDestinationId !== 'rootFolder' ? moveDestinationId : null);
  }
  
  const updateElementInfoListPosition = () => {
    // Updates listPosition after moving
    let filteredList = props?.protisListElements;
    if (props?.elementInfo?.isFolder) {
        filteredList = filteredList.filter(element => element.isFolder);
    } else if (props?.elementInfo?.isPhoto) {
        filteredList = filteredList.filter(element => element.isPhoto);
    } else if (props?.elementInfo?.isVideo) {
        filteredList = filteredList.filter(element => element.isVideo);
    }

    const matchingElement = filteredList?.find(element => element.id === props.elementInfo.id);

    if (matchingElement && matchingElement.listPosition !== props.elementInfo.listPosition) {
      props?.setElementInfo(prevElementInfo => ({
        ...prevElementInfo,
        listPosition: matchingElement.listPosition
      }));
    }
  }

  const moveProtisElement = async () => {
    setProtisDetailLoading(true);
    try {
      var config = {
        url: props.elementInfo.isPhoto? MISSIONPROTISPHOTOMOVE_URL : props.elementInfo.isVideo ? MISSIONPROTISVIDEOMOVE_URL : props.elementInfo.isFolder ? MISSIONPROTISFOLDERMOVE_URL : null ,
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
        params: {
          id_mission: props.idMission,
          id_mission_photo: props.elementInfo.isPhoto ? props.elementInfo.id : null,
          id_mission_video : props.elementInfo.isVideo ? props.elementInfo.id : null,
          id_mission_container : props.elementInfo.isFolder ? props.elementInfo.id : null,
          id_mission_container_destination: moveDestinationId !== 'rootFolder' ? moveDestinationId : null
        }
      };

      const response = await axios(config);
      
      if (response) {
        
        setProtisDetailLoading(false);
        setMovingForm(false);
        setMoveDestinationId(null);  
        props.setOpenMovingForm(false);
        
        var updatedElementInfo = {};
        
        // Updating of elementInfo after moving it
        if (props?.elementInfo.isPhoto || props.elementInfo.isVideo) {
          updatedElementInfo = { ...props.elementInfo, id_mission_container: moveDestinationId !== 'rootFolder' ? moveDestinationId : null};
        } else if (props?.elementInfo.isFolder) {
          updatedElementInfo = { ...props.elementInfo, id_mission_container_master: moveDestinationId !== 'rootFolder' ? moveDestinationId : null };
        }

        // Refresh element's info and list
        props?.refreshFullTree(updatedElementInfo, false, true);
        openDestinationWithNewBreadcrumbPath();
        props?.setElementInfo(updatedElementInfo);
        
        props.setSnackBarMessage((props.elementInfo?.isPhoto ? 'Photo' : props.elementInfo?.isVideo ? 'Video' : props?.elementInfo?.isFolder ? 'Folder' : null) + ' moved.');
        props.setSnackBarType("success");
        props.setOpenSnackBar(true);
      }

    } catch (err) {
      
      setProtisDetailLoading(false);
      setMovingForm(false);
      setMoveDestinationId(null);  
      props.setOpenMovingForm(false);
      
      console.log(err);
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }
  
  const handleDownloadFileClick = () => {
    const link = document.createElement('a');
    link.href = protisImageSrc ? protisImageSrc : protisVideoSrc;
    link.download = props.elementInfo?.file?.file_name ? props.elementInfo?.file?.file_name : props.elementInfo?.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function handleCropImageClick() {
    //if crop inactive : pass to crop mode
    if (!imgCropActive) {
      setImgCropActive(true);
    } else {//id crop active : do crop img
      const image = new Image();
      image.src = protisImageSrc;
      const canvas = document.createElement('canvas');
      const scale = image.naturalHeight / imgHeight;
      canvas.width = imgCrop.width;
      canvas.height = imgCrop.height;
      const ctx = canvas.getContext('2d');

      const pixelRatio = window.devicePixelRatio;
      canvas.width = imgCrop.width * pixelRatio;
      canvas.height = imgCrop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        imgCrop.x * scale,
        imgCrop.y * scale,
        imgCrop.width * scale,
        imgCrop.height * scale,
        0,
        0,
        imgCrop.width,
        imgCrop.height,
      );

      // Converting to base64
      const base64Image = canvas.toDataURL('image/jpeg', 1.0);
      //changing img source
      setProtisImageSrc(base64Image);
      //crop mode off + set img changed for save purpose
      setImgCropActive(false);
      setProtisImgChanged(true);
    }
  }

  const handleRotateImageClick = (clockwise = true) => {
    const img = new Image();
    img.src = protisImageSrc;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.height;
      canvas.height = img.width;
      ctx.translate(canvas.width / 2, canvas.height / 2);
      var degrees = 90;
      if (!clockwise) {
        degrees = -90
      }
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      const rotatedImageData = canvas.toDataURL('image/jpeg', 1.0);
      if (rotatedImageData) {
        //change img source + set img changed for save purpose
        setProtisImageSrc(rotatedImageData)
        setProtisImgChanged(true);
      }
    };
  };
  
  const handleOpenFolderButtonClick = () => {
    props.handleOpenFolderClick(props.elementInfo);
    props.setElementFirstChild(props.elementInfo); 
  }

  const handlePlayVideoClick = () => {
    if (protisVideoPlaying) {
      setProtisVideoPlaying(false);
    } else {
      setProtisVideoPlaying(true);
    }
  }

  const openFileUpload = () => {
    fileInput.current.click();
  }

  const handleFileUpload = event => {
    const fileUploaded = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      if (props.elementInfo.isPhoto) {
        setProtisImageSrc(reader.result);
        setProtisFilename(fileUploaded.name);
        setProtisImgChanged(true);
      }
      if (props.elementInfo.isVideo) {
        setProtisVideoSrc(reader.result);
        setProtisFilename(fileUploaded.name);
      }
    }
    reader.readAsDataURL(fileUploaded);
  }


  const handleSaveClick = async (switchToNext) => {
    try {
      setProtisSaveLoading(true);
      //Prepare data depending of element type (photo, video, document or container)
      var data = {};
      
      data.id_mission = Number(props.idMission);
      var url = '';
      //Photo
      if (props.elementInfo?.isPhoto) {
        if (props.elementInfo.id) {
          data.id_mission_photo = props.elementInfo.id;
        }
        data.id_mission_container =props.elementInfo.id_mission_container;
        url = MISSIONPROTISPHOTO_URL;
      } else if (props.elementInfo?.isVideo) {
      //Video
        if (props.elementInfo.id) {
          data.id_mission_video = props.elementInfo.id;
        }
        data.id_mission_container = props.elementInfo.id_mission_container;
        url = MISSIONPROTISVIDEO_URL;
      } else if (props.elementInfo?.isDocument) {
      //Document
        if (props.elementInfo.id) {
          data.id_mission_document = props.elementInfo.id;
        }
        if (protisElementType){
          data.id_mission_document_type = protisElementType;
        }
        
        // Document Pages reorder
        if (props.elementInfo.files && Array.isArray(props.elementInfo.files)) {
          const pagesArray = props.elementInfo.files.map(file => ({
            id_file: file.id,
            order: file.pivot.order
          }));
          
          data.pages = pagesArray;
        }
        
        url = MISSIONPROTISDOCUMENT_URL;
      } else if (props.elementInfo?.isDocumentPage) {
      // Document Page
        if (props.elementInfo.document) {
          data.id_mission_document = props.elementInfo.document.id;
        }
        data.id_file = props.elementInfo.id;
        if(protisImgChanged){
          data.file = protisImageSrc;
        }
        url = MISSIONPROTISDOCUMENTPAGE_URL;
      } else {
      //Folder
        data.id_mission_container = props.elementInfo.id;
        if (props.elementInfo.id_mission_container) {
          data.id_mission_container_master = props.elementInfo.id_mission_container;
        }
        if (protisFolderTitle) {
          data.label = protisFolderTitle;
        }
        url = MISSIONPROTISFOLDER_URL;
      }

      if (isMedia) {
        if (protisElementType) {
          data.filename = protisFilename ? protisFilename : null;
          if (props.elementInfo.isPhoto) {
            data.id_mission_photo_type = protisElementType;
            data.file = protisImageSrc;
          }
          if (props.elementInfo.isVideo) {
            data.id_mission_video_type = protisElementType;
            data.file = protisVideoSrc;
          }
        }
      }

      data.comment = protisElementComment ? protisElementComment : null;

      var method = props.elementInfo?.id ? 'put' : 'post';
      var config = {
        url: url,
        method: method,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      //do save
      const response = await axios(config);

      if (response) {
        if (response.status == 200) {
          var newElement = response.data?.data;
          newElement.isFolder = props.elementInfo?.isFolder ? true : false;
          newElement.isPhoto = props.elementInfo?.isPhoto ? true : false;
          newElement.isVideo = props.elementInfo?.isVideo ? true : false;
          newElement.isDocument = props.elementInfo?.isDocument ? true : false;
          newElement.isDocumentPage = props.elementInfo?.isDocumentPage ? true : false;
          //update elements list
          if (props.elementInfo?.id) {
            //update element
            if(props.elementInfo?.isDocumentPage){
              var updatedList = props.documentsList.map((row) => {
                if (row.id === props.elementInfo.document.id) {
                  var files = row.files.map((file) => {
                    if(file.id === props.elementInfo.id){
                      file.id = newElement.id;
                      file.file_name = newElement.file_name;
                      file.file_path = `data:${newElement.mime_type};base64,${newElement.file_path}`;
                    }
                    return file;
                  });
                  row.files = files;
                  setElementsList(files);
                }
                return row
              });
            } else if (props?.elementInfo?.isDocument) {
              const newDocumentType = protisElementTypeList.find((type) => type.id == protisElementType);
              if (newDocumentType) {
                const updatedElementsList = elementsList.map((document) => 
                  document.id === props?.elementInfo.id
                    ? {
                        ...document,
                        type: newDocumentType
                      }
                    : document
                );
                setElementsList(updatedElementsList);
                props.setDocumentsList(updatedElementsList);
              }
            } else {
              var updatedList = elementsList.map((row) => {
                if (row.id == newElement.id) {
  
                  newElement.listPosition = row.listPosition;
  
                  if (!newElement.file) {
                    newElement.file = row.file;
                  }
                  if (row.files) {
                    newElement.files = row.files;
                  }
                  return newElement;
                }
                return row
              });
            }
          } else {
            //Add new element
            var updatedList = elementsList;
            updatedList.push(newElement);
            setCreationForm(false);
            props.setOpen(false);
          }
          //refresh datas
          if (props.elementInfo.isDocumentPage) {
            props.setDocumentsList(updatedList);
          } 
          if (!props?.elementInfo.isDocument && !props?.elementInfo.isDocument) {
            props.refreshList(updatedList);
            props.refreshFullTree(newElement, false, false);
          }
          setProtisImgChanged(false);
        }
      }

      if (switchToNext) {
        handleNextElementClick();
      }

      setProtisSaveLoading(false);
      props.setSnackBarMessage(((props.elementInfo?.isPhoto || props.elementInfo?.isVideo)  ? 'File' : props?.elementInfo?.isDocument ? 'Document' : 'Folder') + ' saved.');
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setProtisSaveLoading(false);
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  };

  const loadProtisVideo = async () => {
    setProtisDetailLoading(true);
    var config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      params: {
        id_mission: props.elementInfo.id_mission,
        id_mission_video: props.elementInfo.id
      },
      responseType: 'blob'
    };

    const responseVideo = await axios.get(MISSIONPROTISVIDEOFILE_URL, config);
    var url = URL.createObjectURL(responseVideo.data);
    setProtisVideoSrc(url);

    setProtisDetailLoading(false);
  }
  
  const loadDocumentsTypes = async () => {
    try {
      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        }
      };

      const response = await axios.get(MISSIONPROTISDOCUMENTSTYPES_URL, config);
      setProtisElementTypeList(response.data)
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const handleBreadcrumbsClick = (element) => {
    props.breadcrumbsClick(element);
    props.setElementFirstChild(element);
  }

  const handleSaveToSharepointClick = async () => {
    try {
      await handleSaveClick(false);

      setProtisSaveLoading(true);

      var config = {
        url: MISSIONPROTISDOCUMENTSTORE_URL,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          id_mission: props.idMission,
          id_mission_document: props.elementInfo.id
        },
        responseType: 'arraybuffer'
      };

      const response = await axios(config);

      if (response) {
        if (response.status == 200) {
          //remove document from list
          props.setDocumentsList(
            props.documentsList.filter((row) => row.id !== props.elementInfo.id)
          );
          //close pop-in
          props.setOpen(false);
          //add file to the files block 
          props.setReloadFileBlock(true);
          //display success message
          props.setSnackBarMessage("Document succesfully stored to sharepoint.");
          props.setSnackBarType("success");
          props.setOpenSnackBar(true);

        }
      }

      setProtisSaveLoading(false);
    } catch (err) {
      setProtisSaveLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const handlePreviewPdfClick = async () => {
    try {
      setProtisSaveLoading(true);

      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          id_mission: props.idMission,
          id_mission_document: props.elementInfo.id
        },
        responseType: 'arraybuffer'
      };

      const response = await axios.get(MISSIONPROTISDOCUMENTPREVIEW_URL, config);

      // Let the user save the file.
      var blob = new Blob([response.data], { type: 'application/pdf' });

      saveAs(blob, Date.now() + ' - ' + props.idMission + '.pdf');

      setProtisSaveLoading(false);
    } catch (err) {
      setProtisSaveLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const handleDocumentPageClick = (documentPage) => {
    if (!isDragging) {
      setCurrentDocument(props.elementInfo);
      setElementsList(props.elementInfo.files);
      props.setElementInfo(documentPage);
    } else {
      setIsDragging(false)
    }
  }

  const handleBackToDocumentClick = () => {
    var doc = props.documentsList.find(({ id }) => id === props.elementInfo.document.id);
    props.setElementInfo(doc);
    setElementsList(props.documentsList);
    setIsMedia(false);
  }

  const handleAutoCropClick = () => {
    
  }
  
  const handleCloseModal = () => {
    props.setOpen(false);
    props.setOpenMovingForm(false);
  }
  
  function handleDragAndDrop(sourceId, sourceIndex, targetIndex, targetId) {
    const tempItems = swap(props.elementInfo.files, sourceIndex, targetIndex);
    
    // Update the pivot.order property based on the new index positions
    const updatedItems = tempItems.map((item, index) => {
      return {
        ...item,
        pivot: {
          ...item.pivot,
          order: index + 1 
        }
      };
    });
    
    props.setElementInfo({
      ...props.elementInfo,
      files: updatedItems,
    });
  }
  
  const MoveButtonsRenderer = () => {
    return (
      (props?.allowMoving && !props.elementInfo?.isDocumentPage &&
        <>
          { protisDetailLoading ?    
            <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width:'48px', marginRight: '8px' }} />
          :
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {movingForm &&
                <Tooltip title="Cancel" placement="top">
                  <IconButton
                    className={imgCropActive ? 'hideOnEditMode' : ''}
                    onClick={() => handleMoveCancelClick()}
                    size='large'
                    style={{ backgroundColor: '#ff0000', color: 'white', marginRight: '8px', opacity: '100%' }}
                  >
                    <img src={CancelIcon} style={{ width: '24px', height: '24px' }} />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title={(movingForm && !moveDestinationId) ? "Choose a folder" : "Move"} placement="top">
                <span>
                  <IconButton
                      className={imgCropActive || (movingForm && !moveDestinationId) ? 'hideOnEditMode' : ''}
                      onClick={() => { handleMoveFileClick(); }}
                      size='large'
                      style={{ backgroundColor: movingForm ? '#00cd00' : '#1976d2', color: 'white', marginRight: '8px' }}
                      disabled={movingForm ? !moveDestinationId : false}
                  >
                      <img src={MoveIcon} style={{ width: '24px', height: '24px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          }
        </>
      )
    )
  }

  // Main return
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        className={"modalPopin info"}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '99%',
            width: '60%',
            bgcolor: 'rgb(52,52,52)',
            //border: '2px solid #000',
            boxShadow: 24,
            borderRadius: 2,
            p: 2,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ margin: 0, color: 'white' }}>{
                props.elementInfo.isPhoto ? 'Photo - ' : 
                  props.elementInfo.isVideo ? 'Video - ' : 
                    props.elementInfo.isDocument ? 'Document' : 
                      props.elementInfo.isDocumentPage ? 'Document / ' + props.elementInfo.document?.type?.label : 
                        'Folder - '
              }</h4>
              {!props.elementInfo.isDocument && !props.elementInfo.isDocumentPage ? <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <BreadcrumbsCustom
                  listElements={props.breadcrumbsList}
                  handleClick={handleBreadcrumbsClick}
                  color={"rgb(255,255,255)"}
                  maxItems={3}
                  reset={true}
                />
              </div> : null}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: 'white', fontSize: '15px', marginRight: '15px' }}>
                {protisDetailLoading ?
                  <Skeleton variant="text" sx={{ bgcolor: 'grey.700', height: '32px', width: '150px' }} />
                  :
                  <>
                    {!creationForm && formatDate(props.elementInfo.updated_at ? props.elementInfo.updated_at : props.elementInfo.created_at)}
                  </>
                }
              </div>
              <IconButton aria-label="close" onClick={handleCloseModal} className="eventEnabled">
                <CloseIcon style={{ marginBottom: '3px', fontSize: '30px', color: 'white' }} />
              </IconButton>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: "9%", width:' 80%' }}
            className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}>
            {protisDetailLoading ?
              <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.700', height: '60px', width: '40%' }} />
              :
              (isMedia && !props.elementInfo?.isDocumentPage ?
                <SelectCustom
                  title="Type"
                  api="mission/photo/type/list"
                  apiParam=""
                  apiParamValue="1"
                  dataValue={protisElementType}
                  setDataValue={handleChangeProtisElementType}
                  disableNone="true"
                  creator={createProtisElementTypeHandler}
                  cached="false"
                  variant="filled"
                  backgroundColor="white"
                  colorText="black"
                  size='medium'

                  read={true}
                  write={!missionCanceled}
                  controlFieldsStatus={true}
                />
                : props.elementInfo.isDocument ?
                  <SelectCustom
                    title="Type"
                    api="file_type/list"
                    apiParam=""
                    apiParamValue="1"
                    dataValue={protisElementType}
                    setDataValue={handleChangeProtisElementType}
                    disableNone="true"
                    creator={createProtisElementTypeHandler}
                    cached="false"
                    variant="filled"
                    backgroundColor="white"
                    colorText="black"
                    size='medium'

                    read={true}
                    write={!missionCanceled}
                    controlFieldsStatus={true}
                  />
                : props.elementInfo.isFolder ?
                  <TextFieldCustom
                    label="Folder's title"
                    value={protisFolderTitle}
                    onBlur={handleChangeProtisFolderTitle}
                    className={"protisFolderTitle"}
                    variant="filled"
                    backgroundColor="white"
                    error={!!folderTitleError}
                    helperText={folderTitleError}
                    borderRadius={1}
                    smallWidth
                    fullSizeWidth

                    read={true}
                    write={!missionCanceled}
                    controlFieldsStatus={true}
                  />
                : null
              )
            }
          </div>

          <div className="contentPopin" ref={imgRef} style={{ width:'100%', height: props.elementInfo.isDocumentPage ? '70%' : '45%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {!creationForm && (
              <div style={{ position: 'absolute', left: '0', top: '0', height: '100%', display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={(e) => { handlePreviousElementClick(); }} style={{ height: '100%', borderRadius: 0 }} disabled={protisDetailLoading}>
                  <ArrowBackIosIcon style={{ fontSize: '50px', marginLeft: '50%', color: 'rgb(154,154,154)' }} />
                </IconButton>
              </div>
            )}
            
            <div style={{ display: 'flex', alignItems:'center', justifyContent: 'center',  width: "80%", height:"100%"}}>
              {/* Block for moving elements */}
              {(movingForm && props?.protisData) &&
                (protisDetailLoading ?
                  <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.700', height: '100%', width: '40%', marginRight: '20px' }} />
                :
                  <MissionDetailsProtisDetailTree
                    moveDestinationId={moveDestinationId}
                    setMoveDestinationId={setMoveDestinationId}
                    elementInfo={props?.elementInfo}
                    protisData={props?.protisData}
                  />
                )
              }
              
              {(props.elementInfo.isPhoto || props.elementInfo.isDocumentPage) && 
                (protisDetailLoading ?
                  <CircularProgress size={100} />
                : 
                  (imgCropActive ?
                    <ReactCrop
                      crop={imgCrop}
                      onChange={c => setImgCrop(c)}
                      style={{
                        position: 'relative',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        filter: isMedia ? 'none' : 'brightness(0) invert(1)',
                      }}>
                      <img
                        src={protisImageSrc}
                        style={{ margin: 'auto', height: imgHeight }} />
                    </ReactCrop>
                    :
                    (protisImageSrc &&
                      <img
                        src={protisImageSrc}
                        style={{
                          width: movingForm ? "calc(60% - 20px)" : "80%",
                          maxHeight: '100%',
                          objectFit: 'contain',
                          filter: isMedia || props.elementInfo.isDocumentPage ? 'none' : 'brightness(0) invert(1)',
                          display: 'block'
                        }} 
                      />
                    )
                  )
              )}

              {(props.elementInfo.isVideo) && 
                (protisDetailLoading ?
                  <CircularProgress size={100} />
                : 
                  ( protisVideoSrc &&
                    <ReactPlayer
                      url={protisVideoSrc}
                      playing={protisVideoPlaying}
                      onClick={handlePlayVideoClick}
                      controls={true}
                      width= { movingForm ? "calc(60% - 20px)" : "80%" }
                      style={{ marginBottom: movingForm ? '6%' : '0%'}}
                      height="100%"
                    />
                  )
                )
              }

              {(props.elementInfo.isDocument && props.elementInfo.files?.length > 0) && 
                (protisDetailLoading  ?
                  <CircularProgress size={100} />
                : 
                  props.elementInfo.files?.length > 0 &&
                  <GridContextProvider onChange={handleDragAndDrop}>
                    <div className='gridContext'>
                      <GridDropZone
                        id="items"
                        boxesPerRow={3}
                        rowHeight={220}
                        style={{ width: "100%", height: "100%" }}
                      >
                        {props.elementInfo.files?.map((file) => (
                          <GridItem 
                            key={file.id} 
                            onClick={(e) => { handleDocumentPageClick(file) }}
                            onDragStart={(e) => setIsDragging(true)}
                          >
                            <div className="gridItem">
                              <div className="gridItemContent">
                                <img 
                                  src={file.file_path} 
                                  className="gridItemContentImg" 
                                  onDragStart={(e) => { e.preventDefault() }}
                                />
                              </div>
                            </div>
                          </GridItem>
                        ))}
                      </GridDropZone>
                    </div>
                  </GridContextProvider> 
              )}
              {(creationForm && !movingForm) &&
                (isMedia && !protisImageSrc && !protisVideoSrc && (
                  <>
                    <Tooltip title="Upload" placement="top">
                      <IconButton onClick={(e) => { openFileUpload(); }} style={{ borderRadius: 0 }} disabled={protisDetailLoading}>
                        <NoteAdd style={{ fontSize: '50px', color: 'rgb(154,154,154)' }} />
                      </IconButton>
                    </Tooltip>
                    <input
                      accept={props.elementInfo.isPhoto ? "image/*" : "video/*"}
                      type="file"
                      ref={fileInput}
                      style={{ display: 'none' }}
                      onChange={handleFileUpload} />
                  </>
                ))
              }
              {(props.elementInfo.isFolder) && (
                <img
                  src={FolderIcon}
                  style={{
                    width : movingForm ? "calc(60% - 20px)" : "80%",
                    maxHeight: '100%',
                    objectFit: 'contain',
                    filter: 'brightness(0) invert(1)',
                    display: 'block'
                  }} />
              )}
            </div>
            {!creationForm &&
              (<div style={{ position: 'absolute', right: '0', top: '0', height: '100%', display: 'flex', alignItems: 'center'}}>
                <IconButton onClick={(e) => { handleNextElementClick(); }} style={{ height: '100%', borderRadius: 0 }} disabled={protisDetailLoading}>
                  <ArrowForwardIosIcon style={{ fontSize: '50px', color: 'rgb(154,154,154)' }} />
                </IconButton>
              </div>
              )
            }
          </div>

          <div className="footerPopin">
            {!props.elementInfo.isDocumentPage && <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}>
              {protisDetailLoading ?
                <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.700', height: '80px', width: '80%' }} />
                :
                <TextFieldCustom
                  label={(props.elementInfo.isPhoto ? "Photo's " : props.elementInfo.isFolder ? "Folder's "  : props.elementInfo.isDocument ? "Document's " : "Video's ") + "comment"}
                  value={protisElementComment}
                  onBlur={handleElementCommentChange}
                  multiline
                  rows={2}
                  className={"protisElementComment"}
                  variant="filled"
                  backgroundColor="white"
                  borderRadius={1}
                  mediumWidth
                  fullSizeWidth

                  read={true}
                  write={!missionCanceled}
                  controlFieldsStatus={true}
                />
              }
            </div>}
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: '0 auto', marginTop: '15px' }}>
              {!creationForm && (protisDetailLoading ?
                  <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px' }} />
                :
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}
                      onClick={(e) => { props.handleDeleteDialog(props.elementInfo); }}
                      size='large'
                      style={{ backgroundColor: '#d32f2f', color: 'white' }}
                    >
                      <DeleteIcon style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                  </Tooltip>
              )}
              {!creationForm && (isMedia || props.elementInfo.isDocumentPage ?
                <>
                  <div style={{ display: 'flex', marginLeft: '15%' }} >
                  
                    {/* Renderes move buttons */}
                    <MoveButtonsRenderer/>
                    
                    {protisDetailLoading ?
                        <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px' }} />
                      :
                        <Tooltip title="Download" placement="top">
                          <IconButton
                            className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}
                            onClick={handleDownloadFileClick}
                            size='large'
                            style={{ backgroundColor: '#1976d2', color: 'white' }}
                          >
                            <img src={DownloadIcon} style={{ width: '24px', height: '24px' }} />
                          </IconButton>
                        </Tooltip>
                    }
                  </div>
                  {(props.elementInfo.isPhoto || props.elementInfo.isDocumentPage) && 
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/*props.elementInfo.isDocumentPage ? protisDetailLoading ?
                      <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px', marginRight: '8px' }} />
                      :
                      <IconButton
                        onClick={handleAutoCropClick}
                        size='large'
                        style={{ backgroundColor: '#1976d2', color: 'white', marginRight: '8px' }}
                      >
                        <img src={MagicWandIcon} style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                  : null*/}
                      { imgCropActive && (protisDetailLoading ?
                          <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px', marginRight: '8px' }} />
                        :
                          <Tooltip title="Cancel" placement="top">
                            <IconButton
                              className={movingForm ? 'hideOnEditMode' : ''}
                              onClick={(e) => { setImgCropActive(false) }}
                              size='large'
                              style={{ backgroundColor: '#ff0000', color: 'white', marginRight: '8px', opacity: '100%' }}
                            >
                              <img src={CancelIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                          </Tooltip>
                        )
                      }

                      {protisDetailLoading ?
                          <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px', marginRight: '8px' }} />
                        :
                          <Tooltip title="Crop" placement="top">
                            <IconButton
                              className={movingForm ? 'hideOnEditMode' : ''}
                              onClick={(e) => { handleCropImageClick() }}
                              size='large'
                              style={{ backgroundColor: imgCropActive ? '#00cd00' : '#1976d2', color: 'white', marginRight: '8px'}}
                            >
                              <img src={CropIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                          </Tooltip>
                      }

                      {protisDetailLoading ?
                          <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px', marginRight: '8px' }} />
                        :
                          <Tooltip title="Rotate Left" placement="top">
                            <IconButton
                              className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}
                              onClick={(e) => { handleRotateImageClick(false); }}
                              size='large'
                              style={{ backgroundColor: '#1976d2', color: 'white', marginRight: '8px' }}
                            >
                              <img src={RotateCounterClockwiseIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                          </Tooltip>
                      }

                      {protisDetailLoading ?
                          <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px' }} />
                        :
                          <Tooltip title="Rotate Right" placement="top">
                            <IconButton
                              className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}
                              onClick={(e) => { handleRotateImageClick(); }}
                              size='large'
                              style={{ backgroundColor: '#1976d2', color: 'white' }}
                            >
                              <img src={RotateClockwiseIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                          </Tooltip>
                      }
                    </div>
                  }
                </>
                : props.elementInfo.isFolder ?
                <>
                  {protisDetailLoading ?
                    <Skeleton variant="circular" sx={{ bgcolor: 'grey.700', height: '48px', width: '48px', marginRight: '8px' }} />
                    :
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      
                      {/* Renderes move buttons */}
                      <MoveButtonsRenderer/>
                      
                      <Tooltip title="Open" placement="top">
                        <IconButton
                          className={imgCropActive || movingForm ? 'hideOnEditMode' : ''}
                          onClick={handleOpenFolderButtonClick}
                          size='large'
                          style={{ backgroundColor: '#1976d2', color: 'white' }}
                        >
                          <img src={HalfFolderIcon} style={{ width: '24px', height: '24px' }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                </> : null
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: '0 auto', marginTop: '15px' }}>
              {!creationForm && <div style={{ display: 'flex', marginLeft: '47%', marginTop: "5px" }}>
                <span style={{ color: 'white' }}>
                  {protisDetailLoading ?
                    <Skeleton variant="text" sx={{ bgcolor: 'grey.700', height: '32px', width: '30px' }} />
                    :
                    <>
                      {props.elementInfo?.listPosition}/{elementsList?.length}
                    </>
                  }
                </span>
              </div>}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!creationForm && props.elementInfo.isDocumentPage && <LoadingButton
                  onClick={handleBackToDocumentClick}
                  variant="contained"
                  style={{ marginRight: '8px' }}
                  sx={{
                    backgroundColor: '#1976d2',
                    "&.Mui-disabled": {
                      background: "#727272"
                    }
                  }}
                  loading={protisDetailLoading}
                >
                  Back
                </LoadingButton>
                }
                <LoadingButton
                  onClick={(e) => { handleSaveClick(false); }}
                  variant="contained"
                  style={{ marginRight: '8px' }}
                  sx={{
                    backgroundColor: '#1976d2',
                    "&.Mui-disabled": {
                      background: "#727272"
                    }
                  }}
                  loading={protisDetailLoading}
                  disabled={creationForm &&
                    (
                      (props.elementInfo.isFolder && protisFolderTitle == '') ||
                      (props.elementInfo.isPhoto && (protisImageSrc == '' || protisElementType == '')) ||
                      (props.elementInfo.isVideo && (protisVideoSrc == '' || protisElementType == '')) || 
                      !!folderTitleError
                    ) ? true : false
                  }
                >
                  Save
                </LoadingButton>
                {!creationForm && !props.elementInfo.isDocument && <LoadingButton
                  onClick={(e) => { handleSaveClick(true); }}
                  endIcon={<KeyboardArrowRightIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#1976d2',
                    "&.Mui-disabled": {
                      background: "#727272",
                      color: "#727272"
                    }
                  }}
                  loading={protisDetailLoading}
                >
                  Save & Next
                </LoadingButton>
                }
                {!creationForm && props.elementInfo.isDocument && <LoadingButton
                  onClick={(e) => { handlePreviewPdfClick(); }}
                  variant="contained"
                  style={{ marginRight: '8px' }}
                  sx={{
                    backgroundColor: '#1976d2',
                    "&.Mui-disabled": {
                      background: "#727272"
                    }
                  }}
                  loading={protisDetailLoading}
                >
                  Preview
                </LoadingButton>
                }
                {!creationForm && props.elementInfo.isDocument && <LoadingButton
                  onClick={(e) => { handleSaveToSharepointClick(); }}
                  variant="contained"
                  style={{ marginRight: '8px' }}
                  sx={{
                    backgroundColor: '#1976d2',
                    "&.Mui-disabled": {
                      background: "#727272"
                    }
                  }}
                  loading={protisDetailLoading}
                >
                  Merge & Save
                </LoadingButton>
                }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmDialog
          title={"Move " + (props?.elementInfo?.isPhoto || props?.elementInfo?.isVideo ? 'file' : props?.elementInfo?.isFolder ? 'folder' : '')}
          open={openMoveConfirmationDialog}
          setOpen={setOpenMoveConfirmationDialog}
          onConfirm={handleConfirmMoveDialog}
          params={moveDestinationId}
        >
        {'Are you sure you want to move this ' + (props?.elementInfo?.isPhoto || props?.elementInfo?.isVideo ? 'file' : props?.elementInfo?.isFolder ? 'folder' : '') + ' ?'}
      </ConfirmDialog>
    </>
  );
};

export default ProtisDetail;
