import React, { useState, useContext, useEffect, forwardRef } from 'react';

import momentDate from 'moment';

import UndoIcon from '@mui/icons-material/Undo';
import LoadingButton from '@mui/lab/LoadingButton';

import { isInternalRole } from 'components/config/Roles';
import DetailField from 'components/page/object/DetailField';
import MissionDetailsCancelPopin from './Popin/MissionDetailsCancelPopin';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";


const MissionDetailsCanceled = forwardRef((props, ref) => {

  //config
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //generic
  const { loading, setLoading } = useContext(ContextBlock);

  //mission
  const {general, setGeneral} = useContext(ContextMissionDetails);
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  //cancel data
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [canceler, setCanceler] = useState('');
  const [canceledAt, setCanceledAt] = useState('');

  //cancel popin
  const [openCancel, setOpenCancel] = useState(false);
  const handleClickUnCancel = async () => {
    setOpenCancel(true);
  }


  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //INIT cancel data
      if (general.cancel) {
        setComment(general.cancel.comment);
        setReason(general.cancel.reason.label);
        setCanceledAt(momentDate(general.cancel.canceled_at).format("DD MMM YY"));
        setCanceler(general.cancel.canceler.firstname + ' ' + general.cancel.canceler.lastname);
      }
    }
  }, [general]);


  if (missionCanceled) {
    return (
      <div id="block_canceled" className="contentDetail block_details canceled">
        <h4>Cancelation Informations</h4>

        <div>
          <div className="lineContent noJustify">
            <DetailField title="Canceled by" content={canceler} size="small"/>
            <DetailField title="Canceled at" content={canceledAt} size="small"/>
          </div>

          <div className="lineContent separator noJustify">
            <DetailField title="Cancelation reason" content={reason} size="small"/>
          </div>

          <div className="lineContent noJustify">
            <DetailField title="Cancelation commentary" multiline content={comment} size="full"/>
          </div>

          {(JLBInternalMode) && 
            <div className="block_button">
              <LoadingButton className="cancel" loading={loading} onClick={async ()=> await handleClickUnCancel()} loadingPosition="start" startIcon={<UndoIcon/>}> Uncancel mission</LoadingButton>
            </div>
          }

          <MissionDetailsCancelPopin open={openCancel} setOpen={setOpenCancel}/>
        </div>

      </div>
    );
  }
});

export default MissionDetailsCanceled;
