import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { isInternalRole, isPortRole, isAdminRole, isDirectionRole, getUserRole } from 'components/config/Roles';
import environnement from 'components/config//Environnement';

import CompanyContact from './Company/CompanyContact';

import 'styles/Menu.css';
import 'styles/boxicons.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'styles/CompanyContact.css';

import logomenu from 'imgs/logo-mini.svg';
import myFiles from 'imgs/myFiles.svg';
import globe from 'imgs/globe.svg';
import add from 'imgs/add.svg';
import archive from 'imgs/archive.svg';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExplore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Menu = () => {
  const [menuProfileShow, setMenuProfileShow] = useState(false);
  const pathname = window.location.pathname

  const isDashboard = (pathname === "/dashboard" ? true:false);
  const isDashboardList = (pathname === "/dashboard-list" ? true:false);
  const isArchivedList = (pathname === "/archived-list" ? true:false);
  const isPortDashboard = (pathname === "/dashboard-port" ? true:false);
  const isPortArchivedList = (pathname === "/archived-list-port" ? true : false);
  const isCreateShippingInstruction = (pathname === "/createShippingInstruction" ? true : false);
  const isCreateMission = (pathname === "/createMission" ? true:false);
  const isMailingLists = (pathname === "/mailing-lists" ? true:false);
  const isCompaniesList = (pathname === "/companies-list" ? true:false);
  const isAnalyticsDashboard = (pathname === "/dashboard-analytics" ? true:false);
  const isExportBI = (pathname === "/export-bi" ? true:false);

  const [isOpen, toggleIsOpen] = useState(false);
  const onOpenModalClick = () => toggleIsOpen(true);
  const onCloseModalClick = () => toggleIsOpen(false);
  const duration = 450;

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string, size: Number) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: size,
        height: size,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  function handleProfile() {
    setMenuProfileShow(!menuProfileShow);
  }

  return (
    <>
      <div id="menu" className="nemu">
        <div className="header-menu">
          <a href="/">
            <div className="menu-logo"><img src={logomenu} alt="JLB Expertises" /></div>
            <div className="titre-logo">GYPTIS</div>
          </a>
        </div>
        {(Number(environnement.id) > 100) &&
          <div>
            <Stack spacing={1} alignItems="center" sx={{ marginTop: '20px' }}>
              <Stack direction="row" spacing={1}>
                <Chip label={environnement.label} color={environnement.color} />
              </Stack>
            </Stack>
            <Stack spacing={1} alignItems="center" sx={{ marginTop: '5px' }}>
              <Stack direction="row" spacing={1}>
                <Chip label={"- " + getUserRole(localStorage.getItem("role")) + " -"} color={environnement.color} />
              </Stack>
            </Stack>
          </div>
        }
        <div className="content-menu">
          {(isInternalRole(localStorage.getItem("role"))) &&
            <a href="/dashboard">
              <div className={isDashboard ? "menu-item active" : "menu-item"}>
                <div className="icone-item-menu"><img src={myFiles} alt="My files" /></div>
                <div className="titre-item-menu">My files</div>
              </div>
            </a>
          }
          {(isPortRole(localStorage.getItem("role"))) ? (
            <a href="/dashboard-port">
              <div className={isPortDashboard ? "menu-item active" : "menu-item"}>
                <div className="icone-item-menu"><img src={globe} alt="JLB files" /></div>
                <div className="titre-item-menu">JLB files</div>
              </div>
            </a>
          ) : (
            <a href="/dashboard-list">
              <div className={isDashboardList ? "menu-item active" : "menu-item"}>
                <div className="icone-item-menu"><img src={globe} alt="JLB files" /></div>
                <div className="titre-item-menu">JLB files</div>
              </div>
            </a>
          )}
          {(isInternalRole(localStorage.getItem("role"))) ? (
            <a href="/archived-list">
              <div className={isArchivedList ? "menu-item active" : "menu-item"}>
                <div className="icone-item-menu"><img src={archive} alt="Archived files" /></div>
                <div className="titre-item-menu">Archived files</div>
              </div>
            </a>
          ) : (
            isPortRole(localStorage.getItem("role")) && (
              <a href="/archived-list-port">
                <div className={isPortArchivedList ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><img src={archive} alt="Archived files" /></div>
                  <div className="titre-item-menu">Archived files</div>
                </div>
              </a>
            )
          )}
          {(isInternalRole(localStorage.getItem("role"))) &&
            <a href="/createMission">
              <div className={isCreateMission ? "menu-item active" : "menu-item"}>
                <div className="icone-item-menu"><img src={add} alt="Create a new file" /></div>
                <div className="titre-item-menu">Create a new file</div>
              </div>
            </a>
          }
          {(isInternalRole(localStorage.getItem("role"))) &&
            <>
              <a href="/createShippingInstruction">
                <div className={isCreateShippingInstruction ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><img src={add} alt="Create a new SI" /></div>
                  <div className="titre-item-menu"> Create a new SI</div>
                </div>
              </a>
            </>
          }
          {(!isPortRole(localStorage.getItem("role"))) &&
            <>
              <div className="menu-item-end"></div>
              <a onClick={onOpenModalClick}>
                <div className={isOpen ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><TravelExploreOutlinedIcon fontSize="small" sx={{ color: 'text.disabled' }} /></div>
                  <div className="titre-item-menu">Find a local office</div>
                </div>
              </a>
            </>
          }
          {(isInternalRole(localStorage.getItem("role"))) &&
            <>
              <div className="menu-item-end"></div>
              <a href="/mailing-lists">
                <div className={isMailingLists ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><MailOutlineOutlinedIcon fontSize="small" sx={{ color: 'text.disabled' }} /></div>
                  <div className="titre-item-menu">Mailing lists</div>
                </div>
              </a>
            </>
          }
          {(isInternalRole(localStorage.getItem("role"))) &&
            <>
              <div className="menu-item-end"></div>
              <a href="/companies-list">
                <div className={isCompaniesList ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><BusinessOutlinedIcon fontSize="small" sx={{ color: 'text.disabled' }} /></div>
                  <div className="titre-item-menu">Companies</div>
                </div>
              </a>
            </>
          }
          {(isDirectionRole(localStorage.getItem("role")) || isAdminRole(localStorage.getItem("role"))) &&
            <>
              <div className="menu-item-end"></div>
              <a href="/dashboard-analytics">
                <div className={isAnalyticsDashboard ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><QueryStatsOutlinedIcon fontSize="small" sx={{ color: 'text.disabled' }} /></div>
                  <div className="titre-item-menu">Analytics</div>
                </div>
              </a>
              <a href="/export-bi">
                <div className={isExportBI ? "menu-item active" : "menu-item"}>
                  <div className="icone-item-menu"><FileDownloadIcon fontSize="small" sx={{ color: 'text.disabled' }} /></div>
                  <div className="titre-item-menu">Export BI</div>
                </div>
              </a>
            </>
          }
          <div className="nav-item profile-nav-item" onClick={handleProfile}>
            <div href="#" className="nav-link">
              <div className="menu-profile">
                <div className='avatar'>
                  <Avatar {...stringAvatar(localStorage.getItem("firstname") + ' ' + localStorage.getItem("lastname"), 35)} />
                </div>
                <span className="name">{localStorage.getItem("firstname")} {localStorage.getItem("lastname")}</span>
              </div>
            </div>
            <div className={menuProfileShow ? 'dropdown-menu-profile show' : 'dropdown-menu-profile hide'} >
              <div className="dropdown-header d-flex flex-column align-items-center">
                <div className='figure mb-3'>
                  <Avatar {...stringAvatar(localStorage.getItem("firstname") + ' ' + localStorage.getItem("lastname"), 80)} />
                </div>

                <div className="info text-center">
                  <span className="name">{localStorage.getItem("firstname")} {localStorage.getItem("lastname")}</span>
                  <p className="mb-3 email">{localStorage.getItem("email")}</p>
                </div>
              </div>

              <div className="dropdown-footer">
                <ul className="profile-nav">
                  <li className="nav-item">
                    <a href="/logout" className="nav-link">
                      <i className="bx bx-log-out"></i> <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyContact isOpen={isOpen} onCloseModalClick={onCloseModalClick} />
    </>
  )
}

export default Menu
