import React, { forwardRef, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SelectCustom from 'components/select/SelectCustom';
import Input from '@mui/material/Input';
import axios from 'api/axios';
import { useNavigate } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import 'styles/Popin.css';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ShippingInstructionFilePopin = (props) => {
  const navigate = useNavigate();
  const { setFileUpload } = props;

  const [loadingSave, setLoadingSave] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [errorPermission, setErrorPermission] = useState(false);

  const handleClose = () => {
    props.setOpen(false);
    if (props?.multiUpdateTypeMode) {
      props?.setMultiUpdateTypeMode(false);
    }

    if (props?.multiUpdateRightsMode) {
      props?.setMultiUpdateRightsMode(false);
    }
  }

  function setTypeFile(data) {
    setErrorType(false);
    props.setType(data);
  }

  const setFileSelected = (props) => {
    setErrorFile(false);
    setFileUpload(props.target.files[0]);
  }

  function createSelectItem(datas) {
    return { id: datas.value, title: datas.label, label: datas.label };
  }

  const handleClickSave = async () => {
    if (props?.multiUpdateTypeMode || props?.multiUpdateRightsMode) {
      setLoadingSave(true);
      await props?.multiUpdateRows();
      setLoadingSave(false);
    } else {
      if (props.fileUpload && props.type && props.permission) {
        uploadFile();
      } else {
        if (!props.fileUpload) setErrorFile(true);
        if (!props.type) setErrorType(true);
        if (!props.permission) setErrorPermission(true);
      }
    }
  }

  const uploadFile = async () => {
    try {
      setLoadingSave(true);
      var data = new FormData();
      data.append('id_shipping_instruction', props.idShippingInstruction);
      data.append('file', props.fileUpload);
      data.append('id_file_type', props.type);
      data.append('id_file_permission', props.permission);

      var config = {
        url: "shipping_instruction/si_file",
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data'
        },
        data: data
      };
      const reponse = await axios(config);
      props.handleUploadedFile({ id: reponse.data.data.id, file: props.fileUpload.name, id_file_permission: Number(props.permission), type: Number(props.type), url: reponse.data.data.file.file_path, mimetype: reponse.data.data.file.mime_type });
      handleClose();

      props.setSnackBarMessage("File uploaded.");
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setLoadingSave(false);
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      className='modalPopin file'>
      <Box className='boxPopin'>
        <div className='headerPopin'>{props?.multiUpdateTypeMode ? "Edit Type" : props?.multiUpdateRightsMode ? "Edit Rights" : "Upload file"}</div>
        <div className='contentPopin'>
          {(!props?.multiUpdateTypeMode && !props?.multiUpdateRightsMode) &&
            <Input accept="*/*" type="file" onChange={setFileSelected} required sx={{ marginLeft: '10px', width: '94%', paddingBottom: '5px', }} error={props.errorFile} disabled={loadingSave} />
          }
          {(!props?.multiUpdateRightsMode) &&
            <FormControl sx={{ width: '100%', m: 2, marginBottom: 2, marginLeft: 0 }} disabled={loadingSave}>
              <SelectCustom
                title="Type"
                api=""
                dataValue={props.type}
                setDataValue={setTypeFile}
                setDataAlias=""
                defaultAlias=""
                size='large'
                datas={props.typesOfFile}
                loadByList='true'
                creator={createSelectItem}
                disableNone={props.disableTypeNone}
                error={errorType}
              />
            </FormControl>
          }
          {(!props?.multiUpdateTypeMode) &&
            <FormControl sx={{ width: '100%', m: 2, marginBottom: 2, marginLeft: 0 }} disabled={loadingSave}>
              <SelectCustom
                title="Rights"
                api=""
                dataValue={props.permission}
                setDataValue={props.setPermission}
                setDataAlias=""
                defaultAlias=""
                size='large'
                datas={props.filePermissions}
                loadByList='true'
                creator={createSelectItem}
                disableNone='true'
                error={errorPermission}
                nosort
              />
            </FormControl>
          }
        </div>
        <div className='footerPopin'>
          <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
            <LoadingButton
              onClick={handleClose}
              loadingIndicator="Cancel"
              variant="outlined"
              loading={loadingSave}
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              onClick={handleClickSave}
              loading={loadingSave}
              endIcon={<SaveIcon />}
              loadingPosition="end"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Modal>
  );
}
export default ShippingInstructionFilePopin