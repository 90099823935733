import React, { forwardRef, useContext } from 'react';

import BlockFormSaveButton from '../../Block/BlockFormSaveButton';
import MissionDetailsInstructionList from './MissionDetailsInstructionList';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsInstruction } from "./ContextMissionDetailsInstruction";


const MissionDetailsInstructionForm = forwardRef((props, ref) => {

  //init config
  const { instructionRef } = useContext(ContextMissionDetails);

  //workflow
  const { canWrite } = useContext(ContextMissionWorkflow);

  //handle
  const { saveMissionDetailsInstruction } = useContext(ContextMissionDetailsInstruction);
  const { handleClickCancel } = useContext(ContextMissionDetailsInstruction);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await saveMissionDetailsInstruction(true);
    await handleClickCancel(false);
  };


  return (
    <div>

      <MissionDetailsInstructionList editable={Boolean(canWrite('instruction_block'))} />

      <BlockFormSaveButton blockRef={instructionRef} blockCancel={async () => await handleClickCancel()} blockSave={handleClickSave} />

    </div>
  );
});

export default MissionDetailsInstructionForm;
