import React, { useContext, forwardRef } from 'react';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsSI } from "./ContextMissionDetailsSI";

import MissionDetailsSIForm from './MissionDetailsSIForm';
import MissionDetailsSIView from "./MissionDetailsSIView";


const MissionDetailsSI = forwardRef((props, ref) => {

  //config
  const { update } = useContext(ContextBlock);
  const { loadingUpdate } = useContext(ContextBlock);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);

  //mission general data
  const { missionCanceled, setMissionCanceled } = useContext(ContextMissionDetails);

  //SI data
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextMissionDetailsSI);


  if (update == 1 && !(missionCanceled && !idShippingInstruction) && (canWrite('si_block') || canRead('si_block'))) {
    return (
      <div id="block_si" className={(idShippingInstruction || !loadingUpdate) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
        <h4>Shipping Instruction</h4>

        {(idShippingInstruction || !loadingUpdate) ? <MissionDetailsSIView /> : <MissionDetailsSIForm />}

      </div>
    );
  }
});

export default MissionDetailsSI;
