module.exports = `
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <title>JLB Expertises - Privacy Policy App Protis (for Gyptis)</title>
    <link rel="icon" href="https://gyptis.jlbexpertises.com/favicon.ico">
    <style type="text/css">
        html {
            font-family: 'Times New Roman', Times, serif;
            background:#fff;
        }

        h1,
        h2 {
            color: #024289;
            padding-top: 20px;
        }

        .header {
            background-color: #024289;
            align-content: center;
            padding: 10px;
            height: 110px;
            position: fixed;
            width: 100%;
            z-index: 9999;
        }
        .content {
            padding: 110px 10% 50px 10% !important;
            position: relative;
            background: #fff;
        }
    </style>
</head>

<body>
    <div class="header"><img
            src="https://gyptis.jlbexpertises.com/static/media/logo.d34445c83b528838ea31aaf6fbb052b0.svg"
            alt="JLB Expertises"></div>
    <div class="content">
        <h1><span>Privacy Policy of </span><span>Protis (App for Gyptis)</span></h1>
        <p><span>This Application collects some Personal Data from its Users.</span></p>
        <p><span></span></p>
        <p><span>This document can be printed for reference by using the print command in the settings of any
                browser.</span></p>
        <h2><span>Owner and Data Controller</span></h2>
        <p><span>JLB Expertises</span><span><br></span><span>40 cours Pierre Puget</span><span><br></span><span>13006
                Marseille</span></p>
        <p><span>Owner contact email:</span><span>&nbsp;mail@jlbexpertises.com</span></p>
        <h2><span>Types of Data collected</span></h2>
        <p><span>Among the types of Personal Data that this Application collects, by itself or through third parties,
                there are: Camera permission; Microphone permission; Approximate location permission; Cookies; Usage
                Data; email address; password.</span></p>
        <p><span>Complete details on each type of Personal Data collected are provided in the dedicated sections of this
                privacy policy or by specific explanation texts displayed prior to the Data collection.<br>Personal Data
                may be freely provided by the User, or, in case of Usage Data, collected automatically when using this
                Application.<br>Unless specified otherwise, all Data requested by this Application is mandatory and
                failure to provide this Data may make it impossible for this Application to provide its services. In
                cases where this Application specifically states that some Data is not mandatory, Users are free not to
                communicate this Data without consequences to the availability or the functioning of the
                Service.<br>Users who are uncertain about which Personal Data is mandatory are welcome to contact the
                Owner.<br>Any use of Cookies &ndash; or of other tracking tools &mdash; by this Application or by the
                owners of third-party services used by this Application serves the purpose of providing the Service
                required by the User, in addition to any other purposes described in the present document.</span></p>
        <p><span>Users are responsible for any third-party Personal Data obtained, published or shared through this
                Application.</span></p>
        <h2><span>Mode and place of processing the Data</span></h2>
        <h3><span>Methods of processing</span></h3>
        <p><span>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification,
                or unauthorized destruction of the Data.<br>The Data processing is carried out using computers and/or IT
                enabled tools, following organizational procedures and modes strictly related to the purposes indicated.
                In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in
                charge, involved with the operation of this Application (administration, sales, marketing, legal, system
                administration) or external parties (such as third-party technical service providers, mail carriers,
                hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by
                the Owner. The updated list of these parties may be requested from the Owner at any time.</span></p>
        <h3><span>Place</span></h3>
        <p><span>The Data is processed at the Owner&#39;s operating offices and in any other places where the parties
                involved in the processing are located.<br><br>Depending on the User&#39;s location, data transfers may
                involve transferring the User&#39;s Data to a country other than their own. To find out more about the
                place of processing of such transferred Data, Users can check the section containing details about the
                processing of Personal Data.</span></p>
        <h3><span>Retention time</span></h3>
        <p><span>Unless specified otherwise in this document, Personal Data shall be processed and stored for as long as
                required by the purpose they have been collected for and may be retained for longer due to applicable
                legal obligation or based on the Users&rsquo; consent.</span></p>
        <h2><span>The purposes of processing</span></h2>
        <p><span>The Data concerning the User is collected to allow the Owner to provide its Service, comply with its
                legal obligations, respond to enforcement requests, protect its rights and interests (or those of its
                Users or third parties), detect any malicious or fraudulent activity, as well as the following: Device
                permissions for Personal Data access, Analytics, Registration and authentication, Access to third-party
                accounts, Registration and authentication provided directly by this Application.</span></p>
        <p><span>For specific information about the Personal Data used for each purpose, the User may refer to the
                section &ldquo;Detailed information on the processing of Personal Data&rdquo;.</span></p>
        <h2><span>Device permissions for Personal Data access</span></h2>
        <p><span>Depending on the User&#39;s specific device, this Application may request certain permissions that
                allow it to access the User&#39;s device Data as described below.</span></p>
        <p><span>By default, these permissions must be granted by the User before the respective information can be
                accessed. Once the permission has been given, it can be revoked by the User at any time. In order to
                revoke these permissions, Users may refer to the device settings or contact the Owner for support at the
                contact details provided in the present document.<br>The exact procedure for controlling app permissions
                may be dependent on the User&#39;s device and software.</span></p>
        <p><span>Please note that the revoking of such permissions might impact the proper functioning of this
                Application.</span></p>
        <p><span>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e
                accessed to, modified or removed) by this Application.</span></p>
        <h3><span>Approximate location permission</span></h3>
        <p><span>Used for accessing the User&#39;s approximate device location. This Application may collect, use, and
                share User location Data in order to provide location-based services.</span></p>
        <h3><span>Camera permission</span></h3>
        <p><span>Used for accessing the camera or capturing images and video from the device.</span></p>
        <h3><span>Microphone permission</span></h3>
        <p><span>Allows accessing and recording microphone audio from the User&#39;s device.</span></p>
        <h2><span>Detailed information on the processing of Personal Data</span></h2>
        <p><span>Personal Data is collected for the following purposes and using the following services:</span></p>
        <ul>
            <li><span>Device permissions for Personal Data access (Location, Photos/Vid&eacute;os)</span></li>
            <li><span>Registration and authentication provided directly by this Application</span></li>
        </ul>
        <h2><span>Further Information for Users</span></h2>
        <h3><span>Legal basis of processing</span></h3>
        <p><span>The Owner may process Personal Data relating to Users if one of the following applies:</span></p>
        <ul>
            <li><span>Users have given their consent for one or more specific purposes.</span></li>
            <li><span>provision of Data is necessary for the performance of an agreement with the User and/or for any
                    pre-contractual obligations thereof;</span></li>
            <li><span>processing is necessary for compliance with a legal obligation to which the Owner is
                    subject;</span></li>
            <li><span>processing is related to a task that is carried out in the public interest or in the exercise of
                    official authority vested in the Owner;</span></li>
            <li><span>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a
                    third party.</span></li>
        </ul>
        <p><span>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract. </span></p>
        <h3><span>Further information about retention time</span></h3>
        <p><span>Unless specified otherwise in this document, Personal Data shall be processed and stored for as long as
                required by the purpose they have been collected for and may be retained for longer due to applicable
                legal obligation or based on the Users&rsquo; consent.</span></p>
        <p><span>Therefore:</span></p>
        <ul>
            <li><span>Personal Data collected for purposes related to the performance of a contract between the Owner
                    and the User shall be retained until such contract has been fully performed.</span></li>
            <li><span>Personal Data collected for the purposes of the Owner&rsquo;s legitimate interests shall be
                    retained as long as needed to fulfill such purposes. Users may find specific information regarding
                    the legitimate interests pursued by the Owner within the relevant sections of this document or by
                    contacting the Owner.</span></li>
        </ul>
        <p><span>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given
                consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be
                obliged to retain Personal Data for a longer period whenever required to fulfil a legal obligation or
                upon order of an authority.<br><br>Once the retention period expires, Personal Data shall be deleted.
                Therefore, the right of access, the right to erasure, the right to rectification and the right to data
                portability cannot be enforced after expiration of the retention period.</span></p>
        <h3><span>The rights of Users based on the General Data Protection Regulation (GDPR)</span></h3>
        <p><span>Users may exercise certain rights regarding their Data processed by the Owner.</span></p>
        <p><span>In particular, Users have the right to do the following, to the extent permitted by law:</span></p>
        <ul>
            <li><span>Withdraw their consent at any time.</span><span>&nbsp;Users have the right to withdraw consent
                    where they have previously given their consent to the processing of their Personal Data.</span></li>
            <li><span>Object to processing of their Data.</span><span>&nbsp;Users have the right to object to the
                    processing of their Data if the processing is carried out on a legal basis other than
                    consent.</span></li>
            <li><span>Access their Data.</span><span>&nbsp;Users have the right to learn if Data is being processed by
                    the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the
                    Data undergoing processing.</span></li>
            <li><span>Verify and seek rectification.</span><span>&nbsp;Users have the right to verify the accuracy of
                    their Data and ask for it to be updated or corrected.</span></li>
            <li><span>Restrict the processing of their Data.</span><span>&nbsp;Users have the right to restrict the
                    processing of their Data. In this case, the Owner will not process their Data for any purpose other
                    than storing it.</span></li>
            <li><span>Have their Personal Data deleted or otherwise removed.</span><span>&nbsp;Users have the right to
                    obtain the erasure of their Data from the Owner.</span></li>
            <li><span>Receive their Data and have it transferred to another controller.</span><span>&nbsp;Users have the
                    right to receive their Data in a structured, commonly used and machine readable format and, if
                    technically feasible, to have it transmitted to another controller without any hindrance.</span>
            </li>
            <li><span>Lodge a complaint.</span><span>&nbsp;Users have the right to bring a claim before their competent
                    data protection authority.</span></li>
        </ul>
        <p><span>Users are also entitled to learn about the legal basis for Data transfers abroad including to any
                international organization governed by public international law or set up by two or more countries, such
                as the UN, and about the security measures taken by the Owner to safeguard their Data.</span></p>
        <h4><span>Details about the right to object to processing</span>
        </h4>
        <p><span>Where Personal Data is processed for a public interest, in the exercise of an official authority vested
                in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to
                such processing by providing a ground related to their particular situation to justify the
                objection.</span></p>
        <h4><span>How to exercise these rights</span></h4>
        <p><span>Any requests to exercise User rights can be directed to the Owner through the contact details provided
                in this document. Such requests are free of charge and will be answered by the Owner as early as
                possible and always within one month, providing Users with the information required by law. Any
                rectification or erasure of Personal Data or restriction of processing will be communicated by the Owner
                to each recipient, if any, to whom the Personal Data has been disclosed unless this proves impossible or
                involves disproportionate effort. At the Users&rsquo; request, the Owner will inform them about those
                recipients.</span></p>
        <h2><span>Additional information about Data collection and processing</span></h2>
        <h3><span>Legal action</span></h3>
        <p><span>The User&#39;s Personal Data may be used for legal purposes by the Owner in Court or in the stages
                leading to possible legal action arising from improper use of this Application or the related
                Services.<br>The User declares to be aware that the Owner may be required to reveal personal data upon
                request of public authorities.</span></p>
        <h3><span>Additional information about User&#39;s Personal Data</span>
        </h3>
        <p><span>In addition to the information contained in this privacy policy, this Application may provide the User
                with additional and contextual information concerning particular Services or the collection and
                processing of Personal Data upon request.</span></p>
        <h3><span>System logs and maintenance</span></h3>
        <p><span>For operation and maintenance purposes, this Application and any third-party services may collect files
                that record interaction with this Application (System logs) or use other Personal Data (such as the IP
                Address) for this purpose.</span></p>
        <h3><span>Information not contained in this policy</span></h3>
        <p><span>More details concerning the collection or processing of Personal Data may be requested from the Owner
                at any time. Please see the contact information at the beginning of this document.</span></p>
        <h3><span>Changes to this privacy policy</span></h3>
        <p><span>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users
                on this page and possibly within this Application and/or - as far as technically and legally feasible -
                sending a notice to Users via any contact information available to the Owner. It is strongly recommended
                to check this page often, referring to the date of the last modification listed at the bottom.
                <br><br>Should the changes affect processing activities performed on the basis of the User&rsquo;s
                consent, the Owner shall collect new consent from the User, where required.</span></p>
        <h3><span>Definitions and legal references</span></h3>
        <p><span>Latest update: November 23, 2023</span></p>
        <p><span></span></p>
    </div>
</body>`;