import React, { useContext, useEffect } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import EditOffIcon from '@mui/icons-material/EditOff';

import MissionDetailsWorkflowSaveButton from '../MissionDetails/MissionDetailsWorkflowSaveButton';

import { ContextBlock } from "context/ContextBlock";


const BlockFormSaveButton = (props) => {

  //config
  const { update } = useContext(ContextBlock);

  //generic
  const { loading, setLoading } = useContext(ContextBlock);

  //handle
  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    //cancel
    if (!!props.blockCancel != false) {
      await props.blockCancel();
    } else if (!!props.blockRef != false) {
      handleClickBlockCancel(props.blockRef)
    }
  }
  const handleClickSave = () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    props.blockSave();
  }


  if (update == 1) {
    return(
      <div className="block_button">
        {!!props.notWorkflowSaveButton === false && <MissionDetailsWorkflowSaveButton show={true}/>}
        <LoadingButton className="cancel" loading={loading} onClick={async ()=> await handleClickCancel()} loadingPosition="start" startIcon={<EditOffIcon/>}> Cancel</LoadingButton>
        <LoadingButton disabled={props?.saveDisabled ? props?.saveDisabled : false} loading={loading} onClick={handleClickSave} loadingPosition="start" startIcon={<SaveIcon/>}> Save</LoadingButton>
      </div>
    );
  }
};

export default BlockFormSaveButton;
