import React, { useState, forwardRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import ForwardIcon from '@mui/icons-material/Forward';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LoadingButton from '@mui/lab/LoadingButton';

import { isCustomerRole } from 'components/config/Roles';
import SelectCustom from 'components/select/SelectCustom';
import DetailField from 'components/page/object/DetailField';
import ConfirmDialog from 'components/page/object/ConfirmDialog';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsSI } from "./ContextMissionDetailsSI";


const MissionDetailsSIView = forwardRef((props, ref) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //workflow
  const { canWrite } = useContext(ContextMissionWorkflow);

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //mission general data
  const { missionCanceled, setMissionCanceled } = useContext(ContextMissionDetails);

  //si general data
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextMissionDetailsSI);

  const { portDeparture, setPortDeparture } = useContext(ContextMissionDetailsSI);
  const { portDestination, setPortDestination } = useContext(ContextMissionDetailsSI);

  const { surveyHandler, setSurveyHandler } = useContext(ContextMissionDetailsSI);
  const { surveyHandlerDefault, setSurveyHandlerDefault } = useContext(ContextMissionDetailsSI);

  const { localOffice, setLocalOffice } = useContext(ContextMissionDetailsSI);
  const { principals, setPrincipals } = useContext(ContextMissionDetailsSI);

  const { trader, setTrader } = useContext(ContextMissionDetailsSI);
  const { shipper, setShipper } = useContext(ContextMissionDetailsSI);
  const { cargoType, setCargoType } = useContext(ContextMissionDetailsSI);

  const { cargoLoadingTypeId, setCargoLoadingTypeId } = useContext(ContextMissionDetailsSI);
  const { cargoLoadingTypeDefault, setCargoLoadingTypeDefault } = useContext(ContextMissionDetailsSI);

  const { tonnage, setTonnage } = useContext(ContextMissionDetailsSI);
  const { remainingTonnage, setRemainingTonnage } = useContext(ContextMissionDetailsSI);
  const { contractNumber, setContractNumber } = useContext(ContextMissionDetailsSI);

  //function interactive
  const { saveMissionDetailsSI } = useContext(ContextMissionDetailsSI);
  const { createSurveyHandlerHandler } = useContext(ContextMissionDetailsSI);
  const { createCargoLoadingTypeHandler } = useContext(ContextMissionDetailsSI);

  //validation contract number popin
  const [openConfirmationPopin, setOpenConfirmationPopin] = useState(false);
  const [confirmationPopinContent, setConfirmationPopinContent] = useState("");
  const [confirmationPopinTitle, setConfirmationPopinTitle] = useState("");
  const handleClickRemoveSI = async () => {
    setConfirmationPopinContent("Are you sure that you want to detach the current mission from this SI ?");
    setConfirmationPopinTitle("SI removal");
    setOpenConfirmationPopin(true);
  }

  const handleRemovalConfirm = async () => {
    await saveMissionDetailsSI(true, true);
  }


  return (
    <div>
      <div className="lineContent noJustify">

        <DetailField title='Contract Number' content={contractNumber} />
        <DetailField title="Port Departure" content={portDeparture} size={!JLBCustomerMode ? '' : 'large'} />
        <DetailField title="Port Destination" content={portDestination} size={!JLBCustomerMode ? '' : 'large'} />

        <SelectCustom
          title="Survey handler"
          api="survey_handler/list"
          apiParam=""
          apiParamValue=""
          dataValue={surveyHandler}
          setDataValue={setSurveyHandler}
          defaultAlias=""
          defaultValue={surveyHandlerDefault}
          creator={createSurveyHandlerHandler}
          read={true}
          write={false}
          controlFieldsStatus={true}
        />

        {(!JLBCustomerMode) &&
          <DetailField title='Local office' content={localOffice} />
        }
      </div>
      <div className="lineContent noJustify">
        {(!JLBCustomerMode) &&
          <DetailField title='Principals' content={principals} />
        }
        {(!JLBCustomerMode) &&
          <DetailField title='Trader' content={trader} />
        }
        <DetailField title='Shipper' content={shipper} size={!JLBCustomerMode ? '' : 'large'} />
        <DetailField title='Cargo' content={cargoType} size={!JLBCustomerMode ? '' : 'large'} />

        <SelectCustom
          title="Type of Loading"
          api="cargo/loading_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={cargoLoadingTypeId}
          setDataValue={setCargoLoadingTypeId}
          defaultAlias=""
          defaultValue={cargoLoadingTypeDefault}
          creator={createCargoLoadingTypeHandler}
          read={true}
          write={false}
          controlFieldsStatus={true}
        />
      </div>
      <div className="lineContent noJustify">
        <DetailField title='Tonnage' content={Number(tonnage).toLocaleString()} />
        <DetailField title='Remaining tonnage' content={Number(remainingTonnage).toLocaleString()} />
      </div>

      {(Boolean(canWrite('si_block')) && update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode) &&
        <div className="block_button">
          <LoadingButton className="cancel" onClick={async () => await handleClickRemoveSI()} loadingPosition="start" startIcon={<LinkOffIcon />}> Remove</LoadingButton>
          <LoadingButton className="edit" onClick={() => navigate('/shippingInstructionDetails?id=' + idShippingInstruction, { replace: true })} loadingPosition="start" startIcon={<ForwardIcon fontSize="small" />}> SI informations</LoadingButton>
        </div>
      }

      <ConfirmDialog
        title={confirmationPopinTitle}
        notClose={true}
        open={openConfirmationPopin}
        setOpen={setOpenConfirmationPopin}
        onConfirm={async () => { await handleRemovalConfirm() }}
      >
        <div className="lineContent">
          {confirmationPopinContent}
        </div>
      </ConfirmDialog>
    </div>
  );
});

export default MissionDetailsSIView;
