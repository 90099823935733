import React, {useContext} from 'react';

import ConfirmDialog from 'components/page/object/ConfirmDialog';
import MissionDetailsWorkflowMailingCustomise from './MissionDetailsWorkflowMailingCustomise';

import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";


export default function MissionDetailsWorkflowMailing(props) {

  const {workflowStep} = useContext(ContextMissionWorkflow);
  const {workflowMailingStep} = useContext(ContextMissionWorkflow);
  const {workflowMailingConfirmOpen, setWorkflowMailingConfirmOpen} = useContext(ContextMissionWorkflow);

  return (
    <div>
      <MissionDetailsWorkflowMailingCustomise/>
      <ConfirmDialog
        title={"Email Notification"}
        open={workflowMailingConfirmOpen}
        setOpen={setWorkflowMailingConfirmOpen}
        onConfirm={workflowMailingStep}
        onDisConfirm={workflowStep}
      >
        {"Do you want to send an email notification ?"}
      </ConfirmDialog>
    </div>
  );
};
