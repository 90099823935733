import React, { useState, useContext, useEffect } from 'react';

import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingButtonCustom from 'components/page/object/LoadingButtonCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";


const MissionDetailsWorkflowSaveButton = (props) => {

  //generic
  const { loading, setLoading } = useContext(ContextBlock);

  //workflow
  const { controlFieldsErrorTruncate } = useContext(ContextMissionWorkflow);
  const { controlFields, setControlFields } = useContext(ContextMissionWorkflow);
  const { controlButtons, setControlButtons } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);
  const { idButtonWorkflow, setIdButtonWorkflow } = useContext(ContextMissionWorkflow);
  const { checkWorkflowData, setCheckWorkflowData } = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);

  //handle button
  const [show, setShow] = useState(workflowLoaded ? true : false);
  const {handleClickSave} = useContext(ContextMissionDetailsTreatment);
  const handleClickWorkflow = async (id_button) => {
    setIdButtonWorkflow(null);
    setCheckWorkflowData(true);
    await controlFieldsErrorTruncate(controlFields.map((field)=>{return field.id}));
    handleClickSave(false, id_button);
  }


  //useEffect
  useEffect(() => {
    //maj show state
    var newStateEdit = workflowLoaded ? true : false;
    if (show != newStateEdit) {
      setShow(newStateEdit);
    }
  }, [workflowLoaded]);


  return(
    <div className={!show ? 'workflowSaveButton opacity' : 'workflowSaveButton opacity show'}>
      <Box sx={{margin: 'auto'}}>
        <Box sx={{ '& > button': { m: 1 } }} className="loadingButtonBlock">
          {controlButtons.map((button, index) => {
            var color = 'secondary';
            var icon  = <SaveIcon />;
            if (button.key === 'mission_duplicating_button') {
              color = 'primary';
              icon  = <ContentCopyIcon />;
            }
            return (
              <LoadingButtonCustom
                icon={icon}
                index={index}
                color={color}
                loading={loading}
                disabled={loading}
                loadingPosition="end"
                buttons={controlButtons}
                handleClick={handleClickWorkflow}
                controlFieldsStatus={fieldsSettingsLoaded}
              />
            );
          })}
        </Box>
      </Box>
    </div>
  );
}

export default MissionDetailsWorkflowSaveButton
