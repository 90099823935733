import React, { useState, useEffect, forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Checkbox, FormControlLabel, IconButton, TextField, Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom";
import axios from 'api/axios';
import momentDate from 'moment';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LinkGenerationPopin = (props) => {

  const navigate = useNavigate();

  const handleClose = () => {
    props.setOpen(false)
    initCheckboxes();
    setLink('');
    setLinkMessage('');
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: '80%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const [loadingSave, setLoadingSave] = useState(false);
  const [files, setFiles] = useState([]);
  const [link, setLink] = useState('');
  const [linkMessage, setLinkMessage] = useState('');

  const initCheckboxes = () => {
    setFiles(
      props.files.map((file) => {
        //On start, checked property is set to 0 for every file
        return { ...file, checked: false };
      })
    );
  }

  useEffect(() => {
    initCheckboxes();
  }, [props.files]);


  const handleClickGenerate = async () => {
    setLoadingSave(true);

    try {
      var formData = new FormData();

      formData.append("id_mission", props.idMission);
      files.map((file) => {
        if (file.checked) {
          formData.append("files[]", parseInt(file.id_file));
        }
      });

      var config = {
        url: "mission/documents/link",
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      const response = await axios(config);

      if (response.data.success) {
        setLink(response.data.link.link);
        setLinkMessage("Link generated successfully");
        props.setRowsLink(
          [...props.rowsLink, {
            id: response.data.link.id,
            files: response.data.link.files,
            link: response.data.link.link,
            type: response.data.link.type,
            created_by: response.data.link.creator.firstname + " " + response.data.link.creator.lastname,
            created_on: momentDate(response.data.link.created_at).format("DD MMM YY")
          }]
        );
      }


    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }

    setLoadingSave(false);
  }

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setFiles(
      files.map((file) => {
        if (file.id == name) {
          return { ...file, checked: checked };
        }
        return file;
      })
    );
  }
  var countPublicFiles = 0;
  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      className='modalPopin file'
    >
      <Box sx={style}>
        <div className='headerPopin'>Generate a link to share public files</div>
        <div className='contentPopin'>
          {
            files.map((file, i) => (
              props.permissions.map((permission) => (
                props.types.map((type) => {
                  if (permission.value == file.id_file_permission
                    && type.value == file.type
                    && permission.can_be_shared == 1) {
                    countPublicFiles++;
                    return <FormControlLabel
                      sx={{ minWidth: '80%' }}
                      key={i}
                      control={
                        <Checkbox
                          checked={file.checked}
                          onChange={handleCheck}
                          name={file.id}
                        />
                      }
                      label={type.label + " (" + file.file + ")"}
                    />
                  }
                }


                )
              ))
            ))
          }
          {
            (files.length == 0 || countPublicFiles == 0) &&
            <div>
              No public files found, please check that the files you want to share have public rights
            </div>
          }
        </div>
        {
          link != "" &&
          <div>
            <MuiAlert severity="success">{linkMessage}</MuiAlert>
            <div style={{ margin: "10px" }}>
              <TextField
                sx={{ width: '100%' }}
                variant="standard"
                id="link-copy-to-clipboard"
                label=""
                value={link}
                disabled={true}
                InputProps={{
                  startAdornment: <IconButton
                    aria-label="Copy to clipboard"
                    title="Copy to clipboard"
                    onClick={(event) => {
                      navigator.clipboard.writeText(link)
                      setLinkMessage("Link copied!")
                    }}>
                    <CopyAllIcon />
                  </IconButton>
                }}
              />
            </div>
          </div>
        }
        <div className='footerPopin'>
          <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
            <LoadingButton
              onClick={handleClose}
              loadingIndicator="Cancel"
              variant="outlined"
              loading={loadingSave}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleClickGenerate}
              loading={loadingSave}
              endIcon={<SaveIcon />}
              loadingPosition="end"
              variant="contained"
              disabled={link != "" || files.length == 0 || countPublicFiles == 0}
            >
              Generate
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Modal>
  );
}
export default LinkGenerationPopin