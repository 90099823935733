import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  gridVisibleSortedRowIdsSelector,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { isInternalRole, isPortRole, isCustomerRole } from "components/config/Roles";

import { checkSecurity } from "components/config/Security";
import axios from "api/axios";
const GETCOMPANYLIST_URL = "company/list";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CompaniesList(props) {
  const [JLBInternalMode] = React.useState(
    isInternalRole(localStorage.getItem("role"))
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    props.data.setOpenSnackBar(false);
  };

  // CHECK SECURITY
  const navigate = useNavigate();

  useEffect(() => {
    checkSecurity(navigate, "Companies");
    getCompaniesList();
  }, []);
  // END SECURITY

  const RenderCountries = (props) => {
    if (!props.row.countries) {
      return null; // No chip if countries is empty
    }

    const countries = props.row.countries
      .split(",")
      .map((label) => label.trim());
    if (countries.length === 0) {
      return null; // No Chip if countries is empty after .split
    }

    const rows = Math.ceil(countries.length / 2); // Calculate the number of rows needed

    return (
      <div>
        <Stack direction="column" spacing={1}>
          {[...Array(rows)].map((_, rowIndex) => (
            <Stack key={rowIndex} direction="row" spacing={1}>
              {countries
                .slice(rowIndex * 2, (rowIndex + 1) * 2) // Get countries for the current row
                .map((country, index) => (
                  <Chip key={index} label={country} variant="outlined" />
                ))}
            </Stack>
          ))}
        </Stack>
      </div>
    );
  };

  const RenderCompanyTypes = (props) => {
    if (!props.row.company_types) {
      return null; // No chip if company_types is empty
    }

    const companyTypes = props.row.company_types
      .split(",")
      .map((type) => type.trim());
    if (companyTypes.length === 0) {
      return null; // No Chip if company_types is empty after .split
    }

    const rows = Math.ceil(companyTypes.length / 3); // Calculate the number of rows needed

    return (
      <div>
        <Stack direction="column" spacing={1}>
          {[...Array(rows)].map((_, rowIndex) => (
            <Stack key={rowIndex} direction="row" spacing={1}>
              {companyTypes
                .slice(rowIndex * 3, (rowIndex + 1) * 3) // Get company types for the current row
                .map((type, index) => (
                  <Chip key={index} label={type} variant="outlined" />
                ))}
            </Stack>
          ))}
        </Stack>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "empty_gap",
      headerName: "",
      sortable: false,
      flex: 1,
      width: 5,
      minWidth: 5,
      maxWidth: 5,
      padding: 0,
    },
    {
      field: "id",
      headerName: "ID",
      width: 30,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
    },
    {
      field: "countries",
      headerName: "Countries",
      flex: 1,
      minWidth: 250,
      renderCell: RenderCountries,
    },
    {
      field: "company_types",
      headerName: "Types",
      flex: 1,
      minWidth: 370,
      renderCell: RenderCompanyTypes,
    },
    {
      field: "contacts",
      headerName: "Contacts",
      minWidth: 10,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_jlb",
      headerName: "JLB",
      minWidth: 20,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "access_subcontractor",
      headerName: "A sub.",
      minWidth: 20,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleRowClick = (params) => {
    navigate("/updateCompany?id=" + params.row.id + "&key=" + randomId(), {
      replace: true,
    });
  };

  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const getCompaniesList = async (e) => {
    try {
      props.data.setLoadingGrid(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var url = GETCOMPANYLIST_URL;

      const response = await axios.get(url, config);

      var rowsFromApi = [];
      var countries = [];
      var company_types = [];
      var idRow = 0;

      for (var i = 0; i < response.data.length; i++) {
        idRow = rowsFromApi.length;
        rowsFromApi[idRow] = {};

        if (response.data[i].id) {
          rowsFromApi[idRow].id = response.data[i].id;
        }

        if (response.data[i].name) {
          rowsFromApi[idRow].name = response.data[i].name;
        }

        if (response.data[i].ville) {
          rowsFromApi[idRow].city = response.data[i].ville;
        }

        if (response.data[i].countries.length > 0) {
          rowsFromApi[idRow].countries = response.data[i].countries
            .map(function (country) {
              return country.country.label;
            })
            .join(", ");

          countries.push.apply(
            countries,
            response.data[i].countries.map(function (country) {
              return country.country;
            })
          );
        }

        if (response.data[i].types.length > 0) {
          rowsFromApi[idRow].company_types = response.data[i].types
            .map(function (type) {
              return type.label;
            })
            .join(", ");
          company_types.push.apply(company_types, response.data[i].types);
        }
        if (response.data[i].contacts && response.data[i].contacts.length > 0) {
          rowsFromApi[idRow].contacts = response.data[i].contacts.length;
        } else {
          rowsFromApi[idRow].contacts = 0;
        }
        if (response.data[i].jlb) {
          rowsFromApi[idRow].is_jlb = "X";
        }
        if (response.data[i].access_subcontractor) {
          rowsFromApi[idRow].access_subcontractor = "X";
        }
      }

      props.data.setRows(rowsFromApi);
      props.data.setTotalRows(rowsFromApi);

      countries.sort((a, b) => (a.label > b.label ? 1 : -1));
      props.data.setCountryRows(getUnique(countries, "id"));

      company_types.sort((a, b) => (a.label > b.label ? 1 : -1));
      props.data.setCompanyTypeRows(getUnique(company_types, "id"));

      if (props.data.initFilterCompanies) {
        props.data.initFilterCompanies(rowsFromApi);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        console.log(err);
      }
    } finally {
      props.data.setLoadingGrid(false);
      props.data.setDataGridLoaded(true);
    }
  };

  function CustomNoRowsOverlay() {
    return <div></div>;
  }

  function CustomFooterCompaniesList() {
    const handleClick = () => {
      navigate("/createCompany", {
        replace: true,
      });
    };

    return (
      <GridFooterContainer>
        <Button
          className="iconAddButton"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add Company
        </Button>
        <GridFooter
          sx={{
            border: "none",
          }}
        />
      </GridFooterContainer>
    );
  }

  return (
    <>
      <DataGridPro
        sx={{ height: "100%", width: "100%" }}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
          Footer: CustomFooterCompaniesList,
        }}
        initialState={{
          pagination: {
            pageSize: 15,
          },
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        getRowHeight={({ id, densityFactor }) => {
          const companyTypesRow = props.data.rows.find(
            (row) => row.id === id
          )?.company_types;
          const countriesRow = props.data.rows.find(
            (row) => row.id === id
          )?.countries;

          if (countriesRow || companyTypesRow) {
            var heightValue;

            if (countriesRow) {
              const countries = countriesRow
                .split(",")
                .map((word) => word.trim());

              if (countries.length > 8) {
                heightValue = 170 * densityFactor;
              } else if (countries.length > 4) {
                heightValue = 135 * densityFactor;
              } else if (countries.length > 2) {
                heightValue = 90 * densityFactor;
              }
            }
            if (companyTypesRow) {
              const companies = companyTypesRow
                .split(",")
                .map((word) => word.trim());

              if (companies.length > 9) {
                heightValue = 170 * densityFactor;
              } else if (companies.length > 6) {
                heightValue = 135 * densityFactor;
              } else if (companies.length > 3) {
                heightValue = 100 * densityFactor;
              }
            }
            return heightValue;
          }
        }}
        loading={props.data.loadingGrid}
        rows={props.data.rows}
        columns={columns}
        rowsPerPageOptions={[8, 15, 25, 50, 100]}
        pagination
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        onRowClick={handleRowClick}
        filterModel={props.data.filterModel}
        onFilterModelChange={props.data.rowsSelected}
        onStateChange={(state) => {
          const newRows = gridVisibleSortedRowIdsSelector(state);
          props.data.setVisibleRows(newRows);
        }}
      />
      <Snackbar
        open={props.data.openSnackBar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Technical error ! Please try again or contact our support.
        </Alert>
      </Snackbar>
    </>
  );
}
