import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";

import ShippingInstructionMissionForm from './ShippingInstructionMissionForm';
import ShippingInstructionMissionView from "./ShippingInstructionMissionView";


const ShippingInstructionMission = forwardRef((props, ref) => {

  //init config
  const { update } = useContext(ContextBlock);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { toogleBlockOnEdit } = useContext(ContextShippingInstruction);


  //useEffect
  useEffect(() => {
    toogleBlockOnEdit(ref, onEdit, setOnEdit);
  }, [blockEdit]);


  if (update == 1) {
    return (
      <div id="block_mission" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
        <h4>Missions</h4>

        {(!onEdit) ? <ShippingInstructionMissionView /> : <ShippingInstructionMissionForm />}

      </div>
    );
  }
});

export default ShippingInstructionMission;
