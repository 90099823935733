import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';


export default function LoadingButtonCustom(props) {

  if (props.controlFieldsStatus == true)
  {
    if (props.buttons[props.index]) 
    {
      return (
        <>
          <LoadingButton
            onClick={() => {props.handleClick(props.buttons[props.index].id)}}
            loading={props.loading}
            endIcon={props.icon}
            loadingPosition={props.loadingPosition}
            variant="contained"
            color={!!props.color !== false ? props.color : 'secondary'}
            className="loadingButtonCustom"
          >
            {props.buttons[props.index].label}
          </LoadingButton>
        </>
      );
    }
  }

  return (
    <></>
  );
}
