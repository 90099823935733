import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { checkSecurity } from "components/config/Security";
import CompaniesList from "./section/Company/CompaniesList";
import MuiAlert from "@mui/material/Alert";

//FILTER
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import { GridLinkOperator } from "@mui/x-data-grid-pro";
import FilterCheckBox from "../filter/FilterCheckBox";
//END FILTER

import Menu from "./section/Menu";
import "styles/globalPortalDashboard.css";
import "styles/DashboardList.css";
import "bootstrap/dist/css/bootstrap.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Companies = () => {
  // CHECK SECURITY
  const navigate = useNavigate();

  useEffect(() => {
    checkSecurity(navigate, "Companies");
    var filter = JSON.parse(localStorage.getItem("filter_companies"));

    if (filter) {
      //INIT DATAS Filter list
      setFilterDatas(filter.datas);

      //INIT SEARCH TXT CATEGORY
      if (filter.search) {
        setSearchTxt(filter.search);
      }
      resetFilterResetButton(filter.datas, filter.search);
    }
  }, []);
  // END SECURITY
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [countryRows, setCountryRows] = useState([]);
  const [companyTypeRows, setCompanyTypeRows] = useState([]);

  const [loadingGrid, setLoadingGrid] = useState(false);
  const [dataGridLoaded, setDataGridLoaded] = useState(false);

  const [country, setCountry] = React.useState("");
  const [companyType, setCompanyType] = React.useState("");

  const [searchTxt, setSearchTxt] = React.useState("");
  const [searchTxtState, setSearchTxtState] = React.useState("");

  const [filterModel, setFilterModel] = React.useState({ items: [] });
  const [filterDatas, setFilterDatas] = React.useState({
    country: "",
    company_type: "",
  });

  const [filterOn, setFilterOn] = React.useState(false);

  const [visibleRows, setVisibleRows] = React.useState([]);

  function setFilterSelectedLocalStorage(filterDataParam, searchTxt) {
    var filter = { datas: filterDataParam, search: searchTxt };
    localStorage.setItem("filter_companies", JSON.stringify(filter));
  }

  function initFilterCompanies(totalRowsParam) {
    var filter = JSON.parse(localStorage.getItem("filter_companies"));

    if (filter) {
      setCountry(filter.datas.country);
      setCompanyType(filter.datas.company_type);

      selectDataContent(filter.datas, filter.search, totalRowsParam);
      setSearchTxtAction(filter.search, filter.datas);
    }
  }

  function selectDataContent(filterDataParam, searchTxtParam, totalRowsParam) {
    var searchTxtVar = searchTxt;
    var totalRowsVar = totalRows;
    if (searchTxtParam || totalRowsParam) {
      searchTxtVar = searchTxtParam;
      totalRowsVar = totalRowsParam ? totalRowsParam : totalRows;
    }

    setLoadingGrid(true);
    var selectedRows = [];
    var addRow = true;

    for (var i = 0; i < totalRowsVar.length; i++) {
      addRow = true;
      if (
        filterDataParam.country.length > 0 &&
        !(totalRowsVar[i].countries || "").includes(filterDataParam.country)
      )
        addRow = false;
      if (
        filterDataParam.company_type.length > 0 &&
        !(totalRowsVar[i].company_types || "").includes(
          filterDataParam.company_type
        )
      )
        addRow = false;

      if (addRow) selectedRows[selectedRows.length] = totalRowsVar[i];
    }
    setRows(selectedRows);
    setLoadingGrid(false);
    setFilterSelectedLocalStorage(filterDataParam, searchTxtVar);
    resetFilterResetButton(filterDataParam, searchTxtVar);
  }

  function setSearchTxtAction(searchTxtAct, filterDatasParam) {
    var filterDatasVar = filterDatas;
    if (filterDatasParam) filterDatasVar = filterDatasParam;

    if (searchTxtAct.length > 0) {
      setFilterModel({
        items: [
          {
            id: 1,
            columnField: "name",
            operatorValue: "contains",
            value: searchTxtAct,
          },
          {
            id: 2,
            columnField: "city",
            operatorValue: "contains",
            value: searchTxtAct,
          },
        ],
        linkOperator: GridLinkOperator.Or,
      });
    } else {
      setFilterModel({ items: [] });
    }
    setFilterSelectedLocalStorage(filterDatasVar, searchTxtAct);
    resetFilterResetButton(filterDatasVar, searchTxtAct);
  }

  const handleChangeSearchText = (event) => {
    setSearchTxt(event.target.value);
    if (event.target.value.length > 0) {
      setSearchTxtState("");
      setSearchTxtAction(event.target.value);
    } else {
      if (event.target.value.length === 0) {
        setSearchTxtState("");
        setSearchTxtAction(event.target.value);
      } else {
        setSearchTxtState("warning");
      }
    }
  };
  //SETTER
  function createCountryHandler(data) {
    return { id: data.id, alias: data.label };
  }

  function createCompanyTypeHandler(data) {
    return { id: data.id, alias: data.label };
  }

  //Filter
  function setCountryHandler(data) {
    setCountry(data);
    var filterData = { country: data, company_type: companyType };
    setFilterDatas(filterData);
    selectDataContent(filterData);
  }

  function setCompanyTypeHandler(data) {
    setCompanyType(data);
    var filterData = { country: country, company_type: data };
    setFilterDatas(filterData);
    selectDataContent(filterData);
  }

  function resetFilterResetButton(filterDatas, searchTxt) {
    var showFilter = false;
    if (searchTxt.length > 0) {
      showFilter = true;
    }
    if (filterDatas.country !== "" || filterDatas.company_type !== "") {
      showFilter = true;
    }
    setFilterOn(showFilter);
  }

  function handleResetAll() {
    setCountry("");
    setCompanyType("");

    setCountryRows([]);
    setCompanyTypeRows([]);

    setSearchTxt("");
    setSearchTxtState("");

    var filterData = { country: "", company_type: "" };
    setFilterDatas(filterData);
    setSearchTxtAction("", "");
    selectDataContent(filterData, []);
    setFilterOn(false);
    localStorage.setItem("filter_companies", null);
  }

  return (
    <div className="FULL-CONTENT DASHBOARD">
      <div className="content">
        <div className="contentFilter">
          <Box
            sx={{ display: "flex", alignItems: "flex-end", width: "99%" }}
            className="FilterSearchBlockField"
          >
            <Box className="SearchBoxText" sx={{ width: "99%" }}>
              <SearchRoundedIcon
                sx={{ color: "action.active" }}
                className="iconeLeft"
              />
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 300,
                  width: "100%",
                  paddingLeft: 3,
                  paddingRight: 2,
                }}
              >
                <TextField
                  id="input-search-with-sx"
                  label="Search"
                  variant="standard"
                  value={searchTxt}
                  fullWidth
                  onChange={handleChangeSearchText}
                  color={searchTxtState}
                />
              </FormControl>
            </Box>
            <div
              className={
                filterOn
                  ? "resetButtonFilterCompanies"
                  : "resetButtonFilterCompanies hide"
              }
              onClick={handleResetAll}
            >
              <i className="bx bx-reset"></i>
              <span>Reset all</span>
            </div>

            {Boolean(dataGridLoaded) && (
              <FilterCheckBox
                title="Country"
                width="200"
                api=""
                apiParam=""
                apiParamValue=""
                creator={createCountryHandler}
                setValueOfFilterAndSearch={setCountryHandler}
                dataValueHandler={country}
                setDataValueHandler={setCountry}
                datas={countryRows}
                loadByList="true"
              />
            )}

            {Boolean(dataGridLoaded) && (
              <FilterCheckBox
                title="Type"
                width="200"
                api=""
                apiParam=""
                apiParamValue=""
                creator={createCompanyTypeHandler}
                setValueOfFilterAndSearch={setCompanyTypeHandler}
                dataValueHandler={companyType}
                setDataValueHandler={setCompanyType}
                datas={companyTypeRows}
                loadByList="true"
              />
            )}
          </Box>
        </div>
        <div className="contentListCompanies">
          <CompaniesList
            data={{
              filterModel,
              setFilterModel,
              rows,
              setRows,
              setTotalRows,
              loadingGrid,
              setLoadingGrid,
              setCountryRows,
              setCompanyTypeRows,
              setDataGridLoaded,
              setVisibleRows,
              openSnackBar,
              setOpenSnackBar,
              initFilterCompanies,
            }}
          />
        </div>
      </div>
      <Menu />
    </div>
  );
};

export default Companies;
