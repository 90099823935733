import React, { useEffect, useState, SyntheticEvent, forwardRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'api/axios';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { randomId } from "@mui/x-data-grid-generator";
import moment from 'moment';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SelectMultiCheckbox = (props, ref) => {
  const navigate = useNavigate();

  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const getList = async (e) => {
    try {
      setLoading(false);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            }
        };
        /*
        apiParam="company_role"
        apiParamValue="Pincipals"
        */
        var getValue = "";
        if (props.apiParam.length > 0)
        {
          getValue = "?"+props.apiParam+"="+props.apiParamValue;
        }

        //GESTION DU CACHE
        if (localStorage.getItem(props.api+getValue+"TTL"))
        {
          if (moment.unix(localStorage.getItem(props.api+getValue+"TTL")).unix() <  moment().unix())
          {
            localStorage.removeItem(props.api+getValue);
            localStorage.removeItem(props.api+getValue+"TTL");
          }
        }

        if (!localStorage.getItem(props.api+getValue)||!props.cached) {
          var response = await axios.get(props.api+getValue, config);
          localStorage.setItem(props.api+getValue, JSON.stringify(response));
          if (props.cacheTTL)
          {
            localStorage.setItem(props.api+getValue+"TTL", Number(moment().unix())+Number(props.cacheTTL));
          }
        }
        else
        {
          var response = JSON.parse(localStorage.getItem(props.api+getValue));
        }
        //END OF CACHE

        var rowsFromApi = [];
        for(var i=0; i < response.data.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = props.creator(response.data[i]);
        }
        setDataFilter(rowsFromApi);

    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        setOpenSnackBar(true);
      }
    } finally
    {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (props.loadByList === 'false') {
      getList();
    }
    else {
      var rowsFromApi = [];
      for (var i = 0; i < props.datas.length; i++) {
        rowsFromApi[i] = {};
        rowsFromApi[i] = props.creator(props.datas[i]);
      }
      setDataFilter(rowsFromApi);
    }
  }, []);

  const handleChangeFilterHandler = (event) => {
    const {
      target: { key, value },
    } = event;
    props.setDataValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  //auto open
  const [selectOpen, setSelectOpen] = useState(false);
  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  useEffect(() => {
    if (!!props?.autoOpen !== false) {
      handleSelectOpen();
    }
  }, [props?.autoOpen]);
  

  return (
    <FormControl sx={{ m: 1, width: '38%' }} size="small" error={props.error} disabled={props.disabled}>
      <InputLabel id="select-multiple-checkbox-label">{props.title}</InputLabel>
      <Select
        inputRef={props?.inputRef}
        id={'select-multiple-checkbox-' + randomId()}
        labelId="select-multiple-checkbox-label"
        value={props.dataValue}
        multiple
        onChange={handleChangeFilterHandler}
        input={<OutlinedInput label={props.title} />}
        MenuProps={MenuProps}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.3 }}>
            {selected.map(function(value) {
              var label = '';
              dataFilter.map(function (dataItem) {
                if (dataItem.id == value) {
                  label = dataItem.label;
                }
              });
              return <Chip key={value} label={label} />;
            })}
          </Box>
        )}

        open={selectOpen}
        onClose={handleSelectClose}
        onOpen={handleSelectOpen}
      >

        {dataFilter.map((dataItem) => (
          <MenuItem key={dataItem.id} value={dataItem.id} name={dataItem.label}>
            <Checkbox checked={props.dataValue.indexOf(dataItem.id) > -1} />
            <ListItemText primary={dataItem.label} />
          </MenuItem>
        ))}
      </Select>
      <LinearProgress hidden={loading}  />
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Technical error ! Please try again or contact our support.
        </Alert>
      </Snackbar>
    </FormControl>
  );
}
export default SelectMultiCheckbox
