import React, { useState, forwardRef, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';

import { isCustomerRole } from 'components/config/Roles';
import MissionDetailsInstructionList from './MissionDetailsInstructionList';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";


const MissionDetailsInstructionView = forwardRef((props, ref) => {

  //init config
  const { update } = useContext(ContextBlock);
  const { instructionRef } = useContext(ContextMissionDetails);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //workflow
  const { canWrite } = useContext(ContextMissionWorkflow);

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //mission general data
  const { missionCanceled, setMissionCanceled } = useContext(ContextMissionDetails);

  //fhandle
  const { handleClickBlockEdit } = useContext(ContextBlock);


  return (
    <div>
      <MissionDetailsInstructionList editable={false} />

      {(Boolean(canWrite('instruction_block')) && update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode) &&
        <div className="block_button">
          <LoadingButton className="edit" onClick={() => handleClickBlockEdit(instructionRef)} loadingPosition="start" startIcon={<EditIcon />}> Edit</LoadingButton>
        </div>
      }
    </div>
  );
});

export default MissionDetailsInstructionView;
