import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from 'imgs/logo.svg';
import 'styles/Login.css';
import 'styles/RenewPassword.css';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'api/axios';

const ProtisSignUp = () => {

  // CHECK SECURITY
  const navigate = useNavigate();
  const SIGNUP_URL = 'user/protis/signup';

  const location = useLocation();
  const from = location.state?.from?.pathname || "/protis/sign-up/done";

  const inputRef = useRef();
  const errRef = useRef();

  const [port, setPort] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [accept, setAccept] = useState(false);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');

  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    inputRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [email, port, company, country, accept, lastname, firstname])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(SIGNUP_URL,
        JSON.stringify({ 
          port: port,
          email: email,
          company: company,
          country: country,
          accept: accept,
          lastname: lastname,
          firstname: firstname,
        }),
        {  headers: { 'Content-Type': 'application/json' } }
      );

      setPort('');
      setEmail('');
      setCompany('');
      setCountry('');
      setAccept(false);
      setLastname('');
      setFirstname('');
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Informations');
      } else if (err.response?.status === 401) {
        setErrMsg('Invalid Informations');
      } else if (err.response?.status === 422) {
        setErrMsg('Invalid Informations');
      } else {
        setErrMsg('Server Failed');
      }
      errRef.current.focus();
    }
  }

  return (
    <div className="Login">
      <div className="welcome-to-gyptis">Gyptis - Protis Sign Up</div>
      <div className="Login-box">
        <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
        <div className="Login-formulaire">
          <form onSubmit={handleSubmit}>
            <div ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</div>
            <div className="Login-identifier">First Name *</div>
            <input className="Login-identifier-input" type="text" id="firstname" ref={inputRef} onChange={(e) => setFirstname(e.target.value)} value={firstname} required />
            <div className="Login-identifier">Last Name *</div>
            <input className="Login-identifier-input" type="text" id="lastname" onChange={(e) => setLastname(e.target.value)} value={lastname} required />
            <div className="Login-identifier">Company *</div>
            <input className="Login-identifier-input" type="text" id="company" onChange={(e) => setCompany(e.target.value)} value={company} required />
            <div className="Login-identifier">Email *</div>
            <input className="Login-identifier-input" type="text" id="email" onChange={(e) => setEmail(e.target.value)} value={email} required />
            <div className="Login-identifier">Country *</div>
            <input className="Login-identifier-input" type="text" id="country" onChange={(e) => setCountry(e.target.value)} value={country} required />
            <div className="Login-identifier">Port *</div>
            <input className="Login-identifier-input" type="text" id="port" onChange={(e) => setPort(e.target.value)} value={port} required />
            <div className="Login-identifier">
              <input type="checkbox" id="accept" onChange={(e) => setAccept(e.target.checked)} checked={accept} required />&nbsp;
              I accept the <a href="https://gyptis.jlbexpertises.com/protis/privacy-policy" target="_blank" className="link">Terms of use & Privacy Policy</a>
            </div>
            <button className="Login-rectangle-button" onSubmit={handleSubmit}>Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProtisSignUp;
