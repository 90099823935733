import React, { forwardRef, useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import ConfirmDialog from 'components/page/object/ConfirmDialog';

import { ContextBlock } from "context/ContextBlock";

import axios from 'api/axios';

const DocumentsLinkBloc = forwardRef((props, ref) => {

  const navigate = useNavigate();

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  const [confirmLinkDeleteOpen, setConfirmLinkDeleteOpen] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState([]);

  const handleDeleteRow = (id) => {
    setConfirmLinkDeleteOpen(true);
    setLinkToDelete(id);
  }

  const deleteRowLink = async (event, linkId) => {
    try {
      var config = {
        url: 'mission/documents/link',
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: { id: linkId },
      }
      await axios(config);
      props.setRowsLink(props.rowsLink.filter((row) => row.id !== linkId));
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }
  
  const columnsFile : GridColDef[] = [
    { field: 'id', headerName: '', editable: false, sortable: false, width: 50, hide: true},
    { field: '-', headerName: '', editable: false, sortable: false, width: 150,
    renderCell: (cellValues) => {
      return (
        <div>
          <IconButton aria-label="Delete" title="Delete" onClick={(event) => {
            handleDeleteRow(cellValues.id);
          }}>
            <DeleteIcon />
          </IconButton>
          <IconButton 
            aria-label="Copy to clipboard"
            title="Copy to clipboard"
            onClick={(event) => {
                navigator.clipboard.writeText(cellValues.row.link)
                props.setSnackBarMessage("Link successfully copied to clipboard");
                props.setSnackBarType("success");
                props.setOpenSnackBar(true);
            }}>
            <CopyAllIcon />
          </IconButton>
          <IconButton 
            aria-label="Open in new window"
            title="Open in new window"
            target="_blank"
            href={cellValues.row.link}
            >
            <LaunchIcon />
          </IconButton>
        </div>
      );
    }},
    { field: 'created_by', headerName: 'Created By', editable: false, sortable: true, width:200},
    { field: 'created_on', headerName: 'Created On', editable: false, sortable: true, width:200},
    { field: 'type', headerName: 'Type', editable: false, sortable: true, width:200,
      renderCell: (cellValues) => {
        return cellValues.value == 'files' ? 'Files' : 'Photos/Videos';
      }
    },
    { field: 'files', headerName: 'Files', editable: false, sortable: true, flex:1,
      renderCell: (cellValues) => {
        var txt = "";
        var prefix = "";
  
        for(var i=0; i <  cellValues.row.files.length; i++) {
          var type = cellValues.row.files[i]?.file_type ? cellValues.row.files[i].file_type.label + ' - ' : '';
          txt = txt + prefix + type + cellValues.row.files[i].file.file_name;
          prefix = "\n\r";
        }

        return (
        <div>
          <span>{cellValues.row.files.length}</span>
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
            placement="right"
          >
              <IconButton>
                <FormatListBulletedIcon />
              </IconButton>
          </Tooltip>
        </div>
        );
      }
    }
  ];

  //if mission is init
  if(props.rowsLink.length > 0){
    return (
      <div>
        <br />
        <h4>Public links</h4>
        <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-links">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            density="compact"
            hideFooter
            rows={props.rowsLink}
            columns={columnsFile}
            experimentalFeatures={{ newEditingApi: true }}
            componentsProps={{
              toolbar: {write: props.write },
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay
            }}
          />
        </FormControl>

        <ConfirmDialog
          title={"Delete link"}
          open={confirmLinkDeleteOpen}
          setOpen={setConfirmLinkDeleteOpen}
          onConfirm={deleteRowLink}
          params={linkToDelete}
        >
          Are you sure you want to delete this link ?
        </ConfirmDialog>
      </div>
    );
  }
});

export default DocumentsLinkBloc;
