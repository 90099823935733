import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfosPopin = (props) => {
    const handleClose = () => props.setOpen(false);
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      bgcolor: 'background.paper',
      //border: '2px solid #000',
      boxShadow: 24,
      p: 2,
    };

    function msgInitBr() {
        return props.msg.replaceAll(/(?:\r\n|\r|\n)/g, "<br />");
    }
    
    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
            className={"modalPopin info " + props.type}>
            <Box sx={style}>
                <div className="headerPopin">
                    Information{(!!props.title != false) ? " - " + props.title : ""}
                    <span className="closePopin">
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </span>
                </div>

                {!props.isHTML && <div className="contentPopin" dangerouslySetInnerHTML={{__html: msgInitBr()}}></div>}
                {props.isHTML && <div className="contentPopin">{props.msg}</div>}
            </Box>
        </Modal>
    );
}
export default InfosPopin