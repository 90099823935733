import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { randomId } from "@mui/x-data-grid-generator";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import SelectCustom from 'components/select/SelectCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'
import Tooltip from '@mui/material/Tooltip';
import CompanyForm from 'components/page/section/Company/CompanyForm'
import axios from 'api/axios';

import "styles/detailsCommon.css";

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddElement = (props) => {
  const navigate = useNavigate();

  const [componentTreatmentNotValidated, setComponentTreatmentNotValidated ] = React.useState(false);

  const componentRef = useRef("componentRef");

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorLabel, setErrorLabel] = React.useState({
    field1: false,
    field2: false,
    field3: false,
    fieldText: false
  });
  const [errorLabelMessage, setErrorLabelMessage] = React.useState({
    field1: "",
    field2: "",
    field3: "",
    fieldText: ""
  });

  const handleOpen = () => {
    setField1(props?.upd && props?.field1?.initValues ? props?.field1?.initValues : "");
    setField2(props?.upd && props?.field2?.initValues ? props?.field2?.initValues : "");
    setFieldText(props?.upd && props?.fieldText?.initValues ? props?.fieldText?.initValues : "");
    setErrorLabelMessage({
      field1: "",
      field2: "",
      field3: "",
      fieldText: ""
    });
    setErrorLabel({
      field1: false,
      field2: false,
      field3: false,
      fieldText: false
    });
    if (props.field3) setField3(props.field3.initValues); else setField3(0);
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    if(props.onClick) props.onClick();
  }

  const [field1, setField1] = React.useState("");
  const onchageField1Handler = (event) => {
    var value = props.field1?.notUpper ? event.target.value : event.target.value.toUpperCase();
    setField1(value);
  };

  const [field2, setField2] = React.useState("");
  const onchageField2Handler = (event) => {
    var value = props.field2?.notUpper ? event.target.value : event.target.value.toUpperCase();
    setField2(value);
  };

  const [fieldText, setFieldText] = React.useState("");
  const onchageFieldTextHandler = (event) => {
    var value = props.fieldText?.notUpper ? event.target.value : event.target.value.toUpperCase();
    setFieldText(value);
  };

  const [field3, setField3] = React.useState("");
  const Field3Component = !Boolean(props.field3?.autocomplete) ? SelectCustom : SelectAutoCustom;
  function createField3(data) {
    return { id: data.id, [!Boolean(props.field3?.autocomplete) ? 'title' : 'label']: data.label.toUpperCase() };
  }

  const [field4, setField4] = React.useState({});

  const [field5TXT, setField5TXT] = React.useState("");
  const [field5, setField5] = React.useState(0);

  useEffect(() => {
    setErrorLabelMessage({
      field1: "",
      field2: "",
      field3: "",
      fieldText: ""
    });
    setErrorLabel({
      field1: false,
      field2: false,
      field3: false,
      fieldText: false
    });

    if (props.field1 && props?.upd){
      if (props.field1.initValues) setField1(props.field1.initValues);
    }
    if (props.field2 && props?.upd){
      if (props.field2.initValues) setField2(props.field2.initValues);
    }
    if (props.fieldText && props?.upd){
      if (props.fieldText.initValues) setFieldText(props.fieldText.initValues);
    }
    if (props.field3){
      if (props.field3.initValues) setField3(props.field3.initValues);
    }
    if (props.field4){
      if (props.field4.thirdButton) setField4(props.field4.thirdButton);
    }
    if (props.field5){
      setField5TXT(" - "+props.field5.label);
      setField5(props.field5.id);
    }
  }, []);

  const addElement = (event) => {
    handleOpen();
  };

  const saveObject = async (e) => {
    setLoading(true);
    try
    {
      var data = new FormData();
      if (props.field1) data.append(props.field1.field, field1.trim());
      if (props.field2) data.append(props.field2.field, field2.trim());
      if (props.fieldText) data.append(props.fieldText.field, fieldText.trim());
      if (props.field3) data.append(props.field3.field, field3);
      if (props.field5) data.append(props.field5.field, props.field5.id);
      if (props?.upd && props?.dataValueId) {
        data.append('_method', 'put');
        data.append('id', Number(props?.dataValueId));
      }


      //API DATA SENT
      var config = {
        url: props.api,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          "Content-Type": "multipart/form-data"
        },
        data: data,
      };
      const response = await axios(config);

      //RESET CACHE
      //company
      if (props.api == 'company'){
        localStorage.removeItem("company/list?company_role=Principals");
        localStorage.removeItem("company/list?company_role=Trader");
        localStorage.removeItem("company/list?company_role=Insured");
        localStorage.removeItem("company/list?company_role=Local Office");
        localStorage.removeItem("company/list?company_role=Receiver");
        localStorage.removeItem("company/list?company_role=Warehouse Keeper");
        localStorage.removeItem("company/list?company_role=Shipper");
        localStorage.removeItem("company/list?company_role=Stevedore");
      }else{
        if(typeof props.resetCache != "undefined" && props.resetCache != "0"){
          var getValue = "";
          if (props.parentApiParam?.length > 0) {
            getValue = "?"+props.parentApiParam+"="+props.parentApiParamValue;
          }
          localStorage.removeItem(props.parentApi+getValue);
          localStorage.removeItem(props.parentApi+getValue+"TTL");
        }
      }

      props.setNewElement(response.data.data, field1, field2, fieldText);

      handleClose();
      props.setSnackBarMessage(props.title.replace(" *", "") + " saved.");
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    }
    catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
    setLoading(false);
  }

  function handleClickSave(redirectAfterSave) {
    setErrorLabelMessage({
      field1: "",
      field2: "",
      field3: "",
      fieldText: ""
    });
    setErrorLabel({
      field1: false,
      field2: false,
      field3: false,
      fieldText: false
    });
    if(!props.field4){
      if (undefined != props.duplicate ){
        if (!Boolean(props.duplicate)&&props.datas){
          //Check Exists Data label
          for (var i=0; i<props.datas.length; i++){
            var existField = [];
            if (props.datas[i].labelValue.toUpperCase().trim() == field1.toUpperCase().trim()){
              existField[1] = "Already exists";
            }
            if (props.field2 && typeof props.field2.alreadyExist != "undefined" && props.field2.alreadyExist == true && props.datas[i].alias.toUpperCase().trim() == field2.toUpperCase().trim()){
              existField[2] = "Already exists";
            }
            if (props.fieldText && typeof props.fieldText.alreadyExist != "undefined" && props.fieldText.alreadyExist == true && props.datas[i].alias.toUpperCase().trim() == fieldText.toUpperCase().trim()){
              existField[3] = "Already exists";
            }
            if(existField.length > 0){
              setErrorLabelMessage({
                field1: (typeof existField[1] != "undefined" ? existField[1] : ""),
                field2: (typeof existField[2] != "undefined" ? existField[2] : ""),
                field3: "",
                fieldText: (typeof existField[3] != "undefined" ? existField[3] : "")
              });
              setErrorLabel({
                field1: (typeof existField[1] != "undefined" ? true : false),
                field2: (typeof existField[2] != "undefined" ? true : false),
                field3: false,
                fieldText: (typeof existField[3] != "undefined" ? true : false)
              });
              return;
            }
          }
          //Check Empty Data
          var emptyField = [];
          if (props.field1 && typeof props.field1.required != "undefined" && props.field1.required == true && field1.trim() == ""){
            emptyField[1] = "Required value";
          }
          if (props.field2 && typeof props.field2.required != "undefined" && props.field2.required == true && field2.trim() == ""){
            emptyField[2] = "Required value";
          }
          if (props.field3 && typeof props.field3.required != "undefined" && props.field3.required == true && (field3 == "0" || field3 == "" || typeof field3 == "undefined")){
            emptyField[3] = "Required value";
          }
          if (props.fieldText && typeof props.fieldText.required != "undefined" && props.fieldText.required == true && fieldText.trim() == ""){
            emptyField[4] = "Required value";
          }
          if(emptyField.length > 0){
            setErrorLabelMessage({
              field1: (typeof emptyField[1] != "undefined" ? emptyField[1] : ""),
              field2: (typeof emptyField[2] != "undefined" ? emptyField[2] : ""),
              field3: (typeof emptyField[3] != "undefined" ? emptyField[3] : ""),
              fieldText: (typeof emptyField[4] != "undefined" ? emptyField[4] : "")
            });
            setErrorLabel({
              field1: (typeof emptyField[1] != "undefined" ? true : false),
              field2: (typeof emptyField[2] != "undefined" ? true : false),
              field3: (typeof emptyField[3] != "undefined" ? true : false),
              fieldText: (typeof emptyField[4] != "undefined" ? true : false)
            });
            return;
          }
        }
      }
      saveObject();
    } else if(props.field4) {
      componentRef.current.saveComponent().then(({newElementId, newElementName}) => {
        props.setNewElement({id: newElementId, value: newElementId, name: newElementName, label: newElementName}, newElementName, newElementName);

        if (redirectAfterSave == true && field4.url) {
          var url = field4.url.replace('{id}', newElementId) + randomId();
          var newTab = window.open(url, "_blank");
          if(field4.focusElementId){
            newTab.onload = function() {newTab.document.getElementById(field4.focusElementId).scrollIntoView({ behavior: 'smooth' });}
          }
        }
        if (props.companySelectRefresh) {
          props.companySelectRefresh();
        }
        handleClose();
        props.setSnackBarMessage(props.title.replace(" *", "") + " saved.");
        props.setSnackBarType("success");
        props.setOpenSnackBar(true);
      });
    } 
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpenSnackBar(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };

  return (
    <div className={props.classType}>
      <IconButton disabled={props.disabled} onClick={(event) => {
        addElement(event);
      }}>
        {Boolean(props?.upd) && <EditIcon />}
        {Boolean(!props?.upd) && <AddCircleOutlineIcon />}
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        className={props.field4?.componentClass ? "modalPopin " + props.field4.componentClass : "modalPopin"}
      >
        <Box sx={props.field4?.componentClass ? {} : style} className='modalBox'>
          <div className={"headerPopin"}>Create {props.title.replace(" *", "")}{(props.field5?" - "+props.field5.label:"")}</div>
          <div className='contentPopin'>
            {Boolean(props.field1) && (
              <FormControl sx={{width:'95%', m: 2, marginBottom: 2, marginLeft: 1, marginRight: 0}} required={typeof props.field1.required != "undefined" ? props.field1.required : false}>
                <TextField label={props.field1.label} 
                  variant="standard" 
                  value={field1}  
                  onChange={onchageField1Handler}  
                  helperText={errorLabelMessage.field1} 
                  error={errorLabel.field1} 
                />
              </FormControl>
            )}
            {Boolean(props.field2) && (
              <FormControl sx={{width:'95%', m: 2, marginBottom: 2, marginLeft: 1, marginRight: 0}} required={typeof props.field2.required != "undefined" ? props.field2.required : false}>
                <TextField label={props.field2.label} 
                  variant="standard" value={field2}  
                  onChange={onchageField2Handler}  
                  helperText={errorLabelMessage.field2} 
                  error={errorLabel.field2} 
                  type={typeof props.field2.type != "undefined" ? props.field2.type : "text"} 
                />
              </FormControl>
            )}
            {Boolean(props.fieldText) && (
              <FormControl sx={{width:'95%', m: 2, marginBottom: 2, marginLeft: 1, marginRight: 0}} required={typeof props.fieldText.required != "undefined" ? props.fieldText.required : false}>
                <TextField label={props.fieldText.label} 
                  variant="standard" value={fieldText}  
                  onChange={onchageFieldTextHandler}  
                  helperText={errorLabelMessage.fieldText} 
                  error={errorLabel.fieldText} 
                  type={typeof props.fieldText.type != "undefined" ? props.fieldText.type : "text"} 
                />
              </FormControl>
            )}
            {Boolean(props.field3) && (
              <Field3Component
                title={props.field3.label}
                api={props.field3.api}
                apiParam=""
                apiParamValue=""
                dataValue={field3}
                defaultValue={field3}
                setDataValue={setField3}
                setDataAlias=""
                defaultAlias=""
                creator={createField3}
                size='large'
                cached={true}
                cacheTTL="600"
                error={errorLabel.field3}
                helperText={errorLabelMessage.field3} 
                required={typeof props.field3.required != "undefined" ? props.field3.required : false}
              />
            )}
            {Boolean(props.field4?.componentName === "CompanyForm") && (
             <CompanyForm
                ref={componentRef}
                update={0}
                idCompany={0}
                //SAVE BUTTON DISABLE CONTROL
                setComponentTreatmentNotValidated={setComponentTreatmentNotValidated}
                //PRECHECKED TYPES
                preChecked={props.field4.initValues}
                //POPIN STYLE
                popinStyle={true}
                //Snackbar
                setSnackBarMessage={props.setSnackBarMessage}
                setSnackBarType={props.setSnackBarType}
                setOpenSnackBar={props.setOpenSnackBar}
             />
            )}
          </div>
          <div className={"footerPopin"}>
              <Box textAlign="center" sx={{ '& > span > .MuiLoadingButton-root': { m: 1 } }}>
                <span>
                  <LoadingButton
                    onClick={handleClose}
                    loadingIndicator="Cancel"
                    variant="outlined"
                    loading={loading}
                  >
                    Cancel
                  </LoadingButton>
                </span>

                <Tooltip title={props.field4?.buttonDisableControls && componentTreatmentNotValidated ? "Some fields are empty or contain errors, please check all red fields before saving" : ""} placement="top">
                  <span>
                    <LoadingButton
                      onClick={handleClickSave}
                      loading={loading}
                      endIcon={<SaveIcon />}
                      loadingPosition="end"
                      variant="contained"
                      disabled={props.field4?.buttonDisableControls ?
                        componentTreatmentNotValidated
                        : false}
                    >
                      Save
                    </LoadingButton>
                  </span>
                </Tooltip>

                {Boolean(props.field4?.thirdButton) && (
                  <Tooltip title={props.field4?.buttonDisableControls && componentTreatmentNotValidated ? "Some fields are empty or contain errors, please check all red fields before saving" : ""} placement="top">
                    <span>
                      <LoadingButton
                        onClick={()=>handleClickSave(true)}
                        loading={loading}
                        endIcon={<SaveIcon />}
                        loadingPosition="end"
                        variant="contained"
                        disabled={props.field4?.buttonDisableControls ?
                          componentTreatmentNotValidated
                          : false}
                      >
                        {props.field4.thirdButton.label}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
export default AddElement
