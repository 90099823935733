import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MuiAlert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { GridLinkOperator } from '@mui/x-data-grid-pro';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import { ContextFilter } from "context/ContextFilter";
import { checkSecurity } from 'components/config/Security';
import FilterCheckBox from 'components/filter/FilterCheckBox'
import FilterStatusList from 'components/filter/FilterStatusList'
import { isCustomerRole, isPortRole } from 'components/config/Roles';


const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DashboardMainFilter = forwardRef((props, ref) => {
  //config
  const navigate = useNavigate();
  const JLBPortRole = isPortRole(localStorage.getItem("role"));
  const JLBCustomerRole = isCustomerRole(localStorage.getItem("role"));

  //context
  const { setRows } = useContext(ContextFilter);
  const { totalRows } = useContext(ContextFilter);
  const { vesselRows, setVesselRows } = useContext(ContextFilter);
  const { surveyRows, setSurveyRows } = useContext(ContextFilter);
  const { principalRows, setPrincipalRows } = useContext(ContextFilter);
  const { missionRows, setMissionRows } = useContext(ContextFilter);
  const { portRows, setPortRows } = useContext(ContextFilter);
  const { insuredRows, setInsuredRows } = useContext(ContextFilter);
  const { receiverRows, setReceiverRows } = useContext(ContextFilter);
  const { shipperRows, setShipperRows } = useContext(ContextFilter);
  const { contractNumberRows, setContractNumberRows } = useContext(ContextFilter);

  const filterName = props.filterName;
  const { setLoadingGrid } = useContext(ContextFilter);
  const { dataGridLoaded } = useContext(ContextFilter);
  const { setFilterModel } = useContext(ContextFilter);
  const { filterOn, setFilterOn } = useContext(ContextFilter);

  const { category, setCategory } = useContext(ContextFilter);
  const { searchTxt, setSearchTxt } = useContext(ContextFilter);
  const { searchTxtState, setSearchTxtState } = useContext(ContextFilter);

  const { filterDatas, setFilterDatas } = useContext(ContextFilter);
  const { filterStatusArray, setFilterStatusArray } = useContext(ContextFilter);

  //const
  const [survey, setSurvey] = useState('');
  const [principals, setPrincipals] = useState('');
  const [missionType, setMissionType] = useState('');
  const [port, setPort] = useState('');
  const [insured, setInsured] = useState('');
  const [receiver, setReceiver] = useState('');
  const [shipper, setShipper] = useState('');
  const [vessel, setVessel] = useState('');
  const [contractNumber, setContractNumber] = useState('');
  const [cancel, setCancel] = useState('');

  const [statusFilter1, setStatusFilter1] = useState(false);
  const [resetStatusFilter1, setResetStatusFilter1] = useState(false);
  const [statusFilter2, setStatusFilter2] = useState(false);
  const [resetStatusFilter2, setResetStatusFilter2] = useState(false);
  const [statusFilter3, setStatusFilter3] = useState(false);
  const [resetStatusFilter3, setResetStatusFilter3] = useState(false);
  const [statusFilter4, setStatusFilter4] = useState(false);
  const [resetStatusFilter4, setResetStatusFilter4] = useState(false);
  const [statusFilter5, setStatusFilter5] = useState(false);
  const [resetStatusFilter5, setResetStatusFilter5] = useState(false);
  const [statusFilter6, setStatusFilter6] = useState(false);
  const [resetStatusFilter6, setResetStatusFilter6] = useState(false);
  const [statusFilter7, setStatusFilter7] = useState(false);
  const [resetStatusFilter7, setResetStatusFilter7] = useState(false);
  const [statusFilter8, setStatusFilter8] = useState(false);
  const [resetStatusFilter8, setResetStatusFilter8] = useState(false);
  const [statusFilter9, setStatusFilter9] = useState(false);
  const [resetStatusFilter9, setResetStatusFilter9] = useState(false);
  const [statusFilter10, setStatusFilter10] = useState(false);
  const [resetStatusFilter10, setResetStatusFilter10] = useState(false);


  //useEffect
  useEffect(() => {
    checkSecurity(navigate, "DashboardList");
    var filter = JSON.parse(localStorage.getItem(filterName));

    if (filter) {
      //INIT DATAS Filter list
      setFilterDatas(filter.datas);

      //INIT FILTER Status
      if (filter.status) {
        filter.status.map((itemStatus) => {
          setFilterStatus(filter.status, itemStatus, true, true);
        });
      }

      //INIT SEARCH TXT CATEGORY
      if (filter.search) {
        setSearchTxt(filter.search);
        setCategory(filter.category);
      }
      resetFilterResetButton(filter.datas, filter.status, filter.search, filter.category);
    }
  }, []);
  

  //function
  useImperativeHandle(ref, () => ({
    initFilter(totalRowsParam) {
      initFilterMissions(totalRowsParam);
    },
  }));

  function initFilterMissions(totalRowsParam) {
    var filter = JSON.parse(localStorage.getItem(filterName));

    if (filter) {
      setSurvey(filter.datas.survey);
      setPrincipals(filter.datas.principals);
      setMissionType(filter.datas.missionType);
      setPort(filter.datas.port);
      setInsured(filter.datas?.insured);
      setReceiver(filter.datas?.receiver);
      setShipper(filter.datas?.shipper);
      setVessel(filter.datas?.vessel);
      setContractNumber(filter.datas?.contractNumber);
      setCancel(filter.datas?.cancel);

      selectDataContent(filter.datas, filter.status, filter.search, filter.category, totalRowsParam);
      setSearchTxtAction(filter.search, filter.category, filter.datas, filter.status);
    }
  }

  function setFilterSelectedLocalStorage(filterDataParam, filterStatusArray, searchTxt, category) {
    var filter = { datas: filterDataParam, status: filterStatusArray, search: searchTxt, category: category };
    localStorage.setItem(filterName, JSON.stringify(filter));
  }

  function selectDataContent(filterDataParam, filterStatusArray, searchTxtParam, categoryParam, totalRowsParam) {
    var searchTxtVar = searchTxt;
    var categoryVar = category;
    var totalRowsVar = totalRows;
    if (searchTxtParam || categoryParam || totalRowsParam) {
      searchTxtVar = searchTxtParam;
      categoryVar = categoryParam;
      totalRowsVar = totalRowsParam;
    }

    setLoadingGrid(true);
    var selectedRows = [];
    var addRow = true;
    for (var i = 0; i < totalRowsVar.length; i++) {
      addRow = true;
      if (filterDataParam.survey.length > 0 && totalRowsVar[i].survey_handler !== filterDataParam.survey) addRow = false;
      if (filterDataParam.principals.length > 0 && totalRowsVar[i].principals !== filterDataParam.principals) addRow = false;
      if (filterDataParam.missionType.length > 0 && totalRowsVar[i].missionTypeLabel !== filterDataParam.missionType) addRow = false;
      if (filterDataParam.port.length > 0 && totalRowsVar[i].port !== filterDataParam.port) addRow = false;
      if (filterDataParam.insured?.length > 0 && totalRowsVar[i].insured !== filterDataParam.insured) addRow = false;
      if (filterDataParam.receiver?.length > 0 && totalRowsVar[i].receiver.indexOf(filterDataParam.receiver) < 0) addRow = false;
      if (filterDataParam.shipper?.length > 0 && totalRowsVar[i].shipper !== filterDataParam.shipper) addRow = false;
      if (filterDataParam.vessel?.length > 0 && totalRowsVar[i].vessel !== filterDataParam.vessel) addRow = false;
      if (filterDataParam.contractNumber?.length > 0 && totalRowsVar[i].contractNumber !== filterDataParam.contractNumber) addRow = false;
      //if filter canceled = 'no' => we dont want the line
      if (filterDataParam.cancel?.length == 2 && !!totalRowsVar[i].missionCancelReason !== false) addRow = false;
      //if filter canceled = 'yes' => we want just the line
      if (filterDataParam.cancel?.length == 3 && !!totalRowsVar[i].missionCancelReason === false) addRow = false;

      //Status filter
      if (filterStatusArray.length > 0) {
        if (!filterStatusArray.includes(totalRowsVar[i].status.statut_type)) addRow = false;
      }

      if (addRow) selectedRows[selectedRows.length] = totalRowsVar[i];
    }

    setRows(selectedRows);
    setLoadingGrid(false);
    setFilterSelectedLocalStorage(filterDataParam, filterStatusArray, searchTxtVar, categoryVar);
    resetFilterResetButton(filterDataParam, filterStatusArray, searchTxtVar, categoryVar);
  }

  function setSearchTxtAction(searchTxtAct, cat, filterDatasParam, filterStatusArrayParam) {
    var filterDatasVar = filterDatas;
    if (filterDatasParam) filterDatasVar = filterDatasParam;
    var filterStatusArrayVar = filterStatusArray;
    if (filterStatusArrayParam) filterStatusArrayVar = filterStatusArrayParam;

    if (searchTxtAct.length > 0) {
      if (cat.length < 1) {
        setFilterModel({
          items: [
            { id: 1, columnField: "ref", operatorValue: 'contains', value: searchTxtAct, },
            { id: 2, columnField: "port", operatorValue: 'contains', value: searchTxtAct, },
            { id: 3, columnField: "principals", operatorValue: 'contains', value: searchTxtAct, },
            { id: 4, columnField: "principalsRef", operatorValue: 'contains', value: searchTxtAct, },
            { id: 5, columnField: "vessel", operatorValue: 'contains', value: searchTxtAct, },
            { id: 6, columnField: "b_o_l", operatorValue: 'contains', value: searchTxtAct, },
            { id: 7, columnField: "b_o_l_si", operatorValue: 'contains', value: searchTxtAct, },
            { id: 8, columnField: "warehouse", operatorValue: 'contains', value: searchTxtAct, },
            { id: 9, columnField: "receiverSearch", operatorValue: 'contains', value: searchTxtAct, },
            { id: 10, columnField: "cargo", operatorValue: 'contains', value: searchTxtAct, },
            { id: 11, columnField: "trader", operatorValue: 'contains', value: searchTxtAct, },
            { id: 12, columnField: "insured", operatorValue: 'contains', value: searchTxtAct, },
            { id: 13, columnField: "shipper", operatorValue: 'contains', value: searchTxtAct, },
            { id: 14, columnField: "localOffice", operatorValue: 'contains', value: searchTxtAct, },
            { id: 15, columnField: "typeOfIntervention", operatorValue: 'contains', value: searchTxtAct, },
            { id: 16, columnField: "contractNumber", operatorValue: 'contains', value: searchTxtAct, },
          ],
          linkOperator: GridLinkOperator.Or,
        });
      }
      else {
        const params = {};
        const items  = [{ id: 1, columnField: cat, operatorValue: 'contains', value: searchTxtAct, }];
        if (cat == 'b_o_l') {
          items.push({ id: 2, columnField: 'b_o_l_si', operatorValue: 'contains', value: searchTxtAct, });
          params.linkOperator = GridLinkOperator.Or;
        }
        params.items = items;

        setFilterModel(params);
      }
    }
    else {
      setFilterModel({ items: [] });
    }
    setFilterSelectedLocalStorage(filterDatasVar, filterStatusArrayVar, searchTxtAct, cat);
    resetFilterResetButton(filterDatasVar, filterStatusArrayVar, searchTxtAct, cat);
  }

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    setSearchTxtAction(searchTxt, event.target.value);
  };

  const handleChangeSearchText = (event) => {
    setSearchTxt(event.target.value);
    if (event.target.value.length > 0) {
      setSearchTxtState("");
      setSearchTxtAction(event.target.value, category);
    }
    else {
      if (event.target.value.length === 0) {
        setSearchTxtState("");
        setSearchTxtAction(event.target.value, category);
      }
      else {
        setSearchTxtState("warning");
      }
    }
  }

  //SETTER
  function createSurveyHandler(data) {
    return { id: data.id, alias: data.firstname + " " + data.lastname };
  }

  function createPrincipalsHandler(data) {
    return { id: data.id, alias: data.name };
  }

  function createMissionTypeHandler(data) {
    return { id: data.nomenclature, alias: data.label };
  }

  function createPortHandler(data) {
    return { id: data.nomenclature, alias: data.label };
  }

  function createInsuredHandler(data) {
    return { id: data.id, alias: data.name };
  }

  function createReceiverHandler(data) {
    return { id: data.id, alias: data.name };
  }

  function createShipperHandler(data) {
    return { id: data.id, alias: data.name };
  }

  function createVesselHandler(data) {
    return { id: data.id, alias: data.name };
  }

  function createContractNumberHandler(data) {
    return { id: data.id, alias: data.contractNumber };
  }

  function createCancelHandler(data) {
    return { id: data.id, alias: data.name };
  }

  //Filter
  function setSurveyHandler(data) {
    setSurvey(data);
    var filterData = { survey: data, principals: principals, missionType: missionType, port: port, insured: insured, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setPrincipalsHandler(data) {
    setPrincipals(data);
    var filterData = { survey: survey, principals: data, missionType: missionType, port: port, insured: insured, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setMissionTypeHandler(data) {
    setMissionType(data);
    var filterData = { survey: survey, principals: principals, missionType: data, port: port, insured: insured, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setPortHandler(data) {
    setPort(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: data, insured: insured, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setInsuredHandler(data) {
    setInsured(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: port, insured: data, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setReceiverHandler(data) {
    setReceiver(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: port, insured: insured, receiver: data, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setShipperHandler(data) {
    setShipper(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: port, insured: insured, receiver: receiver, shipper: data, vessel: vessel, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setVesselHandler(data) {
    setVessel(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: port, insured: insured, receiver: receiver, shipper: shipper, vessel: data, contractNumber: contractNumber, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setContractNumberHandler(data) {
    setContractNumber(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: port, insured: insured, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: data, cancel: cancel };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setCancelHandler(data) {
    setCancel(data);
    var filterData = { survey: survey, principals: principals, missionType: missionType, port: port, insured: insured, receiver: receiver, shipper: shipper, vessel: vessel, contractNumber: contractNumber, cancel: data };
    setFilterDatas(filterData);
    selectDataContent(filterData, filterStatusArray);
  }

  function setFilterStatus(filter, index, value, init) {
    setFilterStatusArray(filter);
    if (!init) selectDataContent(filterDatas, filter);
    if (index == 1) { setStatusFilter1(value); }
    else if (index == 2) { setStatusFilter2(value); }
    else if (index == 3) { setStatusFilter3(value); }
    else if (index == 4) { setStatusFilter4(value); }
    else if (index == 5) { setStatusFilter5(value); }
    else if (index == 6) { setStatusFilter6(value); }
    else if (index == 7) { setStatusFilter7(value); }
    else if (index == 8) { setStatusFilter8(value); }
    else if (index == 9) { setStatusFilter9(value); }
    else if (index == 10) { setStatusFilter10(value); }
  }

  function resetFilterResetButton(filterDatas, filter, searchTxt, category) {
    var showFilter = false;
    if (filter.length > 0) showFilter = true;
    if (filterDatas.survey !== ''
      || filterDatas.principals !== ''
      || filterDatas.missionType !== ''
      || filterDatas.port !== ''
      || filterDatas.insured !== ''
      || filterDatas.receiver !== ''
      || filterDatas.shipper !== ''
      || filterDatas.vessel
      || filterDatas.contractNumber !== ''
      || filterDatas.cancel) showFilter = true;
    if (searchTxt !== '') showFilter = true;
    if (category !== '') showFilter = true;

    setFilterOn(showFilter);
  }

  function handleResetAll() {
    setSurvey('');
    setPrincipals('');
    setMissionType('');
    setPort('');
    setInsured('');
    setReceiver('');
    setShipper('');
    setVessel('');
    setContractNumber('');
    setCancel('');

    setVesselRows([]);
    setSurveyRows([]);
    setPrincipalRows([]);
    setMissionRows([]);
    setPortRows([]);
    setInsuredRows([]);
    setReceiverRows([]);
    setShipperRows([]);
    setContractNumberRows([]);
    setFilterStatusArray([]);

    setCategory('');
    setSearchTxt('');
    setSearchTxtState("");

    setResetStatusFilter1(true);
    setStatusFilter1(false);
    setResetStatusFilter2(true);
    setStatusFilter2(false);
    setResetStatusFilter3(true);
    setStatusFilter3(false);
    setResetStatusFilter4(true);
    setStatusFilter4(false);
    setResetStatusFilter5(true);
    setStatusFilter5(false);
    setResetStatusFilter6(true);
    setStatusFilter6(false);
    setResetStatusFilter7(true);
    setStatusFilter7(false);
    setResetStatusFilter8(true);
    setStatusFilter8(false);
    setResetStatusFilter9(true);
    setStatusFilter9(false);
    setResetStatusFilter10(true);
    setStatusFilter10(false);

    var filterData = { survey: '', principals: '', missionType: '', port: '', insured: '', receiver: '', shipper: '', vessel: '', contractNumber: '', cancel: '' };
    setFilterDatas(filterData);
    setSearchTxtAction("", "");
    selectDataContent(filterData, []);
    setFilterOn(false);
    localStorage.setItem(filterName, null);
  }

  
  return (
    <div className="contentFilter">
      <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '99%' }} className="FilterSearchBlockField">
        <Box className='SearchBoxText' sx={{ width: '99%' }}>
          <SearchRoundedIcon sx={{ color: 'action.active' }} className='iconeLeft' />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300, width: '100%', paddingLeft: 3, paddingRight: 2 }}>
            <TextField id="input-search-with-sx" label="Search" variant="standard" value={searchTxt} fullWidth onChange={handleChangeSearchText} color={searchTxtState} />
          </FormControl>
        </Box>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }}>
          <InputLabel id="category-simple-select-standard-label">Category</InputLabel>
          <Select
            labelId="category-simple-select-standard-label"
            id="category-simple-select-standard"
            value={category}
            onChange={handleChangeCategory}
            label="Category"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            {props?.visibleCategories?.principalsRefCategory &&
              <MenuItem value="principalsRef">Your Ref.</MenuItem>
            }
            {props?.visibleCategories?.refJLBCategory &&
              <MenuItem value="ref">Ref. JLB</MenuItem>
            }
            {props?.visibleCategories?.portCategory &&
              <MenuItem value="port">Port</MenuItem>
            }
            {props?.visibleCategories?.principalsCategory &&
              <MenuItem value="principals">Principals</MenuItem>
            }
            {props?.visibleCategories?.vesselCategory &&
              <MenuItem value="vessel">Vessel</MenuItem>
            }
            {props?.visibleCategories?.billOfLadingCategory &&
              <MenuItem value="b_o_l">Bill of lading</MenuItem>
            }
            {props?.visibleCategories?.warehouseCategory &&
              <MenuItem value="warehouse">Warehouse</MenuItem>
            }
            {props?.visibleCategories?.receiverCategory &&
              <MenuItem value="receiver">Receiver</MenuItem>
            }
            {props?.visibleCategories?.cargoCategory &&
              <MenuItem value="cargo">Cargo</MenuItem>
            }
            {props?.visibleCategories?.traderCategory &&
              <MenuItem value="trader">Trader</MenuItem>
            }
            {props?.visibleCategories?.insuredCategory &&
              <MenuItem value="insured">Insured</MenuItem>
            }
            {props?.visibleCategories?.shipperCategory &&
              <MenuItem value="shipper">Shipper</MenuItem>
            }
            {props?.visibleCategories?.localOfficeCategory &&
              <MenuItem value="localOffice">Local office</MenuItem>
            }
            {props?.visibleCategories?.typeOfInterventionCategory &&
              <MenuItem value="typeOfIntervention">Type of intervention</MenuItem>
            }
            {props?.visibleCategories?.contractNumberCategory &&
              <MenuItem value="contractNumber">Contract number</MenuItem>
            }
          </Select>
        </FormControl>
        {props?.visibleFilters?.statusFilter && props.archivedList != 1 &&
          <FormControl variant="standard" sx={{ display: 'inline', m: 1, minWidth: 300 }}>
            <FilterStatusList
              setFilter={setFilterStatus}
              filterStatus={filterStatusArray}
              statusFilter={[
                statusFilter1,
                statusFilter2,
                statusFilter3,
                statusFilter4,
                statusFilter5,
                statusFilter6,
                statusFilter7,
                statusFilter8,
                statusFilter9,
                statusFilter10
              ]}
              statusFilterReset={[
                resetStatusFilter1,
                resetStatusFilter2,
                resetStatusFilter3,
                resetStatusFilter4,
                resetStatusFilter5,
                resetStatusFilter6,
                resetStatusFilter7,
                resetStatusFilter8,
                resetStatusFilter9,
                resetStatusFilter10
              ]}
              changeStatusFilter={[
                setResetStatusFilter1,
                setResetStatusFilter2,
                setResetStatusFilter3,
                setResetStatusFilter4,
                setResetStatusFilter5,
                setResetStatusFilter6,
                setResetStatusFilter7,
                setResetStatusFilter8,
                setResetStatusFilter9,
                setResetStatusFilter10
              ]}
              JLBInternalMode={!JLBCustomerRole}
              hiddenStatusFilters={JLBPortRole ? [10] : []}
            />
          </FormControl>
        }
        <div className={filterOn ? 'resetButtonFilter' : 'resetButtonFilter hide'} onClick={handleResetAll}><i className="bx bx-reset"></i><span>Reset all</span></div>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} className="FilterSearchBlockFieldBottom">
        {Boolean(dataGridLoaded) && props?.visibleFilters?.surveyHandlerFilter && (
          <FilterCheckBox
            title="Survey Handler"
            width="200"
            api="survey_handler/list"
            apiParam=""
            apiParamValue=""
            creator={createSurveyHandler}
            setValueOfFilterAndSearch={setSurveyHandler}
            dataValueHandler={survey}
            setDataValueHandler={setSurvey}
            datas={surveyRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.principalsFilter && (
          <FilterCheckBox
            title="Principals"
            width="200"
            api="company/list"
            apiParam="company_role"
            apiParamValue="Principals"
            creator={createPrincipalsHandler}
            setValueOfFilterAndSearch={setPrincipalsHandler}
            dataValueHandler={principals}
            setDataValueHandler={setPrincipals}
            datas={principalRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.missionTypeFilter && (
          <FilterCheckBox
            title="Mission type"
            width="200"
            api="mission_type/list"
            apiParam=""
            apiParamValue=""
            creator={createMissionTypeHandler}
            setValueOfFilterAndSearch={setMissionTypeHandler}
            dataValueHandler={missionType}
            setDataValueHandler={setMissionType}
            datas={missionRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.portFilter && (
          <FilterCheckBox
            title="Port"
            width="200"
            api="port/list"
            apiParam=""
            apiParamValue=""
            creator={createPortHandler}
            setValueOfFilterAndSearch={setPortHandler}
            dataValueHandler={port}
            setDataValueHandler={setPort}
            datas={portRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.insuredFilter && (
          <FilterCheckBox
            title="Insured"
            width="200"
            api="company/list"
            apiParam="company_role"
            apiParamValue="Insured"
            creator={createInsuredHandler}
            setValueOfFilterAndSearch={setInsuredHandler}
            dataValueHandler={insured}
            setDataValueHandler={setInsured}
            datas={insuredRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.receiverFilter && (
          <FilterCheckBox
            title="Receiver"
            width="200"
            api="company/list"
            apiParam="company_role"
            apiParamValue="Receiver"
            creator={createReceiverHandler}
            setValueOfFilterAndSearch={setReceiverHandler}
            dataValueHandler={receiver}
            setDataValueHandler={setReceiver}
            datas={receiverRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.shipperFilter && (
          <FilterCheckBox
            title="Shipper"
            width="200"
            api="company/list"
            apiParam="company_role"
            apiParamValue="Shipper"
            creator={createShipperHandler}
            setValueOfFilterAndSearch={setShipperHandler}
            dataValueHandler={shipper}
            setDataValueHandler={setShipper}
            datas={shipperRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.vesselFilter && (
          <FilterCheckBox
            title="Vessel"
            width="200"
            api="vessel/list"
            apiParam=""
            apiParamValue=""
            creator={createVesselHandler}
            setValueOfFilterAndSearch={setVesselHandler}
            dataValueHandler={vessel}
            setDataValueHandler={setVessel}
            datas={vesselRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.contractNumberFilter && (
          <FilterCheckBox
            title="Contract N°"
            width="200"
            api="si/list"
            apiParam=""
            apiParamValue=""
            creator={createContractNumberHandler}
            setValueOfFilterAndSearch={setContractNumberHandler}
            dataValueHandler={contractNumber}
            setDataValueHandler={setContractNumber}
            datas={contractNumberRows}
            loadByList='true'
          />
        )}

        {Boolean(dataGridLoaded) && props?.visibleFilters?.cancelFilter && (
          <FilterCheckBox
            title="Canceled"
            width="200"
            api=""
            apiParam=""
            apiParamValue=""
            creator={createCancelHandler}
            setValueOfFilterAndSearch={setCancelHandler}
            dataValueHandler={cancel}
            setDataValueHandler={setCancel}
            datas={[{ 'id': 0, 'name': 'No' }, { 'id': 1, 'name': 'Yes' }]}
            loadByList='true'
          />
        )}
      </Box>
    </div>
  )
})

DashboardMainFilter.propTypes = {
  visibleFilters: PropTypes.shape({
    surveyHandlerFilter: PropTypes.bool,
    principalsFilter: PropTypes.bool,
    missionTypeFilter: PropTypes.bool,
    portFilter: PropTypes.bool,
    insuredFilter: PropTypes.bool,
    receiverFilter: PropTypes.bool,
    shipperFilter: PropTypes.bool,
    vesselFilter: PropTypes.bool,
    statusFilter: PropTypes.bool,
    contractNumberFilter: PropTypes.bool,
    cancelFilter: PropTypes.bool,
  }),

  visibleCategories: PropTypes.shape({
    refJLBCategory: PropTypes.bool,
    portCategory: PropTypes.bool,
    principalsRefCategory: PropTypes.bool,
    principalsCategory: PropTypes.bool,
    vesselCategory: PropTypes.bool,
    billOfLadingCategory: PropTypes.bool,
    warehouseCategory: PropTypes.bool,
    receiverCategory: PropTypes.bool,
    cargoCategory: PropTypes.bool,
    traderCategory: PropTypes.bool,
    insuredCategory: PropTypes.bool,
    shipperCategory: PropTypes.bool,
    contractNumberCategory: PropTypes.bool,
  })
};

export default DashboardMainFilter;
