import React, { useState, forwardRef, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';

import SelectCustom from 'components/select/SelectCustom';
import DetailField from 'components/page/object/DetailField';
import { isCustomerRole } from 'components/config/Roles';
import ShippingInstructionGeneralInstructionList from './ShippingInstructionGeneralInstructionList';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";
import { ContextShippingInstructionGeneral } from "./ContextShippingInstructionGeneral";


const ShippingInstructionGeneralView = forwardRef((props, ref) => {

  //init config
  const { update } = useContext(ContextBlock);
  const { generalRef } = useContext(ContextShippingInstruction);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //si general data
  const { portDeparture, setPortDeparture } = useContext(ContextShippingInstructionGeneral);
  const { portDestination, setPortDestination } = useContext(ContextShippingInstructionGeneral);

  const { surveyHandler, setSurveyHandler } = useContext(ContextShippingInstructionGeneral);
  const { surveyHandlerDefault, setSurveyHandlerDefault } = useContext(ContextShippingInstructionGeneral);

  const { localOffice, setLocalOffice } = useContext(ContextShippingInstructionGeneral);
  const { principals, setPrincipals } = useContext(ContextShippingInstructionGeneral);

  const { trader, setTrader } = useContext(ContextShippingInstructionGeneral);
  const { shipper, setShipper } = useContext(ContextShippingInstructionGeneral);
  const { cargoType, setCargoType } = useContext(ContextShippingInstructionGeneral);

  const { cargoLoadingTypeId, setCargoLoadingTypeId } = useContext(ContextShippingInstructionGeneral);
  const { cargoLoadingTypeDefault, setCargoLoadingTypeDefault } = useContext(ContextShippingInstructionGeneral);

  const { tonnage, setTonnage } = useContext(ContextShippingInstructionGeneral);
  const { remainingTonnage, setRemainingTonnage } = useContext(ContextShippingInstructionGeneral);
  const { contractNumber, setContractNumber } = useContext(ContextShippingInstructionGeneral);

  //handle
  const { handleClickBlockEdit } = useContext(ContextBlock);

  //function interactive
  const { createSurveyHandlerHandler } = useContext(ContextShippingInstructionGeneral);
  const { createCargoLoadingTypeHandler } = useContext(ContextShippingInstructionGeneral);

  return (
    <div>
      <div className="lineContent noJustify">

        <DetailField title='Contract Number' content={contractNumber} />
        <DetailField title="Port Departure" content={portDeparture} size={!JLBCustomerMode ? '' : 'large'} />
        <DetailField title="Port Destination" content={portDestination} size={!JLBCustomerMode ? '' : 'large'} />

        <SelectCustom
          title="Survey handler"
          api="survey_handler/list"
          apiParam=""
          apiParamValue=""
          dataValue={surveyHandler}
          setDataValue={setSurveyHandler}
          defaultAlias=""
          defaultValue={surveyHandlerDefault}
          creator={createSurveyHandlerHandler}
          read={true}
          write={false}
          controlFieldsStatus={true}
        />

        {(!JLBCustomerMode) &&
          <DetailField title='Local office' content={localOffice} />
        }
      </div>
      <div className="lineContent noJustify">
        {(!JLBCustomerMode) &&
          <DetailField title='Principals' content={principals} />
        }
        {(!JLBCustomerMode) &&
          <DetailField title='Trader' content={trader} />
        }
        <DetailField title='Shipper' content={shipper} size={!JLBCustomerMode ? '' : 'large'} />
        <DetailField title='Cargo' content={cargoType} size={!JLBCustomerMode ? '' : 'large'} />

        <SelectCustom
          title="Type of Loading"
          api="cargo/loading_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={cargoLoadingTypeId}
          setDataValue={setCargoLoadingTypeId}
          defaultAlias=""
          defaultValue={cargoLoadingTypeDefault}
          creator={createCargoLoadingTypeHandler}
          read={true}
          write={false}
          controlFieldsStatus={true}
        />
      </div>
      <div className="lineContent noJustify">
        <DetailField title='Tonnage' content={Number(tonnage).toLocaleString()} />
        <DetailField title='Remaining tonnage' content={Number(remainingTonnage).toLocaleString()} />
      </div>

      <ShippingInstructionGeneralInstructionList editable={false} />

      {(update == 1 && loadingUpdate && !JLBCustomerMode) &&
        <div className="block_button">
          <LoadingButton className="edit" onClick={() => handleClickBlockEdit(generalRef)} loadingPosition="start" startIcon={<EditIcon />}> Edit</LoadingButton>
        </div>
      }
    </div>
  );
});

export default ShippingInstructionGeneralView;
