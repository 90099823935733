import React, { useContext } from 'react';

import { ContextMissionDetails } from "context/ContextMissionDetails";


const MissionDetailsHeaderCanceled = (props) => {

  //mission
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  if (missionCanceled) {
    return (
      <div className="header_canceled">
        <div className="canceled_title">Mission Canceled</div>
      </div>
    );
  }
};

export default MissionDetailsHeaderCanceled;
