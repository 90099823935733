import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AddCircle from '@mui/icons-material/AddCircle';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import FolderIcon from 'imgs/icon-folder.svg';
import ProtisPhoto from './MissionDetailsProtisPhoto';

const PdfRecursiveListItem = (props) => {

  const [medias, setMedias] = useState([]);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    if (props.currentFolderData?.containers) {
      setFolders(props.currentFolderData.containers)
    }
    if (!!props.withoutVideo !== false && props.currentFolderData?.photos) {
      setMedias(props.currentFolderData.photos)
    } else if (props.currentFolderData?.photos || props.currentFolderData.videos) {
      setMedias(props.currentFolderData.photos.concat(props.currentFolderData.videos))
    }
  }, [props?.currentFolderData]);

  const toggleFolder = async (folder) => {
    folders.forEach((row) => {
      if (row.id == folder.id) {
        if (folder.isOpen === undefined) {
          folder.isOpen = false;
        }
        row.isOpen = !folder.isOpen;
      }
    });
    setFolders(folders);
    props.setCurrentFolderId(folder.id);
  }

  const handleSelectedClick = (media) => {
    props.addMediaToSelected(media, media.id_mission_container);
  }

  const handleLoadedMedia = (media) => {
    var newMedias = medias.map((element) => {
      if (element.id == media.id) {
        return media;
      }
      return element;
    });
    setMedias(newMedias);
  }

  return (
    <>
      {folders.map((element, index) => (
        <Accordion key={`folder${index}`} className='accordionPdfModal'>
          <AccordionSummary sx={{ padding: '0px 5px', margin: '0px' }} onClick={(e) => toggleFolder(element)} className='photoPdfModal'>
            <div className='photoPdfModalColumnDiv' style={{ width: '60px', height: '60px' }}>
              <img src={FolderIcon} style={{ width: '40px', height: '40px', filter: 'brightness(0) invert(1)', margin: '0px 5px' }} />
            </div>
            <div className='photoPdfModalColumnDiv' style={{ width: '70%' }}>
              <div><b>{element.label}</b></div>
              <div className={element.isOpen ? 'photoPdfModalComment' : 'folderCommentShort'}>{element.comment}</div>
            </div>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0px 0px 0px 20px' }}>
            <PdfRecursiveListItem
              {...props}
              currentFolderData={element}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      {medias.map((element, index) => (
        <div key={`media${index}`} className='photoPdfModal'>
          <div className={`photoPdfModalColumnDiv ${element.isSelected ? 'selectedPhotoDiv' : ''}`}
            style={{ maxWidth: '30%' }}
            onClick={(e) => { handleSelectedClick(element) }}>
            {element.isPhoto ? ( 
              <ProtisPhoto
                {...props}
                element={element}
                width={60}
                height={60}
                loadPhoto={props.currentFolderId == element.id_mission_container}
                handleLoadedPhoto={handleLoadedMedia}
              />
            ) : (
              <PlayCircleOutlineIcon sx={{ width: '60px', height: '60px' }} fontSize={'large'} />
            )}
            {!element.isSelected && ((element.isPhoto && element.isLoaded) || element.isVideo) && <AddCircle className='photoPdfModalIcon' style={{ width: '40px', height: '40px' }} />}
          </div>
          <div className={`photoPdfModalColumnDiv ${element.isSelected ? 'selectedPhotoDiv' : ''}`} style={{ width: '70%' }}>
            <div><b>{element.type?.label}</b></div>
            <div className='photoPdfModalComment'><span>{element.comment}</span></div>
          </div>
        </div>
      ))}
    </>
  );
}

export default PdfRecursiveListItem;
