import React, { useState, useEffect, createContext, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";

import axios from 'api/axios';


const ContextShippingInstructionGeneral = createContext();

const ShippingInstructionGeneralProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { generalRef } = useContext(ContextShippingInstruction);
  const { getShippingInstruction } = useContext(ContextShippingInstruction);

  //API URL
  const SI_URL = 'shipping_instruction';
  const SI_INSTRUCTION_URL = SI_URL + '/si_instruction';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //init id si
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //si general state
  const { general, setGeneral } = useContext(ContextShippingInstruction);

  const [rowsSiInstruction, setRowsSiInstruction] = useState([]);
  const [rowsSiInstructionDeleted, setRowsSiInstructionDeleted] = useState([]);

  const [portDeparture, setPortDeparture] = useState('');
  const [portDepartureId, setPortDepartureId] = useState(0);
  const [portDepartureAlias, setPortDepartureAlias] = useState("YYY");
  const [portDepartureCountryId, setPortDepartureCountryId] = useState(0);

  const [portDestination, setPortDestination] = useState('');
  const [portDestinationId, setPortDestinationId] = useState(0);
  const [portDestinationAlias, setPortDestinationAlias] = useState("YYY");
  const [portDestinationCountryId, setPortDestinationCountryId] = useState(0);

  const { surveyHandler, setSurveyHandler } = useContext(ContextShippingInstruction);
  const [surveyHandlerDefault, setSurveyHandlerDefault] = useState(0);

  const [localOffice, setLocalOffice] = useState('');
  const [localOfficeId, setLocalOfficeId] = useState(0);

  const [principals, setPrincipals] = useState(null);
  const { principalsId, setPrincipalsId } = useContext(ContextShippingInstruction);
  const { principalsAlias, setPrincipalsAlias } = useContext(ContextShippingInstruction);

  const [trader, setTrader] = useState('');
  const [traderId, setTraderId] = useState(0);

  const [shipper, setShipper] = useState('');
  const [shipperId, setShipperId] = useState(0);

  const [cargoType, setCargoType] = useState('');
  const [cargoTypeId, setCargoTypeId] = useState(0);

  const [cargoLoadingTypeId, setCargoLoadingTypeId] = useState(0);
  const [cargoLoadingTypeDefault, setCargoLoadingTypeDefault] = useState(0);

  const [tonnage, setTonnage] = useState(0);
  const [ remainingTonnage, setRemainingTonnage ] = useState(0);
  const [ missionsVesselTonnage, setMissionsVesselTonnage ] = useState([]);
  const { contractNumber, setContractNumber } = useContext(ContextShippingInstruction);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const { saveBlock } = useContext(ContextBlockTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async (reload = true) => {
    handleClickBlockCancel(generalRef, reload);
  }

  //useEffect
  useEffect(() => {
    if (!(update == 1)) {
      //init default survey
      setSurveyHandlerDefault(localStorage.getItem("userID"));
      setSurveyHandler(localStorage.getItem("userID"));
    }
  }, []);

  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit = blockEdit.findIndex(obj => (obj.blockRef === generalRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        if (reloadData > oldReloadData) {
          setOldReloadData(reloadData);
        }

        //INIT Contract Number
        setContractNumber(general.contract_number);

        //INIT SURVEY HANDLER
        setSurveyHandler(general.survey_handler.id);

        //INIT PRINCIPALS
        setDatasPrincipals(general.principal.id, general.principal.name, general.principal.name);

        //INIT PORT
        if (general.departure_port) setDatasPortDeparture(general.departure_port.id, general.departure_port.label, general.departure_port.nomenclature, general.departure_port);
        else setDatasPortDeparture(0, '', '');
        if (general.destination_port) setDatasPortDestination(general.destination_port.id, general.destination_port.label, general.destination_port.nomenclature, general.destination_port);
        else setDatasPortDestination(0, '', '');

        //INIT Local Office
        //TODO label
        if (general.local_office) setDatasOffice(general.local_office.id, general.local_office.name, '');
        else setDatasOffice(0, '', '');

        //INIT Trader
        if (general.trader) setDatasTrader(general.trader.id, general.trader.name, '');
        else setDatasTrader(0, '', '');

        //INIT Shipper
        if (general.shipper) setDatasShipper(general.shipper.id, general.shipper.name, '');
        else setDatasShipper(0, '', '');

        //INIT Cargo Type
        if (general.cargo_type) setDatasCargoType(general.cargo_type.id, general.cargo_type.label, '');
        else setDatasCargoType(0, '', '');

        //INIT Cargo Loading Type
        if (general.cargo_loading_type) setCargoLoadingTypeId(general.cargo_loading_type.id);
        else setCargoLoadingTypeId(0);

        //INIT Tonnage
        if (general.tonnage) setTonnage(general.tonnage);
        else setTonnage(0);
        
        //INIT Missions Vessel Tonnage
        if (general.missions_vessel_tonnage) setMissionsVesselTonnage(general.missions_vessel_tonnage);
        else setMissionsVesselTonnage([]);
        
        //INIT Remaining Tonnage
        var totalMissionsTonnage = 0;
        general?.missions_vessel_tonnage?.forEach(mission => {
          if (mission?.vessel_tonnage) {
            totalMissionsTonnage += mission.vessel_tonnage;
          }
        });
        var siTonnage = 0;
        if (general.tonnage) {
          siTonnage = general.tonnage
        }
        setRemainingTonnage(siTonnage - totalMissionsTonnage);

        //INIT SI Instruction
        var rowsFromApi = [];
        if (general.si_instructions) {
          if (Object.keys(general.si_instructions).length > 0) {
            var i = 0;
            for (const [key, siIntruction] of Object.entries(general.si_instructions)) {
              rowsFromApi[i] = {
                id: siIntruction.id,
                id_shipping_instruction: siIntruction.id_shipping_instruction,
                id_instruction_type: siIntruction.instruction_type.id,
                id_place_of_operations: siIntruction.place_of_operations?.id,
                begining_date: siIntruction.begining_date,
                completion_date: siIntruction.completion_date,
                dhl: siIntruction.dhl,
                remark: siIntruction.remark
              };
              i++;
            }
          }
        }
        setRowsSiInstruction(rowsFromApi);
      }
    }
    else setRowsSiInstruction([]);
  }, [general, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(generalRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockEdit.findIndex(obj => obj.blockRef === generalRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlock(generalRef, saveShippingInstructionGeneral);
  }, [blockSave]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockSave.findIndex(obj => obj.blockRef === generalRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function interactive
  const processRowUpdateSiInstruction = (newRow) => {
    setRowsSiInstruction(rowsSiInstruction.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  }

  function createSurveyHandlerHandler(data) {
    return { id: data.id, title: data.firstname + " " + data.lastname, alias: "", labelValue: data.firstname + " " + data.lastname };
  }

  function createCargoLoadingTypeHandler(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }

  function setDatasPortDeparture(id, label, alias, data) {
    setPortDepartureId(id);
    setPortDeparture(label);
    setPortDepartureAlias(alias);
    if (!!data !== false) setPortDepartureCountryId(data.id_country);
  }

  function setDatasPortDestination(id, label, alias, data) {
    setPortDestinationId(id);
    setPortDestination(label);
    setPortDestinationAlias(alias);
    if (!!data !== false) setPortDestinationCountryId(data.id_country);
  }

  function setDatasOffice(id, label, alias) {
    setLocalOfficeId(id)
    setLocalOffice(label);
  }

  function setDatasPrincipals(id, label, alias) {
    setPrincipalsId(id)
    setPrincipals(label?.toUpperCase().trim());
    setPrincipalsAlias(alias);
  }

  function setDatasTrader(id, label, alias) {
    setTraderId(id)
    setTrader(label?.toUpperCase().trim());
  }

  function setDatasShipper(id, label, alias) {
    setShipperId(id)
    setShipper(label?.toUpperCase().trim());
  }

  function setDatasCargoType(id, label, alias) {
    setCargoTypeId(id)
    setCargoType(label?.toUpperCase().trim());
  }

  //function treatment
  const getDataSaveShippingInstructionGeneral = async () => {
    const data = {
      tonnage: Number(tonnage),
      contract_number: contractNumber,
      id_principal: Number(principalsId),
      id_survey_handler: Number(surveyHandler),
      id_trader: traderId ? Number(traderId) : null,
      id_shipper: shipperId ? Number(shipperId) : null,
      id_cargo_type: cargoTypeId ? Number(cargoTypeId) : null,
      id_local_office: localOfficeId ? Number(localOfficeId) : null,
      id_departure_port: portDepartureId ? Number(portDepartureId) : null,
      id_destination_port: portDestinationId ? Number(portDestinationId) : null,
      id_cargo_loading_type: cargoLoadingTypeId ? Number(cargoLoadingTypeId) : null
    };
    if (update == 1) data.id_shipping_instruction = Number(idShippingInstruction);

    return data;
  }

  const saveShippingInstructionGeneral = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      const config = {
        method: 'post',
        url: SI_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: await getDataSaveShippingInstructionGeneral()
      };

      if (update == 1) {
        config.method = 'put';
      }

      const response = await axios(config);

      //ID registration
      var idSI = idShippingInstruction;
      if (!(update == 1)) {
        idSI = response.data.data.id;
        setIdShippingInstruction(idSI);
      }

      //SI Instruction
      await saveSiInstruction(idSI);

      if (justBlock) {
        setSnackBarMessage("General Informations saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getShippingInstruction();
      } else {
        setDoneBlockSave(generalRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(generalRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }

  const saveSiInstruction = async (idSI) => {
    try {
      const saveSiInstructionPromises = [];
      //Saving SI Instruction
      for (var i = 0; i < rowsSiInstruction.length; i++) {
        const data = {
          dhl: rowsSiInstruction[i].dhl,
          remark: rowsSiInstruction[i].remark,
          id_shipping_instruction: Number(idSI),
          id_instruction_type: Number(rowsSiInstruction[i].id_instruction_type),
          id_place_of_operations: rowsSiInstruction[i].id_place_of_operations ? Number(rowsSiInstruction[i].id_place_of_operations) : null,
          begining_date: rowsSiInstruction[i].begining_date ? momentDate(rowsSiInstruction[i].begining_date).format("YYYY-MM-DD") : null,
          completion_date: rowsSiInstruction[i].completion_date ? momentDate(rowsSiInstruction[i].completion_date).format("YYYY-MM-DD") : null
        };

        var methode = 'post';
        if (rowsSiInstruction[i].id.toString().length < 16) {
          methode = 'put';
          data.id_si_instruction = Number(rowsSiInstruction[i].id);
        }

        const config = {
          url: SI_INSTRUCTION_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveSiInstructionPromises.push(response);

        if (rowsSiInstruction[i].id.toString().length > 16) {
          rowsSiInstruction[i].id = Number(response.data.data.id);
          processRowUpdateSiInstruction(rowsSiInstruction[i]);
        }
      }

      //Deleting SI Instruction
      for (var i = 0; i < rowsSiInstructionDeleted.length; i++) {
        const config = {
          url: SI_INSTRUCTION_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_si_instruction: Number(rowsSiInstructionDeleted[i].id) }
        };
        const response = await axios(config);
        saveSiInstructionPromises.push(response);
      }

      //end treatment
      setRowsSiInstructionDeleted([]);
      await Promise.all(saveSiInstructionPromises);
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <ContextShippingInstructionGeneral.Provider
      value=
      {{
        idShippingInstruction, setIdShippingInstruction,

        rowsSiInstruction, setRowsSiInstruction,
        rowsSiInstructionDeleted, setRowsSiInstructionDeleted,
        processRowUpdateSiInstruction,

        portDeparture, setPortDeparture,
        portDepartureId, setPortDepartureId,
        portDepartureAlias, setPortDepartureAlias,
        portDepartureCountryId, setPortDepartureCountryId,
        setDatasPortDeparture,

        portDestination, setPortDestination,
        portDestinationId, setPortDestinationId,
        portDestinationAlias, setPortDestinationAlias,
        portDestinationCountryId, setPortDestinationCountryId,
        setDatasPortDestination,

        surveyHandler, setSurveyHandler,
        surveyHandlerDefault, setSurveyHandlerDefault,
        createSurveyHandlerHandler,

        localOffice, setLocalOffice,
        localOfficeId, setLocalOfficeId,
        setDatasOffice,

        principals, setPrincipals,
        principalsId, setPrincipalsId,
        principalsAlias, setPrincipalsAlias,
        setDatasPrincipals,

        trader, setTrader,
        traderId, setTraderId,
        setDatasTrader,

        shipper, setShipper,
        shipperId, setShipperId,
        setDatasShipper,

        cargoType, setCargoType,
        cargoTypeId, setCargoTypeId,
        setDatasCargoType,

        cargoLoadingTypeId, setCargoLoadingTypeId,
        cargoLoadingTypeDefault, setCargoLoadingTypeDefault,
        createCargoLoadingTypeHandler,

        tonnage, setTonnage,
        missionsVesselTonnage, setMissionsVesselTonnage,
        remainingTonnage, setRemainingTonnage,
        contractNumber, setContractNumber,

        handleClickCancel,
        saveShippingInstructionGeneral
      }}
    >
      {children}
    </ContextShippingInstructionGeneral.Provider>
  );
};

export { ShippingInstructionGeneralProvider, ContextShippingInstructionGeneral };
