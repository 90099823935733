import React, { useEffect } from "react";
import { forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import { randomId } from "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom } from 'components/page/object/DataGridProCustom';

import "bootstrap/dist/css/bootstrap.css";

import axios from "api/axios";

const CountryForm = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [rowsCompanyCountries, setRowsCompanyCountries] = React.useState([]);
  const [companyCountries, setCompanyCountries] = React.useState([]);

  const GETCOMPANYCOUNTRIES_URL = "/country/list";

  useEffect(() => {
    loadingCompanyCountries();
  }, []);

  useEffect(() => {
    setRowsCompanyCountries(props.rows);
  }, [props.rows]);


  useImperativeHandle(ref, () => ({
    saveComponent(formData) {
      saveCountryForm(formData);
    },
  }));

  const loadingCompanyCountries = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var url = GETCOMPANYCOUNTRIES_URL;

      const response = await axios.get(url, config);
      const tempAllCompanyCountries = response.data.map((country) => ({
        id: country.id,
        value: country.label,
        label: country.label,
      }));

      tempAllCompanyCountries.sort((a, b) => (a.label > b.label ? 1 : -1));
      setCompanyCountries(tempAllCompanyCountries);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        console.log(err);
      }
    }
  };

  const saveCountryForm = async (formData) => {
    if (rowsCompanyCountries && rowsCompanyCountries.length > 0) {
      const tempRowsCountries = rowsCompanyCountries.map((row) => row.country);
      const tempRowsCountryIds = tempRowsCountries
        .map((tempCountry) => {
          const foundCountry = companyCountries.find(
            (country) => country.label === tempCountry
          );
          return foundCountry ? foundCountry.id : null;
        })
        .filter((id) => id !== null);

      for (var i = 0; i < tempRowsCountryIds.length; i++) {
        formData.append("countries[]", tempRowsCountryIds[i]);
      }
      return formData;
    }
    return formData;
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  function CustomNoRowsOverlay() {
    return <div className="customNoRowsOverlay"></div>;
  }

  var listColumnsCountries = [];

  listColumnsCountries.push(
    {
      field: "delete_column",
      headerName: "",
      editable: false,
      sortable: false,
      width: 10,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              deleteRowCompanyCountries(event, cellValues.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      sortable: true,
      flex: 1,
      type: "singleSelect",
      valueOptions: companyCountries,
      editable: true,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        if (cellValues.value === "" || cellValues.value == undefined)
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: "40px", width: "100%" }} />
            </Tooltip>
          );
      },
    }
  );

  const columnsCountries: GridColDef[] = listColumnsCountries;

  const processRowUpdateCountry = (newRow) => {
    setRowsCompanyCountries(
      rowsCompanyCountries.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const deleteRowCompanyCountries = (event, id) => {
    setRowsCompanyCountries(
      rowsCompanyCountries.filter((row) => row.id !== id)
    );
  };

  function EditToolbarCountry(props) {
    const { setRowsCompanyCountries } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsCompanyCountries((oldRows) => [
        ...oldRows,
        { id: id, delete_column: "", country: "" },
      ]);
    };
    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button
            className="iconAddButton"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add Country
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <FormControl
      sx={{ width: "100%", height: "10%" }}
      size="small"
      id="datagrid-country"
    >
      <DataGridPro
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdateCountry}
        experimentalFeatures={{ newEditingApi: true }}
        columns={columnsCountries}
        rows={rowsCompanyCountries}
        autoHeight
        hideFooter={true}
        componentsProps={{
          toolbar: { setRowsCompanyCountries },
        }}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: EditToolbarCountry,
        }}
      />
    </FormControl>
  );
});

export default CountryForm;
