import React, { useState, forwardRef, useContext, useEffect, GridRenderEditCellParams } from 'react';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import { randomId } from '@mui/x-data-grid-generator';

import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridTextCustom, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionMission } from "./ContextShippingInstructionMission";

import axios from 'api/axios';


const ShippingInstructionGeneralForm = forwardRef((props, ref) => {
  //init config
  const navigate = useNavigate();

  //generic
  const { setSnackErrorMsg } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //SI Instruction Grid
  const [rowsMissionDressingByIdMission, setRowsMissionDressingByIdMission] = useState([]);
  const { rowsMissionDressing, setRowsMissionDressing } = useContext(ContextShippingInstructionMission);
  const { rowsMissionDressingDeleted, setRowsMissionDressingDeleted } = useContext(ContextShippingInstructionMission);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //list dataGrid
  const [rowsDryBagsTypes, setRowsDryBagsTypes] = useState([]);
  const [rowsDryBagsBrands, setRowsDryBagsBrands] = useState([]);
  const [rowsDressingTypes, setRowsDressingTypes] = useState([]);
  const [rowsDressingBrands, setRowsDressingBrands] = useState([]);

  const [loadedDryBagsTypes, setLoadedDryBagsTypes] = useState(false);
  const [loadedDressingTypes, setLoadedDressingTypes] = useState(false);


  //useEffect
  useEffect(() => {
    loadDryBagsTypes();
    loadDryBagsBrands();
    loadDressingTypes();
    loadDressingBrands();

    //load mission dressing by id mission
    if (rowsMissionDressing) {
      if (rowsMissionDressing.length > 0) {
        setRowsMissionDressingByIdMission(rowsMissionDressing.filter(obj => Number(obj.id_mission) === Number(props.idMission)));
      }
    }
  }, []);

  useEffect(() => {
    //load mission dressing by id mission
    if (rowsMissionDressing) {
      if (rowsMissionDressing.length > 0) {
        setRowsMissionDressingByIdMission(rowsMissionDressing.filter(obj => Number(obj.id_mission) === Number(props.idMission)));
      }
    }
  }, [rowsMissionDressing]);


  //function interactive
  const { processRowUpdateMissionDressing } = useContext(ContextShippingInstructionMission);

  const loadDryBagsTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('si_dry_bags_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label, id_dry_bags_brand: response.data[i].dry_bags_brand.id };
      }
      setRowsDryBagsTypes(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadedDryBagsTypes(true);
    }
  }

  const loadDryBagsBrands = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('si_dry_bags_brand/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label };
      }
      setRowsDryBagsBrands(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadDressingTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('si_dressing_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label, id_dressing_brand: response.data[i].dressing_brand.id };
      }
      setRowsDressingTypes(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadedDressingTypes(true);
    }
  }

  const loadDressingBrands = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('si_dressing_brand/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label };
      }
      setRowsDressingBrands(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const setDryBagsType = (dryBagsBrandId, rowId) => {
    var dryBagsType = rowsDryBagsTypes.filter(obj => Number(obj.id_dry_bags_brand) === Number(dryBagsBrandId));

    var i = 0;
    var rowsTypes = [];
    if (dryBagsType?.length > 0) {
      dryBagsType.map((type, index) => {
        rowsTypes[i] = {
          value: type.value,
          label: type.label
        };
        i++;
      });
    }

    //check if current lot is in new list
    var rowData = rowsMissionDressing.find(obj => Number(obj.id) === Number(rowId));
    if (rowData && rowData.id_dry_bags_type != 0) {
      var type = rowsTypes.findIndex(obj => Number(obj.value) === Number(rowData.id_dry_bags_type));
      if (type < 0) {
        processRowUpdateMissionDressing({ ...rowData, id_dry_bags_type: 0 });
      }
    }

    return rowsTypes;
  }

  const setDressingType = (dressingBrandId, rowId) => {
    var dressingType = rowsDressingTypes.filter(obj => Number(obj.id_dressing_brand) === Number(dressingBrandId));

    var i = 0;
    var rowsTypes = [];
    if (dressingType?.length > 0) {
      dressingType.map((type, index) => {
        rowsTypes[i] = {
          value: type.value,
          label: type.label
        };
        i++;
      });
    }

    //check if current lot is in new list
    var rowData = rowsMissionDressing.find(obj => Number(obj.id) === Number(rowId));
    if (rowData && rowData.id_dressing_type != 0) {
      var type = rowsTypes.findIndex(obj => Number(obj.value) === Number(rowData.id_dressing_type));
      if (type < 0) {
        processRowUpdateMissionDressing({ ...rowData, id_dressing_type: 0 });
      }
    }

    return rowsTypes;
  }

  const deleteRowMissionDressing = (event, id) => {
    setRowsMissionDressing(rowsMissionDressing.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsMissionDressingDeleted((oldRows) => [...oldRows, { id: id }]);
  };

  function EditToolbarMissionDressing(props) {
    const { setRowsMissionDressing, idMission } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsMissionDressing((oldRows) => [...oldRows, { 
        id: id,
        id_mission: idMission,
        id_dry_bags_type: 0,
        id_dry_bags_brand: 0,
        id_dressing_type: 0,
        id_dressing_brand: 0,
        quantity: '' 
      }]);
    };

    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Mission Dressing
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }


  //xGrid
  var listColumnMissionDressing = [];
  //mode edit
  if (props.editable) {
    listColumnMissionDressing.push({
      field: 'id', headerName: '', editable: false, sortable: false, width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => { deleteRowMissionDressing(event, cellValues.id) }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnMissionDressing.push(
    { field: 'id_dry_bags_brand', headerName: 'Dry Bags Brand', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable, 
      valueOptions: rowsDryBagsBrands,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateMissionDressing}
          />
        )
      }
    },
    { field: 'id_dry_bags_type', headerName: 'Dry Bags Type', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable, 
      valueOptions: ({ row }) => {
        return !row || row.id_dry_bags_brand === 0 || !loadedDryBagsTypes ? [] : setDryBagsType(row.id_dry_bags_brand, row.id);
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateMissionDressing}
            error={params.value == 0 ? true : false}
          />
        )
      }
    },
    { field: 'quantity', headerName: 'Quantity', width: 250, sortable: true, flex: 1, editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            processRowUpdate={processRowUpdateMissionDressing}
          />
        )
      }
    },
    { field: 'id_dressing_brand', headerName: 'Dressing Brand', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable, 
      valueOptions: rowsDressingBrands,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateMissionDressing}
          />
        )
      }
    },
    { field: 'id_dressing_type', headerName: 'Dressing Type', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable, 
      valueOptions: ({ row }) => {
        return !row || row.id_dressing_brand === 0 || !loadedDressingTypes ? [] : setDressingType(row.id_dressing_brand, row.id);
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateMissionDressing}
            error={params.value == 0 ? true : false}
          />
        )
      }
    }
  );
  const columnsMissionDressing: GridColDef[] = listColumnMissionDressing;


  return (
    <div className="lineContent noJustify">
      <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-si-instruction">
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          hideFooter={true}
          rows={rowsMissionDressingByIdMission}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateMissionDressing}
          columns={columnsMissionDressing}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsMissionDressing, idMission: props.idMission },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: props.editable ? EditToolbarMissionDressing : null,
          }}
        />
      </FormControl>
    </div>
  );
});

export default ShippingInstructionGeneralForm;
