import React, { createContext, useContext, useEffect } from "react";

import { ContextBlock } from "./ContextBlock";


const ContextBlockTreatment = createContext({
  //generic
  idElement: 0,
  blockRedirect: () => {}, 
  blockGetData: () => { },
  //workflow
  canWrite: () => {},
  workflowLoaded: false,
  idButtonWorkflow: 0,
  setCheckWorkflowData: () => {},
  controlFieldsErrorTruncate: () => {}
});

const BlockTreatmentProvider = ({ children, value }) => {

  //init config
  const { update } = useContext(ContextBlock);

  const { 
    //generic
    idElement,
    blockRedirect, 
    blockGetData, 
    //workflow
    canWrite,
    workflowLoaded, 
    idButtonWorkflow, 
    setCheckWorkflowData,
    controlFieldsErrorTruncate 
  } = value;

  //init param
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);

  const { setCancelEdit } = useContext(ContextBlock);
  const { setResetSave } = useContext(ContextBlock);
  const { setStartSave } = useContext(ContextBlock);
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setDoneBlockMultiSave } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const { blockOnSave, setBlockOnSave } = useContext(ContextBlock);
  const { blockOrderSave, setBlockOrderSave } = useContext(ContextBlock);

  //init data save
  const { saveIsInError, setSaveIsInError } = useContext(ContextBlock);
  const { saveIsFinished, setSaveIsFinished } = useContext(ContextBlock);

  //handle button
  const handleClickCancel = async (reload = true) => {
    await controlFieldsErrorTruncate();
    setCancelEdit(reload);
  }


  //useEffect
  //init save mission
  useEffect(() => {
    async function checkBlockSaveInProgress() {
      if (blockOnSave && !saveIsFinished) {
        var saveInProgress = false;
        //validation workflow
        if (!!idButtonWorkflow !== false) {
          saveInProgress = true;
        } else {
          //general save then must have one block on edit
          blockSave.map((block) => {
            if (block.onSave.start === true) {
              if (blockEdit.findIndex(obj => (obj.blockRef === block.blockRef && obj.onEdit === true)) >= 0) {
                saveInProgress = true;
              }
            }
          });
        }
        
        if (saveInProgress === true) {
          //check if save of one block is in error
          var blockIsInError = blockSave.findIndex(obj => (obj.onSave.start === true && obj.onSave.error === true));
          var saveIsInError = blockIsInError >= 0 ? true : false;
          if (!saveIsInError) {
            //check if save is done for all block
            var saveIsDone = true;
            blockSave.map((block) => {
              //if block is available during creation (or update)
              if (update === 1 || (update !== 1 && block.allowCreation === true)) {
                saveIsDone = saveIsDone && (block.onSave.start === true && block.onSave.done === true);
              }
            });
            if (saveIsDone) {
              setSaveIsFinished(true);
            }
          } else {
            setSaveIsInError(true);
          }
        }
      }
    }
    checkBlockSaveInProgress();
  }, [blockSave, blockOnSave]);

  useEffect(() => {
    if (saveIsFinished) {
      async function checkBlockSaveFinished() {
        //FINALISATION
        setResetSave();

        //redirection if create mission
        if (!(update == 1) && idElement != 0) {
          blockRedirect(1);
        }

        setSnackBarMessage("Data saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        if (update == 1) {
          await blockGetData();
          setCancelEdit(false);
        }
        setLoading(false);
        setLoadingUpdate(true);
      }
      checkBlockSaveFinished();
    }
  }, [saveIsFinished]);

  useEffect(() => {
    if (saveIsInError) {
      async function checkBlockSaveInError() {
        //FINALISATION
        setResetSave();

        //redirection if create mission
        if (!(update == 1) && idElement != 0) {
          console.log('redirect error');
          blockRedirect(0);
        }

        setSnackBarMessage("Problem saving data.");
        setSnackBarType("error");
        setOpenSnackBar(true);
        //init false not valid workflow
        setCheckWorkflowData(false);
        //reload data api
        if (update == 1) {
          await blockGetData();
          setCancelEdit(false);
        }
        setLoading(false);
        setLoadingUpdate(true);
      }
      checkBlockSaveInError();
    }
  }, [saveIsInError]);


  //function treatment
  //block save
  async function saveBlock(blockRef, blockFuncSave, blockFuncCheck = null) {
    var blockIndex = blockSave.findIndex(obj => obj.blockRef === blockRef);
    
    if (blockIndex >= 0) {
      var blockOnSave = blockSave[blockIndex];

      if (blockOnSave.onSave.start === true && blockOnSave.onSave.done === false && blockOnSave.onSave.error === false) {
        if (blockOnSave.saveOrder == blockOrderSave) {
          var blockMultiRefs = [blockRef];
          var blockOnEditSave = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === true));
          
          if (blockOnEditSave >= 0) {
            await blockFuncSave();
          } else if (!!idButtonWorkflow != false && blockFuncCheck != null) {
            await blockFuncCheck();
            setDoneBlockSave(blockRef);
          } else {
            setDoneBlockSave(blockRef);
          }

          //jump to next block if the follow is not available at this step of workflow
          if (workflowLoaded) {
            var blockCurrent = blockOnSave;
            do {
              var blockAvailable = true;
              var blockNextOrder = blockSave.findIndex(obj => obj.saveOrder === (blockCurrent.saveOrder + 1));
              if (blockNextOrder >= 0) {
                blockCurrent = blockSave[blockNextOrder];
                if (!!blockCurrent.workflowLabel !== false) {
                  if (typeof blockCurrent.workflowLabel === 'string') {
                    blockAvailable = Boolean(canWrite(blockCurrent.workflowLabel));
                  } else {
                    var blockIsWrite = blockCurrent.workflowLabel.findIndex(label => canWrite(label));
                    blockAvailable = blockIsWrite >= 0 ? true : false;
                  }
                }
                //if not available, we jump
                if (!blockAvailable) {
                  setDoneBlockSave(blockCurrent.blockRef);
                  blockMultiRefs.push(blockCurrent.blockRef);
                }
              }
            } while (!blockAvailable);
          }

          //done multi block if necessary
          if (blockMultiRefs.length > 1) {
            setDoneBlockMultiSave(blockMultiRefs);
          }
        }
      }
    }
  }


  return (
    <ContextBlockTreatment.Provider
      value=
      {{
        //handle
        handleClickCancel,

        //treament
        saveBlock
      }}
    >
      {children}
    </ContextBlockTreatment.Provider>
  );
};

export { BlockTreatmentProvider, ContextBlockTreatment };