import React, { useEffect }  from "react";
import { useNavigate } from "react-router-dom";

import AnalyticsDashboardInfo from './AnalyticsDashboardInfo';
import ConnectionsLineChart from './Connections/ConnectionsLineChart';
import ConnectionsTableChart from './Connections/ConnectionsTableChart';
import LocalOfficeVisitsLineChart from './LocalOfficeVisits/LocalOfficeVisitsLineChart';
import LocalOfficeVisitsTableChart from './LocalOfficeVisits/LocalOfficeVisitsTableChart';
import TonnageTableChart from './Tonnage/TonnageTableChart';
import ArchivedMissionsFilesPieChart from './ArchivedMissionsFiles/ArchivedMissionsFilesPieChart';
import ArchivedMissionsFilesTableChart from './ArchivedMissionsFiles/ArchivedMissionsFilesTableChart';
import FieldsCompletionTableChart from './FieldsCompletion/FieldsCompletionTableChart';
import AnalyticsSnackBar from './AnalyticsSnackBar';
import { checkSecurity } from "../../config/Security";
import { AnalyticsProvider } from "../../../context/ContextAnalytics";
import Menu from "../../page/section/Menu";
import '../../../styles/Analytics.css';

const AnalyticsDashboard = () => {
    // CHECK SECURITY
    const navigate = useNavigate();
    
        useEffect(() => {
        checkSecurity(navigate, "AnalyticsDashboard");
    }, []);
    
        return (
        <div className="FULL-CONTENT DASHBOARD">
            <div className="content">
                <AnalyticsProvider>
                    <div className="lineInfoContainer">
                        {/* Users Connections Chart */}
                        <AnalyticsDashboardInfo
                            chartTypeButtons={[
                                { label: 'Line', value: 'line' },
                                { label: 'Table', value: 'table' },
                            ]}
                            defaultChartType="line"
                            chartTitle="Users Connections"
                            chartComponentMap={{
                                line: ConnectionsLineChart,
                                table: ConnectionsTableChart,
                            }}
                        />
                        {/* Find A Local Office Visits Chart */}
                        <AnalyticsDashboardInfo
                            chartTypeButtons={[
                                { label: 'Line', value: 'line' },
                                { label: 'Table', value: 'table' },
                            ]}
                            defaultChartType="line"
                            chartTitle="Find A Local Office Visits"
                            chartComponentMap={{
                                line: LocalOfficeVisitsLineChart,
                                table: LocalOfficeVisitsTableChart,
                            }}
                        />
                    </div>
                    <div className="lineInfoContainer">
                        {/* Tonnage Chart */}
                        <AnalyticsDashboardInfo
                            chartTypeButtons={[
                                { label: 'Table', value: 'table' },
                            ]}
                            defaultChartType="table"
                            chartTitle="Tonnage"
                            chartComponentMap={{
                                table: TonnageTableChart,
                            }}
                        />
                        {/* Archived Missions Files Chart */}
                        <AnalyticsDashboardInfo
                            chartTypeButtons={[
                                { label: 'Pie', value: 'pie' },
                                { label: 'Table', value: 'table' },
                            ]}
                            defaultChartType="pie"
                            chartTitle="Archived Missions Files"
                            chartComponentMap={{
                                pie: ArchivedMissionsFilesPieChart,
                                table: ArchivedMissionsFilesTableChart,
                            }}
                            hideCanceledMissions={true}
                        />
                    </div>
                    <div className="lineInfoContainer">
                        {/* Fields Completion */}
                        <AnalyticsDashboardInfo
                            chartTypeButtons={[
                                { label: 'Table', value: 'table' }
                            ]}
                            defaultChartType="table"
                            chartTitle="Fields Completion"
                            chartComponentMap={{
                                table: FieldsCompletionTableChart,
                            }}
                            missionStatusFilter={true}
                            missionTypeFilter={true}
                            hiddenMissionTypes={["COD"]}
                        />
                    </div>
                    <AnalyticsSnackBar/>
                </AnalyticsProvider>
            </div>
            <Menu />
                    </div>
    );
};

export default AnalyticsDashboard;