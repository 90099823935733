import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { isInternalRole } from 'components/config/Roles';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { MissionDetailsReceiverProvider } from "./ContextMissionDetailsReceiver";

import MissionDetailsReceiverForm from './MissionDetailsReceiverForm';
import MissionDetailsReceiverView from "./MissionDetailsReceiverView";
import MissionDetailsReceiverCRDispatch from "./Dispatch/MissionDetailsReceiverCRDispatch";


const MissionDetailsReceiver = forwardRef((props, ref) => {

  //config
  const { update } = useContext(ContextBlock);
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {controlFieldsReceiver, setControlFieldsReceiver} = useContext(ContextMissionWorkflow);
  const {toogleBlockOnEditAndCheckBlockOnWorkflowError} = useContext(ContextMissionWorkflow);

  //init receiver data
  const {receivers, setReceivers} = useContext(ContextMissionDetails);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit, controlFieldsReceiver, true);
  }, [blockEdit, controlFieldsError]);


  if (canWrite("receiver_block") || canRead("receiver_block")) {
    return (
      <MissionDetailsReceiverProvider>
        <div id="block_receiver" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
          <h4>Receivers</h4>

          {(!onEdit) ? <MissionDetailsReceiverView/> : <MissionDetailsReceiverForm/>}

          {(update == 1 && receivers.length > 0 && Boolean(canRead("export_block")) && JLBInternalMode) && 
          <MissionDetailsReceiverCRDispatch
            countReceivers={receivers.length}
          />
          }
        </div>
      </MissionDetailsReceiverProvider>
    );
  }
});

export default MissionDetailsReceiver;
