import React, { forwardRef, useContext, useState } from 'react';
import { Stack} from "react-bootstrap";

import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { isCustomerRole } from 'components/config/Roles';
import {format2Decimals} from 'components/page/object/Utils';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsReceiver } from "./ContextMissionDetailsReceiver";


const MissionDetailsReceiverView = forwardRef((props, ref) => {

  //init config
  const {update} = useContext(ContextBlock);
  const {receiverRef} = useContext(ContextMissionDetails);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //workflow state
  const { canRead } = useContext(ContextMissionWorkflow);

  //mission
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  //init receiver data
  const { rowsReceiverView, setRowsReceiverView } = useContext(ContextMissionDetailsReceiver);

  //context
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //handle
  const { handleClickBlockEdit } = useContext(ContextBlock);

  //xGrid
  const columnsReceiver: GridColDef[] = [];

  if (canRead("id_receiver")) {
    columnsReceiver.push({ field: 'receiver', headerName: 'Receiver', editable: false, sortable: true, width: 200, flex: 1 })
  }
  if (canRead("r.tonnage")) {
    columnsReceiver.push({ field: 'tonnage', headerName: 'Tonnage', type: 'number', editable: false, width: 200, flex: 1, sortable: true })
  }
  if (canRead("id_cargo")) {
    columnsReceiver.push({ field: 'cargo', headerName: 'Cargo', editable: false, width: 200, flex: 1, sortable: true })
  }
  if (canRead("id_mission_ending")) {
    columnsReceiver.push({ field: 'mission_ending', headerName: 'Mission ending', editable: false, width: 200, flex: 1, sortable: true })
  }
  const renderReceiversBL = (props) => {
    var txt = "";
    var prefix = "";

    for (var i = 0; i < props.row.bills_of_lading.length; i++) {
      txt = txt + prefix + props.row.bills_of_lading[i].number;
      prefix = "\n\r";
    }

    if (props.row.bills_of_lading.length > 0) {
      return (
        <Tooltip
          title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
          placement="right">
          <IconButton>
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
      );
    }
    else return (<></>);
  };
  const renderReceiversCompanies = (props) => {
    var txt = "";
    var prefix = "";

    for (var i = 0; i < props.row?.companies.length; i++) {
      txt = txt + prefix + props.row?.companies[i].name;
      prefix = "\n\r";
    }

    if (props.row.companies.length > 0) {
      return (
        <Tooltip
          title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
          placement="right">
          <IconButton>
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
      );
    }
    else return (<></>);
  };
  const getReceiverDetailsHeight = React.useCallback(() => 250, []);
  const getReceiversDetailPanelContent = (rowProp) => {
    var data = rowProp.row;
    var lots = data.lots.map((lot) => {
      if (lot.loading_type) lot = { ...lot, loading_type: lot.loading_type.label };
      if (lot.brand) lot = { ...lot, brand: lot.brand.name };
      if (lot.packaging) lot = { ...lot, packaging_type: lot.packaging.size };
      if (lot.packaging) lot = { ...lot, net_weight: lot.packaging.net_weight };
      if (lot.packaging_quantity) lot = { ...lot, quantity: lot.packaging_quantity };
      if (lot.origin) lot = { ...lot, origin: lot.origin.label };
      if (lot.quality) lot = { ...lot, quality: lot.quality.label };
      if (lot.variety) lot = { ...lot, variety: lot.variety.label };
      if (lot.packaging_quantity && lot.gross_tonnage) lot = { ...lot, gross_weight: format2Decimals(lot.gross_tonnage / lot.packaging_quantity) };
      if (lot.packaging_quantity && lot.empty_spare_packaging_percentage) lot = { ...lot, empty_spare_packaging_value: format2Decimals(lot.empty_spare_packaging_percentage * lot.packaging_quantity / 100) };
      if (lot.empty_spare_packaging_percentage) lot = { ...lot, empty_spare_packaging_percentage: lot.empty_spare_packaging_percentage + "%" };

      return lot;
    });

    var columns = [];

    if (canRead("id_loading_type")) {
      columns.push({ field: 'loading_type', headerName: 'Loading type', flex: 1, editable: false, width: 200, sortable: true })
    }
    if (canRead("id_cargo_brand")) {
      columns.push({ field: 'brand', headerName: 'Brand', flex: 1, editable: false, width: 200, sortable: true })
    }
    if (canRead("id_packaging")) {
      columns.push({ field: 'packaging_type', headerName: 'Packaging', flex: 1, editable: false, width: 200, sortable: true })
    }
    if (canRead("packaging_net_weight")) {
      columns.push({ field: 'net_weight', headerName: 'Net weight', flex: 1, editable: false, width: 100, sortable: false })
    }
    if (canRead("packaging_quantity")) {
      columns.push({ field: 'quantity', headerName: 'Quantity', flex: 1, editable: false, width: 150, sortable: false })
    }
    if (canRead("net_tonnage")) {
      columns.push({ field: 'net_tonnage', headerName: 'Net tonnage', flex: 1, editable: false, width: 150, sortable: false })
    }
    if (canRead("gross_tonnage")) {
      columns.push({ field: 'gross_tonnage', headerName: 'Gross tonnage', flex: 1, editable: false, width: 150, sortable: false })
    }
    if (canRead("id_cargo_origin")) {
      columns.push({ field: 'origin', headerName: 'Origin', flex: 1, editable: false, width: 200, sortable: true })
    }
    if (canRead("id_cargo_quality")) {
      columns.push({ field: 'quality', headerName: 'Quality', flex: 1, editable: false, width: 200, sortable: true })
    }
    if (canRead("id_cargo_variety")) {
      columns.push({ field: 'variety', headerName: 'Variety', flex: 1, editable: false, width: 200, sortable: true })
    }
    if (canRead("bills_of_lading")) {
      columns.push({ field: 'bills_of_lading', headerName: 'Bills of lading', flex: 1, width: 200, sortable: true, renderCell: renderReceiversBL })
    }
    if (canRead("companies_stevedore")) {
      columns.push({ field: 'companies', headerName: 'Stevedore', flex: 1, width: 200, sortable: true, renderCell: renderReceiversCompanies })
    }
    return (
      <Stack className="datagridLot">
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          density="compact"
          columns={columns}
          rows={lots}
          sx={{ flex: 1 }}
          hideFooter
        />
      </Stack>
    );
  }


  return (
    <div className="lineContent">
      <FormControl sx={{ m: 1, width: '100%', height: 1 }} size="small" id="datagrid-receivers">
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          rows={rowsReceiverView}
          columns={columnsReceiver}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          getDetailPanelHeight={getReceiverDetailsHeight}
          getDetailPanelContent={getReceiversDetailPanelContent}
          hideFooter
        />
      </FormControl>

      {(update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode) && 
      <div className="block_button">
        <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(receiverRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
      </div>
      }
    </div>
  );
});

export default MissionDetailsReceiverView;
