import React, { useState, useEffect, useContext } from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { Pie } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ContextAnalytics } from "../../../../context/ContextAnalytics";
import '../../../../styles/Analytics.css';

const ArchivedMissionsFilesPieChart = (props) => {
    
    const { cubejsApi } = useContext(ContextAnalytics);
    const { openSnackBar, setOpenSnackBar } = useContext(ContextAnalytics);
    const { snackBarType, setSnackBarType } = useContext(ContextAnalytics);
    const { snackBarMessage, setSnackBarMessage } = useContext(ContextAnalytics);
    const { setSnackErrorMsg } = useContext(ContextAnalytics);
    
    const [ secondaryResultSet, setSecondaryResultSet] = useState(null);
    const [ totalCount, setTotalCount ] = useState(0);

    const COLORS_SERIES = [
        '#0288d1', // For the part that represents missions with all mandatory files
        '#c23f38', // For the part that represents missions without all mandatory files
    ];
    
    // Fetchs secondary data for the pie chart
    useEffect(() => {
        const fetchData = async () => {
            if (props.formattedQueryStartDate && props.formattedQueryEndDate) {
                const query = {
                    segments: [
                        "mission.archived_missions",
                        "mission.missions_without_all_mandatory_files"
                    ],
                    timeDimensions: [
                        {
                            dimension: "mission.created_at",
                            dateRange: [props.formattedQueryStartDate, props.formattedQueryEndDate],
                        },
                    ],
                    measures: ["mission.total_count"],
                    filters: []
                };
                
                if (props?.hideCanceledMissions) {
                    query.filters.push({
                        member: "mission.is_canceled",
                        operator: "equals",
                        values: ["false" ]
                    })
                }
    
                try {
                    const resultSetSecondary = await cubejsApi.load(query);
                    if (resultSetSecondary) {
                        setSecondaryResultSet(resultSetSecondary);
                    }
                } catch (error) {
                    var errorMsg = (!!error.response != false ? setSnackErrorMsg(error.response.data) : "");
                    setSnackBarMessage(errorMsg);
                    setSnackBarType("error");
                    setOpenSnackBar(true);
                    console.log(error);
                }
            }
        };
    
        fetchData();
    }, [props.formattedQueryStartDate, props.formattedQueryEndDate, props?.hideCanceledMissions]);
    
    // Custom tooltips plugin
    const customTooltips = (tooltipModel) => {
        const tooltipInfo = tooltipModel.dataset.data[tooltipModel.dataIndex];
        const percentage = ((tooltipInfo/totalCount) * 100).toFixed(2) + ' %';
        const outputArray = [`Total Count : ${tooltipInfo} (${percentage})`];
        
        return outputArray.flat();
    };
    
    // Chart options
    const commonOptions = {
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: () => '',
                    afterLabel: customTooltips,
                },
            },
            datalabels: {
                color: '#f2f2f2',
                formatter: function(value, context) {
                    if (totalCount && ((value / totalCount) * 100).toFixed(2) > 5) {
                        return ((value / totalCount) * 100).toFixed(2) + ' %';
                    }
                    return null;
                },
                labels: {
                    title: {
                      font: {
                        weight: 'bold'
                      }
                    },
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false
                },
                border:{
                    display:false
                }
            },
            y: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false
                },
                border:{
                    display:false
                }
            },
        }
    };

    // Called on tooltip rendering
    const useDrilldownCallback = ({ datasets, labels, onDrilldownRequested, pivotConfig }) => {
        return React.useCallback(
            (elements) => {
                if (elements.length <= 0) return;
                const { datasetIndex, index } = elements[0];
                const { yValues } = datasets[datasetIndex];
                const xValues = [labels[index]];

                if (typeof onDrilldownRequested === 'function') {
                    onDrilldownRequested(
                        {
                            xValues,
                            yValues,
                        },
                        pivotConfig
                    );
                }
            },
            [datasets, labels, onDrilldownRequested]
        );
    };

    // Renders a pie based on resultSet and secondaryResultSet
    const PieChartRenderer = ({ resultSet, secondaryResultSet, pivotConfig, onDrilldownRequested }) => {
        let combinedData = null;

        if (resultSet && secondaryResultSet) {
            // Contains total of missions will all mandatory files
            const resultSetData = resultSet.series(pivotConfig).map((s) => s.series.map((r) => r.value));
            // Contains total of missions without all mandatory files
            const secondaryResultSetData = secondaryResultSet.series(pivotConfig).map((s) => s.series.map((r) => r.value));
    
            combinedData = [];
    
            resultSetData.forEach((value) => {
                combinedData.push(value);
            });
    
            secondaryResultSetData.forEach((value) => {
                combinedData.push(value);
            });
        }
        
        useEffect(() => {
            if (combinedData) {
                const total = combinedData.flat().reduce((acc, curr) => acc + curr, 0);
                setTotalCount(total);
            }
        }, [combinedData]);
        
        const data = {
            labels: ['Missions With All Mandatory Files', 'Missions With Missing Mandatory Files'],
            datasets: [{
                label: 'Total Count ',
                data: combinedData,
                backgroundColor: COLORS_SERIES,
                hoverBackgroundColor: COLORS_SERIES,
            }]
        }
    
        const getElementAtEvent = useDrilldownCallback({
            datasets: data.datasets,
            labels: data.labels,
            pivotConfig,
            onDrilldownRequested,
        });
            return (
                (totalCount > 0 &&
                    <Pie
                        type="pie"
                        data={data}
                        options={commonOptions}
                        getElementAtEvent={getElementAtEvent}
                        plugins={[ ChartDataLabels ]}
                    />
                )
            );
    };

    // Renders a pie or loading in progress
    const renderChart = ({ resultSet, error, pivotConfig, onDrilldownRequested }) => {

        return (
            resultSet ? (
                <PieChartRenderer
                    resultSet={resultSet}
                    secondaryResultSet={secondaryResultSet}
                    pivotConfig={pivotConfig}
                    onDrilldownRequested={onDrilldownRequested}
                />
            ) : (
                <div className="loadingChartContainer">
                    <CircularProgress />
                </div>
            )
        );
    };

    return (
        <div className="chartPieContainer">
            {props.formattedQueryStartDate && props.formattedQueryEndDate && (
                // CubeJS query
                <QueryRenderer
                    query={{
                        limit: 5000,
                        segments: [
                            "mission.archived_missions",
                            "mission.missions_with_all_mandatory_files"
                        ],
                        timeDimensions: [
                            {
                                dimension: "mission.created_at",
                                dateRange: [props.formattedQueryStartDate, props.formattedQueryEndDate],
                            },
                        ],
                        measures: ["mission.total_count"],
                        filters: props?.hideCanceledMissions ?
                            [{
                                member: "mission.is_canceled",
                                operator: "equals",
                                values: ["false" ]
                            }]
                        : 
                            []
                    }}
                    cubejsApi={cubejsApi}
                    resetResultSetOnChange={false}
                    render={(props) =>
                        renderChart({
                            ...props,
                            chartType: "pie",
                            pivotConfig: {
                                x: [],
                                y: ["measures"],
                                fillMissingDates: true,
                                joinDateRange: false,
                            },
                        })
                    }
                />
            )}
        </div>
    );
};

export default ArchivedMissionsFilesPieChart;
