import React, { useState, useContext } from 'react';

import ConfirmDialog from 'components/page/object/ConfirmDialog';

import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionMission } from "../Mission/ContextShippingInstructionMission";


const ShippingInstructionCreateMissionPopin = (props) => {

  //SI
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);
  const { createMission } = useContext(ContextShippingInstructionMission);


  //function interactive
  const handleCreateMission = async () => {
    props.setOpen(false);
    await createMission();
  };


  return (
    <ConfirmDialog
      title="Create a new Mission from SI ?"
      notClose={true}
      open={props.open}
      setOpen={props.setOpen}
      onConfirm={async () => { await handleCreateMission() }}
    >
      <div className="lineContent">
        Are you sure you want to create a new mission from Shipping Instructions ?
      </div>
    </ConfirmDialog>
  );
};

export default ShippingInstructionCreateMissionPopin;