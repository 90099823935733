import React, { useEffect, useState } from 'react';


const useMissionDetailsProtisModalLogic = ( props ) => {
  
  //commun
  const [elmtLoading, setElmtLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedMedias, setSelectedMedias] = useState([]);
  const [protisFullTree, setProtisFullTree] = useState([]);
  const [groupByFolder, setGroupByFolder] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [protisFullData, setProtisFullData] = useState({ containers: [], photos: [], videos: [] });
  //pdf
  const [showMissionComment, setShowMissionComment] = useState(true);
  const [showFoldersComment, setShowFoldersComment] = useState(true);
  const [showPhotoType, setShowPhotoType] = useState(true);
  const [showPhotoDate, setShowPhotoDate] = useState(true);
  const [showPhotoComment, setShowPhotoComment] = useState(true);

  const {
    protisData,
    withoutVideo
  } = props;


  useEffect(() => {
    if (protisData) {
      prepareFullTree(protisData, !!withoutVideo !== false);
    }
  }, [protisData]);

  useEffect(() => {
    selectedMedias.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
  }, [selectedMedias]);

  useEffect(() => {
    selectedFolders.forEach((folder) => {
      folder.photos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
      folder.videos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    });
  }, [selectedFolders]);


  const handleResetClick = () => {
    resetForm();
  }

  const handleSelectAllClick = () => {
    selectAll();
  }

  const selectAll = () => {
    const fullData = protisFullData;
    const allPhotos = fullData.photos?.length > 0 ? fullData.photos : [];
    const allVideos = fullData.videos?.length > 0 ? fullData.videos : [];
    fullData.containers?.forEach((row) => {
      if (row.photos?.length > 0) {
        allPhotos.concat(row.photos);
      }
      if (row.videos?.length > 0) {
        allVideos.concat(row.videos);
      }
    });
    
    var allData = allPhotos;
    if (!!withoutVideo !== true) allData = allPhotos.concat(allVideos);
    addMediasToSelected(allData);
  }

  const resetForm = () => {

    //pdf
    setShowMissionComment(false);
    setGroupByFolder(false);
    setShowFoldersComment(false);
    setShowPhotoType(false);
    setShowPhotoDate(false);
    setShowPhotoComment(false);
    //commun
    setSelectedFolders([]);
    setSelectedMedias([]);

    var fullData = protisFullData;
    fullData.photos?.forEach((row) => {
      row.isSelected = false;
      delete row.selectOrder;
    });
    fullData.videos?.forEach((row) => {
      row.isSelected = false;
      delete row.selectOrder;
    });
    fullData.containers?.forEach((row) => {
      row.isSelected = false;
      delete row.selectOrder;
      row.photos.forEach((photo) => {
        photo.isSelected = false;
        delete photo.selectOrder;
      });
      row.videos.forEach((video) => {
        video.isSelected = false;
        delete video.selectOrder;
      });
    });
    prepareFullTree(fullData, !!withoutVideo !== false);
  }

  const prepareFullTree = (data, withoutVideo) => {
    const containers = data.containers.map((rowContainer) => {
      rowContainer.photos = data.photos.filter((rowPhoto) => rowPhoto.id_mission_container == rowContainer.id);
      if (!withoutVideo)
        rowContainer.videos = data.videos.filter((rowPhoto) => rowPhoto.id_mission_container == rowContainer.id);
      rowContainer.containers = data.containers.filter((child) => child.id_mission_container_master == rowContainer.id);
      return rowContainer;
    });
    const rootPhotos = data.photos.filter((rowPhoto) => rowPhoto.id_mission_container == null);
    const fullTree = {
      containers: containers.filter((container) => container.id_mission_container_master == null),
      photos: rootPhotos
    };
    if (!withoutVideo) {
      const rootVideos = data.videos.filter((rowVideo) => rowVideo.id_mission_container == null);
      fullTree.videos = rootVideos;
    }
    setProtisFullTree(fullTree);
    setProtisFullData(protisData);
  }

  const addMediaToSelected = (media, folderId) => {
    //photo
    var selectedMedia;
    const selectedPhoto = protisFullData.photos.find((row) => media.id == row.id);
    if (selectedPhoto) {
      selectedPhoto.isSelected = true;
      selectedPhoto.selectOrder = selectedMedias.filter((elmt) => elmt.isPhoto).length;
      selectedMedia = selectedPhoto;
    }
    //video
    const selectedVideo = protisFullData.videos.find((row) => media.id == row.id);
    if (selectedVideo) {
      selectedVideo.isSelected = true;
      selectedVideo.selectOrder = selectedMedias.filter((elmt) => elmt.isVideo).length;
      selectedMedia = selectedVideo;
    }
    //Root case => add to selectedPhotos
    if ((folderId == null || !groupByFolder) && !selectedMedias.some((item) => item.id == media.id)) {
      setSelectedMedias([...selectedMedias, selectedMedia]);
    }

    //Folder case => add to folder and add folder if not already selected
    if (groupByFolder) {
      //try to find in selectedFolders
      var folders = selectedFolders;
      const folder = folders.find((row) => media.id_mission_container == row.id);
      //If not found => search in full data and pass to selected
      if (!folder) {
        folder = protisFullData.containers.find((row) => media.id_mission_container == row.id);
        if (!!folder !== false) {
          folder.isSelected = true;
          folder.photos = folder.photos.filter((row) => row.isSelected);
          folder.videos = folder.videos.filter((row) => row.isSelected);
          folders.push(folder);
        }
      }
      //update selected folders
      var folders = folders.map((item) => {
        if (!!folder !== false && item.id == folder.id) {
          if (!!selectedPhoto !== false && !folder.photos.some((row) => row.id == selectedPhoto.id)) {
            folder.photos.push(selectedPhoto);
          }
          if (!!selectedVideo !== false && !folder.videos.some((row) => row.id == selectedVideo.id)) {
            folder.videos.push(selectedVideo);
          }
          folder.selectOrder = folders.length;
          return folder;
        }
        return item;
      });
      setSelectedFolders(folders);
    }
  }

  const addMediasToSelected = (medias) => {
    const allSelectedMedias = [];
    var allSelectedFolders = [];
    medias?.forEach((media) => {
      var folderId = media.id_mission_container;
      //photo
      var selectedMedia;
      const selectedPhoto = protisFullData.photos.find((row) => media.id == row.id);
      if (selectedPhoto) {
        selectedPhoto.isSelected = true;
        selectedPhoto.selectOrder = allSelectedMedias.filter((elmt) => elmt.isPhoto).length;
        selectedMedia = selectedPhoto;
      }
      //video
      const selectedVideo = protisFullData.videos.find((row) => media.id == row.id);
      if (selectedVideo) {
        selectedVideo.isSelected = true;
        selectedVideo.selectOrder = allSelectedMedias.filter((elmt) => elmt.isVideo).length;
        selectedMedia = selectedVideo;
      }
      //Root case => add to selectedPhotos
      if ((folderId == null || !groupByFolder) && !allSelectedMedias.some((item) => item.id == media.id)) {
        allSelectedMedias.push(selectedMedia);
      }
      //Folder case => add to folder and add folder if not already selected
      if (groupByFolder) {
        //try to find in selectedFolders
        var folders = allSelectedFolders;
        const folder = folders.find((row) => media.id_mission_container == row.id);
        //If not found => search in full data and pass to selected
        if (!folder) {
          folder = protisFullData.containers.find((row) => media.id_mission_container == row.id);
          if (!!folder !== false) {
            folder.isSelected = true;
            folder.photos = folder.photos.filter((row) => row.isSelected);
            folder.videos = folder.videos.filter((row) => row.isSelected);
            folders.push(folder);
          }
        }
        //update selected folders
        var folders = folders.map((item) => {
          if (!!folder !== false && item.id == folder?.id) {
            if (!!selectedPhoto !== false && !folder.photos.some((row) => row.id == selectedPhoto.id)) {
              folder.photos.push(selectedPhoto);
            }
            if (!!selectedVideo !== false && !folder.videos.some((row) => row.id == selectedVideo.id)) {
              folder.videos.push(selectedVideo);
            }
            folder.selectOrder = folders.length;
            return folder;
          }
          return item;
        });
        allSelectedFolders = folders;
      }
    });
    setSelectedMedias(allSelectedMedias);
    setSelectedFolders(allSelectedFolders);
  }

  const handleRemoveMediaClick = (media) => {
    setSelectedMedias(selectedMedias.filter((row) => row.id !== media.id));
    //photo
    protisFullData.photos.forEach((row) => {
      if (row.id == media.id) {
        row.isSelected = false;
        delete row.selectOrder;
      }
    });
    //videos
    protisFullData.videos.forEach((row) => {
      if (row.id == media.id) {
        row.isSelected = false;
        delete row.selectOrder;
      }
    });
    //if folder is not null
    if (media.id_mission_container !== null) {
      var folder = selectedFolders.find((row) => row.id == media.id_mission_container);

      if (!!folder !== false) {
        //remove photo from folder
        folder.photos = folder.photos.filter((row) => row.id !== media.id);
        //reorder photos
        folder.photos.forEach((row, i) => {
          row.selectOrder = i;
        });
        //remove video from folder
        folder.videos = folder.videos.filter((row) => row.id !== media.id);
        //reorder videos
        folder.videos.forEach((row, i) => {
          row.selectOrder = i;
        });
        //if folder is empty => remove folder
        if (!folder.photos?.some((photo) => photo.isSelected) || !folder.videos?.some((video) => video.isSelected)) {
          var folders = selectedFolders.filter((row) => row.id !== folder.id);
          folders.forEach((row, i) => {
            row.selectOrder = i;
          })
          setSelectedFolders(folders);
        } else {//otherwise remove only photo from folder
          setSelectedFolders(selectedFolders.filter((row) => {
            if (row.id == folder.id) {
              return folder;
            }
            return row;
          }));
        }
      }
    }
  }

  const handleFolderDownClick = (folder) => {
    const currentOrder = folder.selectOrder;

    var folders = selectedFolders.map((row) => {
      if (row.isSelected && row.selectOrder == (currentOrder + 1)) {
        row.selectOrder--;
      }
      if (folder.id == row.id) {
        row.selectOrder++;
      }
      return row;
    });

    folders.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
    setSelectedFolders(folders);
  }

  const handleFolderUpClick = (folder) => {
    const currentOrder = folder.selectOrder;

    var folders = selectedFolders.map((row) => {
      if (row.isSelected && row.selectOrder == (currentOrder - 1)) {
        row.selectOrder++;
      }
      if (folder.id == row.id) {
        row.selectOrder--;
      }
      return row;
    });

    folders.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
    setSelectedFolders(folders);
  }

  const handleMediaDownClick = (media, folder) => {
    const currentOrder = media.selectOrder;
    const selectedPhotos = selectedMedias.filter(elmt => !!elmt.isPhoto !== false);
    const selectedVideos = selectedMedias.filter(elmt => !!elmt.isVideo !== false);
    const folderPhotos = folder == undefined ? selectedPhotos : folder.photos;
    const folderVideos = folder == undefined ? selectedVideos : folder.videos;
    const folderMedias = folderPhotos.concat(folderVideos);

    //photo+video
    const medias = folderMedias.map((row) => {
      if (media.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder + 1)) {
        row.selectOrder--;
      }
      if (media.id == row.id) {
        row.selectOrder++;
      }
      return row;
    });
    //photo
    const photos = folderPhotos.map((row) => {
      if (media.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder + 1)) {
        row.selectOrder--;
      }
      if (media.id == row.id) {
        row.selectOrder++;
      }
      return row;
    });
    //video
    const videos = folderVideos.map((row) => {
      if (media.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder + 1)) {
        row.selectOrder--;
      }
      if (media.id == row.id) {
        row.selectOrder++;
      }
      return row;
    });

    medias.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    photos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    videos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    if (folder == undefined) {
      setSelectedMedias(medias);
    } else {
      folder.photos = photos;
      folder.videos = videos;
      var folders = selectedFolders.map((row) => {
        if (row.id = folder.id) {
          return folder;
        }
        return row;
      })
      setSelectedFolders(folders);
    }
  }

  const handleMediaUpClick = (media, folder) => {
    const currentOrder = media.selectOrder;
    const selectedPhotos = selectedMedias.filter(elmt => !!elmt.isPhoto !== false);
    const selectedVideos = selectedMedias.filter(elmt => !!elmt.isVideo !== false);
    const folderPhotos = folder == undefined ? selectedPhotos : folder.photos;
    const folderVideos = folder == undefined ? selectedVideos : folder.videos;
    const folderMedias = folderPhotos.concat(folderVideos);

    //photo+video
    const medias = folderMedias.map((row) => {
      if (media.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder - 1)) {
        row.selectOrder++;
      }
      if (media.id == row.id) {
        row.selectOrder--;
      }
      return row;
    });
    //photo
    const photos = folderPhotos.map((row) => {
      if (media.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder - 1)) {
        row.selectOrder++;
      }
      if (media.id == row.id) {
        row.selectOrder--;
      }
      return row;
    });
    //video
    const videos = folderVideos.map((row) => {
      if (media.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder - 1)) {
        row.selectOrder++;
      }
      if (media.id == row.id) {
        row.selectOrder--;
      }
      return row;
    });

    medias.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    photos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    videos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    if (folder == undefined) {
      setSelectedMedias(medias);
    } else {
      folder.photos = photos;
      folder.videos = videos;
      var folders = selectedFolders.map((row) => {
        if (row.id = folder.id) {
          return folder;
        }
        return row;
      })
      setSelectedFolders(folders);
    }
  }

  const handleLoadedPhoto = (media) => {
    //photo
    protisFullData.photos.forEach((row) => {
      if (row.id == media.id) {
        row = media;
      }
    });
  }

  const handleGroupByFolderChange = (group) => {
    const folders = [];
    const medias = [];
    if (group) {
      selectedMedias.forEach((media) => {
        //sort folder photos
        if (media.id_mission_container == null) {
          media.selectOrder = medias.length;
          medias.push(media);
        } else {
          const folder = folders.find((row) => media.id_mission_container == row.id);
          //If not found => search in full data and pass to selected
          if (!folder) {
            folder = protisFullData.containers.find((row) => media.id_mission_container == row.id);
            folder.isSelected = true;
            folder.selectOrder = folders.length;
            media.selectOrder = 0;
            if (media.isPhoto)
              folder.photos = [media];
            else
              folder.videos = [media];
            folders.push(folder)
          } else {
            media.selectOrder = folder.photos.length;
            if (media.isPhoto)
              folder.photos.push(media);
            else
              folder.videos.push(media);
          }
        }
      })
    } else {
      selectedFolders.forEach((folder) => {
        var folderMedia = folder.photos.concat(folder.videos);
        //sort folder medias
        var sorted = folderMedia.filter((row) => row.isSelected);
        sorted.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
        //add each to medias
        folderMedia.forEach((media) => {
          media.selectOrder = medias.length;
          medias.push(media);
        });
      });
      selectedMedias.forEach((media) => {
        media.selectOrder = medias.length;
        medias.push(media);
      });
    }

    setGroupByFolder(group);
    setSelectedMedias(medias);
    setSelectedFolders(folders);
  }


  return {
    //commun state
    elmtLoading, setElmtLoading,
    modalLoading, setModalLoading,
    selectedMedias, setSelectedMedias,
    protisFullTree, setProtisFullTree,
    groupByFolder, setGroupByFolder,
    selectedFolders, setSelectedFolders,
    currentFolderId, setCurrentFolderId,
    protisFullData, setProtisFullData,

    //pdf state
    showMissionComment, setShowMissionComment,
    showFoldersComment, setShowFoldersComment,
    showPhotoType, setShowPhotoType,
    showPhotoDate, setShowPhotoDate,
    showPhotoComment, setShowPhotoComment,

    //function
    handleResetClick,
    handleSelectAllClick,
    selectAll,
    resetForm,
    prepareFullTree,
    addMediaToSelected,
    addMediasToSelected,
    handleRemoveMediaClick,
    handleFolderDownClick,
    handleFolderUpClick,
    handleMediaDownClick,
    handleMediaUpClick,
    handleLoadedPhoto,
    handleGroupByFolderChange
  };
};

export default useMissionDetailsProtisModalLogic;
