import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from 'imgs/logo.svg';
import 'styles/Login.css';
import 'styles/RenewPassword.css';
import 'bootstrap/dist/css/bootstrap.css';

const SetNewPasswordDone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";

  const handleSubmit = (e) => {
      e.preventDefault();
      navigate(from, { replace: true });
    }

    return (
      <div className="Login">
        <div className="welcome-to-gyptis">Gyptis - Password changed successfully</div>
        <div className="Login-box">
          <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
          <div className="Login-formulaire">
            <form onSubmit={handleSubmit}>
              <p className="info-reset-passwd">Your password was changed successfully.<br />Please try to login with your new password.</p>
              <button className="Login-rectangle-button" onSubmit={handleSubmit}>PROCEDD TO LOGIN</button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default SetNewPasswordDone;
