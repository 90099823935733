import React, { useState, forwardRef, useImperativeHandle } from 'react';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import axios from 'api/axios';
import 'styles/Popin.css';

const Alert = forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExportPopin = forwardRef((props, ref) => {
    const navigate = useNavigate();
    
    const [includeArchivedMissions, setIncludeArchivedMissions] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    
    useImperativeHandle(ref, () => ({
        async exportFiles() {
            await handleClickSave();
        },
    }));
    
    const handleIncludeArchivedMissions = (event) => {
        setIncludeArchivedMissions(!includeArchivedMissions);
    }
    
    const handleClose = () => {
        setIncludeArchivedMissions(false);
        props.setOpen(false)
    };
    
    const handleClickSave = async (e) => {
        
        if (props?.visibleRows.length > 0) {
          try {
            setLoadingSave(true);
            var finalExportUrl = includeArchivedMissions ? props?.exportUrl + '/v2' : props?.exportUrl
            
            const config = {
              url: finalExportUrl,
              responseType: 'arraybuffer',
              method: "post",
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
              },
              data: !includeArchivedMissions ? { id: props?.visibleRows } : null,
              params: {}
            }
            if (includeArchivedMissions) {
                const filters = props.setFilterApiParams();
                config.params = { ...config.params, ...filters };
            }
            const response = await axios(config);
  
            // Let the user save the file.
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
            saveAs(blob, 'Export_files_'+Moment().format('YYYYMMDD-HHmmss')+'.xlsx');
            props?.setIsDownload(true);
            if (props?.open) {
                props?.setOpen(false); 
            }
            setLoadingSave(false);
            setIncludeArchivedMissions(false);
          } catch (err) {
            console.log(err);
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            } else {
                props?.setOpenSnackBar(true);
            }
          }
        }
    }
    

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
            className='modalPopin export'
        >
            <Box className='boxPopin' >
                <div className='headerPopin'>Export files</div>
                <div className='contentPopin'>
                    <FormControlLabel 
                        label='Include archived files'
                        control={<Checkbox checked={includeArchivedMissions} onChange={handleIncludeArchivedMissions} />}
                    />
                </div>
                <div className='footerPopin'>
                    <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
                        <LoadingButton
                            onClick={handleClose}
                            loadingIndicator="Cancel"
                            variant="outlined"
                            loading={loadingSave}
                        >
                            Cancel
                        </LoadingButton>

                        <LoadingButton
                            onClick={handleClickSave}
                            loading={loadingSave}
                            endIcon={<FileDownloadIcon />}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Export
                        </LoadingButton>
                    </Box>
                </div>
            </Box>
        </Modal>
    );
})

export default ExportPopin