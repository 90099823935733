import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Chip from '@mui/material/Chip';
import 'chart.js/auto';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import CalendarIcon from '@mui/icons-material/Event';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers-pro/";
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';

import { ContextAnalytics } from "context/ContextAnalytics";
import axios from '../../../../api/axios';
import '../../../../styles/Analytics.css';

const AnalyticsToolbar = (props) => {
    const [defaultDateRange, setDefaultDateRange] = useState([startOfMonth(new Date()), endOfMonth(new Date())]);
    const [selectedDateRange, setSelectedDateRange] = useState([startOfMonth(new Date()), endOfMonth(new Date())]);
    
    const [formattedChipStartDate, setFormattedChipStartDate] = useState(null);
    const [formattedChipEndDate, setFormattedChipEndDate] = useState(null);
    
    const [dateRangePickerPosition, setDateRangePickerPosition] = useState(null);
    const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
    
    const [missionStatusList, setMissionStatusList] = useState(null);
    const [openMissionStatusMenu, setOpenMissionStatusMenu] = useState(false);
    const [anchorMissionStatusMenu, setAnchorMissionStatusMenu] = useState(null);
    
    const [missionTypeList, setMissionTypeList] = useState(null);
    const [openMissionTypeMenu, setOpenMissionTypeMenu] = useState(false);
    const [anchorMissionTypeMenu, setAnchorMissionTypeMenu] = useState(null);
    
    const { openSnackBar, setOpenSnackBar } = useContext(ContextAnalytics);
    const { snackBarType, setSnackBarType } = useContext(ContextAnalytics);
    const { snackBarMessage, setSnackBarMessage } = useContext(ContextAnalytics);
    const { setSnackErrorMsg } = useContext(ContextAnalytics);

    const GETMISSIONTYPESLIST_URL = 'mission_type/list';
    const GETSTATUSLIST_URL= 'status/list';
    const navigate = useNavigate();
    
    // Sets and formats the query date range, if its different from the default one (current month) 
    useEffect(() => {
        if (defaultDateRange?.length == 2 && selectedDateRange?.length == 2) {
            const defaultStartDateString = format(defaultDateRange[0], 'yyyy-MM-dd');
            const defaultEndDateString = format(defaultDateRange[1], 'yyyy-MM-dd');
            const selectedStartDateString = format(selectedDateRange[0], 'yyyy-MM-dd');
            const selectedEndDateString = format(selectedDateRange[1], 'yyyy-MM-dd');
            
            var dateRangeToUse = defaultDateRange;
            if (defaultStartDateString !== selectedStartDateString || defaultEndDateString !== selectedEndDateString) {
                var dateRangeToUse = selectedDateRange;
            }
            
            setFormattedChipStartDate(format(dateRangeToUse[0], 'dd/MM/yy'))
            setFormattedChipEndDate(format(dateRangeToUse[1], 'dd/MM/yy'))
            
            props.setFormattedQueryStartDate(format(dateRangeToUse[0], 'yyyy-MM-dd'))
            props.setFormattedQueryEndDate(format(dateRangeToUse[1], 'yyyy-MM-dd'))
        }
    }, [defaultDateRange, selectedDateRange])
    
    
    useEffect(() => {
        if (props?.missionStatusFilter) {
            getMissionStatusList();
        }
    }, [props.missionStatusFilter])
    
    useEffect(() => {
        if (props?.missionTypeFilter) {
            getMissionTypesList();
        }
    }, [props.missionTypeFilter])
    
    
    const getMissionStatusList = async () => {
        try {
            var config = {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                },
                params: {}
            };
            
            var url = GETSTATUSLIST_URL;
            
            config.params.status_type = 'mission-internal'
            
            const response = await axios.get(url, config);
            
            if (response.data.length > 0) {
                setMissionStatusList(response.data);
            }
    
        } catch (err) {
          if (err.response?.status === 401) {
              navigate("/logout", { replace: true });
          }
          else {
            var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
            setSnackBarMessage(errorMsg);
            setSnackBarType("error");
            setOpenSnackBar(true);
            console.log(err);
          }
        }
    }

    
    const getMissionTypesList = async () => {
        try {
            const config = {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                },
                params: {}
            };
            var url = GETMISSIONTYPESLIST_URL;
            
            if (props?.hiddenMissionTypes && props?.hiddenMissionTypes?.includes("COD")) {
                config.params.hide_cocoa_departure = 1;
            }
              
            const response = await axios.get(url, config);
            if (response.data.length > 0) {
                setMissionTypeList(response.data);
            }
    
        } catch (err) {
          if (err.response?.status === 401) {
              navigate("/logout", { replace: true });
          }
          else {
            var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
            setSnackBarMessage(errorMsg);
            setSnackBarType("error");
            setOpenSnackBar(true);
            console.log(err);
          }
        }
    }
    
    // Handles the chart type changes
    const handleChartTypeChange = (event, newValue) => {
        if (newValue) {
            props.setSelectedChartType(newValue);
        }
    }
    
    // Handles calendar position on range picker click
    const handleDateRangePickerVisible = (event) => {
        if (openDateRangePicker) {
            setOpenDateRangePicker(false);
        } else {
            // Position of DateRangePicker
            const rect = event.currentTarget.getBoundingClientRect();
            const left = rect.left + window.scrollX;
            const top = rect.bottom + window.scrollY;

            setDateRangePickerPosition({ left, top });
            setOpenDateRangePicker(true);
        }
    }
    
    // Handles date range changes
    const handleDateChange = (newDateRange) => {
        if (!newDateRange.some(date => date === null)) {
            setSelectedDateRange(newDateRange.map(momentObj => momentObj.toDate()));
        }
    };
    
    // Status Filter
    const handleMissionStatusClick = (event) => {
        setAnchorMissionStatusMenu(event.currentTarget);
        setOpenMissionStatusMenu(true);
    };
    
    const handleCloseMissionStatusMenu = () => {
        setAnchorMissionStatusMenu(null);
        setOpenMissionStatusMenu(false);
    };
    
    const handleMissionStatusChange = (status) => {
        if (status == 'all') {
            props?.setSelectedMissionStatusList([]);
        } else {
            const index = props?.selectedMissionStatusList.indexOf(status.value);
            if (index !== -1) {
                // If status exists in the list, remove it
                const updatedList = [...props?.selectedMissionStatusList];
                updatedList.splice(index, 1);
                if (updatedList.length === 0) {
                    // If it was the last one, set the list to null
                    props?.setSelectedMissionStatusList([]);
                } else {
                    props?.setSelectedMissionStatusList(updatedList.sort((a, b) => a - b));
                } 
            } else {
                props?.setSelectedMissionStatusList([...props?.selectedMissionStatusList, status.value].sort((a, b) => a - b));
            }
        }
    }    
    
    // Type Filter
    const handleMissionTypeClick = (event) => {
        setAnchorMissionTypeMenu(event.currentTarget);
        setOpenMissionTypeMenu(true);
    };
    
    const handleCloseMissionTypeMenu = () => {
        setAnchorMissionTypeMenu(null);
        setOpenMissionTypeMenu(false);
    };
    
    const handleMissionTypeChange = (type) => {
        if (type == 'all' || props?.selectedMissionType == type.nomenclature) {
            props?.setSelectedMissionType(null);
        } else {
            props?.setSelectedMissionType(type.nomenclature);
        }
        handleCloseMissionTypeMenu();
    }
      
    return (
        <div className='chartToolbarContainer'>
            <ToggleButtonGroup
                color='primary'
                value={props?.selectedChartType}
                exclusive
                onChange={handleChartTypeChange}
                className='chartTypeToggleButtonGroup'
            >
                {props?.chartTypeButtons.map((chartTypeButton, index) => (
                    <ToggleButton key={index} value={chartTypeButton.value} className='chartTypeToggleButton'>{chartTypeButton.label}</ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Chip label={props?.chartTitle} style={{ color: 'white', backgroundColor: '#0288d1' }}/>
            { (props?.missionStatusFilter && missionStatusList?.length > 0) &&
                <>
                    <Tooltip 
                        title='Mission Status'
                        placement='top'
                        arrow
                    >
                        <Chip
                            color='primary'
                            variant="outlined"
                            label={`${props?.selectedMissionStatusList?.length > 0 ? props?.selectedMissionStatusList : 'All'}`}
                            size="small"
                            style={{width: '10%'}}
                            onClick={handleMissionStatusClick}
                            onDelete={handleMissionStatusClick}
                            deleteIcon={<PublishedWithChangesOutlinedIcon />}
                        />
                    </Tooltip>
                    <Menu
                        anchorEl={anchorMissionStatusMenu}
                        open={openMissionStatusMenu}
                        onClose={handleCloseMissionStatusMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem key={-1} selected={props?.selectedMissionStatusList.length <= 0} onClick={() => handleMissionStatusChange('all')}>All</MenuItem>
                        {missionStatusList?.map((missionStatus, index) => (
                            <MenuItem key={index} selected={props?.selectedMissionStatusList?.length > 0 && props?.selectedMissionStatusList?.includes(missionStatus.value)} onClick={() => handleMissionStatusChange(missionStatus)}>{missionStatus?.value}</MenuItem>
                        ))}
                    </Menu>
                </> 
            }
            { (props?.missionTypeFilter && missionTypeList?.length > 0) &&
                <>
                    <Tooltip 
                        title='Mission Type'
                        placement='top'
                        arrow
                    >
                        <Chip
                            color='primary'
                            variant="outlined"
                            label={`${props?.selectedMissionType ? props?.selectedMissionType : 'All'}`}
                            size="small"
                            onClick={handleMissionTypeClick}
                            onDelete={handleMissionTypeClick}
                            style={{width: '8%'}}
                            deleteIcon={<FolderSpecialOutlinedIcon />}
                        />
                    </Tooltip>
                    <Menu
                        anchorEl={anchorMissionTypeMenu}
                        open={openMissionTypeMenu}
                        onClose={handleCloseMissionTypeMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem key={-1} selected={!props?.selectedMissionType} onClick={() => handleMissionTypeChange('all')}>All</MenuItem>
                        {missionTypeList?.map((missionType, index) => (
                            <MenuItem key={index}  selected={props?.selectedMissionType == missionType.nomenclature} onClick={() => handleMissionTypeChange(missionType)}>{missionType?.label}</MenuItem>
                        ))}
                    </Menu>
                </> 
            }
            <Chip
                color='primary'
                variant="outlined"
                label={`${formattedChipStartDate} - ${formattedChipEndDate}`}
                size="small"
                onClick={handleDateRangePickerVisible}
                onDelete={handleDateRangePickerVisible}
                deleteIcon={<CalendarIcon />}
            />
            { openDateRangePicker &&
                <div style={{ 
                    position: 'fixed', 
                    left: dateRangePickerPosition.left, 
                    top: dateRangePickerPosition.top, 
                    zIndex: 999
                }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDateRangePicker
                            defaultValue={defaultDateRange}
                            value={selectedDateRange}
                            open={openDateRangePicker}
                            calendars={2}
                            onOpen={handleDateRangePickerVisible}
                            onClose={handleDateRangePickerVisible}
                            onChange={handleDateChange}
                            renderInput={() => (<div></div>)}
                        />
                    </LocalizationProvider>
                </div>
            }
        </div>
    );
};

export default AnalyticsToolbar;