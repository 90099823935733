import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import LoadingButton from '@mui/lab/LoadingButton';
import { randomId } from '@mui/x-data-grid-generator';
import BLDateRow from './MissionDetailsBLDateRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";
import { ContextMissionDetailsBL } from "./ContextMissionDetailsBL";

export default function SignaturePlaceRow(props) {

  //workflow state
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { isRequired } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);

  //init BL data
  const { rowsCheckBL, setRowsCheckBL } = useContext(ContextMissionDetailsBL);
  const { rowsBL, setRowsBL } = useContext(ContextMissionDetailsReceiverBL);
  const { countries, setCountries } = useContext(ContextMissionDetailsBL);

  //state
  const [signaturePlace, setSignaturePlace] = useState({ id: "" });
  const [rowsBLDate, setRowsBLDate] = useState([]);
  //new param for default extand
  const [expanded, setExpanded] = useState((!!props.signaturePlace.isExpanded != false ? props.signaturePlace.isExpanded : props.isExpanded));


  useEffect(() => {
    setSignaturePlace(props.signaturePlace);
    setBLDates();
  }, [props.signaturePlace]);


  //function interactive
  function setBLDates() {
    var blDates = [];
    if (props.signaturePlace.bl_rows) {
      for (var i = 0; i < props.signaturePlace.bl_rows.length; i++) {
        var exists = blDates.find(dateRow => {
          return dateRow.value === props.signaturePlace.bl_rows[i].bl_date;
        });
        if (!exists) {
          blDates.push({
            id: randomId(),
            value: props.signaturePlace.bl_rows[i].bl_date,
            bl_rows: [props.signaturePlace.bl_rows[i]],
            isExpanded: (!!props.signaturePlace.bl_rows[i].isExpanded != false ? props.signaturePlace.bl_rows[i].isExpanded : false)
          });
        } else {
          exists.bl_rows.push(props.signaturePlace.bl_rows[i]);
          if (!!props.signaturePlace.bl_rows[i].isExpanded != false && props.signaturePlace.bl_rows[i].isExpanded != false) {
            exists.isExpanded = true;
          }
        }
      }
    }
    setRowsBLDate(blDates);
  }

  function createSelectItem(datas) {
    return { id: datas.id, label: datas.label.toUpperCase().trim(), labelValue: datas.label.toUpperCase().trim() };
  }

  function updateSignaturePlace(value) {
    const rowsChanged = [];
    var sigPlace = countries.find((country) => { return country.id === parseInt(value); });
    setSignaturePlace(sigPlace);
    for (var i = 0; i < props.signaturePlace.bl_rows.length; i++) {
      setRowsBL(rowsBL.map((row) => {
        if (row.id == props.signaturePlace.bl_rows[i].id) {
          row.signature_place = sigPlace;
          rowsChanged.push(row.id);
        }
        return row;
      }));
    }
    setRowsCheckBL(rowsChanged);
  }


  const handleAddBlDate = () => {
    const id = randomId();
    setRowsBLDate((oldRows) => [...oldRows, { id: id, value: '', bl_rows: [], isExpanded: true }]);
  };

  //function for change state of extand
  const handleChange = (toggle) => (event: SyntheticEvent) => {
    setExpanded(!toggle);
  }

  return (
    <div className="signaturePlaceDiv">
      <Accordion className="signaturePlaceAccordion" expanded={expanded} onChange={handleChange(expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {!!signaturePlace != false && signaturePlace.label ? signaturePlace.label.toUpperCase() : "NEW SIGNATURE PLACE"}
        </AccordionSummary>
        <AccordionDetails>
          <div className='lineContentBL'>
            <SelectAutoCustom
              title="Signature Place"
              api="country/list"
              apiParam=""
              apiParamValue=""
              dataValue={signaturePlace?.label || ""}
              setDataValue={updateSignaturePlace}
              error={false}
              setDataAlias=""
              defaultAlias=""
              creator={createSelectItem}
              cached={true}
              cacheTTL="600"
              resetCache="1"
              nosort

              read={canRead("bl_block")}
              write={canWrite("bl_block")}
              required={isRequired("bl_block")}
              controlFieldsStatus={workflowLoaded}
            />

            {(canWrite("bl_block")) ? (
              <LoadingButton
                onClick={handleAddBlDate}
                loadingIndicator="Adding Date"
                variant="outlined"
                className='addButtonSelect2'
              >
                Add BL Date
              </LoadingButton>
            ) : ""}
          </div>
          <div >
            {rowsBLDate.length >= 1 && rowsBLDate.map((item, index) => {
              return (
                <BLDateRow
                  key={item.id}
                  {...props}
                  blDateRow={item}
                  setRowsBLDate={setRowsBLDate}
                  signaturePlace={signaturePlace}
                  isExpanded={item.isExpanded !== undefined ? item.isExpanded : false}
                />
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
