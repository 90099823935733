import React, { useState, forwardRef, useContext, useEffect, GridRenderEditCellParams } from 'react';
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import { randomId } from '@mui/x-data-grid-generator';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { isInternalRole } from 'components/config/Roles';
import { useGridApiRef, DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridDatePickerCustom, DataGridTextCustom, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';

import ShippingInstructionFilePopin from '../Popin/ShippingInstructionFilePopin';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionReport } from "./ContextShippingInstructionReport";
import { ContextShippingInstructionBL } from "../BL/ContextShippingInstructionBL";
import { ContextShippingInstructionMission } from "../Mission/ContextShippingInstructionMission";

import axios from 'api/axios';


const ShippingInstructionBLForm = forwardRef((props, ref) => {
  //init config
  const navigate = useNavigate();
  const dataGridRef = useGridApiRef();
  const { update } = useContext(ContextBlock);
  const { reportRef } = useContext(ContextShippingInstruction);
  const [JLBInternalRole] = useState(isInternalRole(localStorage.getItem("role")));

  //generic
  const { setSnackErrorMsg } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  const { setFieldErrorBlockGrid } = useContext(ContextBlock);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //SI Report Grid
  const { rowsSiBls, setRowsSiBls } = useContext(ContextShippingInstructionBL);
  const { rowsMissions, setRowsMissions } = useContext(ContextShippingInstructionMission);

  const { rowsSiReports, setRowsSiReports } = useContext(ContextShippingInstructionReport);
  const { rowsSiReportsDeleted, setRowsSiReportsDeleted } = useContext(ContextShippingInstructionReport);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);
  
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //list dataGrid
  const [rowsVessel, setRowsVessel] = useState([]);
  const [rowsBlList, setRowsBlList] = useState([]);
  const [rowsMissionList, setRowsMissionList] = useState([]);

  //const file
  const [type, setType] = useState();
  const [right, setRight] = useState(0);
  const [openPopin, setOpenPopin] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [currentPopinRow, setPopinRow] = useState();
  const [typesOfFile, setTypesOfFile] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  //const rightOfFile = [{ id: 2, label: "Team", can_write: 1, value: 2 }];
  const [rightOfFile, setRightOfFile] = useState([]);

  const { saveShippingInstructionReport } = useContext(ContextShippingInstructionReport);


  //useEffect
  useEffect(() => {
    loadVessel();
    loadBlList();
    loadFileTypes();
    loadMissionList();
    loadFilePermissions();
  }, []);

  useEffect(() => {
    loadBlList();
  }, [rowsSiBls]);

  useEffect(() => {
    loadMissionList();
  }, [rowsMissions]);


  //function interactive
  const { processRowUpdateSiReport } = useContext(ContextShippingInstructionReport);

  const loadVessel = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('vessel/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].name.toUpperCase().trim() };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setRowsVessel(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const setVesselList = (bls, rowId) => {
    if (rowsVessel?.length > 0) {
      const blList = rowsSiBls.filter(obj => bls.includes(Number(obj.id)));
      const vesselBl = [];
      blList.map(bl => {
        const idVessel = Number(bl.id_vessel);
        if (!vesselBl.includes(idVessel)) {
          vesselBl.push(idVessel);
        }
      });
      const vesselList = rowsVessel.filter(obj => vesselBl.includes(Number(obj.value)));

      //check if current lot is in new list
      var rowData = rowsSiReports.find(obj => Number(obj.id) === Number(rowId));
      if (rowData && rowData.id_vessel != 0) {
        var vessel = vesselList.findIndex(obj => Number(obj.value) === Number(rowData.id_vessel));
        if (vessel < 0) {
          processRowUpdateSiReport({ ...rowData, id_vessel: 0 });
        }
      }

      return vesselList;
    } else {
      return [];
    }
  }

  const loadFileTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get("file_type/list", config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].workflow_key == 'sealing_report') {
          rowsFromApi[rowsFromApi.length] = { value: response.data[i].id, label: response.data[i].label };
          setType(Number(response.data[i].id));
        }
      }
      setTypesOfFile(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadFilePermissions = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get("file_permission/list", config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].label.toUpperCase() == 'TEAM') {
          rowsFromApi[rowsFromApi.length] = { id: response.data[i].id, value: response.data[i].id, label: response.data[i].label, can_write: response.data[i].can_write };
          setRight(Number(response.data[i].id));
        }
      }
      setRightOfFile(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadBlList = async () => {
    if (rowsSiBls.length > 0) {
      var rowsFromApi = [];
      for (var i = 0; i < rowsSiBls.length; i++) {
        rowsFromApi[i] = { value: rowsSiBls[i].id, label: rowsSiBls[i].number, checked: false };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setRowsBlList(rowsFromApi);
    }
  }

  const setBlList = () => {
    var blList = rowsBlList;
    blList.map((bl, idx) => {
      var checked = false;
      for (var i = 0; i < rowsSiReports.length; i++) {
        const obj = rowsSiReports[i];
        const found = obj.bls?.findIndex(ass => Number(ass) === Number(bl.value));
        checked = found > -1 ? true : false;
        if (checked) break;
      }
      blList[idx] = { ...bl, checked: checked };
    });
    setRowsBlList(blList);
    return blList;
  }

  const setCreatorBlListHandle = (data) => {
    return { id: data.value, title: data.label, alias: data.label, labelValue: data.label, checkedValue: data.checked };
  }

  const loadMissionList = async () => {
    if (rowsMissions.length > 0) {
      var rowsFromApi = [];
      for (var i = 0; i < rowsMissions.length; i++) {
        rowsFromApi[i] = { value: rowsMissions[i].id, label: rowsMissions[i].jlb_ref };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setRowsMissionList(rowsFromApi);
    }
  }

  const deleteRowSiReport = (event, id) => {
    setRowsSiReports(rowsSiReports.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsSiReportsDeleted((oldRows) => [...oldRows, { id: id }]);
  }

  const handleUploadSiReportClick = (row) => {
    //Open File Drop Zone
    setOpenPopin(true);
    setPopinRow(row);
    //INIT MODAL BEFORE
    setFileUpload(null);
    setErrorFile(false);
  }

  const handleUploadedFile = async (file) => {
    var rows = rowsSiReports.map((row) => {
      if (row.report_number.toUpperCase() === currentPopinRow.report_number.toUpperCase() && !row.file) {
        row.file = { file_name: file.file, id: file.id, file_path: file.url };
      }
      return row;
    });
    setRowsSiReports(rows);
    await saveShippingInstructionReport();
  };

  const deleteSiReportFile = (delRow) => {
    delRow.file = null;
    processRowUpdateSiReport(delRow);
  }

  const editFile = (file) => {
    window.open(file.file_path, "_blank");
  }

  function EditToolbarSiReport(props) {
    const { setRowsSiReports } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsSiReports((oldRows) => [...oldRows, {
        id: id,
        id_file: 0,
        id_vessel: 0,
        report_number: '',
        invoice_month: '',

        bls: [],
        missions: []
      }]);
      setFieldErrorBlockGrid(reportRef, 'report_number', true);
    };

    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Report
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }


  //xGrid
  var listColumnSiReport = [];
  //mode edit
  if (props.editable) {
    listColumnSiReport.push({
      field: 'id', headerName: '', editable: false, sortable: false, width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => { deleteRowSiReport(event, cellValues.id) }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnSiReport.push(
    { field: 'report_number', headerName: 'Report Number', sortable: false, minWidth: 120, flex: 1, editable: props.editable, required: true,
      valueParser: (value: GridCellValue, params: GridCellParams) => {
        return value.toString().toUpperCase();
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            processRowUpdate={processRowUpdateSiReport}
            error={params.value == 0 ? true : false}
            required={true}
          />
        )
      },
      //check cell data after edit
      preProcessEditCellProps: async (params) => {
        const hasError = (params.props.value?.trim() === '' || params.props.value === null);
        setFieldErrorBlockGrid(reportRef, 'report_number', hasError);
        //alert popin if error
        if (hasError) {
          triggerInfosPopin("Fields required", 'Report Number is required', "error");
        }
        return { ...params.props, error: hasError };
      }
    },
    { field: 'bls', headerName: 'BLs', sortable: false, type: 'singleSelect', width: 200, flex: 1, editable: props.editable,
      valueOptions: ({ row }) => {
        return !row ? [] : setBlList();
      },
      valueFormatter: () => { },
      renderCell: (cellValues) => {
        if (props.editable && ((Array.isArray(cellValues.value) && cellValues.value.length === 0) || cellValues.value == '')) {
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
            </Tooltip>
          );
        } else if (Array.isArray(cellValues.value) && cellValues.value.length > 0) {
          var txt = '', prefix = '';
          for (var i = 0; i < cellValues.value.length; i++) {
            const option = rowsBlList.find(obj => Number(obj.value) === Number(cellValues.value[i]));
            if (!!option !== false) {
              txt = txt + prefix + option.label;
              prefix = "\n\r";
            }
          }
          return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>} placement="right">
              <IconButton>
                <FormatListBulletedIcon />
              </IconButton>
            </Tooltip>
          );
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            multiple={true}
            disableNone={true}
            keepCellEditMode={true}
            processRowUpdate={processRowUpdateSiReport}
            setCreatorHandle={setCreatorBlListHandle}
          />
        )
      }
    },
    { field: 'id_vessel', headerName: 'Vessel', sortable: false, type: 'singleSelect', width: 150, flex: 1, editable: props.editable,
      valueOptions: ({ row }) => {
        return !row || row.bls?.length === 0 ? [] : setVesselList(row.bls, row.id);
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateSiReport}
          />
        )
      }
    },
    { field: 'file', headerName: 'File', editable: false, width: 250, sortable: false,
      renderCell: (cellValues) => {
        //Displaying upload button only if no existing file
        if (props.editable && cellValues.row.file == null) {
          return (
            <IconButton aria-label="upload" onClick={(event) => {
              handleUploadSiReportClick(cellValues.row);
            }}>
              <UploadFileIcon />
            </IconButton>);
        } else {
          if (cellValues.row.file != null) {
            return (
              <>
                <span className="subInvoiceFilenameSpan">{cellValues.row.file.file_name}</span>

                {(props.editable && update == 1 && JLBInternalRole) && (
                  <IconButton aria-label="delete" onClick={(event) => {
                    deleteSiReportFile(cellValues.row);
                  }}>
                    <ClearIcon />
                  </IconButton>
                )}

                {(JLBInternalRole) && (
                  <IconButton aria-label="edit" onClick={(event) => {
                    editFile(cellValues.row.file);
                  }}>
                    {props.editable ? <EditIcon /> : <VisibilityIcon />}
                  </IconButton>
                )}
              </>
            );
          }
        }
      }
    },
    { field: 'invoice_month', headerName: 'Invoice Month', sortable: false, width: 160, type: 'date', editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            views={['year', 'month']}
            processRowUpdate={processRowUpdateSiReport}
          />
        )
      }
    },
    { field: 'invoiced_without_si', headerName: 'Invoice Without SI', sortable: false, width: 120, type: 'boolean', editable: props.editable },
    { field: 'missions', headerName: 'Missions', sortable: false, type: 'singleSelect', width: 200, flex: 1, editable: props.editable,
      valueOptions: rowsMissionList,
      valueFormatter: () => { },
      renderCell: (cellValues) => {
        if (props.editable && ((Array.isArray(cellValues.value) && cellValues.value.length === 0) || cellValues.value == '')) {
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
            </Tooltip>
          );
        } else if (Array.isArray(cellValues.value) && cellValues.value.length > 0) {
          var txt = '', prefix = '';
          for (var i = 0; i < cellValues.value.length; i++) {
            const option = rowsMissionList.find(obj => Number(obj.value) === Number(cellValues.value[i]));
            if (!!option !== false) {
              txt = txt + prefix + option.label;
              prefix = "\n\r";
            }
          }
          return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>} placement="right">
              <IconButton>
                <FormatListBulletedIcon />
              </IconButton>
            </Tooltip>
          );
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            multiple={true}
            disableNone={true}
            keepCellEditMode={true}
            processRowUpdate={processRowUpdateSiReport}
          />
        )
      }
    }
  );
  const columnsSiReport: GridColDef[] = listColumnSiReport;


  return (
    <div className="lineContent noJustify">
      <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-si-bl">
        <DataGridPro
          apiRef={dataGridRef}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          hideFooter={true}
          rows={rowsSiReports}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateSiReport}
          columns={columnsSiReport}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsSiReports },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: props.editable ? EditToolbarSiReport : null,
          }}
        />
      </FormControl>

      <ShippingInstructionFilePopin
        idShippingInstruction={idShippingInstruction}
        handleUploadedFile={handleUploadedFile}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarType={setSnackBarType}
        setOpenSnackBar={setOpenSnackBar}
        setOpen={setOpenPopin}
        setFileUpload={setFileUpload}
        setPermission={setRight}
        setType={setType}
        setErrorFile={setErrorFile}
        open={openPopin}
        permission={right}
        type={type}
        fileUpload={fileUpload}
        errorFile={errorFile}
        filePermissions={rightOfFile}
        typesOfFile={typesOfFile}
        disableTypeNone={'true'}
      />
    </div>
  );
});

export default ShippingInstructionBLForm;
