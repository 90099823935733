import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import FilterItemStatus from './FilterItemStatus'
import {isCustomerRole} from 'components/config/Roles';
import axios from 'api/axios';

const GETSTATUS_API_URL= 'status/list';

const FilterStatusList = (props) => {

    const navigate = useNavigate();
    const [rowsStatus, setRowsStatus] = React.useState([]);
    
    useEffect(()=>{
        getStatus();
    },[]);

    const getStatus = async () => {
        try {
            var statusType = (typeof props.JLBInternalMode == "undefined" || props.JLBInternalMode) ? 'mission-internal' : 'mission-public';
    
            var config = {
                url: GETSTATUS_API_URL+"?status_type="+statusType,
                method: 'get',
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
                }
            };
            const reponseGet = await axios(config);
            
            //INITIALISATION STATUS
            var rowsStatusApi = [];
            for (let i = 0; i < reponseGet.data.length; i++) {
                rowsStatusApi[i] = {};
                rowsStatusApi[i].id    = reponseGet.data[i].id;
                rowsStatusApi[i].label = reponseGet.data[i].label;
                rowsStatusApi[i].value = reponseGet.data[i].value;
                rowsStatusApi[i].color = reponseGet.data[i].color;
            }

            setRowsStatus(rowsStatusApi);
        }
        catch (err) {
            if (err.response?.status === 422) {
                console.warn("Status list not found !");
            }
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            }
        }
    }

    return (
    <div className="FilterStatusContent">
        {rowsStatus.length > 0 && React.Children.toArray(rowsStatus.map((item, index) => {
            if (!isCustomerRole(localStorage.getItem("role"))) {
                return (
                    (!props?.hiddenStatusFilters?.includes(item.value) &&
                        <FilterItemStatus
                            value={item.value}
                            color={item.color}
                            setFilter={props.setFilter}
                            filterStatus={props.filterStatus}
                            statusFilter={props.statusFilter[index]}
                            statusFilterReset={props.statusFilterReset[index]}
                            changeStatusFilter={props.changeStatusFilter[index]}
                            title={item.label}
                            disable={props.disable}
                            missionEdit={props.missionEdit}
                            workflowLoaded={props.workflowLoaded}
                            opacity={(props.opacity && index >= 8 ? props.opacity : '100%')}
                        />
                    )
                );   
            } else {
                return (
                    <FilterItemStatus
                        value={item.value}
                        color={item.color}
                        setFilter={props.setFilter}
                        filterStatus={props.filterStatus}
                        statusFilter={props.statusFilter[index]}
                        statusFilterReset={props.statusFilterReset[index]}
                        changeStatusFilter={props.changeStatusFilter[index]}
                        title={item.label}
                        disable={props.disable}
                        missionEdit={props.missionEdit}
                        workflowLoaded={props.workflowLoaded}
                        opacity={(props.opacity && index >= 8 ? props.opacity : '100%')}
                    />
                );   
            }
        }))}
    </div>
    );
}
export default FilterStatusList