import React, { useState, forwardRef, useContext } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import AddLinkIcon from '@mui/icons-material/AddLink';

import SelectAutoCustom from 'components/select/SelectAutoCustom';
import MissionDetailsSiPopin from '../Popin/MissionDetailsSiPopin';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetailsSI } from "./ContextMissionDetailsSI";


const MissionDetailsSIForm = forwardRef((props, ref) => {

  //generic
  const { loading, setLoading } = useContext(ContextBlock);

  //si data-
  const { newSI, setNewSI } = useContext(ContextMissionDetailsSI);
  const { newSIId, setNewSIId } = useContext(ContextMissionDetailsSI);

  //validation contract number popin
  const [openCreate, setOpenCreate] = useState(false);
  const handleClickAddSI = async () => {
    setOpenCreate(true);
  }


  //function interactive
  function createSIHandler(data) {
    return { label: data.contract_number.toUpperCase().trim(), id: data.id, alias: data.contract_number.toUpperCase().trim(), labelValue: data.contract_number.toUpperCase().trim() };
  }

  function setDatasSI(id, label, alias, data) {
    setNewSIId(id);
    setNewSI(label);
  }


  return (
    <div id="siForm">
      <div className="lineContent noJustify">

        <SelectAutoCustom
          title="Contract Number"
          api="si/list"
          apiParam=""
          apiParamValue=""
          dataValue={newSI}
          setDataValue={setDatasSI}
          defaultAlias="YYY"
          creator={createSIHandler}
          openOnFocus={false}
          freeSolo={true}
        />

        <LoadingButton
          size="small"
          variant="outlined"
          className="classic_button"
          loading={loading} loadingPosition="start"
          startIcon={<AddLinkIcon />}
          onClick={handleClickAddSI}
          disabled={Boolean(!(newSI !== ''))}
        >
          Add SI
        </LoadingButton>

      </div>

      <MissionDetailsSiPopin newSIId={newSIId} open={openCreate} setOpen={setOpenCreate} />

    </div>
  );
});

export default MissionDetailsSIForm;
