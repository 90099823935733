import React, { useContext } from 'react';

import ConfirmDialog from 'components/page/object/ConfirmDialog';

import { ContextMissionDetailsSI } from "../SI/ContextMissionDetailsSI";


const MissionDetailsSiPopin = (props) => {

  //SI
  const { newSIId } = props;
  const { saveMissionDetailsSI } = useContext(ContextMissionDetailsSI);

  const title = newSIId > 0 ? 'Assign this SI to the Mission?' : 'Create a new SI from Mission ?';
  const content = newSIId > 0 ? 'Are you sure you want to assign this SI to the Mission?' : 'Are you sure you want to create a new Shipping Instructions from Mission ?';


  //function interactive
  const handleAddSI = async () => {
    props.setOpen(false);
    await saveMissionDetailsSI(true);
  };


  return (
    <ConfirmDialog
      title={title}
      notClose={true}
      open={props.open}
      setOpen={props.setOpen}
      onConfirm={async () => { await handleAddSI() }}
    >
      <div className="lineContent">
        {content}
      </div>
    </ConfirmDialog>
  );
};

export default MissionDetailsSiPopin;