import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";

import axios from 'api/axios';


const ContextShippingInstructionNote = createContext();

const ShippingInstructionNoteProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { noteRef } = useContext(ContextShippingInstruction);
  const { getShippingInstruction } = useContext(ContextShippingInstruction);

  //API URL
  const SI_URL = 'shipping_instruction';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //init id si
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //si general state
  const {general, setGeneral} = useContext(ContextShippingInstruction);

  //data
  const [internalNote, setInternalNote] = useState('');

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlock} = useContext(ContextBlockTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    handleClickBlockCancel(noteRef);
  }


  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === noteRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        if (reloadData > oldReloadData) {
          setOldReloadData(reloadData);
        }

        //INIT internal_note
        if (general.internal_note) setInternalNote(general.internal_note);
        else setInternalNote('');
      }
    }
  }, [general, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(noteRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === noteRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlock(noteRef, saveShippingInstructionNote);
  }, [blockSave, idShippingInstruction]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === noteRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function treatment
  const saveShippingInstructionNote = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var data = {
        internal_note: internalNote,
        id_shipping_instruction: Number(idShippingInstruction)
      };

      //API DATA SENT
      var config = {
        url: SI_URL,
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
      const response = await axios(config);

      if (justBlock) {
        setSnackBarMessage("Notes saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getShippingInstruction();
      } else {
        setDoneBlockSave(noteRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(noteRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  };


  return (
    <ContextShippingInstructionNote.Provider
      value=
      {{
        internalNote, setInternalNote,

        handleClickCancel,
        saveShippingInstructionNote
      }}
    >
      {children}
    </ContextShippingInstructionNote.Provider>
  );
};

export { ShippingInstructionNoteProvider, ContextShippingInstructionNote };
