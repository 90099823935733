import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from 'imgs/logo.svg';
import 'styles/Login.css';
import 'styles/RenewPassword.css';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'api/axios';
const PSWRESET_URL = 'user/passwordreset';
const CHECKTOKEN_URL = 'user/passwordtokenvalidity';

const SetNewPassword = () => {

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const location = useLocation();

  const from = location.state?.from?.pathname || "/SetNewPasswordDone";
  const errRef = useRef();
  const pwd1Ref = useRef();
  const pwd2Ref = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [pwd1, setPwd1] = useState('');
  const [pwd2, setPwd2] = useState('');

  const checkLink = async (e) => {
    if (reset_token) {

      try {
          await axios.get(CHECKTOKEN_URL+'?reset_token='+reset_token);

          setLinkValide(true);
          setLinkNotValide(false);
      } catch (err) {
        setLinkValide(false);
        setLinkNotValide(true);
      }
    }
    else
    {
      setLinkValide(false);
      setLinkNotValide(true);
    }
  }

  useEffect(() => {
      pwd1Ref.current.focus();
      setErrMsg('');
      checkLink();
  }, [])

  const [linkValide, setLinkValide] = useState("");
  const [linkNotValide, setLinkNotValide] = useState("");
  const reset_token = queryParams.get('reset_token');

  const handleSubmit = async (e) => {
      e.preventDefault();

      if (pwd1 === pwd2)
      {
        //The two password fields match.
        try {
            await axios.post(PSWRESET_URL,
                JSON.stringify({ reset_token:reset_token, password:pwd1, password_confirmation:pwd2}),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Invalid Token or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Invalid Token or Password');
            } else if (err.response?.status === 422) {
                if (err?.response?.data?.password[0]) setErrMsg(err?.response?.data?.password[0]);
                else setErrMsg('Invalid Token or Password');
            } else {
              setErrMsg('Server Failed');
            }
            errRef.current.focus();
        }
      }
      else
      {
        //The two password fields didn’t match.
        setErrMsg('The two password fields didn’t match.');
      }
  }


      return (
        <>
          <div className={`Login ${linkValide ? "" : "hidden"}` }>
            <div className="welcome-to-gyptis">Gyptis - Set a new password</div>
            <div className="Login-box">
              <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
              <div className="Login-formulaire">
                <form onSubmit={handleSubmit}>
                  <div ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</div>
                  <p className="info-reset-passwd">Please set your new password below.</p>
                  <input className="Login-identifier-input Login-input-nomaginbottom" type="password" id="password1" ref={pwd1Ref} onChange={(e) => setPwd1(e.target.value)} value={pwd1} required />
                  <div className="hint_id_new_password">min. 8 characters, at least one letter and number</div>
                  <input className="Login-identifier-input" type="password" id="password2" ref={pwd2Ref} onChange={(e) => setPwd2(e.target.value)} value={pwd2} required />
                  <button className="Login-rectangle-button" onSubmit={handleSubmit}>SAVE PASSWORD</button>
                </form>
              </div>
            </div>
          </div>

          <div className={`Login ${linkNotValide ? "" : "hidden"}` }>
            <div className="welcome-to-gyptis">Gyptis - Invalid Link</div>
            <div className="Login-box">
              <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
              <div className="Login-formulaire">
                  <p className="info-reset-passwd">Sorry, that reset password link is not valid or expired. Please make sure you copied the link correctly and try again.<br /><br />If you continue to experience problems,
please contact us.</p>
              </div>
            </div>
          </div>
        </>
      );
}

export default SetNewPassword;
