import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import Delete from '@mui/icons-material/Delete';
import { FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import ProtisPhoto from './MissionDetailsProtisPhoto';
import PdfRecursiveListItem from './PdfRecursiveListItem';

import useMissionDetailsProtisModalLogic from "./useMissionDetailsProtisModalLogic";

import axios from 'api/axios';


const PdfModal = (props) => {
  const navigate = useNavigate();
  const withoutVideo = true;

  const MISSIONPROTISPDF_URL = "/mission/pdf/photo"
  const MISSIONPROTISPDFFILE_URL = "/mission/pdf/photo/file"
  const MISSIONPROTISPDFSTORE_URL = "/mission/pdf/photo/store"

  //context
  const {
    elmtLoading, setElmtLoading,
    modalLoading, setModalLoading,
    selectedMedias, setSelectedMedias,
    protisFullTree, setProtisFullTree,
    groupByFolder, setGroupByFolder,
    selectedFolders, setSelectedFolders,
    currentFolderId, setCurrentFolderId,
    protisFullData, setProtisFullData,

    showMissionComment, setShowMissionComment,
    showFoldersComment, setShowFoldersComment,
    showPhotoType, setShowPhotoType,
    showPhotoDate, setShowPhotoDate,
    showPhotoComment, setShowPhotoComment,

    handleResetClick,
    handleSelectAllClick,
    resetForm,
    prepareFullTree,
    addMediaToSelected,
    handleRemoveMediaClick,
    handleFolderDownClick,
    handleFolderUpClick,
    handleMediaDownClick,
    handleMediaUpClick,
    handleLoadedPhoto,
    handleGroupByFolderChange
  } = useMissionDetailsProtisModalLogic({ protisData: props.protisData, withoutVideo: withoutVideo });


  useEffect(() => {
    if (props.open) {
      setCurrentFolderId(null);
      if (protisFullData.photos) {
        initSelected();
      }
    } else {
      resetForm();
    }
  }, [props.open]);


  const handleSaveClick = async () => {
    try {
      setElmtLoading(true);
      await savePdfData();
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setElmtLoading(false);
    }
  }

  const handleSharepointClick = async () => {
    try {
      setElmtLoading(true);

      await savePdfData();

      var config = {
        method: 'post',
        url: MISSIONPROTISPDFSTORE_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: {
          id_mission: props.idMission
        }
      };

      await axios(config);

      props?.fileForm?.current.reloadFiles();

      props.setSnackBarMessage('Pdf stored in sharepoint successfully');
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setElmtLoading(false);
    }
  }

  const initSelected = async () => {
    try {
      setElmtLoading(true);

      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          id_mission: props.idMission
        }
      };

      const response = await axios.get(MISSIONPROTISPDF_URL, config);
      var data = response.data.data;

      if (data) {
        setShowMissionComment(data.show_mission_comment);
        setGroupByFolder(data.group_by_folder);
        setShowFoldersComment(data.show_folders_comment);
        setShowPhotoType(data.show_photo_comment);
        setShowPhotoDate(data.show_photo_date);
        setShowPhotoComment(data.show_photo_comment);

        setSelectedFolders([]);
        setSelectedMedias([]);
        var photos = [];
        var folders = [];

        data.photos.forEach((dbRow) => {
          var photo = protisFullData.photos.find((row) => row.id == dbRow.id_mission_photo);
          if (photo) {
            photo.isSelected = true;
            photo.isPhoto = true;
            photo.selectOrder = dbRow.order;

            //Root case => add to selectedMedias
            if ((!data.group_by_folder || photo.id_mission_container == null) && !photos.some((item) => item.id == photo.id)) {
              photo.selectOrder = photos.length;
              photos.push(photo);
            }
            //Folder case => add to folder and add folder if not already selected
            if (photo.id_mission_container !== null && data.group_by_folder) {
              //try to find in selectedFolders
              var folder = folders.find((row) => photo.id_mission_container == row.id);
              //If not found => search in full data and pass to selected
              if (!folder) {
                folder = protisFullData.containers.find((row) => photo.id_mission_container == row.id);
                var pdfFolderData = data.containers.find((row) => photo.id_mission_container == row.id_mission_container);
                folder.isSelected = true;
                folder.selectOrder = pdfFolderData.order;
                folder.photos = folder.photos.filter((row) => row.isSelected);
              }
              if (!folder.photos?.some((row) => row.id == photo.id)) {
                folder.photos = [...folder.photos, photo];
              }
              if (!folders.some((item) => item.id == folder.id)) {
                folders.push(folder);
              }
            }
          }
        });

        setSelectedFolders(folders);
        setSelectedMedias(photos);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setElmtLoading(false);
    }
  }

  const handlePreviewClick = async () => {
    try {
      setElmtLoading(true);

      await savePdfData();

      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          id_mission: props.idMission
        },
        responseType: 'arraybuffer'
      };

      const response = await axios.get(MISSIONPROTISPDFFILE_URL, config);

      // Let the user save the file.
      var blob = new Blob([response.data], { type: 'application/pdf' });

      saveAs(blob, Date.now() + ' - ' + props.idMission + '.pdf');
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    } finally {
      setElmtLoading(false);
    }
  }

  const savePdfData = async () => {
    try {
      const photosIds = [];
      const containersIds = [];
      selectedFolders.forEach((row) => {
        row.photos.map((photo) => {
          if (photo.isSelected) {
            photosIds = [...photosIds, photo.id];
          }
        });
        containersIds.push(row.id);
      });
      selectedMedias.forEach((row) => {
        if (row.isSelected) {
          photosIds.push(row.id);
        }
      });

      var config = {
        method: 'post',
        url: MISSIONPROTISPDF_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: {
          id_mission: props.idMission,
          mission_photos: photosIds,
          mission_containers: containersIds,
          show_mission_comment: showMissionComment ? 1 : 0,
          group_by_folder: groupByFolder ? 1 : 0,
          show_folders_comment: showFoldersComment ? 1 : 0,
          show_photo_type: showPhotoType ? 1 : 0,
          show_photo_date: showPhotoDate ? 1 : 0,
          show_photo_comment: showPhotoComment ? 1 : 0
        }
      };

      await axios(config);

      props.setSnackBarMessage('Pdf configuration saved');
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    } catch (err) {
      setElmtLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const handleRestoreClick = () => {
    resetForm();
    prepareFullTree(protisFullData, withoutVideo);
    initSelected();
  }


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        className={"modalPopin info"}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '99%',
            width: '60%',
            bgcolor: 'rgb(52,52,52)',
            //border: '2px solid #000',
            boxShadow: 24,
            borderRadius: 2,
            p: 2,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ margin: 0, color: 'white' }}>PDF Configuration</h4>
            </div>
            <IconButton aria-label="close" onClick={() => props.setOpen(false)}>
              <CloseIcon style={{ marginBottom: '3px', fontSize: '30px', color: 'white' }} />
            </IconButton>
          </div>
          <div className="pdfModalCheckboxDiv">
            <FormControlLabel control={<Checkbox checked={showMissionComment ? true : false} onChange={(e) => { setShowMissionComment(!showMissionComment) }} />} label="Show mission comment" />
          </div>
          <div className="pdfModalCheckboxDiv">
            <FormControlLabel control={<Checkbox checked={groupByFolder ? true : false} onChange={(e) => { handleGroupByFolderChange(!groupByFolder) }} />} label="Group by folder" />
            <FormControlLabel control={<Checkbox checked={showFoldersComment ? true : false} onChange={(e) => { setShowFoldersComment(!showFoldersComment) }} />} label="Show folder comment" />
          </div>
          <div className="pdfModalCheckboxDiv">
            <FormControlLabel control={<Checkbox checked={showPhotoType ? true : false} onChange={(e) => { setShowPhotoType(!showPhotoType) }} />} label="Show photo type" />
            <FormControlLabel control={<Checkbox checked={showPhotoDate ? true : false} onChange={(e) => { setShowPhotoDate(!showPhotoDate) }} />} label="Show photo date" />
            <FormControlLabel control={<Checkbox checked={showPhotoComment ? true : false} onChange={(e) => { setShowPhotoComment(!showPhotoComment) }} />} label="Show photo comment" />
          </div>
          <div style={{ display: 'flex', height: '75%', marginTop: '10px' }}>
            <div style={{ width: '49%', margin: '1%', height: '90%', border: '1px solid lightgray', overflow: 'auto' }}>
              <PdfRecursiveListItem
                {...props}
                withoutVideo={withoutVideo}
                currentFolderId={currentFolderId}
                setCurrentFolderId={setCurrentFolderId}
                currentFolderData={protisFullTree}
                addMediaToSelected={addMediaToSelected} />
            </div>
            <div style={{ width: '49%', margin: '1%', height: '90%', border: '1px solid lightgray', overflow: 'auto' }}>
              {showMissionComment ? <div className='containerPdfModal'>
                <div className='photoPdfModalColumnDiv'>
                  <div className='photoPdfModalComment'>{props.photosComment}</div>
                </div>
                <div className='containerSeparator'></div>
              </div> : ''}
              {selectedFolders.map((element, index) => (
                <div key={index} className='containerPdfModal'>
                  <div className='containerPdfModalHeader'>
                    <div className={`photoPdfModalColumnDiv`} style={{ width: '6%' }}>
                      <IconButton sx={{ color: 'lightGrey', padding: '0px', display: element.selectOrder > 0 ? 'inline-block' : 'none' }} onClick={() => handleFolderUpClick(element)}>
                        <ExpandLess className='photoPdfArrowIcon' />
                      </IconButton>
                      <IconButton sx={{ color: 'lightGrey', padding: '0px', display: element.selectOrder !== selectedFolders.filter((folder) => folder.isSelected).length - 1 ? 'inline-block' : 'none' }} onClick={() => handleFolderDownClick(element)}>
                        <ExpandMore className='photoPdfArrowIcon' />
                      </IconButton>
                    </div>
                    <div className='photoPdfModalColumnDiv' style={{ width: '93%' }}>
                      <div><b>{element.label}</b></div>
                      {showFoldersComment ? <div className='photoPdfModalComment'>{element.comment}</div> : ''}
                    </div>
                  </div>
                  {element.photos.map((photo, i) => (
                    photo.isSelected && <div key={i} className='photoPdfModal'>
                      <div className={`photoPdfModalColumnDiv`} style={{ width: '8%' }}>
                        <IconButton sx={{ color: 'lightGrey', display: photo.selectOrder > 0 ? 'inline-block' : 'none' }} onClick={() => handleMediaUpClick(photo, element)}>
                          <ExpandLess className='photoPdfArrowIcon' />
                        </IconButton>
                        <IconButton sx={{ color: 'lightGrey', display: photo.selectOrder !== element.photos.filter((folderPhoto) => folderPhoto.isSelected).length - 1 ? 'inline-block' : 'none' }} onClick={() => handleMediaDownClick(photo, element)}>
                          <ExpandMore className='photoPdfArrowIcon' />
                        </IconButton>
                      </div>
                      <div className={`photoPdfModalColumnDiv`} style={{ maxWidth: '30%' }}>
                        <IconButton sx={{ color: 'red' }} onClick={() => handleRemoveMediaClick(photo)}>
                          <ProtisPhoto
                            {...props}
                            element={photo}
                            width={60}
                            height={60}
                            loadPhoto={photo.isLoaded ? false : true}
                            handleLoadedPhoto={handleLoadedPhoto}
                          />
                          <Delete className='photoPdfModalIcon' style={{ width: '50px', height: '50px' }} />
                        </IconButton>
                      </div>
                      <div className={`photoPdfModalColumnDiv`} style={{ width: '60%' }}>
                        {showPhotoType ? <div><b>{photo.type?.label}</b></div> : ''}
                        {showPhotoDate ? <div className='photoPdfModalComment'>{photo.created_at}</div> : ''}
                        {showPhotoComment ? <div className='folderCommentShort'>{photo.comment}</div> : ''}
                      </div>
                    </div>
                  ))}
                  <div className='containerSeparator'></div>
                </div>
              ))}
              {selectedMedias.map((element, index) => (
                <div key={index} className='photoPdfModal'>
                  <div className={`photoPdfModalColumnDiv`} style={{ width: '8%' }}>
                    <IconButton sx={{ color: 'lightGrey', display: element.selectOrder > 0 ? 'inline-block' : 'none' }} onClick={() => handleMediaUpClick(element)}>
                      <ExpandLess className='photoPdfArrowIcon' />
                    </IconButton>
                    <IconButton sx={{ color: 'lightGrey', display: element.selectOrder !== selectedMedias.length - 1 ? 'inline-block' : 'none' }} onClick={() => handleMediaDownClick(element)}>
                      <ExpandMore className='photoPdfArrowIcon' />
                    </IconButton>
                  </div>
                  <div className={`photoPdfModalColumnDiv`} style={{ maxWidth: '30%' }}>
                    <IconButton sx={{ color: 'red' }} onClick={() => handleRemoveMediaClick(element)}>
                      <ProtisPhoto
                        {...props}
                        element={element}
                        width={60}
                        height={60}
                        loadPhoto={!element.isLoaded}
                        handleLoadedPhoto={handleLoadedPhoto}
                      />
                      <Delete className='photoPdfModalIcon' style={{ width: '50px', height: '50px' }} />
                    </IconButton>
                  </div>
                  <div className={`photoPdfModalColumnDiv`} style={{ width: '60%' }}>
                    {showPhotoType ? <div><b>{element.type?.label}</b></div> : ''}
                    {showPhotoDate ? <div className='photoPdfModalComment'>{element.created_at}</div> : ''}
                    {showPhotoComment ? <div className='folderCommentShort'>{element.comment}</div> : ''}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              onClick={(e) => { handleResetClick(); }}
              variant="contained"
              disabled={
                ((selectedMedias.length == 0 && selectedFolders.length == 0) || elmtLoading)
                && !showMissionComment
                && !groupByFolder
                && !showFoldersComment
                && !showPhotoType
                && !showPhotoDate
                && !showPhotoComment}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: 'red',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={modalLoading}
            >
              Reset
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleSelectAllClick(); }}
              variant="contained"
              disabled={elmtLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={modalLoading}
            >
              Select all
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleRestoreClick(); }}
              variant="contained"
              disabled={elmtLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={modalLoading}
            >
              Restore last saved
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handlePreviewClick(); }}
              variant="contained"
              disabled={(selectedMedias.length == 0 && selectedFolders.length == 0) || elmtLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={modalLoading}
            >
              Preview
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleSaveClick(); }}
              variant="contained"
              disabled={(selectedMedias.length == 0 && selectedFolders.length == 0) || elmtLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={modalLoading}
            >
              Save
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleSharepointClick(); }}
              variant="contained"
              disabled={(selectedMedias.length == 0 && selectedFolders.length == 0) || elmtLoading}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={modalLoading}
            >
              Save to Sharepoint
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>);
};

export default PdfModal;