import React from 'react';

import logo from 'imgs/logo.svg';
import 'styles/Login.css';
import 'styles/RenewPassword.css';
import 'bootstrap/dist/css/bootstrap.css';

const RenewPasswordDone = () => {

    return (
      <div className="Login">
        <div className="welcome-to-gyptis">Gyptis - Reset Your Password</div>
        <div className="Login-box">
          <a href="/"><div className="Login-logo"><img src={logo} alt="JLB Expertises" /></div></a>
          <div className="Login-formulaire">
              <p className="info-reset-passwd">Please check your email and click the provided link to finish resetting your password.</p>
          </div>
        </div>
      </div>
    );
}

export default RenewPasswordDone;
