import { useNavigate } from "react-router-dom";
import React, {useEffect} from "react";
import {checkSecurity} from './Security';

const Routing = () => {

    // CHECK SECURITY
    const navigate = useNavigate();

    useEffect(() => {
      checkSecurity(navigate);
    }, []);
    // END SECURITY

    return (
        <div>ROUTING</div>
    )
}

export default Routing
