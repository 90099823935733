import React, { useEffect, useState } from 'react';
import axios from 'api/axios';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { randomId } from "@mui/x-data-grid-generator";
import AddElement from 'components/page/object/AddElement';
import DetailField from 'components/page/object/DetailField';
import { isCustomerRole } from 'components/config/Roles';
import { getCastTypeFromValue } from 'components/page/object/Utils';

import moment from 'moment';

/* SELECT */
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
/* END SELECT */

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SelectCustom = (props, ref) => {

  const navigate = useNavigate();

  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("");
  const [duplicate, setDuplicate] = useState(false);

  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  var dataVal = props.dataValue;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  /* SELECT */
  const handleChange = (event) => {
    const value = event.target.dataset.value;
    const alias = event.target.dataset.alias;
    var data = null;

    if(event.target.dataset.item != undefined){
      data = JSON.parse(event.target.dataset.item);
    }
    
    if (props.targetItem) {
      props.setDataValue(value, alias, alias, props.targetItem)
    } else {
      props.setDataValue(value, alias, alias, data);
    }
    if (props.setDataAlias) props.setDataAlias(alias);
  }

  const handleChangeMultiple = (event) => {
    const { target: { value } } = event;

    var data = [], alias = [];
    if (value.length > 0 && dataFilter.length > 0) {
      value.map((val) => {
        const callType = getCastTypeFromValue(val);
        const dataVal = dataFilter.find(options => callType(options.id) === callType(val));
        data.push(dataVal);
        alias.push(dataVal?.alias);
      });
    }

    if (props.targetItem) {
      props.setDataValue(value, alias, alias, props.targetItem)
    } else {
      props.setDataValue(value, alias, alias, data);
    }
    if (props.setDataAlias) props.setDataAlias(alias);
  }
  /* END SELECT */

  function setNewElement(data, label, alias){
    setDataFilter((oldRows) => [...oldRows, props.creator(data)]);
    if (props.targetItem) {
      props.setDataValue(data.id, label, alias, props.targetItem)
    } else {
      props.setDataValue(data.id, label, alias, data);
    }
  }

  const checkDataValue = () => {
    if (Array.isArray(props.dataValue) && props.dataValue.length > 0 && dataVal.length > 0) {
      props.dataValue.map((options, idx) => {
        const callType = getCastTypeFromValue(options);
        var dataId = dataFilter.findIndex(obj => callType(obj.id) === callType(options));
        if (dataId < 0) {
          delete props.dataValue[idx];
          props.setDataValue(props.dataValue, props.defaultAlias, props.defaultAlias, props.targetItem);
        }
      });
    } else if ((typeof props.dataValue === 'string' && props.dataValue !== '' && dataVal !== '')
      || (typeof props.dataValue === 'number' && parseInt(props.dataValue) !== 0 && parseInt(dataVal) !== 0)) {
      const callType = getCastTypeFromValue(props.dataValue);
      var dataId = dataFilter.findIndex(obj => callType(obj.id) === callType(props.dataValue));
      if (dataId < 0) {
        dataVal = 0;
        props.setDataValue(dataVal, props.defaultAlias, props.defaultAlias, props.targetItem);
      }
    }
  }

  const getList = async (e) => {
    try {
      if(typeof props.apiParam != "undefined"){
        setLoading(false);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            }
        };

        var getValue = "";
        if (props.apiParam.length > 0) {
          getValue = "?"+props.apiParam+"="+props.apiParamValue;
        }

        //GESTION DU CACHE
        if (props.cacheTTL && localStorage.getItem(props.api+getValue+"TTL")) {
          if (moment.unix(localStorage.getItem(props.api+getValue+"TTL")).unix() <  moment().unix()) {
            localStorage.removeItem(props.api+getValue);
            localStorage.removeItem(props.api+getValue+"TTL");
          }
        }

        if (!localStorage.getItem(props.api+getValue)||!props.cached) {
          var response = await axios.get(props.api+getValue, config);
          localStorage.setItem(props.api+getValue, JSON.stringify(response));
          if (props.cacheTTL) {
            localStorage.setItem(props.api+getValue+"TTL", Number(moment().unix())+Number(props.cacheTTL));
          }
        }else {
          var response = JSON.parse(localStorage.getItem(props.api+getValue));
        }
        //END OF CACHE
        
        var rowsFromApi = [];
        var responseData = Array.isArray(response.data) ? response.data : response.data.data;

        for (var i = 0; i < responseData?.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = typeof props.creator === 'function' ? props.creator(responseData[i]) : '';
        }
        var sort = true;
        if (props.nosort) sort = false;
        if (sort == true) rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
        setDataFilter(rowsFromApi);

        dataVal = props.dataValue;
        if (dataFilter.length > 0) {
          checkDataValue();
        }
        if (props.defaultValue) props.setDataValue(props.defaultValue);
      }
    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        console.debug(err);
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally
    {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (props.duplicate) setDuplicate(true);
    //if (typeof props.api != "undefined" && props.api.length > 0) getList();
    if (!!props.api == false || props.api.length <= 0) {
      var rowsFromApi = [];
      if (!!props.datas !== false) {
        for(var i=0; i < props.datas.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = props.creator(props.datas[i]);
        }
      }
      var sort = true;
      if (props.nosort) sort = false;

      if (sort==true) rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      
      setDataFilter(rowsFromApi);

      dataVal = props.dataValue;
      if (dataFilter.length > 0) {
        if (((Array.isArray(props.dataValue) && props.dataValue.length > 0) || props.dataValue > 0 || props.dataValue !== '') && props.dataValue != dataVal)
          dataVal = props.dataValue;
        checkDataValue();
      }
    }
  }, []);

  useEffect(() => {
    if (typeof props.api != "undefined" && props.api.length > 0) getList();
  }, [props.apiParamValue]);

  useEffect(() => {
    if (!!props.api == false || props.api.length <= 0){
      var rowsFromApi = [];
      if (!!props.datas !== false) {
        for(var i=0; i < props.datas.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = props.creator(props.datas[i]);
        }
      }
      var sort = true;
      if (props.nosort) sort = false;

      if (sort==true) rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      
      setDataFilter(rowsFromApi);
    }
    
    if(dataFilter.length > 0) {
      if (((Array.isArray(props.dataValue) && props.dataValue.length > 0) || props.dataValue > 0 || props.dataValue !== '') && props.dataValue != dataVal) 
        dataVal = props.dataValue;
      checkDataValue();
    }
  }, [props]);

  var paddingRight = "0px";
  if (props.enableAddButton)
  {
      paddingRight = "30px";
  }

  var size='18%';
  if(props.size)
  {
    if (props.size == 'large')
    {
      size = '95%';
    }
    
    if (props.size == 'medium') 
    {
      size = '40%'
    }
  }

  var canRead = 1;
  var canWrite = 1;
  var isRequired = false;

  if (props.controlFieldsStatus) {
    canRead = props.read;
    canWrite = props.write;
    isRequired = props.required;
  } else if (typeof props.required != "undefined") {
    isRequired = props.required;
  }

  var disableNone = (typeof props.disableNone != "undefined") ? props.disableNone : false;
  if (isRequired) disableNone = "true";

  const definitiveValue = !props.isDuplicate ? dataVal : props.dataValue;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  const renderMultipleValue = (selected) => {
    const render = [];
    if (dataFilter.length > 0) {
      selected.map((val) => {
        const callType = getCastTypeFromValue(val);
        const dataVal = dataFilter.find(options => callType(options.id) === callType(val));
        render.push(dataVal.labelValue);
      });
    }
    return render?.join(', ');
  }


  //auto open
  const [selectOpen, setSelectOpen] = useState(false);
  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  useEffect(() => {
    if (!!props?.autoOpen !== false) {
      handleSelectOpen();
    }
  }, [props?.autoOpen]);


  if (canWrite) {
    return (
    <FormControl className="selectCustom" sx={{ m: 1, width: size, paddingRight: paddingRight}} variant={ props.variant ? props.variant : 'outlined' } size="small" error={props.error} disabled={props.disabled} required={isRequired}>
      {props?.title && (<InputLabel id="demo-select-small">{props.title}</InputLabel>)}
      {Boolean(props.enableAddButton) && (
        <AddElement
          {...props}
          api={props.apiAddButton}
          setNewElement={setNewElement}
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          duplicate={duplicate}
          datas={dataFilter}
          parentApi={props.api}
          parentApiParam={!!props.apiParam != false ? props.apiParam : ''}
          parentApiParamValue={!!props.apiParamValue != false ? props.apiParamValue : ''}
          resetCache={typeof props.resetCache != "undefined" ? props.resetCache : "0"}
        />
      )}
      <Select
        inputRef={props?.inputRef}
        id={'select-custom-id-' + randomId()}
        labelId="select-custom-label"
        value={definitiveValue}
        label={props?.title ? props.title.toUpperCase().trim() : ''}
        error={props.error}
        style={{ 
          backgroundColor: props?.backgroundColor ? props.backgroundColor : '', 
          color: props?.colorText ? props?.colorText : '' 
        }}
        
        //multiple
        MenuProps={props?.multiple ? MenuProps : {}}
        multiple={props?.multiple ? props?.multiple : false}
        onChange={props?.multiple ? handleChangeMultiple : null}
        input={props?.multiple ? <OutlinedInput label="Tag" /> : null}
        renderValue={props?.multiple ? (selected) => (Array.isArray(selected) && selected.length > 0 ? renderMultipleValue(selected) : '') : null}

        open={selectOpen}
        onClose={handleSelectClose}
        onOpen={handleSelectOpen}
      >
      {!Boolean(disableNone) && (<MenuItem value={null} data-alias={props.defaultAlias? props.defaultAlias : "None"} onClick={handleChange}><em>None</em></MenuItem>)}
      {dataFilter.map((dataItem) => (
        <MenuItem 
          disabled={props?.multiple && dataItem?.checkedValue && !(definitiveValue ? (definitiveValue.findIndex(options => options === dataItem.id) > -1) : false)}
          key={dataItem.id} value={dataItem.id} data-alias={dataItem.alias} data-item={JSON.stringify(dataItem)} onClick={!props?.multiple ? handleChange : null}
        >
          {!props?.multiple ? (dataItem?.title ? dataItem.title.toUpperCase().trim() : '-') : '' }
          {props?.multiple && 
          <>
            <Checkbox checked={definitiveValue ? (definitiveValue.findIndex(options => options === dataItem.id) > -1) : false} />
            <ListItemText primary={dataItem.alias} />
          </>
          }
        </MenuItem>
      ))}
      </Select>
      {((props.error || !!props.infos !== false) && (!!props.helperText != false)) && <FormHelperText sx={{ ml: 0}}>{props.helperText}</FormHelperText>}
      <LinearProgress hidden={loading} />
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </FormControl>
    );
  }

  if (canRead){
    var theValue = "-";
    if (((Array.isArray(props.dataValue) && props.dataValue.length > 0) || props.dataValue > 0 || props.dataValue !== '') && dataFilter) {
      if (Array.isArray(props.dataValue) && props.dataValue.length > 0 && dataVal.length > 0) {
        theValue = '';
        props.dataValue.map((options, idx) => {
          const callType = getCastTypeFromValue(options);
          var itemId = dataFilter.findIndex(obj => callType(obj.id) === callType(options));
          if (itemId >= 0) {
            theValue += (" " + dataFilter[itemId].labelValue).toUpperCase().trim();
          }
        });
      } else {
        const callType = getCastTypeFromValue(props.dataValue);
        var itemId = dataFilter.findIndex(obj => callType(obj.id) === callType(props.dataValue));
        if(itemId >= 0){
          theValue = (" "+dataFilter[itemId].labelValue).toUpperCase().trim();
        }
      }
    }

    return (<DetailField title={props.title.toUpperCase().trim()} content={theValue} size={!JLBCustomerMode ? '':'large'} canRead={canRead} />);
  }
}
export default SelectCustom
