import React, { useState, forwardRef, useContext, useEffect, GridRenderEditCellParams } from 'react';
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import { randomId } from '@mui/x-data-grid-generator';

import { useGridApiRef, DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridDatePickerCustom, DataGridTextCustom, DataGridSelectCustom, DataGridSelectAutoCustom } from 'components/page/object/DataGridProCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetailsInstruction } from "./ContextMissionDetailsInstruction";

import axios from 'api/axios';


const MissionDetailsInstruction = forwardRef((props, ref) => {
  //init config
  const navigate = useNavigate();
  const dataGridRef = useGridApiRef();

  //generic
  const { setSnackErrorMsg } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //SI Instruction Grid
  const { rowsInstruction, setRowsInstruction } = useContext(ContextMissionDetailsInstruction);
  const { rowsInstructionDeleted, setRowsInstructionDeleted } = useContext(ContextMissionDetailsInstruction);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //list dataGrid
  const [rowsWarehouses, setRowsWarehouses] = useState([]);
  const [rowsInstructionTypes, setRowsInstructionTypes] = useState([]);


  //useEffect
  useEffect(() => {
    loadWarehouses();
    loadInstructionTypes();
  }, []);


  //function interactive
  const { deleteRowInstruction } = useContext(ContextMissionDetailsInstruction);
  const { processRowUpdateInstruction } = useContext(ContextMissionDetailsInstruction);

  const loadInstructionTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('si_instruction_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label };
      }
      setRowsInstructionTypes(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadWarehouses = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('warehouse/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = createWarehouseHandle(response.data[i]);
      }
      setRowsWarehouses(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const createWarehouseHandle = (data) => {
    var label = data.label;
    if (!!data.city !== false) label = data.city + ' - ' + data.label;
    return { id: data.id, value: data.id, label: label, alias: label, labelValue: label, city: data.city };
  }

  function EditToolbarInstruction(props) {
    const { setRowsInstruction, idMission } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsInstruction((oldRows) => [...oldRows, {
        id: id,
        id_mission: idMission,
        id_si_instruction: null,
        id_instruction_type: 0,
        id_place_of_operations: 0,
        begining_date: '',
        completion_date: '',
        dhl: '',
        remark: ''
      }]);
    };

    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Instruction
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }


  //xGrid
  var listColumnInstruction = [];
  //mode edit
  if (props.editable) {
    listColumnInstruction.push({
      field: 'id', headerName: '', editable: false, sortable: false, width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => { deleteRowInstruction(cellValues.id) }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnInstruction.push(
    { field: 'id_instruction_type', headerName: 'Type', sortable: false, type: 'singleSelect', width: 150, editable: props.editable,
      valueOptions: rowsInstructionTypes,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateInstruction}
            error={params.value == 0 ? true : false}
            required={true}
          />
        )
      }
    },
    { field: 'id_place_of_operations', headerName: 'Place of Operations', sortable: false, type: 'singleSelect', width: 150, editable: props.editable,
      valueOptions: rowsWarehouses,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectAutoCustom
            {...params}
            processRowUpdate={processRowUpdateInstruction}

            apiUrl="warehouse/list"
            title="Place of Operations"
            //resultFormat={{ 'label': ['city', '-', 'label'] }}

            enableAddButton={true}
            apiAddButton="warehouse"
            classType="addButtonSelect1 autocomplete"
            apiAddBackValueConcat={true}
            setValueOptions={setRowsWarehouses}
            setCreatorHandle={createWarehouseHandle}
            field1={{ field: "label", label: "Label", required: true }}
            field2={{ field: "city", label: "City", required: true }}
            field3={{ field: "id_port", label: "Port", api: "port/list", required: true, autocomplete: true }}
            cached={true}
            cacheTTL="600"
            resetCache="1"
          />
        )
      }
    },
    { field: 'begining_date', headerName: 'Begining Date', width: 250, sortable: true, type: "date", editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            processRowUpdate={processRowUpdateInstruction}
          />
        )
      }
    },
    { field: 'completion_date', headerName: 'Completion Date', width: 250, sortable: true, type: "date", editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            processRowUpdate={processRowUpdateInstruction}
          />
        )
      }
    },
    { field: 'dhl', headerName: 'DHL', width: 250, sortable: true, flex: 1, editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            processRowUpdate={processRowUpdateInstruction}
          />
        )
      }
    },
    { field: 'remark', headerName: 'Remark', width: 250, sortable: true, flex: 1, editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            processRowUpdate={processRowUpdateInstruction}
          />
        )
      }
    }
  );
  const columnsInstruction: GridColDef[] = listColumnInstruction;


  return (
    <div className="lineContent noJustify">
      <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-si-instruction">
        <DataGridPro
          apiRef={dataGridRef}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          hideFooter={true}
          rows={rowsInstruction}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateInstruction}
          columns={columnsInstruction}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsInstruction, idMission: props.idMission },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: props.editable ? EditToolbarInstruction : null,
          }}
        />
      </FormControl>
    </div>
  );
});

export default MissionDetailsInstruction;
