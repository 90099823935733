import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PortraitIcon from '@mui/icons-material/Portrait';
import axios from 'api/axios';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CompanyContact = (props) => {

    const navigate = useNavigate();
    //routes api
    const GETZONESCOUNTRIES = 'zone/country/list';
    const GETCOMPANIESCONTACT = 'company/contacts';

    //snackbar for technical error api
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [snackBarType, setSnackBarType] = React.useState("");

    //props
    const [rowsZones, setRowsZones] = React.useState([]);   //list of area with his countries
    const [rowsAllCountries, setRowsAllCountries] = React.useState([]);   //list of all countries
    const [rowsCompaniesContacts, setRowsCompaniesContacts] = React.useState([]);   //list of companies with his default contact
    const [zoneId, setZoneId] = React.useState(0);    //area selected
    const [countryId, setCountryId] = React.useState(0);    //country selected
    const [countryName, setCountryName] = React.useState("");   //name of country selected
    const [hasDefaultCompany, setHasDefaultCompany] = React.useState(false);    //flag if default company (marseille)

    const handleClose = () => props.onCloseModalClick();

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackBar(false);
    };

    //Init zones+countries
    useEffect(()=>{
      getZonesCountries();
    },[]);

    //Refresh companies contact list
    useEffect(()=>{
      var itemId = rowsAllCountries.findIndex(obj => Number(obj.id) === Number(countryId));
      if (itemId >= 0) setCountryName(rowsAllCountries[itemId].label);
      getCompaniesContact(countryId);
    },[countryId]);

    //call api for zones+countries
    const getZonesCountries = async () => {
      try
      {
        var data = new FormData();
        var config = {
          url: GETZONESCOUNTRIES,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        };
        const reponseGet = await axios(config);

        //INIT Zones
        var rowsZonesApi = [];
        var rowsCountriesApi = [];
        if (reponseGet.data) {
          for(var i=0; i < reponseGet.data.length; i++) {
            rowsZonesApi[i] = {};
            rowsZonesApi[i].id = reponseGet.data[i].id;
            rowsZonesApi[i].label = reponseGet.data[i].label;
            rowsZonesApi[i].countries = reponseGet.data[i].countries;
            rowsZonesApi[i].countries.sort((a, b) => (a.label > b.label) ? 1 : -1);

            //log of all countries in an array
            //init number of countries already logged
            var nCountry = rowsCountriesApi.length;
            for(var n=0; n < rowsZonesApi[i].countries.length; n++) {
              //increase key with number of countries already logged
              rowsCountriesApi[(n + nCountry)] = rowsZonesApi[i].countries[n];
            }
          }
        }
        rowsZonesApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
        rowsCountriesApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
        setRowsZones(rowsZonesApi);
        setRowsAllCountries(rowsCountriesApi);
      }
      catch (err)
      {
        console.log(err);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        }
        else
        {
          setSnackBarMessage("Technical error ! Please try again or contact our support.");
          setSnackBarType("error");
          setOpenSnackBar(true);
        }
      }
    }

    //call api for campanies contact
    const getCompaniesContact = async (countryId) => {
      if(countryId > 0) {
        try
        {
          var config = {
            url: GETCOMPANIESCONTACT,
            method: 'get',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'multipart/form-data',
            },
            params: {
              type: 'Local Office',
              id_country: countryId,
              log: 1
            }
          };
          const reponseGet = await axios(config);

          //INIT Company Contact
          var isDefaultCompany = false;
          var rowsCompaniesContactsApi = [];
          if (reponseGet.data.data) {
            for(var i=0; i < reponseGet.data.data.length; i++) {
              if(reponseGet.data.data[i].company.default_company) isDefaultCompany = true;
              rowsCompaniesContactsApi[i] = reponseGet.data.data[i].company;
            }
          }
          setRowsCompaniesContacts(rowsCompaniesContactsApi);
          setHasDefaultCompany((rowsCompaniesContactsApi.length == 1 && isDefaultCompany));
        }
        catch (err)
        {
          console.log(err);
          if (err.response?.status === 401) {
              navigate("/logout", { replace: true });
          }
          else
          {
            setSnackBarMessage("Technical error ! Please try again or contact our support.");
            setSnackBarType("error");
            setOpenSnackBar(true);
          }
        }
      }
    }

    //display all countries or countries of area in 3 columns
    const displayCountries = (idZone, arrData) =>  {
      var arrCountries = [];
      if(idZone >= 1) {
        var itemId = arrData.findIndex(obj => Number(obj.id) === Number(idZone));
        arrCountries = arrData[itemId].countries;
      } else {
        arrCountries = arrData;
      }
      
      if(arrCountries.length >= 1) {
        var arrColumn = [], nColumn = Math.floor(arrCountries.length / 3);
        if((nColumn * 3) < arrCountries.length)
          nColumn++;

        arrColumn[0] = arrCountries.slice(0, nColumn);
        arrColumn[1] = arrCountries.slice(nColumn, (nColumn * 2));
        arrColumn[2] = arrCountries.slice((nColumn * 2), arrCountries.length);

        return (
          <>
          {React.Children.toArray(arrColumn.map((countries, i) => {
            return (
            <ul className="blockList">
              {React.Children.toArray(countries.map((item, k) => {
                  return (
                  <li>
                    <span className={(countryId == item.id ? 'active' : '')} onClick={(event) => { setCountryId(item.id) }}>{item.label}</span>
                  </li>
                  );
              }))}
            </ul>
            );
          }))}
          </>
        );
      }
    }
    
    /*<Slide className={(rowsCompaniesContacts.length >= 3 ? 'slideCollapse big' : 'slideCollapse')} direction="right" in={props.isOpen} timeout={350}>*/
    return (
      <Slide className='slideCollapse big' direction="right" in={props.isOpen} timeout={350}>
        <div className="slideCompanyContact">
          <div className="slideHeader">
            Find a local office
            <span className="slideClose">
                <IconButton aria-label="close" onClick={handleClose}>
                    <HighlightOffIcon style={{ fontSize: 75 }} />
                </IconButton>
            </span>
          </div>
          <div className="slideContent">

            <div className="block blockZone">
              <div className="blockTitle">Area</div>
              <div className="blockContent">
                <ul>
                  <li>
                    <span className={(zoneId == 0 ? 'active' : '')} onClick={(event) => { setZoneId(0) }}>All</span>
                  </li>
                  {rowsZones.length >= 1 && React.Children.toArray(rowsZones.map((item, index) => {
                      return (
                      <li>
                        <span className={(zoneId == item.id ? 'active' : '')} onClick={(event) => { setZoneId(item.id) }}>{item.label}</span>
                      </li>
                      );
                  }))}
                </ul>
              </div>
            </div>

            <div className="block blockCountry">
              <div className="blockTitle">Country</div>
              <div className="blockContent">

                {(zoneId == 0) && displayCountries(zoneId, rowsAllCountries)}
                {(zoneId >= 1) && displayCountries(zoneId, rowsZones)}
                <div className="spacer"></div>

              </div>
            </div>

            <div className="block blockContact">
              <div className="blockTitle">Office & contact</div>
              <div className="blockContent">

                {(countryId == 0) && (
                <ul className="blockList">
                  <li>
                    <span className="txt">Select a country</span>
                  </li>
                </ul>
                )}

                {(countryId >= 1 && rowsCompaniesContacts.length >= 1) && (
                  <>
                  {React.Children.toArray(rowsCompaniesContacts.map((item, index) => {
                    return (
                    <>
                    <ul className="blockList">
                      {item.jlb === 0 ? (
                        <div>
                          <li className="company_name">JLB Expertises</li>
                          {item.name && <li className="company_co_name">c/o {item.name}</li>}
                        </div>
                      ) : (
                        <div>
                          {(item.name) && (<li className="company_name">{item.name}</li>)}
                        </div>
                      )}
                      {(item.adresse) && (<li className="company_address">
                        <PinDropOutlinedIcon className="icon" fontSize="small" sx={{ color: 'text.disabled' }} />
                        {item.adresse}
                      </li>)}
                      {(item.cp || item.ville) && (<li className="company_address_comp">{item.cp} {item.ville}</li>)}
                      {(item.tel) && (<li className="company_phone">
                        <PhoneInTalkIcon className="icon" fontSize="small" sx={{ color: 'text.disabled' }} />
                        {item.tel}
                      </li>)}
                      {(item.email) && (<li className="company_email">
                        <MailOutlineIcon className="icon" fontSize="small" sx={{ color: 'text.disabled' }} />
                        <a href={'mailto:'+item.email+((!hasDefaultCompany) ? '?cc=mail@jlbexpertises.com' : '')} title={'send email to '+item.email}>
                        {item.email}
                        </a>
                      </li>)}
                      <li>&nbsp;</li>
                      {(item.contact_default?.firstname || item.contact_default?.lastname) && (<li className="contact_name">
                        <PortraitIcon className="icon" fontSize="small" sx={{ color: 'text.disabled' }} />
                        {item.contact_default.firstname} {item.contact_default.lastname}
                      </li>)}
                      {(item.contact_default?.telephone) && (<li className="contact_phone">
                        <PhoneInTalkIcon className="icon" fontSize="small" sx={{ color: 'text.disabled' }} />
                        {item.contact_default.telephone}
                      </li>)}
                      {(item.contact_default?.email) && (<li className="contact_email">
                        <MailOutlineIcon className="icon" fontSize="small" sx={{ color: 'text.disabled' }} />
                        <a href={'mailto:'+item.contact_default.email+((!hasDefaultCompany) ? '?cc=mail@jlbexpertises.com' : '')} title={'send email to '+item.contact_default.email}>
                        {item.contact_default.email}
                        </a>
                      </li>)}
                    </ul>
                    <div className="spacer"></div>
                    
                    {/*(index % 2 != 0) && (
                      <>
                      <div className="spacer"></div>

                      {(index < (rowsCompaniesContacts.length - 1)) && (
                        <div className="separator"></div>
                      )}
                      </>
                    )*/}
                    
                    {(index < (rowsCompaniesContacts.length - 1)) && (
                      <div className="separator"></div>
                    )}
                    </>
                    );
                  }))}
                  </>
                )}
                <div className="spacer"></div>

                {(rowsCompaniesContacts.length >= 1 && !hasDefaultCompany) && (
                  <>
                  {/*(rowsCompaniesContacts.length % 2 == 0) && (
                    <div className="blockPsMarge"></div>
                  )*/}
                  <div className="blockPsMarge"></div>
                  <div className="blockPs">
                    When you contact us, please to copy Marseille office at <a href="mailto:mail@jlbexpertises.com" title="send email to mail@jlbexpertises.com">mail@jlbexpertises.com</a>
                  </div>
                  </>
                )}

              </div>
            </div>

            <div className="spacer"></div>

          </div>
          <Snackbar className="slideSnackbar" open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
              {snackBarMessage}
            </Alert>
          </Snackbar>
        </div>
      </Slide>
    );
}
export default CompanyContact