import React, {useContext, useState} from 'react';

import { MissionDetailsProtisProvider } from "./ContextMissionDetailsProtis";

import {isCustomerRole} from 'components/config/Roles';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import MissionDetailsProtisForm from './MissionDetailsProtisForm';


const MissionDetailsProtis = (props) => {

  //init config
  const {update} = useContext(ContextBlock);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //init mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  const {latestSyncAt, setLatestSyncAt} = useContext(ContextMissionDetails);
  const {photosComment, setPhotosComment} = useContext(ContextMissionDetails);
  const { reloadFileBlock, setReloadFileBlock } = useContext(ContextBlock);


  if (update == 1 && !JLBCustomerMode) {
    return (
      <MissionDetailsProtisProvider>
        <MissionDetailsProtisForm
        fileForm={props?.fileForm}
        idMission={idMission}
        latestSyncAt={latestSyncAt}
        photosComment={photosComment}
        setPhotosComment={setPhotosComment}
        missionCanceled={missionCanceled}

        setOpenSnackBar={setOpenSnackBar}
        setSnackBarType={setSnackBarType}
        setSnackBarMessage={setSnackBarMessage}
        setReloadFileBlock={setReloadFileBlock}
        />
      </MissionDetailsProtisProvider>
    );
  }
}

export default MissionDetailsProtis
