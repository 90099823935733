import React, { useState, forwardRef, useContext, useEffect } from 'react';

import { isInternalRole } from 'components/config/Roles';

import SelectCustom from 'components/select/SelectCustom';
import TextFieldCustom from 'components/select/TextFieldCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import BlockFormSaveButton from '../../Block/BlockFormSaveButton';
import ShippingInstructionGeneralInstructionList from './ShippingInstructionGeneralInstructionList';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionGeneral } from "./ContextShippingInstructionGeneral";


const ShippingInstructionGeneralForm = forwardRef((props, ref) => {

  //init config
  const { generalRef } = useContext(ContextShippingInstruction);
  const { contentRef } = useContext(ContextShippingInstruction);
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //composant company ref
  const { companyTraderSelectRef } = useContext(ContextBlock);
  const { companyShipperSelectRef } = useContext(ContextBlock);
  const { companyPrincipalsSelectRef } = useContext(ContextBlock);
  const { companyLocalOfficeSelectRef } = useContext(ContextBlock);

  //generic
  const { loading, setLoading } = useContext(ContextBlock);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //init control for save
  const [saveDisabled, setSaveDisable] = useState(false);
  const { blockGrid, getErrorBlockGrid } = useContext(ContextBlock);

  //si general data
  const { portDeparture, setPortDeparture } = useContext(ContextShippingInstructionGeneral);
  const { portDepartureId, setPortDepartureId } = useContext(ContextShippingInstructionGeneral);
  const { setDatasPortDeparture } = useContext(ContextShippingInstructionGeneral);

  const { portDestination, setPortDestination } = useContext(ContextShippingInstructionGeneral);
  const { portDestinationId, setPortDestinationId } = useContext(ContextShippingInstructionGeneral);
  const { setDatasPortDestination } = useContext(ContextShippingInstructionGeneral);

  const { surveyHandler, setSurveyHandler } = useContext(ContextShippingInstructionGeneral);
  const { surveyHandlerDefault, setSurveyHandlerDefault } = useContext(ContextShippingInstructionGeneral);

  const { localOffice, setLocalOffice } = useContext(ContextShippingInstructionGeneral);
  const { setDatasOffice } = useContext(ContextShippingInstructionGeneral);

  const { principals, setPrincipals } = useContext(ContextShippingInstructionGeneral);
  const { principalsId, setPrincipalsId } = useContext(ContextShippingInstructionGeneral);
  const { setDatasPrincipals } = useContext(ContextShippingInstructionGeneral);

  const { trader, setTrader } = useContext(ContextShippingInstructionGeneral);
  const { setDatasTrader } = useContext(ContextShippingInstructionGeneral);

  const { shipper, setShipper } = useContext(ContextShippingInstructionGeneral);
  const { setDatasShipper } = useContext(ContextShippingInstructionGeneral);

  const { cargoType, setCargoType } = useContext(ContextShippingInstructionGeneral);
  const { setDatasCargoType } = useContext(ContextShippingInstructionGeneral);

  const { cargoLoadingTypeId, setCargoLoadingTypeId } = useContext(ContextShippingInstructionGeneral);
  const { cargoLoadingTypeDefault, setCargoLoadingTypeDefault } = useContext(ContextShippingInstructionGeneral);

  const { tonnage, setTonnage } = useContext(ContextShippingInstructionGeneral);
  const changeTonnageHandler = (event) => setTonnage(event.target.value);
  
  const { missionsVesselTonnage, setMissionsVesselTonnage } = useContext(ContextShippingInstructionGeneral);
  const { remainingTonnage, setRemainingTonnage } = useContext(ContextShippingInstructionGeneral);
  
  const { contractNumber, setContractNumber } = useContext(ContextShippingInstructionGeneral);
  const changeContractNumberHandler = (event) => setContractNumber(event.target.value);

  //handle
  const { saveShippingInstructionGeneral } = useContext(ContextShippingInstructionGeneral);
  const { handleClickCancel } = useContext(ContextShippingInstructionGeneral);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    if (contractNumber == "" || surveyHandler == "0" || principalsId == "0") {
      setLoading(true);
      //open error poppin
      triggerInfosPopin("Fields required", 'Contract Number, Survey Handler and Principal are required', "error");
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
      setLoading(false);
    } else {
      await saveShippingInstructionGeneral(true);
      await handleClickCancel(false);
    }
  }

  //useEffect
  useEffect(() => {
    setSaveDisable(getErrorBlockGrid(generalRef));
  }, [blockGrid]);

  // Update remaining tonnage
  useEffect(() => {
    if (tonnage) {
      var totalMissionsTonnage = 0;
      missionsVesselTonnage?.forEach(mission => {
        if (mission?.vessel_tonnage) {
          totalMissionsTonnage += mission.vessel_tonnage;
        }
      });
      setRemainingTonnage(tonnage - totalMissionsTonnage);
    }
  }, [tonnage, missionsVesselTonnage]);

  //function interactive
  const { companySelectRefresh } = useContext(ContextBlock);
  const { createSurveyHandlerHandler } = useContext(ContextShippingInstructionGeneral);
  const { createCargoLoadingTypeHandler } = useContext(ContextShippingInstructionGeneral);

  function createPortHandler(data) {
    return { label: data.label.toUpperCase().trim(), id: data.id, alias: data.nomenclature, labelValue: data.label.toUpperCase().trim() };
  }

  function createLocalOfficeHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createPrincipalsHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createTraderHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createShipperHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), alias: data.name, labelValue: data.name.toUpperCase().trim() };
  }

  function createCargoTypeHandler(data) {
    return { id: data.id, label: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }


  return (
    <div id="generalForm">
      <div className="lineContent noJustify">
        <TextFieldCustom
          id="contractNumber"
          label="Contract Number"
          variant="standard"
          value={contractNumber}
          required={true}
          onChange={changeContractNumberHandler}
        />

        <SelectAutoCustom
          title="Port Departure"
          api="port/list"
          apiParam=""
          apiParamValue=""
          dataValue={portDeparture}
          setDataValue={setDatasPortDeparture}
          defaultAlias="YYY"
          creator={createPortHandler}
          enableAddButton
          classType="addButtonSelect2"
          apiAddButton="port"
          field1={{ field: "label", label: "Label", required: true }}
          field2={{ field: "nomenclature", label: "Nomenclature", required: true, alreadyExist: true }}
          field3={{ field: "id_country", label: "Country", api: "country/list", required: true, autocomplete: true }}
          cached={true}
          cacheTTL="600"
          resetCache="1"
        />

        <SelectAutoCustom
          title="Port Destination"
          api="port/list"
          apiParam=""
          apiParamValue=""
          dataValue={portDestination}
          setDataValue={setDatasPortDestination}
          defaultAlias="YYY"
          creator={createPortHandler}
          enableAddButton
          classType="addButtonSelect2"
          apiAddButton="port"
          field1={{ field: "label", label: "Label", required: true }}
          field2={{ field: "nomenclature", label: "Nomenclature", required: true, alreadyExist: true }}
          field3={{ field: "id_country", label: "Country", api: "country/list", required: true, autocomplete: true }}
          cached={true}
          cacheTTL="600"
          resetCache="1"
        />

        <SelectCustom
          title="Survey handler"
          api="survey_handler/list"
          apiParam=""
          apiParamValue=""
          dataValue={surveyHandler}
          setDataValue={setSurveyHandler}
          setDataAlias=""
          defaultAlias=""
          defaultValue={surveyHandlerDefault}
          creator={createSurveyHandlerHandler}
          required={true}
          cached={true}
        />

        <SelectAutoCustom
          title="Local office"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Local Office"
          dataValue={localOffice}
          setDataValue={setDatasOffice}
          defaultAlias=""
          creator={createLocalOfficeHandler}
          ref={companyLocalOfficeSelectRef}
          companySelectRefresh={companySelectRefresh}
          cached={true}
        />

      </div>
      <div className="lineContent noJustify">
        <SelectAutoCustom
          title="Principals"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Principals"
          dataValue={principals}
          setDataValue={setDatasPrincipals}
          defaultAlias=""
          creator={createPrincipalsHandler}
          enableAddButton={JLBInternalMode}
          ref={companyPrincipalsSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: true, trader: false, insured: false, receiver: false, shipper: false, warehouseKeeper: false, stevedore: false },
            }}
          required={true}
          cached={true}
          cacheTTL="600"
          resetCache="1"
        />

        <SelectAutoCustom
          title="Trader"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Trader"
          dataValue={trader}
          setDataValue={setDatasTrader}
          defaultAlias=""
          creator={createTraderHandler}
          enableAddButton={JLBInternalMode}
          ref={companyTraderSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: false, trader: true, insured: false, receiver: false, shipper: false, warehouseKeeper: false, stevedore: false },
            }}
          cached={true}
          cacheTTL="600"
          resetCache="1"
        />

        <SelectAutoCustom
          title="Shipper"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Shipper"
          dataValue={shipper}
          setDataValue={setDatasShipper}
          defaultAlias=""
          creator={createShipperHandler}
          enableAddButton={JLBInternalMode}
          ref={companyShipperSelectRef}
          companySelectRefresh={companySelectRefresh}
          classType="addButtonSelect2"
          apiAddButton="company"
          field4={
            {
              componentName: "CompanyForm",
              componentClass: "companyFormClass",
              buttonDisableControls: true,
              thirdButton:
                { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
              initValues:
                { principals: false, trader: false, insured: false, receiver: false, shipper: true, warehouseKeeper: false, stevedore: false },
            }}
          cached={true}
          cacheTTL="600"
          resetCache="1"
        />

        <SelectAutoCustom
          title="Cargo"
          api="cargo/list"
          apiParam=""
          apiParamValue=""
          dataValue={cargoType}
          setDataValue={setDatasCargoType}
          setDataAlias=""
          defaultAlias=""
          creator={createCargoTypeHandler}
          enableAddButton={JLBInternalMode}
          classType="addButtonSelect2"
          apiAddButton="cargo"
          field1={{ field: "label", label: "Label", required: true }}
          cached={true}
          cacheTTL="600"
          resetCache="1"
        />

        <SelectCustom
          title="Type of Loading"
          api="cargo/loading_type/list"
          apiParam=""
          apiParamValue=""
          dataValue={cargoLoadingTypeId}
          setDataValue={setCargoLoadingTypeId}
          setDataAlias=""
          defaultAlias=""
          defaultValue={cargoLoadingTypeDefault}
          creator={createCargoLoadingTypeHandler}
          cached={true}
        />
      </div>

      <div className="lineContent noJustify">
        <TextFieldCustom
          id="tonnage"
          label="Tonnage"
          variant="standard"
          value={tonnage}
          type="decimal"
          onChange={changeTonnageHandler}
        />
        
        <TextFieldCustom
          id="remaining_tonnage"
          label="Remaining tonnage"
          variant="standard"
          value={remainingTonnage}r
          disabled={true}
        />
      </div>

      <ShippingInstructionGeneralInstructionList editable={true} />

      <BlockFormSaveButton blockRef={generalRef} blockCancel={async () => await handleClickCancel()} blockSave={handleClickSave} notWorkflowSaveButton={true} saveDisabled={saveDisabled} />

    </div>
  );
});

export default ShippingInstructionGeneralForm;
