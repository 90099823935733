import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import LaunchIcon from '@mui/icons-material/Launch';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";

import axios from 'api/axios';


const ContextMissionDetailsBL = createContext();

const MissionDetailsBLProvider = ({ children }) => {

  //config
  const navigate = useNavigate();
  const { blRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';
  const BL_EXIST_URL = 'mission/bl/exist';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //init mission
  const {idMission, setIdMission} = useContext(ContextMissionDetails);

  //treament
  const [blockCurrentSave, setBlockCurrentSave] = useState({});

  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    handleClickBlockCancel(blRef);
  }

  //init BL data
  const {rowsBL, setRowsBL} = useContext(ContextMissionDetailsReceiverBL);
  const {newRowsBL, setNewRowsBL} = useContext(ContextMissionDetailsReceiverBL);
  const {deletedRowsBL, setDeletedRowsBL} = useContext(ContextMissionDetailsReceiverBL);

  const [rowsBLView, setRowsBLView] = useState([]);
  const [rowsCheckBL, setRowsCheckBL] = useState([]);
  const [expandedRowsBLs, setExpandedRowsBLs] = useState([]);
  const [rowsSignaturePlace, setRowsSignaturePlace] = useState([]);
  const [rowsUnaffectedBls, setRowsUnaffectedBls] = useState([]);
  const [typesOfIntervention, setTypesOfIntervention] = useState([]);
  const [beansTypes, setBeansType] = useState([]);
  const [countries, setCountries] = useState([]);


  //INIT Bills of lading
  useEffect(() => {
    //load data
    if (rowsBL) {
      var rowsBlFromApi = [];
      if (rowsBL.length > 0) {
        var countryKey = [];
        for(var i=0; i < rowsBL.length; i++) {
          //array detail of bol
          //init key by country
          var kF  = 0;
          var kI  = countryKey.length;
          var kCI = (
            rowsBL[i].country != null 
            ? rowsBL[i].country.id 
            : (
              rowsBL[i].signature_place != null 
              ? rowsBL[i].signature_place.id 
              : 0
            )
          );
          if(kI > 0){
            kF = Object.keys(countryKey).find(key => countryKey[key] === kCI);
            if(kF != null){
              kI = kF;
            }
          }
          if(typeof countryKey[kI] == "undefined"){
            countryKey[kI] = kCI;
          }

          if(typeof rowsBlFromApi[kI] == 'undefined'){
            rowsBlFromApi[kI] = {};
            rowsBlFromApi[kI].id = (rowsBL[i].country != null ? rowsBL[i].country.id : 0);
            rowsBlFromApi[kI].country = (
              rowsBL[i].country != null 
              ? rowsBL[i].country.label 
              : (
                rowsBL[i].signature_place != null 
                ? rowsBL[i].signature_place.label 
                : "Unknown"
              )
            );
          }
          //init BL group by country
          if(typeof rowsBlFromApi[kI].bols == 'undefined'){
            rowsBlFromApi[kI].bols = [];
          }
          var kB = rowsBlFromApi[kI].bols.length;
          rowsBlFromApi[kI].bols[kB] = {};
          rowsBlFromApi[kI].bols[kB].id = rowsBL[i].id;
          rowsBlFromApi[kI].bols[kB].bol = rowsBL[i].bol;
          rowsBlFromApi[kI].bols[kB].type_of_intervention = rowsBL[i].type_of_intervention;
          rowsBlFromApi[kI].bols[kB].beans_type = rowsBL[i].beans_type;
          rowsBlFromApi[kI].bols[kB].bl_date = rowsBL[i].bl_date;
          rowsBlFromApi[kI].bols[kB].quantity = rowsBL[i].quantity;
          rowsBlFromApi[kI].bols[kB].deadline_date = rowsBL[i].deadline_date;
          rowsBlFromApi[kI].bols[kB].delivery_date = rowsBL[i].delivery_date;
          rowsBlFromApi[kI].bols[kB].smv = rowsBL[i].smv;
          rowsBlFromApi[kI].bols[kB].note = rowsBL[i].note;
        }
        setExpandedRowsBLs(rowsBlFromApi.map(rowBl => rowBl.id));
      }
      setRowsBLView(rowsBlFromApi);
    }
  }, [rowsBL]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(blRef, saveBLs);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === blRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);

  //init check bl already exist
  useEffect(() => {
    if (rowsBL.length > 0 && rowsCheckBL.length > 0){
      //check if checkBl exist in rowsBl
      var checkBl = true;
      rowsCheckBL.map((blId) => {
        const existBl = rowsBL.findIndex((rows) => rows.id === blId);
        if (existBl <= -1) {
          checkBl = false;
        }
      });
      if (checkBl) processRowsCheckMissionBL(rowsCheckBL);
    }
  }, [rowsBL, rowsCheckBL]);
  

  //function interactive
  const loadingTypesOfIntervention = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("type_of_intervention/list", config);

      var rowsFromApiTOI = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApiTOI[i] = {
          value: response.data[i].id,
          label: response.data[i].label.toUpperCase().trim()
        };
      }
      rowsFromApiTOI.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setTypesOfIntervention(rowsFromApiTOI);

    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadBeansType = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("beans_type/list", config);

      var rowsFromApiTOI = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApiTOI[i] = {
          value: response.data[i].id,
          label: response.data[i].label.toUpperCase().trim()
        };
      }
      rowsFromApiTOI.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setBeansType(rowsFromApiTOI);

    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  function refreshBLs(rows) {
    var signaturePlaces = [];
    var unaffectedBLs = [];
    if (rows !== undefined) {
      //preparing rows to pass on next components
      for (var i = 0; i < rows.length; i++) {
        //If signature place and BL date => new Presentation
        if (rows[i].signature_place && rows[i].bl_date) {
          var exists = signaturePlaces.find(signaturePlaceRow => {
            return signaturePlaceRow.id === (typeof rows[i].signature_place == "object" ? rows[i].signature_place.id : rows[i].signature_place);
          });
          if (!exists) {
            var isExpanded = (!!rows[i].isExpanded != false ? rows[i].isExpanded : false);
            if (typeof rows[i].signature_place == "object") {
              signaturePlaces.push({
                id: rows[i].signature_place.id,
                label: rows[i].signature_place.label,
                bl_rows: [rows[i]],
                isExpanded: isExpanded
              });
            } else {
              var country = countries.find(ctry => {
                return ctry.id === rows[i].signature_place;
              });
              signaturePlaces.push({
                id: rows[i].signature_place,
                label: country.label,
                bl_rows: [rows[i]],
                isExpanded: isExpanded
              });
            }
          } else {
            exists.bl_rows.push(rows[i]);
            if (!!rows[i].isExpanded != false && rows[i].isExpanded != false) {
              exists.isExpanded = true;
            }
          }
        } else {
          //Else => unaffected rows (old rows)
          var urows = structuredClone(rows[i]);
          if (!urows.signature_place) {
            urows.signature_place = "";
          }//we want just id
          else {
            if (typeof urows.signature_place == "object") {
              urows.signature_place = urows.signature_place.id;
            }
          }
          unaffectedBLs.push(
            urows
          );
        }
      }
    }
    setRowsBL(rows);
    setRowsSignaturePlace(signaturePlaces);
    setRowsUnaffectedBls(unaffectedBLs);
  }

  const constructBlMissionLink = (missionList, blList) => {
    return (
      <>
        BL(s) with the same place of operations, date(s) and number(s) is already present in the following mission(s) :
        <div className="info_content">
          <ul>
            {missionList.map((mission) => {
              const blNumbers = [];
              mission.bills_of_lading.map((bl) => {
                if (blList.find(e => bl.bl_date === momentDate(e.bl_date).format("YYYY-MM-DD") && bl.number === e.number && bl.country.id === e.id_country)) {
                  blNumbers.push(bl.number);
                }
              });

              return (
              <li className="withIcon">
                <a href={'/missionDetails?id=' + mission.id} target="_blank">
                  <LaunchIcon /> {mission.jlb_ref} ({blNumbers.join(' - ')})
                </a>
              </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }

  const processRowsCheckMissionBL = async (rows) => {
    try {
      const rowsCheck = rowsBL.filter((bl) => rows.includes(bl.id) && !!bl.bl_date !== false && momentDate(bl.bl_date).isValid() && !!bl.bol !== false && !!bl.signature_place !== false);
      //check data if not empty
      if (rowsCheck && rowsCheck.length > 0) {
        //limit and format data
        const rowsAPI = [];
        rowsCheck.map((row) => {
          rowsAPI.push({
            number: row.bol,
            bl_date: row.bl_date,
            id_country: row.signature_place.id
          });
        });

        var config = {
          url: BL_EXIST_URL,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          params: {
            bls: rowsAPI,
            id_mission: parseInt(idMission)
          }
        };
        const response = await axios(config);
        const missionList = response?.data?.data;
        if (missionList?.length > 0) {
          triggerInfosPopin('BL(s) already exist', constructBlMissionLink(missionList, rowsAPI), null, true);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const saveBLs = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      if (!!rowsBL != false) {
        var saveAndDelBLPromises = [];
        // Passed to CreateMission
        var tempRowsNewBl = [];
        for (var i = 0; i < rowsBL.length; i++) {
          if (rowsBL[i].bol) {
            var data = new FormData();
            data.append('id_mission', Number(idMission));

            data.append('number', rowsBL[i].bol);
            data.append('id_type_of_intervention', rowsBL[i].type_of_intervention);
            data.append('id_beans_type', rowsBL[i].beans_type);
            data.append('note', rowsBL[i].note);

            if (rowsBL[i].quantity) data.append('quantity', rowsBL[i].quantity);
            else data.append('quantity', 0);

            var deadline = ""; var delivery = "";
            if (rowsBL[i].deadline_date) deadline = momentDate(rowsBL[i].deadline_date).format("YYYY-MM-DD");
            if (rowsBL[i].delivery_date) delivery = momentDate(rowsBL[i].delivery_date).format("YYYY-MM-DD");

            data.append('deadline_date', deadline);
            data.append('delivery_date', delivery);
            data.append('smv', rowsBL[i].smv);

            if (rowsBL[i].signature_place) {
              if (rowsBL[i].signature_place.id) {
                data.append('id_country', parseInt(rowsBL[i].signature_place.id));
              } else {
                var country = countries.find((country) => { return country.id === parseInt(rowsBL[i].signature_place); });
                if (country) {
                  data.append('id_country', parseInt(rowsBL[i].signature_place));
                  //updating rowsBL to re render
                  rowsBL[i].signature_place = country;
                }
              }
            }
            else data.append('id_country', null);

            if (rowsBL[i].bl_date) {
              data.append('bl_date', momentDate(rowsBL[i].bl_date).format("YYYY-MM-DD"));
              //updating rowsBL to re render
              rowsBL[i].bl_date = momentDate(rowsBL[i].bl_date).format("YYYY-MM-DD");
            }
            else data.append('bl_date', null);

            var methode = 'post';
            if (rowsBL[i].id.toString().length < 16) {
              methode = 'put';
              data.append('id_mission_bl', rowsBL[i].id);
            }

            var jsonRequestDataBL = JSON.stringify(Object.fromEntries(data));
            var jsonRequestDataFinal = jsonRequestDataBL.replace(/"null"/g, 'null');

            var config = {
              url: MISSION_URL+'/bl',
              method: methode,
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
              },
              data: jsonRequestDataFinal
            };
            const response = await axios(config);
            saveAndDelBLPromises.push(response);

            if (rowsBL[i].id.toString().length > 16) {
              rowsBL[i].id = response.data.data.id;
              tempRowsNewBl.push(rowsBL[i]);
            }
          }
          else {
            console.log("TODO : BOL NUMBER NULL");
          }
        }
        if (tempRowsNewBl?.length > 0) {
          setNewRowsBL(tempRowsNewBl);
        }

        //Delete BL
        for (var i = 0; i < deletedRowsBL.length; i++) {
          var data = new FormData();
          data.append('id_mission', Number(idMission));
          data.append('id_mission_bl', deletedRowsBL[i].id);

          var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));

          var config = {
            url: MISSION_URL+'/bl',
            method: 'delete',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data: jsonRequestDataReceiver
          };
          const response = await axios(config);
          saveAndDelBLPromises.push(response);
        }

        //end treatment
        setDeletedRowsBL([]);
        await Promise.all(saveAndDelBLPromises);
        //refresh rows to re render all
        refreshBLs(rowsBL);

        if (justBlock) {
          setSnackBarMessage("Bills of Lading saved.");
          setSnackBarType("success");
          setOpenSnackBar(true);
          //reload data api
          await getMissionDetail();
        } else {
          setDoneBlockSave(blRef);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(blRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }


  return (
    <ContextMissionDetailsBL.Provider
      value=
      {{
        rowsBLView, setRowsBLView,
        rowsCheckBL, setRowsCheckBL,
        expandedRowsBLs, setExpandedRowsBLs,
        rowsSignaturePlace, setRowsSignaturePlace,
        rowsUnaffectedBls, setRowsUnaffectedBls,

        typesOfIntervention, setTypesOfIntervention,
        loadingTypesOfIntervention,

        beansTypes, setBeansType,
        loadBeansType,

        countries, setCountries,
        refreshBLs,

        handleClickCancel,
        saveBLs
      }}
    >
      {children}
    </ContextMissionDetailsBL.Provider>
  );
};

export { MissionDetailsBLProvider, ContextMissionDetailsBL };
