import React, { forwardRef, useContext, useState, useEffect } from 'react';

import ShippingInstructionReportList from './ShippingInstructionReportList';
import BlockFormSaveButton from '../../Block/BlockFormSaveButton';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionReport } from "./ContextShippingInstructionReport";


const ShippingInstructionReportForm = forwardRef((props, ref) => {

  //init config
  const { reportRef } = useContext(ContextShippingInstruction);

  //init control for save
  const [saveDisabled, setSaveDisable] = useState(false);
  const { blockGrid, getErrorBlockGrid } = useContext(ContextBlock);

  //handle
  const { saveShippingInstructionReport } = useContext(ContextShippingInstructionReport);
  const { handleClickCancel } = useContext(ContextShippingInstructionReport);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await saveShippingInstructionReport(true); 
    await handleClickCancel(false);
  };


  //useEffect
  useEffect(() => {
    setSaveDisable(getErrorBlockGrid(reportRef));
  }, [blockGrid]);


  return (
    <div>

      <ShippingInstructionReportList editable={true} />

      <BlockFormSaveButton blockRef={reportRef} blockCancel={async () => await handleClickCancel()} blockSave={handleClickSave} notWorkflowSaveButton={true} saveDisabled={saveDisabled} />

    </div>
  );
});

export default ShippingInstructionReportForm;
