import React, { useEffect, useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoIcon from '@mui/icons-material/Photo';
import VideoIcon from '@mui/icons-material/Theaters';
import FolderMuiIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';

import MoveIcon from 'imgs/move-grey.svg';
import FolderIcon from 'imgs/icon-folder.svg';
import EditIcon from 'imgs/icon-edit.svg';
import { formatDate } from 'components/page/object/Utils'
import ProtisPhoto from './MissionDetailsProtisPhoto';

const ProtisListItem = (props) => {

  const [elementDetails, setElementDetails] = useState(null);

  // Load all list elements
  useEffect(() => {
    setElementDetails(props.element);
  }, [props?.element]);

  return (
    <ListItemButton onClick={() => { props.element.isFolder ? props.handleOpenFolderClick(elementDetails) : props.handleViewElementClick(elementDetails, false); }} className="eventEnabled">
      <ListItem
        className={props.element.isFolder ? "folderItem" : props.element.isPhoto ? "photoItem" : props.element.isVideo ? "videoItem" : "documentItem"}
        secondaryAction={
          <>
           {props?.allowMoving &&
              <Tooltip title="Move" placement="top">
                <IconButton 
                  edge="end" 
                  aria-label="move" 
                  style={{ marginRight: '8px' }} 
                  onClick={(e) => { e.stopPropagation(); props?.handleMoveElementClick(elementDetails) }} 
                  className="eventEnabled"
                > 
                  <img src={MoveIcon} width={24} height={24} /> 
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Edit" placement="top">
              <IconButton 
                edge="end" 
                aria-label="edit" 
                style={{ marginRight: '8px' }} 
                onClick={(e) => { e.stopPropagation(); props?.handleViewElementClick(elementDetails) }} 
                className="eventEnabled"
              > 
                <img src={EditIcon} width={24} height={24} /> 
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <IconButton 
                edge="end" 
                aria-label="delete" 
                style={{ marginRight: '8px' }} 
                onClick={(e) => { e.stopPropagation(); props?.handleDeleteElementClick(elementDetails) }}
              > 
                <DeleteIcon /> 
              </IconButton>
            </Tooltip>
          </>
        }>
        {!props.element?.isDocument ? 
          (<ListItemIcon>
            {props.element?.isPhoto ?
              <ProtisPhoto
                {...props}
                element={props.element}
                handleLoadedPhoto={setElementDetails}
              />
              : (props.element?.isVideo ? (
                <PlayCircleOutlineIcon sx={{ width: '64px', height: "100%" }} fontSize={'large'} />
              ) : (
                <img src={FolderIcon} width={64} height={64} />
              ))}
          </ListItemIcon>) : null 
        }
        <ListItemText
          primary={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
              <h6><b>{props.element?.isFolder ? props.element?.label : props.element?.type?.label}</b></h6>
              <div>
                <div style={{ display: 'inline', color: 'grey', marginRight: '25px' }}>
                  {props.element?.isFolder && props.element.containers?.length > 0 &&
                    <div style={{ display: 'inline' }}><b style={{ fontSize: '14px' }}>{props.element.containers?.length}</b>
                      <FolderMuiIcon width={10} height={10} color={'lightGray'} /></div>}
                  {props.element?.isFolder && props.element.photos?.length > 0 &&
                    <div style={{ display: 'inline' }}><b style={{ fontSize: '14px' }}>{props.element.photos?.length}</b>
                      <PhotoIcon width={10} height={10} color={'lightGray'} /></div>}
                  {props.element?.isDocument && props.element.pagesCount > 0 &&
                    <div style={{ display: 'inline' }}><b style={{ fontSize: '14px' }}>{props.element.pagesCount}</b>
                      <DescriptionIcon width={10} height={10} color={'lightGray'} /></div>}
                  {props.element?.isFolder && props?.protisFullTree?.videos.some(video => video.id_mission_container == props.element.id) && // Fixes absence of videos in element.videos
                    <div style={{ display: 'inline' }}><b style={{ fontSize: '14px' }}>{props?.protisFullTree?.videos.filter(video => video.id_mission_container == props.element.id).length}</b>
                      <VideoIcon width={10} height={10} color={'lightGray'} /></div>}
                </div>
                <b style={{ fontSize: '14px' }}>{props.element.updated_at ? formatDate(props.element.updated_at) : formatDate(props.element.created_at)}</b>
              </div>
            </div>
          }
          secondary={props.element.comment}
          style={{ marginLeft: '15px', maxWidth: '80%' }} />
      </ListItem>
    </ListItemButton>
  );

};

export default ProtisListItem;
