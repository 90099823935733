import React, { useContext } from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { useDeepCompareMemo } from 'use-deep-compare';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ContextAnalytics } from "../../../../context/ContextAnalytics";
import '../../../../styles/Analytics.css';

const ArchivedMissionsFilesTableChart = (props) => {
    
    const {cubejsApi} = useContext(ContextAnalytics);

    // Formats data to be used in the table
    const formatTableData = (columns, data) => {
        function flatten(columns = []) {
            return columns.reduce((memo, column) => {
            if (column.children) {
                return [...memo, ...flatten(column.children)];
            }

            return [...memo, column];
            }, []);
        }

        const typeByIndex = flatten(columns).reduce((memo, column) => { 
            return { ...memo, [column.dataIndex]: column };
        }, {});

        function formatValue(value, { type, format } = {}) {
            if (value == undefined) {
                return value;
            }

            if (type === 'boolean') {
                if (typeof value === 'boolean') {
                    return value.toString();
                } else if (typeof value === 'number') {
                    return Boolean(value).toString();
                }

                return value;
            }

            if (type === 'number' && format === 'percent') {
                return [parseFloat(value).toFixed(2), ' %'].join('');
            }

            return value.toString();
        }

        function format(row) {
            return Object.fromEntries(
                Object.entries(row).map(([dataIndex, value]) => {
                    return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
                })
            );
        }
      
        return data.map(format);
    };

    // Renders a table based on data's columns and rows
    const TableRenderer = ({ resultSet, pivotConfig }) => {
        const [tableColumns, dataSource] = useDeepCompareMemo(() => {
            const columns = resultSet.tableColumns(pivotConfig);
            return [
                columns,
                formatTableData(columns, resultSet.tablePivot(pivotConfig)),
            ];
        }, [resultSet, pivotConfig]);
    
        return (
            <div className='chartTable'>
                <div className='chartTableHead'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column, index) => (
                                    <TableCell key={index} align={index === 0 ? "left" : "right"}>
                                        <Chip label={column?.shortTitle} variant="outlined" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className='chartTableBody'>
                    <Table className='chartTableComponent'>
                        <TableBody>
                            {dataSource.map((source, index) => (
                                <TableRow
                                key={index}
                                hover
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                                }}
                                >
                                    <TableCell>
                                        {source['mission.jlb_ref']}
                                    </TableCell>
                                    <TableCell align="center">
                                        {source['mission.total_files_count']}
                                    </TableCell>
                                    <TableCell align="right">
                                        {source['mission.total_mandatory_files_count']}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    };

    // Renders a table or loading in progress
    const renderChart = ({ resultSet, error, pivotConfig, onDrilldownRequested }) => {

        return (
            (resultSet) ?
                (
                    <TableRenderer 
                        resultSet={resultSet} 
                        pivotConfig={pivotConfig} 
                    />
                )
                
            : 
                (
                    <div className="loadingChartContainer">
                        <CircularProgress/>
                    </div>
                )
        );
    };
    
    return (
        <div className='chartTableContainer'>
            {(props.formattedQueryStartDate && props.formattedQueryEndDate) &&
                // CubeJS query
                <QueryRenderer
                    query={{
                        limit: 5000,
                        order: [
                            [ "mission.total_files_count", "desc" ],
                            [ "mission.total_mandatory_files_count", "desc" ],
                        ],
                        timeDimensions: [{
                            dimension: "mission.created_at",
                            dateRange: [ props.formattedQueryStartDate, props.formattedQueryEndDate ]
                        }],
                        dimensions: [ 
                            "mission.jlb_ref",
                            "mission.total_files_count",
                            "mission.total_mandatory_files_count"
                        ],
                        segments: [
                            "mission.archived_missions",
                            "mission.missions_without_all_mandatory_files"
                        ],
                        filters: props?.hideCanceledMissions ?
                            [{
                                member: "mission.is_canceled",
                                operator: "equals",
                                values: ["false" ]
                            }]
                        : 
                            []
                    }}
                    cubejsApi={cubejsApi}
                    resetResultSetOnChange={false}
                    render={(props) => renderChart({
                        ...props,
                        chartType: 'table',
                        pivotConfig: {
                            x:  [
                                "mission.jlb_ref",
                                "mission.total_files_count",
                                "mission.total_mandatory_files_count"
                            ],
                            y: [],
                            fillMissingDates: true,
                            joinDateRange: false
                        }
                    })}
                />
            }
        </div>
    );
}

export default ArchivedMissionsFilesTableChart;
      