import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import LaunchIcon from '@mui/icons-material/Launch';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextBlockTreatment } from "context/ContextBlockTreatment";

import axios from 'api/axios';


const ContextShippingInstructionBL = createContext();

const ShippingInstructionBLProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { blRef } = useContext(ContextShippingInstruction);
  const { getShippingInstruction } = useContext(ContextShippingInstruction);

  //API URL
  const SI_BL_URL = 'shipping_instruction/si_bl';
  const SI_BL_EXIST_URL = 'shipping_instruction/si_bl/exist';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //init id si
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //si bl state
  const { bls, setBls } = useContext(ContextShippingInstruction);

  const [rowsSiBls, setRowsSiBls] = useState([]);
  const [rowsCheckSiBL, setRowsCheckSiBL] = useState([]);
  const [rowsSiBlsDeleted, setRowsSiBlsDeleted] = useState([]);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const { saveBlock } = useContext(ContextBlockTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async (reload = true) => {
    handleClickBlockCancel(blRef, reload);
  }


  //INIT mission data
  useEffect(() => {
    //load data
    if (bls) {
      if (bls.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit = blockEdit.findIndex(obj => (obj.blockRef === blRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadData > oldReloadData) {
            setOldReloadData(reloadData);
          }

          //INIT BLs List
          var rowsFromApi = [];
          if (bls.length > 0) {
            for (var i = 0; i < bls.length; i++) {
              var missionAssoc = [];
              for (var mi = 0; mi < bls[i].missions?.length; mi++) {
                missionAssoc[mi] = bls[i].missions[mi].id;
              }

              rowsFromApi[i] = {
                id: bls[i].id,
                id_vessel: bls[i].vessel?.id,
                id_si_report: bls[i].si_report?.id,
                id_beans_type: bls[i].beans_type?.id,
                id_type_of_intervention: bls[i].type_of_intervention?.id,

                deadline_date: bls[i].deadline_date,
                delivery_date: bls[i].delivery_date,
                bl_date: bls[i].bl_date,

                type_of_damage: bls[i].type_of_damage,
                quantity: bls[i].quantity,
                tonnage: bls[i].tonnage,
                number: bls[i].number,
                smv: bls[i].smv,
                note: bls.note,

                missions: missionAssoc
              };
            }
            rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
          }
          setRowsSiBls(rowsFromApi);
        }
      }
      else setRowsSiBls([]);
    }
    else setRowsSiBls([]);
  }, [bls, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(blRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockEdit.findIndex(obj => obj.blockRef === blRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlock(blRef, saveShippingInstructionBL);
  }, [blockSave, idShippingInstruction]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockSave.findIndex(obj => obj.blockRef === blRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);

  //init check bl already exist
  useEffect(() => {
    if (rowsSiBls.length > 0 && rowsCheckSiBL.length > 0) {
      //check if checkBl exist in rowsBl
      var checkBl = true;
      rowsCheckSiBL.map((blId) => {
        const existBl = rowsSiBls.findIndex((rows) => rows.id === blId);
        if (existBl <= -1) {
          checkBl = false;
        }
      });
      if (checkBl) processRowsCheckSiBL(rowsCheckSiBL);
    }
  }, [rowsSiBls, rowsCheckSiBL]);


  //function interactive
  const processRowUpdateSiBL = (newRow, checkData = false) => {
    setRowsSiBls(rowsSiBls.map((row) => (row.id === newRow.id ? newRow : row)));
    if (checkData) {
      setRowsCheckSiBL([newRow.id]);
    }
    return newRow;
  }

  const constructBlSiLink = (siList, blList) => {
    return (
      <>
        BL(s) with the same date(s) and number(s) is already present in the following SI(s) :
        <div className="info_content">
          <ul>
            {siList.map((si) => {
              const blNumbers = [];
              si.si_bls.map((bl) => {
                if (blList.find(e => bl.bl_date === momentDate(e.bl_date).format("YYYY-MM-DD") && bl.number === e.number)) {
                  blNumbers.push(bl.number);
                }
              });

              return (
                <li className="withIcon">
                  <a href={'/shippingInstructionDetails?id=' + si.id} target="_blank">
                    <LaunchIcon /> {si.contract_number} ({blNumbers.join(' - ')})
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }

  const processRowsCheckSiBL = async (rows) => {
    try {
      const rowsCheck = rowsSiBls.filter((bl) => rows.includes(bl.id) && !!bl.bl_date !== false && momentDate(bl.bl_date).isValid() && !!bl.number !== false);
      //check data if not empty
      if (rowsCheck && rowsCheck.length > 0) {
        //limit and format data
        const rowsAPI = [];
        rowsCheck.map((row) => {
          rowsAPI.push({
            number: row.number,
            bl_date: row.bl_date
          });
        });

        var config = {
          url: SI_BL_EXIST_URL,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          params: {
            bls: rowsAPI,
            id_shipping_instruction: parseInt(idShippingInstruction)
          }
        };
        const response = await axios(config);
        const siList = response?.data?.data;
        if (siList?.length > 0) {
          triggerInfosPopin('BL(s) already exist', constructBlSiLink(siList, rowsAPI), null, true);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const saveShippingInstructionBL = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var saveSiBLPromises = [];
      //Saving SI BL
      for (var i = 0; i < rowsSiBls.length; i++) {
        var data = {
          id_shipping_instruction: parseInt(idShippingInstruction),
          smv: rowsSiBls[i].smv,
          note: rowsSiBls[i].note,
          number: rowsSiBls[i].number,
          tonnage: Number(rowsSiBls[i].tonnage),
          quantity: Number(rowsSiBls[i].quantity),
          type_of_damage: rowsSiBls[i].type_of_damage,
          id_vessel: rowsSiBls[i].id_vessel ? rowsSiBls[i].id_vessel : null,
          id_si_report: rowsSiBls[i].id_si_report ? rowsSiBls[i].id_si_report : null,
          id_beans_type: rowsSiBls[i].id_beans_type ? rowsSiBls[i].id_beans_type : null,
          id_type_of_intervention: rowsSiBls[i].id_type_of_intervention ? rowsSiBls[i].id_type_of_intervention : null,
          bl_date: rowsSiBls[i].bl_date ? momentDate(rowsSiBls[i].bl_date).format("YYYY-MM-DD") : null,
          deadline_date: rowsSiBls[i].deadline_date ? momentDate(rowsSiBls[i].deadline_date).format("YYYY-MM-DD") : null,
          delivery_date: rowsSiBls[i].delivery_date ? momentDate(rowsSiBls[i].delivery_date).format("YYYY-MM-DD") : null,

          missions: rowsSiBls[i].missions ? rowsSiBls[i].missions : null
        };

        var methode = 'post';
        if (rowsSiBls[i].id.toString().length < 16) {
          methode = 'put';
          data.id_si_bl = rowsSiBls[i].id;
        }

        var config = {
          url: SI_BL_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveSiBLPromises.push(response);

        if (rowsSiBls[i].id.toString().length > 16) {
          rowsSiBls[i].id = response.data.data.id;
          processRowUpdateSiBL(rowsSiBls[i]);
        }
      }

      //Deleting Mission Instruction
      for (var i = 0; i < rowsSiBlsDeleted.length; i++) {
        var config = {
          url: SI_BL_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_si_bl: rowsSiBlsDeleted[i].id }
        };
        const response = await axios(config);
        saveSiBLPromises.push(response);
      }

      //end treatment
      setRowsSiBlsDeleted([]);
      await Promise.all(saveSiBLPromises);

      if (justBlock) {
        setSnackBarMessage("BLs saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getShippingInstruction();
      } else {
        setDoneBlockSave(blRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(blRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }


  return (
    <ContextShippingInstructionBL.Provider
      value=
      {{
        rowsSiBls, setRowsSiBls,
        rowsCheckSiBL, setRowsCheckSiBL,
        rowsSiBlsDeleted, setRowsSiBlsDeleted,

        handleClickCancel,
        processRowUpdateSiBL,
        saveShippingInstructionBL
      }}
    >
      {children}
    </ContextShippingInstructionBL.Provider>
  );
};

export { ShippingInstructionBLProvider, ContextShippingInstructionBL };