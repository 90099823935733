import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { forwardRef, useImperativeHandle } from "react";

import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom } from 'components/page/object/DataGridProCustom';
import FormControl from "@mui/material/FormControl";
import { randomId } from "@mui/x-data-grid-generator";
import Button from "@mui/material/Button";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Radio from "@mui/material/Radio";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import "bootstrap/dist/css/bootstrap.css";

import axios from "api/axios";

const ContactForm = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [rowsCompanyContacts, setRowsCompanyContacts] = React.useState([]);
  const [rowsCompanyContactsDeleted, setRowsCompanyContactsDeleted] = React.useState([]);
  const [companyContactsTypes, setCompanyContactsTypes] = React.useState([]);

  const [contactFormFirstNameError, setContactFormFirstNameError] = React.useState({});
  const [contactFormLastNameError, setContactFormLastNameError] = React.useState({});
  const [contactFormEmailError, setContactFormEmailError] = React.useState({});
  const [contactFormPhoneError, setContactFormPhoneError] = React.useState({});

  const GET_COMPANY_CONTACTS_LIST_BY_ID_URL = "company/" + props.idCompany + "/contacts/list";
  const GET_COMPANY_CONTACTS_TYPES_URL = "company/contacts/types";
  const POSTORPUTCONTACT_URL = "/company/contacts";
  const DELETECONTACT_URL = "/company/contacts";

  useEffect(() => {
    loadingCompanyContactsTypes();
    if(props.update == 1) {
      loadingCompanyContacts();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    async saveComponent(idCompanyParam) {
      await saveContactForm(idCompanyParam);
    },
  }));

  // BUTTON DISABLE CONTROL
  useEffect(() => {
    const validateFields = () => {
      return (
        Object.values(contactFormFirstNameError).some((value) => value === true) ||
        Object.values(contactFormLastNameError).some((value) => value === true) ||
        Object.values(contactFormEmailError).some((value) => value === true) ||
        Object.values(contactFormPhoneError).some((value) => value === true)
      );
    };
    
    if (props.setComponentTreatmentNotValidated) {
      props.setComponentTreatmentNotValidated(validateFields());
    }
  }, [contactFormFirstNameError, contactFormLastNameError, contactFormEmailError, contactFormPhoneError]);
  

  const saveContactForm = async (idCompanyParam) => {
    await saveContact(idCompanyParam);
    await deleteContact();
  };

  const loadingCompanyContactsTypes = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var url = GET_COMPANY_CONTACTS_TYPES_URL;

      const response = await axios.get(url, config);
      const tempAllCompanyContactsTypes = response.data.map((contactType) => ({
        id: contactType.id,
        value: contactType.label,
        label: contactType.label,
      }));

      tempAllCompanyContactsTypes.sort((a, b) => (a.label > b.label ? 1 : -1));

      const emptyOption = {
        id: '',
        value: '',
        label: '-', 
      };

      tempAllCompanyContactsTypes.unshift(emptyOption); // Adds an empty option to the beginning of the list
      setCompanyContactsTypes(tempAllCompanyContactsTypes);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage(
          "Technical error ! Please try again or contact our support."
        );
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  };

  const loadingCompanyContacts = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      };
      var url = GET_COMPANY_CONTACTS_LIST_BY_ID_URL;

      const response = await axios.get(url, config);
      
      if (response.data.data.length > 0) {
        const tempCompanyContacts = response.data.data.map(
          (contact) => {
            if (contact?.firstname?.length == 0) {
              setContactFormFirstNameError((prevState) => ({
                ...prevState,
                [contact.id]: true,
              }));
            }
            if (contact?.lastname?.length == 0) {
              setContactFormLastNameError((prevState) => ({
                ...prevState,
                [contact.id]: true,
              }));
            }
            return {
              id: contact.id,
              contact_firstname: contact.firstname,
              contact_lastname: contact.lastname,
              contact_email: contact.email,
              contact_phone_number: contact.telephone,
              contact_type: contact.contact_type?.label,
              contact_default: contact.default_contact,
            };
          }
        );
        setRowsCompanyContacts(tempCompanyContacts);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage(
          "Technical error ! Please try again or contact our support."
        );
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  };


  const saveContact = async (idCompanyParam) => {
    try {
      if (rowsCompanyContacts && rowsCompanyContacts.length > 0) {
        var saveContactPromises = [];
        for (var i = 0; i < rowsCompanyContacts.length; i++) {
          var data = new FormData();
          data.append("id_company", idCompanyParam);
          data.append("id_contact", rowsCompanyContacts[i].id);

          data.append("firstname", rowsCompanyContacts[i].contact_firstname);
          data.append("lastname", rowsCompanyContacts[i].contact_lastname);
          data.append("email", rowsCompanyContacts[i].contact_email);
          data.append("telephone", rowsCompanyContacts[i].contact_phone_number);
          data.append(
            "default_contact",
            rowsCompanyContacts[i].contact_default ? "1" : "0"
          );

          const contactType = companyContactsTypes.find(
            (contactType) =>
              contactType.label === rowsCompanyContacts[i].contact_type
          );
          
          if (contactType) {
            data.append("id_type", contactType.id);
          } else if (rowsCompanyContacts[i].contact_type === "") {
            data.append("id_type", "null");
          }
          
          var jsonRequestData = JSON.stringify(Object.fromEntries(data));
          var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, "null");

          var axiosMethod;

          if (rowsCompanyContacts[i].isUpdated) {
            axiosMethod = "put";
          } else if (rowsCompanyContacts[i].isNew) {
            axiosMethod = "post";
          } else {
            continue;
          }

          var config = {
            url: POSTORPUTCONTACT_URL,
            method: axiosMethod,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
            data: jsonRequestDataFinal,
          };
          const response = await axios(config);
          saveContactPromises.push(response);
        }
        await Promise.all(saveContactPromises);
      }

    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage(
          "Technical error ! Please try again or contact our support."
        );
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  };

  const deleteContact = async () => {
    try {
      if (rowsCompanyContactsDeleted && rowsCompanyContactsDeleted.length > 0) {
        var delContactPromises = [];
        for (var i = 0; i < rowsCompanyContactsDeleted.length; i++) {
          var data = new FormData();
          data.append("id_contact", rowsCompanyContactsDeleted[i].id);

          var jsonRequestDataContact = JSON.stringify(Object.fromEntries(data));

          var config = {
            url: DELETECONTACT_URL,
            method: "delete",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
            data: jsonRequestDataContact,
          };
          const response = await axios(config);
          delContactPromises.push(response);
        }
        await Promise.all(delContactPromises);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage(
          "Technical error ! Please try again or contact our support."
        );
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleContactDefaultChange = (id) => {
    const updatedRows = rowsCompanyContacts.map((row) => {
      if (row.id === id) {
        if (row.isNew) {
          return {
            ...row,
            contact_default: 1,
          };
        } else {
          return {
            ...row,
            contact_default: 1,
            isUpdated: row.isUpdated ? !row.isUpdated : true,
          };
        }
      } else {
        if (row.isNew) {
          return {
            ...row,
            contact_default: 0,
          };
        } else {
          return {
            ...row,
            contact_default: 0,
            isUpdated: row.isUpdated ? !row.isUpdated : true,
          };
        }
      }
    });
    setRowsCompanyContacts(updatedRows);
  };

  function CustomNoRowsOverlay() {
    return <div className="customNoRowsOverlay"></div>;
  }

  var listColumnsContacts = [];

  listColumnsContacts.push(
    {
      field: "delete_column",
      headerName: "",
      editable: false,
      sortable: false,
      width: 10,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              deleteRowCompanyContacts(event, cellValues.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
    {
      field: "contact_firstname",
      headerName: "First name",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderCell: (cellValues) => {
        if (cellValues.value?.trim() === "" || cellValues.value == null) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid rgb(198, 26, 36)",
                borderRadius: "4px",
                width: "100%",
                height: "26px",
              }}
            >
              <Skeleton variant="text" sx={{ height: "40px", width: "100%" }} />
            </div>
          );
        }
      },
      // SAVE BUTTON DISABLE CONTROL
      preProcessEditCellProps: async (params) => {
        if (params.props.value?.trim() === "" || params.props.value == null) {
          setContactFormFirstNameError((prevState) => ({
            ...prevState,
            [params.row.id]: true,
          }));
          return { ...params.props };
        }
          setContactFormFirstNameError((prevState) => ({
          ...prevState,
          [params.row.id]: false,
        }));
        return { ...params.props };
      },
    },

    {
      field: "contact_lastname",
      headerName: "Last name",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderCell: (cellValues) => {
        if (cellValues.value?.trim() === "" || cellValues.value == null) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid rgb(198, 26, 36)",
                borderRadius: "4px",
                width: "100%",
                height: "26px",
              }}
            >
              <Skeleton variant="text" sx={{ height: "40px", width: "100%" }} />
            </div>
          );
        }
      },
      // SAVE BUTTON DISABLE CONTROL
      preProcessEditCellProps: async (params) => {
        if (params.props.value?.trim() === "" || params.props.value == null) {
          setContactFormLastNameError((prevState) => ({
            ...prevState,
            [params.row.id]: true,
          }));
          return { ...params.props };
        }
        setContactFormLastNameError((prevState) => ({
          ...prevState,
          [params.row.id]: false,
        }));
        return { ...params.props };
      },
    },

    {
      field: "contact_email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderCell: (cellValues) => {
        if (cellValues.value?.trim() === "" || cellValues.value == null) {
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: "40px", width: "100%" }} />
            </Tooltip>
          );
        } else if (contactFormEmailError[cellValues.id]) {
          return (
            <div
              style={{
                display: "inline-block",
                border: "2px solid rgb(198, 26, 36)",
                padding: "4px",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              <div style={{ color: "rgb(198, 26, 36)" }}>
                {cellValues.value}
              </div>
            </div>
          );
        }
      },
      // SAVE BUTTON DISABLE CONTROL
      preProcessEditCellProps: async (params) => {
        if (params.props.value?.trim() === "" || params.props.value == null) {
          setContactFormEmailError((prevState) => ({
            ...prevState,
            [params.row.id]: false,
          }));
          return { ...params.props };
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const result = emailRegex.test(params.props.value.toString());
        setContactFormEmailError((prevState) => ({
          ...prevState,
          [params.row.id]: !result,
        }));
        return { ...params.props };
      },
    },
    {
      field: "contact_phone_number",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderCell: (cellValues) => {
        if (cellValues.value?.trim() === "" || cellValues.value == null) {
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: "40px", width: "100%" }} />
            </Tooltip>
          );
        } else if (contactFormPhoneError[cellValues.id]) {
          return (
            <div
              style={{
                display: "inline-block",
                border: "2px solid rgb(198, 26, 36)",
                padding: "4px",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              <div style={{ color: "rgb(198, 26, 36)" }}>
                {cellValues.value}
              </div>
            </div>
          );
        }
      },
      // SAVE BUTTON DISABLE CONTROL
      preProcessEditCellProps: async (params) => {
        if (params.props.value?.trim() === "" || params.props.value == null) {
          setContactFormPhoneError((prevState) => ({
            ...prevState,
            [params.row.id]: false,
          }));
          return { ...params.props };
        }
        const phoneRegex = /^[\d\s()+]+$/;
        const result = phoneRegex.test(params.props.value.toString());
        setContactFormPhoneError((prevState) => ({
          ...prevState,
          [params.row.id]: !result,
        }));
        return { ...params.props };
      },
    },
    {
      field: "contact_type",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      valueOptions: companyContactsTypes,
      editable: true,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        if (cellValues.value === "" || cellValues.value == undefined)
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: "40px", width: "100%" }} />
            </Tooltip>
          );
      },
    },
    {
      field: "contact_default",
      headerName: "Default",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        return (
          <Radio
            checked={Boolean(cellValues.value)}
            onChange={() => handleContactDefaultChange(cellValues.row.id)}
            name="radio-buttons"
            inputProps={{ "aria-label": "Default contact" }}
          />
        );
      },
    }
  );

  const columnsContacts: GridColDef[] = listColumnsContacts;

  const processRowUpdateContact = (newRow) => {
    var updatedRow;
    if (newRow.id.length > 16) {
      var updatedRow = { ...newRow, isNew: true };
    } else {
      var updatedRow = { ...newRow, isUpdated: true };
    }

    setRowsCompanyContacts(
      rowsCompanyContacts.map((row) =>
        row.id === updatedRow.id ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const deleteRowCompanyContacts = (event, id) => {
    setRowsCompanyContacts(rowsCompanyContacts.filter((row) => row.id !== id));
    if (id.toString().length < 16) {
      setRowsCompanyContactsDeleted((oldRows) => [...oldRows, { id: id }]);
    }
    // SAVE BUTTON DISABLE CONTROL
    setContactFormFirstNameError((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    setContactFormLastNameError((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    setContactFormEmailError((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    setContactFormPhoneError((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  function EditToolbarContacts(props) {
    const {
      rowsCompanyContacts,
      setRowsCompanyContacts,
      setContactFormFirstNameError,
      setContactFormLastNameError,
    } = props;
    const isFirstCreatedRow = rowsCompanyContacts.length == 0;

    const handleClick = () => {
      const id = randomId();
      setRowsCompanyContacts((oldRows) => [
        ...oldRows,
        {
          id: id,
          contact_firstname: "",
          contact_lastname: "",
          contact_email: "",
          contact_phone_number: "",
          contact_type: "",
          contact_default: isFirstCreatedRow ? true : false,
          isNew: true,
        },
      ]);
      setContactFormFirstNameError((prevState) => ({
        ...prevState,
        [id]: true,
      }));
      setContactFormLastNameError((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    };

    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button
            className="iconAddButton"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add Contact
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <FormControl
      sx={{ width: "100%", height: "10%" }}
      size="small"
      id="datagrid-contacts"
    >
      <DataGridPro
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdateContact}
        experimentalFeatures={{ newEditingApi: true }}
        columns={columnsContacts}
        rows={rowsCompanyContacts}
        autoHeight
        hideFooter={true}
        componentsProps={{
          toolbar: { rowsCompanyContacts, setRowsCompanyContacts, setContactFormFirstNameError,setContactFormLastNameError },
        }}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: EditToolbarContacts,
        }}
      />
    </FormControl>
  );
});

export default ContactForm;
