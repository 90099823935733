import React, { useState, createContext } from "react";

const ContextFilter = createContext();

const FilterProvider = ({ children }) => {

  const [filterOn, setFilterOn] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [dataGridLoaded, setDataGridLoaded] = useState(false);

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [visibleRows, setVisibleRows] = useState([]);
  const [visibleCODRows, setVisibleCODRows] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  
  const [filterDatas, setFilterDatas] = useState({ survey: '', principals: '', missionType: '', port: '', insured: '', receiver: '', shipper: '', vessel: '', contractNumber: '', cancel: '' });
  const [filterStatusArray, setFilterStatusArray] = useState([]);
  const [category, setCategory] = useState('');
  const [searchTxt, setSearchTxt] = useState('');
  const [searchTxtState, setSearchTxtState] = useState('');

  const [vesselRows, setVesselRows] = useState([]);
  const [surveyRows, setSurveyRows] = useState([]);
  const [principalRows, setPrincipalRows] = useState([]);
  const [missionRows, setMissionRows] = useState([]);
  const [portRows, setPortRows] = useState([]);
  const [insuredRows, setInsuredRows] = useState([]);
  const [shipperRows, setShipperRows] = useState([]);
  const [receiverRows, setReceiverRows] = useState([]);
  const [contractNumberRows, setContractNumberRows] = useState([]);

  return (
    <ContextFilter.Provider
      value=
      {{
        filterOn, setFilterOn,
        filterModel, setFilterModel,
        filterDatas, setFilterDatas,
        filterStatusArray, setFilterStatusArray,
        category, setCategory,
        searchTxt, setSearchTxt,
        searchTxtState, setSearchTxtState,
        dataGridLoaded, setDataGridLoaded,
        loadingGrid, setLoadingGrid,
        rows, setRows,
        totalRows, setTotalRows, 
        vesselRows, setVesselRows,
        surveyRows, setSurveyRows,
        principalRows, setPrincipalRows,
        missionRows, setMissionRows,
        portRows, setPortRows,
        insuredRows, setInsuredRows,
        shipperRows, setShipperRows,
        receiverRows, setReceiverRows,
        visibleRows, setVisibleRows,
        visibleCODRows, setVisibleCODRows,
        contractNumberRows, setContractNumberRows,
        isDownload, setIsDownload
      }}
    >
      {children}
    </ContextFilter.Provider>
  );
};

export { FilterProvider, ContextFilter };
