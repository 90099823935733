import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";

import ShippingInstructionBLForm from './ShippingInstructionBLForm';
import ShippingInstructionBLView from "./ShippingInstructionBLView";


const ShippingInstructionBL = forwardRef((props, ref) => {

  //init config
  const { update } = useContext(ContextBlock);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { toogleBlockOnEdit } = useContext(ContextShippingInstruction);


  //useEffect
  useEffect(() => {
    toogleBlockOnEdit(ref, onEdit, setOnEdit);
  }, [blockEdit]);


  if (update == 1) {
    return (
      <div id="block_bl" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
        <h4>Bills of Lading</h4>

        {(!onEdit) ? <ShippingInstructionBLView /> : <ShippingInstructionBLForm />}

      </div>
    );
  }
});

export default ShippingInstructionBL;
